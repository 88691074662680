<prosumer-progress-card [showProgress]="loading$ | async">
  <form
    [formGroup]="energyVectorForm"
    (ngSubmit)="onConfirm()"
    [attr.data-testid]="'energy-vector-dialog-form'"
  >
    <h2
      class="mat-title"
      mat-dialog-title
      [attr.data-testid]="'energy-vector-dialog-title'"
    >
      {{ data?.mode | titlecase }}
      {{ 'Scenario.labels.energyVectors.custom' | translate }}
    </h2>
    <div class="mat-body" mat-dialog-content>
      <div fxLayout="column" fxLayoutGap="10px">
        <prosumer-input
          [placeholder]="'Scenario.placeholders.energyVectors.name' | translate"
          [label]="'Scenario.labels.energyVectors.name' | translate"
          [control]="energyVectorForm?.controls?.name"
          [required]="true"
          [errorMessageMap]="errorMessages?.name"
          [attr.data-testid]="'energy-vector-dialog-name-input'"
        >
        </prosumer-input>
        <prosumer-select
          [placeholder]="'Scenario.placeholders.energyVectors.type' | translate"
          [label]="'Scenario.labels.energyVectors.type' | translate"
          [options]="energyVectorTypeOptions"
          [control]="energyVectorForm?.controls?.type"
          [required]="true"
          [errorMessageMap]="errorMessages?.type"
          [tooltip]="'wizard_global.wizard_global_energy_vector_type'"
          [attr.data-testid]="'energy-vector-dialog-type-select'"
        >
        </prosumer-select>
      </div>
    </div>
    <div mat-dialog-actions fxLayout="row">
      <button
        mat-flat-button
        type="submit"
        color="primary"
        [disabled]="energyVectorForm.invalid || (loading$ | async)"
        [attr.data-testid]="'energy-vector-dialog-confirm'"
      >
        {{ 'Generic.labels.ok' | translate }}
      </button>
      <button
        mat-flat-button
        type="button"
        color=""
        (click)="onClose()"
        [attr.data-testid]="'energy-vector-dialog-cancel'"
      >
        {{ 'Generic.labels.cancel' | translate }}
      </button>
    </div>
  </form>
</prosumer-progress-card>
