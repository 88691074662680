import { DialogService } from 'prosumer-app/libs/eyes-core';

import { _isNumberValue, coerceNumberProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Optional,
  Self,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  NgControl,
  Validators,
} from '@angular/forms';
import { ActionInputComponent } from 'prosumer-app/libs/eyes-shared/components/action-input';
import { YearlyChartDialogComponent } from './yearly-chart-dialog';

import { YearlyValues } from 'prosumer-app/shared/models/yearly-values.model';
import { YearlyLoadsValidator } from 'prosumer-app/shared/validators/yearly-loads';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { convertToYearlyValues } from '../../utils';

@Component({
  selector: 'prosumer-yearly-chart-input',
  templateUrl: './yearly-chart-input.component.html',
  styleUrls: ['./yearly-chart-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearlyChartInputComponent
  extends ActionInputComponent
  implements AfterViewInit
{
  @Input() contextHelpMsg: string;
  @Input() errorMessage: string;
  @Input() startYear: string;
  @Input() endYear: string;
  @Input() notRequired = false;
  @Input() isViewOnly = false;
  @Input() processing = false;
  @Input() minValue = 0;

  iconPrefix = 'assessment';
  inputValueControl: FormControl = this.formBuilder.control(
    { value: null, disabled: false, required: !this.notRequired },
    [!this.notRequired && Validators.required],
  );
  hasMultipleValues = false;

  @Input() inputLabel: string;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: FormBuilder,
    private _dialogService: DialogService,
  ) {
    super(ngControl, changeDetector, formBuilder);
  }

  /**
   * Clears all the value for each year.
   */
  clearValues(): void {
    if (this.hasMultipleValues) {
      this.hasMultipleValues = false;
      this.inputValueControl.enable({ onlySelf: true, emitEvent: true });
      this.inputValueControl.patchValue('');
      this.inputValueControl.markAsTouched({ onlySelf: true });
      this.inputValueControl.markAsDirty({ onlySelf: true });
    }
  }

  /**
   * This should open dialog box and perform action on data.
   */
  performAction(): void {
    this._dialogService
      .openDialog(YearlyChartDialogComponent, {
        yearlyValues: this.control.value,
        startYear: this.startYear,
        endYear: this.endYear,
        width: 'auto',
        disableClose: true,
        isViewOnly: this.isViewOnly,
        minValue: this.minValue,
      })
      .pipe(filter((data) => !!data))
      .subscribe((data) => {
        this.control.patchValue(data);
        this.control.markAsDirty();
      });
  }

  ngAfterViewInit(): void {
    const controlValidators = [];

    if (!this.notRequired) {
      controlValidators.push(Validators.required);
    }

    if (this.minValue !== undefined) {
      controlValidators.push(
        YearlyLoadsValidator.yearlyValuesMin(this.minValue),
      );
    }

    this.initValidatorsOfControl(controlValidators);
    this.inputValueControl.setValidators(controlValidators);
    this.inputValueControl.updateValueAndValidity();
    this.subToInputChanges();
  }

  private subToInputChanges() {
    this.inputValueControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter((value) => [this.notRequired, !!value].some(Boolean)),
        this.takeUntilShare(),
        tap((_) => this.inputValueControl.markAsTouched()),
      )
      .subscribe((value) =>
        this.onChange(this.setConstantYearlyValues(value, this.control.value)),
      );
  }

  private initValidatorsOfControl(controlValidators) {
    this.control.setValidators(controlValidators);
    this.control.updateValueAndValidity();
  }

  /**
   * This method sets the yearly values with the given new value.
   * Replaces the old value with the new value as long as the newYearlyVal param is valid.
   * Otherwise, returns null.
   *
   * @param newYearlyVal new yearly value from the input text field.
   * @param yearlyValDict old yearly-value map.
   * @return YearlValues with new value, null if the newYearlyVal is invalid.
   */
  setConstantYearlyValues(newYearlyVal: unknown, yearlyValDict): YearlyValues {
    let newYearlyValStr = String(newYearlyVal);
    if (
      newYearlyValStr !== 'null' &&
      newYearlyValStr !== 'undefined' &&
      newYearlyValStr.trim().length !== 0 &&
      (newYearlyValStr.length > 0 ? _isNumberValue(newYearlyValStr) : true)
    ) {
      let newYearlyValues: YearlyValues = {};
      newYearlyValStr = newYearlyValStr.trim();
      if (!!yearlyValDict && Object.keys(yearlyValDict).length >= 1) {
        const years: Array<string> = Object.keys(yearlyValDict);
        years.forEach((year) => {
          newYearlyValues[year] = newYearlyValStr;
        });
      } else {
        const stYear = coerceNumberProperty(this.startYear);
        const eYear = coerceNumberProperty(this.endYear);
        newYearlyValues = convertToYearlyValues(newYearlyValStr, stYear, eYear);
      }
      return newYearlyValues;
    }
    return null;
  }

  writeValue(value: YearlyValues): void {
    this.determineInputValue(value);
    this.value = value;
    this.markForCheck();
    this.stateChanges.next();
  }

  /**
   * Sets the value of the inputValueControl and set the hasMultipleValues if needed
   *
   * @param value the yearly values from the control.
   */
  determineInputValue(value: YearlyValues): void {
    if (!!value) {
      const valueSet = new Set(Object.values(value));

      if (valueSet.size > 1) {
        this.hasMultipleValues = true;
        this.inputValueControl.disable({ onlySelf: true, emitEvent: false });
        this.inputValueControl.patchValue(undefined, { emitEvent: false });
      } else {
        this.hasMultipleValues = false;
        const valueArr = Array.from(valueSet.values());
        this.inputValueControl.patchValue(
          !!valueArr && valueArr.length === 1 ? valueArr[0] : '',
          { emitEvent: false },
        );
        if (valueArr.length === 0) {
          this.onChange(this.setConstantYearlyValues(null, {}));
        }
        this.inputValueControl.enable({ onlySelf: true, emitEvent: false });
        this.inputValueControl.markAsTouched({ onlySelf: true });
        this.inputValueControl.markAsDirty({ onlySelf: true });
      }
    }
  }
}
