import { ScenarioVariationMap } from 'prosumer-app/+scenario/models';
import {
  ScenarioCompareFacadeService,
  ScenarioFacadeService,
} from 'prosumer-app/+scenario/state';
import { ComparisonPreparerService } from 'prosumer-app/comparison/state/comparison-preparer.service';
import { Utils } from 'prosumer-app/core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import {
  buildEntityId,
  getScenarioVariationIdFrom,
  isVariation,
} from 'prosumer-app/shared';
import { from, Observable } from 'rxjs';
import {
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
  toArray,
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

@Injectable()
export class ScenarioCompareResolverService implements Resolve<any> {
  constructor(
    private scenarioCompareFacade: ScenarioCompareFacadeService,
    private scenarioFacade: ScenarioFacadeService,
    private comparisonPreparer: ComparisonPreparerService,
    private readonly keeper: ActiveKeeperService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const queryParams = route.queryParamMap;

    const projectId = this.keeper.getActiveEntities().project;
    const caseId = this.keeper.getActiveEntities().case;
    const scenarioId = this.keeper.getActiveEntities().scenario;
    this.keeper.setActive({
      project: projectId,
      case: caseId,
      scenario: scenarioId,
    });
    const reference = queryParams.get('reference');
    const selectedIds = Utils.resolveToEmptyString(
      queryParams.get('selected'),
    ).split(',');
    const entityMapList = selectedIds.map((id) => {
      const entityId = id.split('_');
      return {
        projectId,
        caseId,
        scenarioId: entityId[0],
        ...(entityId.length > 1 && {
          variationId: entityId[1],
        }),
      };
    });

    this.setSelectedVariationIdFromReferenceId(reference);
    this.comparisonPreparer.clearTray();
    return from(entityMapList).pipe(
      tap((entity) =>
        this.scenarioFacade.get(entity.scenarioId, {
          projectId: entity.projectId,
          caseId: entity.caseId,
        }),
      ),
      concatMap((entity) =>
        this.scenarioFacade
          .getScenarioVariations(entity.scenarioId, { projectId, caseId })
          .pipe(
            take(1),
            distinctUntilChanged(),
            switchMap((scenarioVariations) =>
              this.formatResultEntity(
                entity,
                scenarioVariations['payload'].datalist || [],
              ),
            ),
          ),
      ),
      toArray(),
      tap((resultList) =>
        this.scenarioCompareFacade.migrate(
          reference,
          projectId,
          caseId,
          resultList,
        ),
      ),
    );
  }

  private formatResultEntity(entity, variations: Array<ScenarioVariationMap>) {
    return this.scenarioFacade.dataById$(entity.scenarioId).pipe(
      filter((scenario) => !!scenario.loaded),
      map(({ id, name, colorClass }) => ({
        id: entity.variationId ? buildEntityId(id, entity.variationId) : id,
        scenarioId: id,
        name: this.getResultsName(name, variations, entity.variationId),
        colorClass,
        variationId: entity.variationId,
      })),
      take(1),
    );
  }

  private getVariation(
    variations: Array<ScenarioVariationMap>,
    variationId: string,
  ) {
    return variations
      .filter((variation) => variation.variationId === variationId)
      .map((variation) => variation)[0];
  }

  private getVariationName(
    variations: Array<ScenarioVariationMap> = [],
    variationId: string,
  ): string {
    return this.getVariation(variations, variationId).name;
  }

  private setSelectedVariationIdFromReferenceId(referenceId: string) {
    if (getScenarioVariationIdFrom(referenceId)) {
      this.scenarioFacade.selectScenarioVariationId(
        getScenarioVariationIdFrom(referenceId),
      );
    }
  }

  private getResultsName(
    scenarioName: string,
    variations: Array<ScenarioVariationMap>,
    variationId: string,
  ): string {
    return variationId
      ? `${scenarioName} [${this.getVariationName(variations, variationId)}]`
      : scenarioName;
  }
}
