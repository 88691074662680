import { PipeUtils, Utils } from 'prosumer-app/core/utils/utils';
import {
  BaseFormComponent,
  collectItemsWithKey,
  contains,
  convertArrayItemsToLowerCase,
  FormFieldErrorMessageMap,
  FormFieldOption,
  getDeletedItems,
  getKeys,
} from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject } from 'rxjs';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  NgControl,
  Validators,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'prosumer-node-filterchip-component',
  templateUrl: './node-filterchip.component.html',
  styleUrls: ['./node-filterchip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NodeFilterchipComponent
  extends BaseFormComponent
  implements OnInit, AfterViewInit
{
  _nodeOptions: Array<FormFieldOption<string>> = [];
  @Input() set nodeOptions(nodeOptions: Array<FormFieldOption<string>>) {
    this._nodeOptions = [...nodeOptions, { name: 'All Nodes', value: 'ALL' }]; // initialization
    this.nodeSelection$.next(this._nodeOptions);
    this.filterSelectableOptions();
  }
  get nodeOptions(): Array<FormFieldOption<string>> {
    return this._nodeOptions;
  }

  @Input() set setSubmitted(submitted: boolean) {
    // istanbul ignore next
    if (submitted) {
      this.submitted$.next(submitted);
    }
  }

  // @Input() required = true;
  @Input() errorMessage: FormFieldErrorMessageMap;
  @Input() tooltip: string;

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  submitted$ = new BehaviorSubject<boolean>(false);
  inputNodeControl = new FormControl();
  nodeSelection$ = new BehaviorSubject<FormFieldOption<string>[]>([]); // node options list dropdown
  selectedNodes = []; // filter chips list
  selectedNodes$ = new BehaviorSubject<string[]>([]); // list of id's selected
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('nodeInput') nodeInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    public ngControl: NgControl,
    public changeDetectorRef: ChangeDetectorRef,
    public formBuilder: FormBuilder,
  ) {
    super(ngControl, changeDetectorRef, formBuilder);

    this.required = true;
  }

  defineForm() {
    return {
      nodes: [[], Validators.required],
    };
  }

  writeValue(value: any) {
    if (value) {
      this.controls.nodes.setValue(value, { emitEvent: false });
      // update selected chips in the UI
      this.init();
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.init();
    // on user input, handle autocomplete filter
    this.inputNodeControl.valueChanges
      .pipe(PipeUtils.filterOutEmptyString)
      .subscribe((value) => this.filter(value));
  }

  ngAfterViewInit() {
    this.selectedNodes$.pipe().subscribe((nodes) => {
      // check if need to update selected nodes
      // istanbul ignore else
      if (JSON.stringify(nodes) !== JSON.stringify(this.controls.nodes.value)) {
        this.controls.nodes.patchValue([...new Set(nodes)]);
      }
    });
  }

  init() {
    // on edit mode, add pre-loaded nodes to selectedNodes array
    if (this.controls.nodes.value) {
      const ids = collectItemsWithKey(this.nodeOptions, 'value');
      this.controls.nodes.value.forEach((id) => {
        const i = ids.indexOf(id);
        this.toggleSelection(this.nodeOptions[i]);
      });
    }
  }

  /**
   * Function to handle user input when looking for nodes
   *
   * @param value - user input value
   */
  filter(value: string) {
    // handle 'string' only values, it sometimes catches obj format value when user 'ENTER's value from options
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      // simple finder of string from original list of nodes
      const list = this.nodeOptions.filter(
        (node: any) => node.name.toLowerCase().indexOf(filterValue) === 0,
      );
      this.nodeSelection$.next(list);
    }
  }

  filterSelectableOptions() {
    this.selectedNodes
      .filter((selectedNode) => {
        // TODO: need to refactor later
        const optionIds = collectItemsWithKey(this.nodeOptions, 'value');
        return optionIds.includes(selectedNode.value);
      })
      .forEach((selectedNode) => this.toggleSelection(selectedNode));
  }

  /**
   * When user clicks on node options list
   *
   * @param event - mouse click from node options list
   * @param option - value of option clicked
   */
  optionSelected(event: Event, option: FormFieldOption<string>) {
    this.toggleSelection(option);
  }

  /**
   * When user selects an option and hits ENTER key
   *
   * @param event - keyboard user event on autocomplete
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    this.toggleSelection(event.option.value);
    this.nodeInput.nativeElement.value = '';
    this.inputNodeControl.setValue(null);
  }

  /**
   * Handles all events and process the final data for the node filterchip form
   *
   * @param node - obj name value pair
   */
  toggleSelection(node: FormFieldOption<string>) {
    // Do nothing if "All nodes" is already selected
    if (
      this.selectedNodes &&
      this.selectedNodes.length &&
      this.selectedNodes[0].value === 'ALL'
    ) {
      this.nodeSelection$.next([]);
    } else if (node.value === 'ALL') {
      this.selectedNodes = [node];
      this.nodeSelection$.next([]);
    } else {
      this.pushUnique(node);
      const filter = getDeletedItems(
        this.nodeOptions,
        this.selectedNodes,
        'value',
      );
      this.nodeSelection$.next(filter);
    }
    this.collectNodeIds();
  }

  /**
   * When user keys a node name and hits a match regardless of font case
   *
   * @param event - when user 'ENTER's an input value and did not select from the options
   */
  add(event: MatChipInputEvent): void {
    if (!contains(this.selectedNodes$.value, 'ALL')) {
      const value = event.value;
      const nodeNames = convertArrayItemsToLowerCase(
        collectItemsWithKey(this.nodeOptions, 'name'),
      );
      if (Utils.resolveToEmptyString(value).trim()) {
        if (contains(nodeNames, value.toLowerCase())) {
          const i = nodeNames.indexOf(value);
          this.toggleSelection(this.nodeOptions[i]);
        }
      }
    } else {
      this.nodeSelection$.next([]);
    }
    // clear input fields
    event.input.value = '';
    this.inputNodeControl.setValue(null);
  }

  /**
   * Handle all selected nodes deletion
   *
   * @param node - to be deleted value
   */
  remove(node: FormFieldOption<string>) {
    if (node.value === 'ALL') {
      this.nodeSelection$.next(this.nodeOptions);
      this.selectedNodes = [];
    } else {
      this.selectedNodes = this.selectedNodes.filter(
        (userData) => userData !== node,
      );
      this.nodeSelection$.next([...this.nodeSelection$.value, node]);
    }
    this.collectNodeIds();
  }

  collectNodeIds() {
    const arr = [];
    this.selectedNodes.forEach((node) => arr.push(node.value));
    this.selectedNodes$.next(arr);
  }

  pushUnique(node: FormFieldOption<string>): void {
    if (!this.selectedNodes$.value.includes(node.value)) {
      this.selectedNodes.push(node);
    }
  }

  /**
   * Control error collector
   *
   * @param errorObj - control errors
   */
  getErrors(errorObj: any) {
    return errorObj ? getKeys(errorObj) : [];
  }
}
