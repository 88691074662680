import { Coerce } from 'prosumer-app/core/utils';
import { Utils } from 'prosumer-app/core/utils/utils';
import { convertDataValuesToString } from 'prosumer-app/libs/eyes-shared';
import { EquipmentInfo } from 'prosumer-app/stores/equipment';
import {
  hasProfilesForSaving,
  hasUpdatedTDBFilters,
  mapVariationToBackend,
  mapVariationToFrontend,
  mapYearlyProfileToBackend,
  mapYearlyProfileToFrontend,
  mapYearlyValuesToBackend,
  mapYearlyValuesToFrontend,
} from 'prosumer-shared';

import {
  Converter,
  Efficiency,
  EfficiencyMatrix,
  EfficiencyPoint,
  Equipment,
  Generator,
  Library,
  OperatingCost,
  Profile,
  RenewableEnergy,
  Station,
  Storage,
  Vehicle,
} from '../../models';

const LIBRARY_TYPE_CUSTOM = 'custom';
const LIBRARY_TYPE_LIBRARY = 'library';
const CONVERSION_EXCEPTIONS = [
  'depreciationTime',
  'technicalLife',
  'minDowntime',
  'minUptime',
  'minRunningTime',
];

export class EquipmentMapper {
  static mapToBackend = (equipment: Equipment) =>
    convertDataValuesToString(
      EquipmentMapper.mapEquipmentSourceToBackend(equipment, {
        equipmentId: Utils.resolveToUndefinedString(equipment.id),
        name: equipment.name,
        derType: equipment.type,
        nodeId: { nodeIds: Utils.resolveToEmptyArray(equipment.nodes) },
        scenarioVariation: mapVariationToBackend(equipment),
        outputEnergyVectorId: Utils.resolveToUndefinedString(
          equipment.outputEnergyVector,
        ),
        profile: {
          name: equipment.loadName,
          isCustom: true,
          localId: equipment.localId,
        },
        forcedInvestment: equipment.forcedInvestment,
        libraryId: EquipmentMapper.deduceLibraryId(equipment.library),
        capacityLoss: mapYearlyValuesToBackend(equipment.yearlyCapacityLoss),
        minPower: mapYearlyValuesToBackend(equipment.yearlyMinPower),
        maxPower: mapYearlyValuesToBackend(equipment.yearlyMaxPower),
        buildCost: mapYearlyValuesToBackend(equipment.yearlyBuildCost),
        indivisibleCost: mapYearlyValuesToBackend(
          equipment.yearlyIndivisibleCost,
        ),
        fOAndMCharge: mapYearlyValuesToBackend(equipment.yearlyFOAndMCharge),
        fOAndMPerInstall: mapYearlyValuesToBackend(
          equipment.yearlyFOAndMInstall,
        ),
        technicalLife: mapYearlyValuesToBackend(equipment.yearlyTechnicalLife),
        depreciationTime: mapYearlyValuesToBackend(
          equipment.yearlyDepreciationTime,
        ),
        existingAsset: equipment.existingAsset,
        capacityExpansion: equipment.capacityExpansion,
        buildEmissionsKw: mapYearlyValuesToBackend(
          equipment.yearlyBuildEmissionsKw,
        ),
        buildEmissionsIndivisible: mapYearlyValuesToBackend(
          equipment.yearlyBuildEmissionsIndivisible,
        ),
        footprint: mapYearlyValuesToBackend(equipment.yearlyFootprint),
      }),
      CONVERSION_EXCEPTIONS,
    );

  static mapEquipmentSourceToBackend(equipment: Equipment, data: any): any {
    switch (equipment.type) {
      case 'vre':
        return {
          ...data,
          tdbProfileFilters: equipment.tdbProfileFilters,
          tdbTechnologyFilter: equipment.tdbTechnologyFilter,
          profile: this.mapLoadProfilesToBackend(equipment),
          operatingCostProfiles: this.mapOperatingCostProfilesToBackend(
            equipment as RenewableEnergy,
          ),
          profileUpdated: hasUpdatedTDBFilters(
            Coerce.toObject(equipment.tdbProfileFilters),
            hasProfilesForSaving(equipment.profiles),
          ),
        };
      case 'converter':
        return {
          ...data,
          profile: undefined,
          tdbTechnologyFilter: equipment.tdbTechnologyFilter,
          mainInputId: !!(equipment as Converter).efficiencyMatrix
            ? (equipment as Converter).efficiencyMatrix.mainEnergyVectorInput
            : undefined,
          mainOutputId: !!(equipment as Converter).efficiencyMatrix
            ? (equipment as Converter).efficiencyMatrix.mainEnergyVectorOutput
            : undefined,
          evInputOutputPairs: this.mapEfficienciesToBackend(
            (equipment as Converter).efficiencyMatrix,
          ),
          operatingCostProfiles: this.mapOperatingCostProfilesToBackend(
            equipment as Converter,
          ),
        };
      case 'storage':
        return {
          ...data,
          tdbTechnologyFilter: equipment.tdbTechnologyFilter,
          inputEnergyVectorId: (equipment as Storage).energyVector,
          outputEnergyVectorId: (equipment as Storage).energyVector,
          capacityLoss2: mapYearlyValuesToBackend(
            (equipment as Storage).yearlySecondCapacityLoss,
          ),
          efficiency: mapYearlyValuesToBackend(
            (equipment as Storage).yearlyRoundTripEfficiency,
          ),
          maxDod: mapYearlyValuesToBackend((equipment as Storage).yearlyMaxDoD),
          minEp: mapYearlyValuesToBackend((equipment as Storage).yearlyMinEp),
          maxEp: mapYearlyValuesToBackend((equipment as Storage).yearlyMaxEp),
          chargingDischargingRateFactor: mapYearlyValuesToBackend(
            (equipment as Storage).yearlyChargeDischargeRateFactor,
          ),
          agingFactor: mapYearlyValuesToBackend(
            (equipment as Storage).yearlyAgingFactor,
          ),
          dissipationRate: mapYearlyValuesToBackend(
            (equipment as Storage).yearlyDissipationRate,
          ),
          buildCost2: mapYearlyValuesToBackend(
            (equipment as Storage).yearlySecondBuildCost,
          ),
          minEnergy: mapYearlyValuesToBackend(
            (equipment as Storage).yearlyMinEnergy,
          ),
          maxEnergy: mapYearlyValuesToBackend(
            (equipment as Storage).yearlyMaxEnergy,
          ),
          fOAndMChargeKwh: mapYearlyValuesToBackend(
            (equipment as Storage).yearlyFOAndMChargeKWh,
          ),
          buildEmissionsKwh: mapYearlyValuesToBackend(
            (equipment as Storage).yearlyBuildEmissionsKwh,
          ),
          operatingCostProfiles: this.mapOperatingCostProfilesToBackend(
            equipment as Storage,
          ),
          profile: undefined,
        };
      case 'generator':
        return {
          ...data,
          tdbTechnologyFilter: equipment.tdbTechnologyFilter,
          inputEnergyVectorId: (equipment as Generator).energyVector,
          individualSize: mapYearlyValuesToBackend(
            (equipment as Generator).yearlyIndividualSize,
          ),
          pmin: mapYearlyValuesToBackend((equipment as Generator).yearlyPMin),
          efficiencyPmin: mapYearlyValuesToBackend(
            (equipment as Generator).yearlyEfficiencyPMin,
          ),
          efficiencyPmax: mapYearlyValuesToBackend(
            (equipment as Generator).yearlyEfficiencyPMax,
          ),
          startUpLoss: mapYearlyValuesToBackend(
            (equipment as Generator).yearlyStartUpLoss,
          ),
          idleLoss: mapYearlyValuesToBackend(
            (equipment as Generator).yearlyIdleLoss,
          ), // shutdown loss
          minUptime: mapYearlyValuesToBackend(
            (equipment as Generator).yearlyMinUpTime,
          ),
          minDowntime: mapYearlyValuesToBackend(
            (equipment as Generator).yearlyMinDownTime,
          ),
          minRunningTime: mapYearlyValuesToBackend(
            (equipment as Generator).yearlyMinRunningTime,
          ),
          profile: undefined,
          runningCost: mapYearlyValuesToBackend(
            (equipment as Generator).yearlyRunningCost,
          ),
          efficiencyCurve: this.mapEfficiencyCurveToBackend(
            Utils.resolveToEmptyArray((equipment as Generator).efficiencyCurve),
          ),
          operatingCostProfiles: this.mapOperatingCostProfilesToBackend(
            equipment as Generator,
          ),
        };
      case 'vehicle':
        return {
          ...data,
          agingFactor: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlyAgingFactor,
          ),
          buildCost2: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlySecondBuildCost,
          ),
          capacityLoss2: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlySecondCapacityLoss,
          ),
          dissipationRate: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlyDissipationRate,
          ),
          efficiency: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlyRoundTripEfficiency,
          ),
          efficiency2: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlyEfficiency2nd,
          ),
          fOAndMChargeKwh: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlyFOAndMChargeKWh,
          ),
          maxDod: mapYearlyValuesToBackend((equipment as Vehicle).yearlyMaxDoD),
          maxEnergy: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlyMaxEnergy,
          ),
          maxEp: undefined,
          minEnergy: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlyMinEnergy,
          ),
          minEp: undefined,
          outputEnergyVectorId: (equipment as Vehicle).energyVector,
          profile: undefined,
          buildEmissionsKwh: mapYearlyValuesToBackend(
            (equipment as Vehicle).yearlyBuildEmissionsKwh,
          ),
          operatingCostProfiles: this.mapOperatingCostProfilesToBackend(
            equipment as Vehicle,
          ),
        };
      case 'station':
        return {
          ...data,
          outputEnergyVectorId: (equipment as Station).outputEnergyVector,
          connectionCost: mapYearlyValuesToBackend(
            (equipment as Station).connectionCost,
          ),
          maxConnections: mapYearlyValuesToBackend(
            (equipment as Station).maxConnections,
          ),
          minConnections: mapYearlyValuesToBackend(
            (equipment as Station).minConnections,
          ),
          numberOfConnections: mapYearlyValuesToBackend(
            (equipment as Station).numberOfConnections,
          ),
          opexPerHour: mapYearlyValuesToBackend(
            (equipment as Station).opexPerHour,
          ),
          powerChargingPerConnection: mapYearlyValuesToBackend(
            (equipment as Station).powerChargingPerConnection,
          ),
          profile: undefined,
          timeSlots: mapYearlyValuesToBackend((equipment as Station).timeSlots),
          vehicleToGrid: (equipment as Station).vehicleToGrid,
        };
      default:
        return {
          ...data,
        };
    }
  }

  static mapLoadProfilesToBackend = (equipment: Equipment): any => {
    if (equipment.profiles === undefined) {
      return undefined;
    }
    const loadProfile = { name: equipment.loadName, profiles: null };
    const profiles = Utils.resolveToEmptyArray(equipment.profiles).map(
      (profile) =>
        mapYearlyProfileToBackend({
          ...profile,
          loadType: 'custom',
        }),
    );
    loadProfile.profiles = profiles;
    return loadProfile;
  };

  static mapEfficiencyCurveToBackend = (effCurve: Array<EfficiencyPoint>) =>
    Utils.resolveToEmptyArray(effCurve).map(
      (point) =>
        ({
          percentageOfCapacity: (point.percentageOfCapacity || '0').toString(),
          consumption: (point.consumption || '0').toString(),
        } as EfficiencyPoint),
    );

  static mapEfficienciesToBackend = (
    efficiencyMatrix: EfficiencyMatrix,
  ): Array<any> => {
    if (!!!efficiencyMatrix || !!!efficiencyMatrix.mainEnergyVectorInput) {
      return [];
    }
    return Utils.resolveToEmptyArray(efficiencyMatrix.efficiencies).map(
      (effPair) =>
        ({
          inputId: effPair.energyVectorIn,
          outputId: effPair.energyVectorOut,
          efficiency: mapYearlyValuesToBackend(effPair.value),
        } as any),
    );
  };

  static mapOperatingCostProfilesToBackend = (equipment: any): any => {
    if (equipment.operatingCostProfiles === undefined) {
      return undefined;
    }
    const operatingCostProfiles = (equipment.operatingCostProfiles || []).map(
      (costProfile) => ({
        parameterType: costProfile.parameterType.toLowerCase(),
        energyVectorId: costProfile.energyVectorId,
        profile: {
          name: 'Cost Profile',
          profiles: Utils.resolveToEmptyArray(costProfile.profiles).map((p) =>
            mapYearlyProfileToBackend(p),
          ),
        },
      }),
    );
    return operatingCostProfiles;
  };

  static mapToFrontend = (equipment, startYear: number, endYear: number) =>
    EquipmentMapper.mapEquipmentSourceToFrontend(
      equipment,
      {
        id: equipment.equipmentId,
        equipmentId: equipment.equipmentId,
        scenarioUuid: equipment.scenarioUuid,
        cascadeEnergyVectorId: equipment.cascadeEnergyVectorId,
        cascadeEquipmentId: equipment.cascadeEquipmentId,
        name: equipment.name,
        nodes:
          equipment.derType !== 'vehicle'
            ? Utils.resolveToEmptyArray(
                Utils.parseYearlyValuesFromBE(equipment.nodeId).nodeIds,
              )
            : undefined,
        type: equipment.derType,
        scenarioVariation: mapVariationToFrontend(equipment),
        outputEnergyVector: !!equipment.outputEnergyVectorId
          ? equipment.outputEnergyVectorId
          : equipment.derType === 'converter'
          ? equipment.mainOutputId
          : undefined,
        loadName: Utils.resolveToEmptyObject(equipment.profile).name,
        forcedInvestment: equipment.forcedInvestment,
        library: EquipmentMapper.deduceLibrary(equipment.libraryId),
        sourceType: EquipmentMapper.deduceLibrary(equipment.libraryId)
          ? LIBRARY_TYPE_LIBRARY
          : LIBRARY_TYPE_CUSTOM,
        yearlyCapacityLoss: mapYearlyValuesToFrontend(
          Utils.parseYearlyValuesFromBE(equipment.capacityLoss),
          startYear,
          endYear,
        ),
        yearlyMinPower: mapYearlyValuesToFrontend(
          Utils.parseYearlyValuesFromBE(equipment.minPower),
          startYear,
          endYear,
        ),
        yearlyMaxPower: mapYearlyValuesToFrontend(
          Utils.parseYearlyValuesFromBE(equipment.maxPower),
          startYear,
          endYear,
        ),
        yearlyBuildCost: mapYearlyValuesToFrontend(
          Utils.parseYearlyValuesFromBE(equipment.buildCost),
          startYear,
          endYear,
        ),
        yearlyIndivisibleCost: mapYearlyValuesToFrontend(
          Utils.parseYearlyValuesFromBE(equipment.indivisibleCost),
          startYear,
          endYear,
        ),
        yearlyFOAndMCharge: mapYearlyValuesToFrontend(
          Utils.parseYearlyValuesFromBE(equipment.fOAndMCharge),
          startYear,
          endYear,
        ),
        yearlyFOAndMInstall: mapYearlyValuesToFrontend(
          Utils.parseYearlyValuesFromBE(equipment.fOAndMPerInstall),
          startYear,
          endYear,
        ),
        yearlyTechnicalLife: mapYearlyValuesToFrontend(
          Utils.parseYearlyValuesFromBE(equipment.technicalLife),
          startYear,
          endYear,
        ),
        yearlyDepreciationTime: mapYearlyValuesToFrontend(
          Utils.parseYearlyValuesFromBE(equipment.depreciationTime),
          startYear,
          endYear,
        ),
        existingAsset: equipment.existingAsset,
        capacityExpansion: equipment.capacityExpansion,
        yearlyBuildEmissionsKw: mapYearlyValuesToFrontend(
          Utils.parseYearlyValuesFromBE(equipment.buildEmissionsKw),
          startYear,
          endYear,
        ),
        yearlyBuildEmissionsIndivisible: mapYearlyValuesToFrontend(
          Utils.parseYearlyValuesFromBE(equipment.buildEmissionsIndivisible),
          startYear,
          endYear,
        ),
        yearlyFootprint: mapYearlyValuesToFrontend(
          Utils.parseYearlyValuesFromBE(equipment.footprint),
          startYear,
          endYear,
        ),
      } as EquipmentInfo,
      startYear,
      endYear,
    );

  static mapEquipmentSourceToFrontend(
    source: any,
    equipment,
    startYear?: number,
    endYear?: number,
  ) {
    switch (source.derType) {
      case 'vre':
        return {
          ...equipment,
          tdbProfileFilters: source.tdbProfileFilters,
          tdbTechnologyFilter: source.tdbTechnologyFilter,
          profiles: this.mapLoadProfilesToFrontend(
            Utils.resolveToEmptyObject(source.profile),
          ),
          operatingCostProfiles: this.mapOperatingCostProfilesToFrontend(
            Utils.resolveToEmptyArray(source.operatingCostProfiles),
          ),
        } as RenewableEnergy;
      case 'storage':
        return {
          ...equipment,
          tdbTechnologyFilter: source.tdbTechnologyFilter,
          energyVector:
            source.inputEnergyVectorId || source.outputEnergyVectorId,
          operatingCostProfiles: this.mapOperatingCostProfilesToFrontend(
            Utils.resolveToEmptyArray(source.operatingCostProfiles),
          ),
          yearlySecondCapacityLoss: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.capacityLoss2),
            startYear,
            endYear,
          ),
          yearlyRoundTripEfficiency: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.efficiency),
            startYear,
            endYear,
          ),
          yearlyMaxDoD: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.maxDod),
            startYear,
            endYear,
          ),
          yearlyMinEp: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.minEp),
            startYear,
            endYear,
          ),
          yearlyMaxEp: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.maxEp),
            startYear,
            endYear,
          ),
          yearlyChargeDischargeRateFactor: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.chargingDischargingRateFactor),
            startYear,
            endYear,
          ),
          yearlyAgingFactor: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.agingFactor),
            startYear,
            endYear,
          ),
          yearlyDissipationRate: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.dissipationRate),
            startYear,
            endYear,
          ),
          yearlyMinEnergy: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.minEnergy),
            startYear,
            endYear,
          ),
          yearlyMaxEnergy: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.maxEnergy),
            startYear,
            endYear,
          ),
          yearlyFOAndMChargeKWh: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.fOAndMChargeKwh),
            startYear,
            endYear,
          ),
          yearlySecondBuildCost: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.buildCost2),
            startYear,
            endYear,
          ),
          yearlyBuildEmissionsKwh: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.buildEmissionsKwh),
            startYear,
            endYear,
          ),
        } as Storage;
      case 'generator':
        return {
          ...equipment,
          tdbTechnologyFilter: source.tdbTechnologyFilter,
          energyVector: source.inputEnergyVectorId,
          inputEnergyVector: source.inputEnergyVectorId,
          efficiencyCurve: source.efficiencyCurve,
          operatingCostProfiles: this.mapOperatingCostProfilesToFrontend(
            Utils.resolveToEmptyArray(source.operatingCostProfiles),
          ),
          /** Start of Yearly values */
          yearlyIndividualSize: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.individualSize),
            startYear,
            endYear,
          ),
          yearlyPMin: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.pmin),
            startYear,
            endYear,
          ),
          yearlyEfficiencyPMin: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.efficiencyPmin),
            startYear,
            endYear,
          ),
          yearlyEfficiencyPMax: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.efficiencyPmax),
            startYear,
            endYear,
          ),
          yearlyMinUpTime: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.minUptime),
            startYear,
            endYear,
          ),
          yearlyMinDownTime: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.minDowntime),
            startYear,
            endYear,
          ),
          yearlyMinRunningTime: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.minRunningTime),
            startYear,
            endYear,
          ),
          yearlyStartUpLoss: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.startUpLoss),
            startYear,
            endYear,
          ),
          yearlyIdleLoss: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.idleLoss),
            startYear,
            endYear,
          ), // shutdown loss
          yearlyRunningCost: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.runningCost),
            startYear,
            endYear,
          ),
          /** End of Yearly values */
        } as Generator;
      case 'converter':
        return {
          ...equipment,
          // inputEnergyVector: source.mainInputId,
          tdbTechnologyFilter: source.tdbTechnologyFilter,
          secondOutputEnergyVector: source.outputEnergyVectorId2, // TODO Is this still used?
          efficiencyMatrix: this.mapEfficiencyMatrixToFrontend(
            source,
            startYear,
            endYear,
          ),
          energyVector: source.mainInputId,
          secondary: source.outputEnergyVectorId2, // TODO Is this still used?
          operatingCostProfiles: this.mapOperatingCostProfilesToFrontend(
            Utils.resolveToEmptyArray(source.operatingCostProfiles),
          ),
          // TODO Are the following still needed?
          yearlyEfficiency: mapYearlyValuesToFrontend(
            source.efficiency,
            startYear,
            endYear,
          ),
          yearlyEfficiency2nd: mapYearlyValuesToFrontend(
            source.efficiency2,
            startYear,
            endYear,
          ),
        } as Converter;
      case 'vehicle':
        return {
          ...equipment,
          energyVector: source.outputEnergyVectorId,
          operatingCostProfiles: this.mapOperatingCostProfilesToFrontend(
            Utils.resolveToEmptyArray(source.operatingCostProfiles),
          ),
          yearlySecondCapacityLoss: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.capacityLoss2),
            startYear,
            endYear,
          ),
          yearlyRoundTripEfficiency: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.efficiency),
            startYear,
            endYear,
          ),
          yearlyEfficiency2nd: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.efficiency2),
            startYear,
            endYear,
          ),
          yearlyMaxDoD: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.maxDod),
            startYear,
            endYear,
          ),
          yearlyAgingFactor: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.agingFactor),
            startYear,
            endYear,
          ),
          yearlyDissipationRate: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.dissipationRate),
            startYear,
            endYear,
          ),
          yearlyMinEnergy: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.minEnergy),
            startYear,
            endYear,
          ),
          yearlyMaxEnergy: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.maxEnergy),
            startYear,
            endYear,
          ),
          yearlyFOAndMChargeKWh: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.fOAndMChargeKwh),
            startYear,
            endYear,
          ),
          yearlySecondBuildCost: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.buildCost2),
            startYear,
            endYear,
          ),
          yearlyBuildEmissionsKwh: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.buildEmissionsKwh),
            startYear,
            endYear,
          ),
        } as Vehicle;
      case 'station':
        return {
          ...equipment,
          connectionCost: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.connectionCost),
            startYear,
            endYear,
          ),
          maxConnections: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.maxConnections),
            startYear,
            endYear,
          ),
          minConnections: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.minConnections),
            startYear,
            endYear,
          ),
          numberOfConnections: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.numberOfConnections),
            startYear,
            endYear,
          ),
          opexPerHour: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.opexPerHour),
            startYear,
            endYear,
          ),
          outputEnergyVector: source.outputEnergyVectorId,
          powerChargingPerConnection: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.powerChargingPerConnection),
            startYear,
            endYear,
          ),
          timeSlots: mapYearlyValuesToFrontend(
            Utils.parseYearlyValuesFromBE(source.timeSlots),
            startYear,
            endYear,
          ),
          vehicleToGrid: !!source.vehicleToGrid,
        } as Station;
    }
    return equipment;
  }

  static mapLoadProfilesToFrontend = (equipment: any): Array<Profile> =>
    (equipment.profiles || []).map((profile) =>
      mapYearlyProfileToFrontend(profile, 'DER'),
    );

  static mapEfficiencyMatrixToFrontend = (
    source: any,
    startYear?: number,
    endYear?: number,
  ): EfficiencyMatrix => {
    if (!!!source) {
      return {
        mainEnergyVectorInput: undefined,
        mainEnergyVectorOutput: undefined,
        efficiencies: [],
      };
    }
    return {
      mainEnergyVectorInput: Utils.resolveToEmptyString(
        source.mainInputId,
      ).toString(),
      mainEnergyVectorOutput: Utils.resolveToEmptyString(
        source.mainOutputId,
      ).toString(),
      efficiencies: EquipmentMapper.mapEfficienciesToFrontend(
        source,
        startYear,
        endYear,
      ),
    } as EfficiencyMatrix;
  };

  static mapEfficienciesToFrontend = (
    source: any,
    startYear?: number,
    endYear?: number,
  ): Array<Efficiency> =>
    !!source
      ? Utils.resolveToEmptyArray(source.evInputOutputPairs).map(
          (effPair) =>
            ({
              energyVectorIn: Utils.resolveToEmptyString(
                (effPair || ({} as any)).inputId,
              ).toString(),
              energyVectorOut: Utils.resolveToEmptyString(
                (effPair || ({} as any)).outputId,
              ).toString(),
              value: mapYearlyValuesToFrontend(
                (effPair || ({} as any)).efficiency,
                startYear,
                endYear,
              ),
            } as Efficiency),
        )
      : [];

  static mapOperatingCostProfilesToFrontend = (
    operatingCostProfiles: any,
  ): Array<OperatingCost> => {
    const costProfiles = (operatingCostProfiles || []).map((costProfile) => ({
      parameterType: costProfile.parameterType.toUpperCase(),
      energyVectorId: costProfile.energyVectorId,
      profiles: Utils.resolveToEmptyArray(
        Utils.resolveToEmptyObject(costProfile.profile).profiles,
      ).map((p) => mapYearlyProfileToFrontend(p, 'DER_OPERATING_COSTS')),
    }));
    return costProfiles;
  };

  private static deduceLibraryId(library: Library): string {
    return library ? library.id : 'custom';
  }

  private static deduceLibrary(libraryId: string): Library {
    return libraryId !== 'custom' ? ({ id: libraryId } as Library) : undefined;
  }
}
