import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

import { startWith, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { BaseComponent } from '../base-component';

@Component({
  selector: 'prosumer-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent extends BaseComponent implements OnInit {
  @Input() label = 'Search';
  @Input() placeholder = '';

  @Output() keyIn = new EventEmitter<string>();
  @Output() search = new EventEmitter<string>();

  searchForm = this.initSearchForm();

  get searchInput() {
    return this.searchForm.controls.searchInput;
  }

  searchInputValue$ = this.searchInput.valueChanges.pipe(
    startWith(this.searchInput.value),
    debounceTime(400),
    distinctUntilChanged(),
    this.takeUntil(),
  );

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.initValueChangeSubscription();
  }

  initValueChangeSubscription() {
    this.searchInputValue$.subscribe((value) => this.keyIn.emit(value));
  }

  initSearchForm(): FormGroup {
    return this.formBuilder.group({
      searchInput: this.initSearchControl(),
    });
  }

  initSearchControl(): FormControl {
    return this.formBuilder.control('');
  }

  onSearch() {
    this.search.emit(this.searchInput.value);
  }
}
