import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { HttpClient } from '@angular/common/http';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/generic_', suffix: '.json' },
    { prefix: './assets/i18n/auth_', suffix: '.json' },
    { prefix: './assets/i18n/user_', suffix: '.json' },
    { prefix: './assets/i18n/project_', suffix: '.json' },
    { prefix: './assets/i18n/case_', suffix: '.json' },
    { prefix: './assets/i18n/scenario_', suffix: '.json' },
    { prefix: './assets/i18n/dashboard_', suffix: '.json' },
    { prefix: './assets/i18n/about_', suffix: '.json' },
    { prefix: './assets/i18n/result_', suffix: '.json' },
    { prefix: './assets/i18n/billing_', suffix: '.json' },
    { prefix: './assets/i18n/notification_', suffix: '.json' },
    { prefix: './assets/i18n/library_', suffix: '.json' },
    { prefix: './assets/i18n/scenario_messages_', suffix: '.json' },
    { prefix: './assets/i18n/support_', suffix: '.json' },
    { prefix: './assets/i18n/renewable_profile_', suffix: '.json' },
    { prefix: './assets/i18n/user_preferences_', suffix: '.json' },
    { prefix: './assets/i18n/tdb_', suffix: '.json' },
  ]);
}
