import { ChangeDetectorRef, Directive, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BaseFormComponent } from '../base-form';

@Directive()
export abstract class FormDialogComponent<C, T> extends BaseFormComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public changeDetector: ChangeDetectorRef,
    public dialogRef: MatDialogRef<C>,
    public formBuilder: FormBuilder,
  ) {
    super(null, changeDetector, formBuilder);
  }

  abstract defineForm(): any;

  onConfirm() {
    const value = this.form.getRawValue();
    if (this.form.valid && !!value) {
      this.dialogRef.close(value);
    }
  }

  onClose() {
    this.dialogRef.close();
  }
}
