<div>
  <div class="banner" *ngFor="let entity of unsavedEntityList | ngrxPush">
    <span
      class="content flex-auto"
      [innerHtml]="
        'Scenario.messages.energyVector.missing' | translate : entity
      "
    ></span>
    <button
      mat-button
      type="button"
      color="primary"
      [disabled]="entity.isSaving"
      [class.spinner]="entity.isSaving"
      [attr.data-testid]="'unsaved-banner-btn-' + entity.name"
      (click)="onClickAddButton(entity)"
    >
      <mat-icon matPrefix>add</mat-icon
      ><b>{{ 'Generic.labels.associate' | translate }}</b>
    </button>
  </div>
</div>
