<mat-card class="lines-investment-parent mat-elevation-z2">
  <div div fxLayout="column" fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-around">
      <!-- origin node-->
      <prosumer-filter-chips
        fxFlex="32%"
        filterBy="name"
        filterTitle="Origin Node"
        [dataSource]="originNodeOptions$ | async"
        (selectedDataSource)="
          getFilteredData($event, filterForm?.controls?.origin)
        "
      >
      </prosumer-filter-chips>
      <!-- destination node-->
      <prosumer-filter-chips
        fxFlex="32%"
        filterBy="name"
        filterTitle="Destination Node"
        [dataSource]="destNodeOptions$ | async"
        (selectedDataSource)="
          getFilteredData($event, filterForm?.controls?.destination)
        "
      >
      </prosumer-filter-chips>
      <!--energy vector-->
      <prosumer-filter-chips
        fxFlex="32%"
        filterBy="name"
        filterTitle="Energy Vector"
        [dataSource]="fluidOptions$ | async"
        (selectedDataSource)="
          getFilteredData($event, filterForm?.controls?.fluid)
        "
      >
      </prosumer-filter-chips>
    </div>

    <mat-table matSort class="eyes-table" [dataSource]="dataSource">
      <ng-container
        *ngFor="let column of columnsDef | keyvalue"
        [cdkColumnDef]="column.key"
      >
        <mat-header-cell
          *cdkHeaderCellDef
          mat-sort-header
          class="align-start"
          [ngClass]="{ 'align-end': column.value.rightAlign }"
          [fxLayoutAlign]="column.value.rightAlign ? 'end' : 'start'"
        >
          {{ column.value.name
          }}<prosumer-tooltip-anchor
            [message]="column.value.headerTooltip"
          ></prosumer-tooltip-anchor>
        </mat-header-cell>
        <mat-cell
          *cdkCellDef="let row"
          class="align-start"
          [ngClass]="{ 'align-end': column.value.rightAlign }"
          [fxLayoutAlign]="column.value.rightAlign ? 'end' : 'start'"
        >
          <div
            *ngIf="
              column.value.metric && column.value.decimals;
              else decimalsOnly
            "
          >
            {{ row[column.key] | metric : column.value.decimals }}
          </div>
          <ng-template #decimalsOnly>
            <div *ngIf="column.value.decimalsOnly; else default">
              {{
                row[column.key]
                  | number
                    : '1.' + column.value.decimals + '-' + column.value.decimals
              }}
            </div>
          </ng-template>
          <ng-template #default>
            {{ row[column.key] }}
          </ng-template>
        </mat-cell>
      </ng-container>

      <mat-header-row *cdkHeaderRowDef="columnsDef | keys"></mat-header-row>
      <mat-row
        *cdkRowDef="let row; columns: columnsDef | keys"
        [@rowAnimation]
      ></mat-row>
    </mat-table>

    <prosumer-table-message
      [noRecordsMessage]="noRecordMessage"
      [style.margin-left.px]="20"
      [style.margin-top.px]="5"
      [loading]="loadingList$ | async"
      [error]="error$ | async"
      [dataLength]="dataSourceLength$ | async"
    >
    </prosumer-table-message>
  </div>
</mat-card>
