import {
  BaseFormComponent,
  collectItemsWithKey,
  findInArray,
  findInFormFieldOptions,
  FormFieldErrorMessageMap,
  FormFieldOption,
  isKeyPressedNumber,
} from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, delay, map, startWith } from 'rxjs/operators';

/* eslint-disable @typescript-eslint/naming-convention */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  NgControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { EnhancedLoadsInput } from './enhanced-loads-input.model';

/**
 * constants for no of days per month.
 */
export const arrDaysAndMonths = () => ({
  January: 31,
  February: 28,
  March: 31,
  April: 30,
  May: 31,
  June: 30,
  July: 31,
  August: 31,
  September: 30,
  October: 31,
  November: 30,
  December: 31,
});

@Component({
  selector: 'prosumer-enhanced-loads-input',
  templateUrl: './enhanced-loads-input.component.html',
  styleUrls: ['./enhanced-loads-input.component.scss'],
})
export class EnhancedLoadsInputComponent
  extends BaseFormComponent
  implements OnInit, AfterViewInit
{
  currentLoadValues$ = new BehaviorSubject<Array<string>>([]); // current load values from the write value
  /**
   * Observable that contains load profile data to be displayed in the scroll panel.
   */
  loadListControl$ = new BehaviorSubject<Array<EnhancedLoadsInput>>([]);

  loadPasteControl = new FormControl();
  loadProfileInput = new FormControl('0', Validators.required);
  dragOver = false;
  validating$ = new BehaviorSubject(false);
  isCopied$ = new BehaviorSubject(false);
  manualInput$ = new BehaviorSubject(false);
  currentEditIndex$ = new BehaviorSubject(-1);
  editMode$ = new BehaviorSubject(false);
  currentLoadValues: Array<string> = [];
  hasInputValueChanges$ = new BehaviorSubject(false);
  hasError$ = new BehaviorSubject(false);
  isInitalData = false;
  errorMessage = '';
  nodesUsed = [];
  nodesUsed$ = new BehaviorSubject<string[]>([]);

  errorMessageMap: FormFieldErrorMessageMap = {
    input: {
      required: this._translate.instant(
        'Scenario.messages.yearlyLoad.input.required',
      ),
      mustBePositiveNumber: this._translate.instant(
        'Scenario.messages.yearlyLoad.input.mustBePositiveNumber',
      ),
      notANumber: this._translate.instant(
        'Scenario.messages.yearlyLoad.input.notANumber',
      ),
      invalidNode: this._translate.instant(
        'Scenario.messages.yearlyLoad.input.invalidNode',
      ),
      commaExist: this._translate.instant(
        'Scenario.messages.yearlyLoad.input.commaExist',
      ),
    },
  };

  canUpload$ = combineLatest([
    this.loadListControl$.pipe(startWith([])),
    this.manualInput$.pipe(startWith(false)),
  ]).pipe(
    map(
      ([loadListData, manualInput]) =>
        loadListData.length === 0 && !manualInput,
    ),
  );

  @Output() dataLoadChanges: EventEmitter<Array<string>> = new EventEmitter<
    Array<string>
  >();
  @Output() dataErrorsOutput: EventEmitter<Array<string>> = new EventEmitter<
    Array<string>
  >();
  @Output() nodesUsedEmitter: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  @Input() panelLabel: string;
  @Input() tooltipMessage: string;
  @Input() loadRequiredMessage: string;
  @Input() set customValidators(validators: Array<ValidatorFn>) {
    this.loadProfileInput.setValidators(validators);
  }

  @Input() nodeOptions: FormFieldOption<string>[];
  @Input() allowStringInput = false;
  @Input() allowNegativeInput = false;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: FormBuilder,
    private _translate: TranslateService,
  ) {
    super(ngControl, changeDetector, formBuilder);
  }

  ngOnInit(): void {
    this.loadPasteControl.setValue('');
    this.hasInputValueChanges$
      .pipe(delay(400), this.takeUntilShare())
      .subscribe((bool) => {
        if (bool) {
          this.dataLoadChanges.emit(
            this.parseLoadTo(this.loadListControl$.value),
          );
          this.hasInputValueChanges$.next(false);
        }
      });

    this.loadListControl$.subscribe((data: Array<EnhancedLoadsInput>) => {
      this.validating$.next(false);
    });

    this.currentLoadValues$.subscribe((data: Array<string>) => {
      if (!!data && typeof data === 'string') {
        data = this.parseLoadToArray(data);
      }
      this.currentLoadValues = data;
      this.patchValues(data);
    });
  }

  ngAfterViewInit(): void {
    if (this.ngControl) {
      this.ngControl.valueChanges.subscribe((data: string) => {
        if (!!data && typeof data === 'string') {
          this.patchValues(this.parseLoadToArray(data));
        }
      });
    }

    this.loadProfileInput.valueChanges
      .pipe(debounceTime(600), this.takeUntilShare())
      .subscribe((data) => this.loadProfileInputValueChange(data));

    this.nodesUsed$.pipe().subscribe((nodes) => {
      if (nodes) {
        this.nodesUsedEmitter.emit([...new Set(nodes)]);
      }
    });
  }

  /**
   * Checks for errors on value change
   *
   * @param data - value of loadProfileInput
   */
  loadProfileInputValueChange(data: any) {
    const oldValue = this.currentLoadValues[this.currentEditIndex$.value];

    if (
      !this.allowNegativeInput &&
      this.allowStringInput &&
      data &&
      data !== '0' &&
      data !== '0.' &&
      !!!parseFloat(data) &&
      !data.toString().startsWith('n:')
    ) {
      const nodeNames = collectItemsWithKey(this.nodeOptions, 'name');
      let index = findInArray(nodeNames, data);
      if (index !== -1) {
        data = `n:${this.nodeOptions[index].value}`;
        this.loadProfileInput.setErrors(null);
        this.nodesUsed.push(this.nodeOptions[index].value);
        this.nodesUsed$.next(this.nodesUsed);
        index = -1;
      } else {
        this.loadProfileInput.setErrors({ invalidNode: { value: data } });
      }
    }

    // emit error when there's any
    if (!!this.loadProfileInput.errors) {
      /* Instead of emitting the errors, emit the data to
       * dataErrorsOutput so that the recepient can revert the
       * loadError is user revert the value back to default
       */
      this.currentLoadValues[this.currentEditIndex$.value] = data;
      this.patchValues(this.currentLoadValues);
      this.dataErrorsOutput.emit(data);
      this.isInitalData = false;
      return;
    }

    if (this.isInitalData || (!!data && data !== oldValue)) {
      this.currentLoadValues[this.currentEditIndex$.value] = data;
      this.patchValues(this.currentLoadValues);
      this.hasInputValueChanges$.next(true);
      this.isInitalData = false;
    }
  }

  /**
   * Patch values to the controls.
   *
   * @param load load profile values.
   */
  patchValues(load: Array<string>): void {
    const loadData: Array<EnhancedLoadsInput> = this.parseLoadFrom(load);
    this.loadListControl$.next(loadData);
  }

  writeValue(load: Array<string>) {
    if (!!load && load.length > 0) {
      this.currentLoadValues$.next(load);
      // this.hasData$.next(true);
    }
  }

  /**
   * Define form of the enhanced loads -input
   */
  defineForm(): any {
    return {
      // loadProfileInput: this.formBuilder.control('0', Validators.required),
    };
  }

  /**
   * Parse load from file reader result.
   *
   * @param loadData file reader result.
   * @param defaultValue optional value to fill profile with. If not provided default value = 0
   */
  parseLoadFromFileResult(
    loadData: string,
    defaultValue?: string,
  ): Array<EnhancedLoadsInput> {
    this.validating$.next(true);
    this.currentEditIndex$.next(-1);
    this.errorMessage = '';
    if (!!loadData) {
      const listData = this.parseLoadToArray(loadData);
      // parse defaultValue to nodeId if given value is nodeName. Returns unmutated value if not
      const parsedDefaultValue =
        !!defaultValue && this.allowStringInput
          ? this.toNodeId([defaultValue])[0]
          : defaultValue;
      const errors = this.validateItems(listData);
      if (!!errors && Object.keys(errors).length > 0) {
        this.hasError$.next(true);
        this.errorMessage = this.formatErrorMessage(errors);
      } else {
        const result = this.parseLoadFrom(listData, parsedDefaultValue);
        // create fillerList only iff defaultValue is given. Let prosumer core handle filling up values.
        const fillerList = defaultValue
          ? this.createList(listData.length, parsedDefaultValue)
          : [];
        const resultList = [...listData, ...fillerList];
        this.dataLoadChanges.emit(resultList);
        this.hasError$.next(false);
        return result;
      }
    }
    return [];
  }

  /**
   * This method formats the list of errors to string.
   *
   * @param errors list of errors to format.
   */
  formatErrorMessage(errors: { [key: number]: string }): string {
    if (!!errors && Object.keys(errors).length > 0) {
      let message = 'The following items are invalid. <br> [Line No. : Value]';
      Object.keys(errors).forEach((key) => {
        const index = parseFloat(key) + 1;
        message = message
          .concat('<br>')
          .concat(index.toString())
          .concat(' : ')
          .concat(errors[key]);
      });
      return message;
    }
    return undefined;
  }

  /**
   * Parse and transform loads to EhhancedLoadsInput object.
   *
   * @param listData list of values.
   * @return List of EnhancedLoadsInput.
   */
  parseLoadFrom(
    listData: Array<string>,
    defaultValue?: string,
  ): Array<EnhancedLoadsInput> {
    let results: Array<EnhancedLoadsInput> = [];
    if (!!listData && listData instanceof Array && listData.length > 0) {
      if (listData.length < 8760) {
        // add items with value = 0 or defaultValue if given
        const newList = this.createList(listData.length, defaultValue);
        listData = [...listData, ...newList];
      }
      results = this.generateLoadInfo(listData);
    }
    this.currentLoadValues = listData;
    return results;
  }

  /**
   * Generates other information like month, day, hour and period.
   *
   * @param listData data source.
   */
  generateLoadInfo(listData: Array<string>): Array<EnhancedLoadsInput> {
    const results: Array<EnhancedLoadsInput> = [];
    // from results need to iterate the number of days per month
    let startMonthHour = 0;
    Object.keys(arrDaysAndMonths()).forEach((month) => {
      let startDayHour = 0;
      const endMonthHour = startMonthHour + arrDaysAndMonths()[month] * 24;
      let day = 1;
      const sliceList = listData.slice(startMonthHour, endMonthHour);
      sliceList.forEach((loadVal) => {
        const period = startDayHour < 12 ? 'AM' : 'PM';
        const hour =
          startDayHour === 0
            ? 12
            : startDayHour > 12
            ? startDayHour - 12
            : startDayHour;
        const mo = month.substring(0, 3);
        const ehLoadItem: EnhancedLoadsInput = {
          name: loadVal,
          value: loadVal,
          month: mo,
          day,
          hour,
          period,
        };
        results.push(ehLoadItem);
        day = startDayHour === 23 ? day + 1 : day;
        startDayHour = startDayHour === 23 ? 0 : startDayHour + 1;
      });
      startMonthHour = endMonthHour;
    });
    return results;
  }

  /**
   * Parses data and transforms to list of string.
   *
   * @param loadValue data to parse.
   */
  parseLoadToArray(loadValue: string): Array<string> {
    let loads = [];
    if (!!loadValue && typeof loadValue === 'string') {
      loads = loadValue
        .split('\n')
        .filter((data) => data && data.trim() !== '');
      if (this.allowStringInput && !this.isInitalData) {
        // replace strings to equivalent node id
        loads = this.toNodeId(loads);
      }
    }
    return loads;
  }

  /**
   * Update node name to node id
   *
   * @param loads- initial parsed load
   */
  toNodeId(loads: string[]): string[] {
    const errors = [];
    if (loads) {
      const nodeNames = collectItemsWithKey(this.nodeOptions, 'name');
      loads.forEach((item) => {
        if (!!!parseFloat(item)) {
          let index = findInArray(nodeNames, item);
          if (index !== -1) {
            loads[loads.indexOf(item)] = `n:${this.nodeOptions[index].value}`;
            this.nodesUsed.push(this.nodeOptions[index].value);
            this.nodesUsed$.next(this.nodesUsed);
            index = -1;
          }
        }
      });
    }
    return loads;
  }

  /**
   * Parses list of EnhancedLoadsInput object and transforms to list of string.
   *
   * @param listData data to parse.
   */
  parseLoadTo(listData: Array<EnhancedLoadsInput>): Array<string> {
    let loadList: Array<string> = [];
    if (!!listData && listData.length > 0) {
      loadList = listData.map((data) => data.value);
    }
    return loadList;
  }

  /**
   * This method validates each item on the given loadValues and
   * returns the the list indices with error
   *
   * @param loadValues list to validate.
   */
  validateItems(loadValues: Array<string>): { [key: number]: string } {
    const indexValueErrorMap = {};

    loadValues.forEach((value, index) => {
      // let the control do the validation for consistency
      this.loadProfileInput.patchValue(value, { emitEvent: false });
      if (!!this.loadProfileInput.errors) {
        indexValueErrorMap[index] = value;
      }
      // this.loadProfileInput.setErrors(null);
    });
    return indexValueErrorMap;
  }

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragOver = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragOver = false;
  }

  @HostListener('drop', ['$event'])
  ondrop(evt) {
    if (this.loadListControl$.getValue().length > 0) {
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
    this.dragOver = false;
    this.validating$.next(true);

    const files = evt.dataTransfer.files;
    if (files && files.length) {
      if (!files[0].name.toLowerCase().endsWith('csv')) {
        //  invalid file
        this.errorMessage = 'The file uploaded is not a valid csv file.';
        this.hasError$.next(true);
        this.validating$.next(false);
        return;
      }
      this.hasError$.next(false);
      this.readFiles(files);
    }
  }

  /**
   * Read first file.
   *
   * @param files files to read.
   */
  readFiles(files) {
    if (files && files.length) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const loadData = this.parseLoadFromFileResult(
          fileReader.result as string,
        );
        this.loadListControl$.next(loadData);
      };
      // Only read one file, ignore the rest
      fileReader.readAsText(files[0]);
    }
  }

  /**
   * Action to be taken to copy values on loadList.
   */
  onCopy() {
    const addToClipboard = (clip) => {
      navigator.clipboard.writeText(clip);
    };
    const value = this.loadListControl$.value;
    const csvFile = this.arrayToCSV(value);

    addToClipboard(csvFile);
    this.isCopied$.next(true);

    setTimeout(() => {
      this.isCopied$.next(false);
    }, 1000);
  }

  /**
   * Parse data to CSV string format.
   *
   * @param rows current values of loadList
   */
  arrayToCSV(rows) {
    const processRow = (row) => {
      const innerValue = row?.value === null ? '0' : row?.value.toString();
      const vale = this.transformLineValue(innerValue);
      const result = vale.replace(/"/g, '""');
      return result + '\n';
    };

    let csvFile = '';
    for (const row of rows) {
      csvFile += processRow(row);
    }
    return csvFile;
  }

  /**
   * Action to be taken when from copy and paste.
   *
   * @param event onPaste event.
   */
  onPaste(event) {
    this.validating$.next(true);
    // @ts-ignore
    const clipboardData = event.clipboardData || window.clipboardData;
    const dataInput = clipboardData.getData('text');
    this.fillupLoadValues(dataInput);
  }

  fillupLoadValues(dataInput: any, defaultValue?: string) {
    const loadData = this.parseLoadFromFileResult(dataInput, defaultValue);
    // clear the control if no error
    this.loadPasteControl.patchValue(this.hasError$.value ? dataInput : '');
    this.loadListControl$.next(loadData);
    this.manualInput$.next(false);
  }

  /**
   * Action taken when user clicks the button, or clicks enter key in #singleLoadValue
   * input element. Fills up the 8760 value with user input value.
   *
   * @param event - the keyboard event
   * @param value - value inside of #singleLoadValue input element
   */
  onSingleValueEnter(event, value: any) {
    this.fillupLoadValues(value, value);
    event?.preventDefault();
  }

  /**
   * Handles key press events for the load profile field to prevent non-number values
   *
   * @param event - the keyboard event
   */
  onKeyPress(event: KeyboardEvent) {
    if (!this.allowStringInput && !isKeyPressedNumber(event)) {
      event.preventDefault();
    }
  }

  /**
   * Resets the value of the copy and paste input field.
   *
   * @param event focus event.
   */
  inputCopyPasteFocusOut(event) {
    this.loadPasteControl.patchValue('');
    this.loadPasteControl.updateValueAndValidity({
      onlySelf: true,
      emitEvent: true,
    });
  }

  /**
   * Clear values of the list.
   */
  onClearValues() {
    this.currentLoadValues$.next([]);
    this.loadListControl$.next([]);
    this.manualInput$.next(false);
    this.dataLoadChanges.emit([]);
  }

  /**
   * sets the editing to manual.
   */
  onInputManually() {
    this.errorMessage = '';
    this.loadProfileInput.setErrors(null);
    this.manualInput$.next(true);
    this.hasError$.next(false);
    this.initializeListControl();
    this.onEdit(0);
  }

  /**
   * Initialize list of items that starts from the given index.
   */
  initializeListControl() {
    const initDataList: Array<string> = this.createList(0);
    this.currentLoadValues$.next(initDataList);
    this.isInitalData = true;
  }

  /**
   * Creates list of item with defaultValue or 0 if no defaultValue param provided as filler value based on the start index given
   * and ends with index = 8759;
   *
   * @param startIndex start index.
   * @param defaultValue optional value to override 0 filler value
   */
  createList(startIndex: number, defaultValue?: string): Array<string> {
    let index = startIndex;
    const initDataList: Array<string> = [];
    while (index < 8760) {
      initDataList.push(defaultValue || '0');
      index += 1;
    }

    return initDataList;
  }

  /**
   * Handles span line value - when node name should be shown to user
   * instead of the node id
   *
   * @param value - single profile value from UI
   */
  transformLineValue(value: string): string {
    if (
      this.allowStringInput &&
      !!!parseFloat(value) &&
      this.nodeOptions &&
      value !== '0' &&
      value.startsWith('n:')
    ) {
      this.nodesUsed.push(value.split(':')[1]);
      this.nodesUsed$.next(this.nodesUsed);
      return findInFormFieldOptions(this.nodeOptions, value.split(':')[1]).name;
    }
    return value;
  }

  /**
   * Get the active item to be edited.
   *
   * @param index item index.
   */
  onEdit(index: number) {
    if (!!this.loadProfileInput.errors) {
      return;
    }
    this.currentEditIndex$.next(index);
    this.editMode$.next(true);
    const activeItem = this.currentLoadValues[index];
    this.loadProfileInput.patchValue(this.transformLineValue(activeItem));
    this.loadProfileInput.markAsTouched();
  }

  /**
   * Action taken when the input field is out of focus.
   *
   * @param index item of the index to unfocus.
   */
  onFocusOut(event: FocusEvent, index?: number) {
    if (!!this.loadProfileInput.errors || !this.editMode$.getValue()) {
      event.preventDefault();
    } else {
      this.editMode$.next(false);
      this.currentEditIndex$.next(-1);
      this.loadListControl$.next(this.loadListControl$.value);
    }
  }
}
