<prosumer-page>
  <prosumer-progress-card [showProgress]="loadingList$ | async">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <prosumer-filter-input
        (filter)="onFilter($event)"
        class="eyes-maximize-width"
      ></prosumer-filter-input>
      <div fxFlex></div>
      <a
        mat-button
        type="button"
        color="primary"
        [routerLink]="routePath?.createProjectRelative()"
      >
        <mat-icon matPrefix>add</mat-icon><b>NEW</b>
      </a>
    </div>

    <mat-table
      matSort
      class="eyes-table"
      [dataSource]="projectDataSource$ | async"
      [attr.data-testid]="'project-list-page-table'"
    >
      <ng-container cdkColumnDef="name">
        <mat-header-cell
          *cdkHeaderCellDef
          mat-sort-header
          data-testid="project-name-header-cell"
        >
          Project Name<prosumer-tooltip-anchor
            [message]="'projects.project_name'"
            tooltipPosition="right"
          ></prosumer-tooltip-anchor>
        </mat-header-cell>
        <mat-cell
          *cdkCellDef="let row"
          [attr.data-testid]="'project-name-data-cell'"
        >
          <ngx-skeleton-loader
            *ngIf="row?.loading && !row?.loaded && !row?.error; else loaded"
            class="eyes-maximize-width"
          ></ngx-skeleton-loader>
          <ng-template #loaded>
            <a
              [routerLink]="
                !row?.deleting && !row?.error && !row.unsharing
                  ? routePath?.projectDetailRelative(row?.id)
                  : []
              "
              >{{ row.name || '-' }}</a
            >
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="owner">
        <mat-header-cell
          *cdkHeaderCellDef
          mat-sort-header
          [attr.data-testid]="'created-by-header-cell'"
        >
          Created By<prosumer-tooltip-anchor
            [message]="'projects.project_created_by'"
            tooltipPosition="right"
          ></prosumer-tooltip-anchor>
        </mat-header-cell>
        <mat-cell
          *cdkCellDef="let row"
          [attr.data-testid]="'created-by-data-cell'"
        >
          <prosumer-owner-resolver class="eyes-maximize-width" [data]="row">
          </prosumer-owner-resolver>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="createdDate">
        <mat-header-cell
          *cdkHeaderCellDef
          mat-sort-header
          [attr.data-testid]="'created-on-header-cell'"
        >
          Created On<prosumer-tooltip-anchor
            [message]="'projects.project_created_on'"
            tooltipPosition="right"
          ></prosumer-tooltip-anchor>
        </mat-header-cell>
        <mat-cell
          *cdkCellDef="let row"
          [attr.data-testid]="'created-on-data-cell'"
        >
          <ngx-skeleton-loader
            *ngIf="row?.loading && !row?.loaded && !row?.error; else loaded"
            class="eyes-maximize-width"
          ></ngx-skeleton-loader>
          <ng-template #loaded>
            {{ (row.createdDate | dateFormat) || '-' }}
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="actions">
        <mat-header-cell
          *cdkHeaderCellDef
          [attr.data-testid]="'actions-header-cell'"
          >Actions</mat-header-cell
        >
        <mat-cell *cdkCellDef="let row">
          <ngx-skeleton-loader
            *ngIf="row?.loading && !row?.loaded && !row?.error; else loaded"
            class="eyes-maximize-width"
          ></ngx-skeleton-loader>
          <ng-template #loaded>
            <div style="position: relative">
              <button
                mat-icon-button
                type="button"
                color="primary"
                [disabled]="
                  row?.copying ||
                  row?.copyStatus ||
                  row?.error ||
                  row?.deleting ||
                  row?.sharing ||
                  row?.unsharing
                "
                (click)="onCopy(row)"
                [attr.data-testid]="'project-duplicate-' + row?.name"
              >
                <mat-icon
                  aria-label="Copy"
                  tooltipPosition="center"
                  eyesTooltip="Duplicate Project"
                  >file_copy</mat-icon
                >
              </button>
              <mat-spinner
                *ngIf="row?.copying"
                diameter="40"
                class="eyes-absolute-top"
                color="accent"
              >
              </mat-spinner>
            </div>

            <div style="position: relative">
              <button
                mat-icon-button
                type="button"
                color="primary"
                [disabled]="
                  row?.deleting ||
                  row?.copyStatus ||
                  row?.error ||
                  row?.copying ||
                  row?.sharing ||
                  row?.unsharing ||
                  (hasAccess$(row, 'CAN_DELETE') | async) !== true
                "
                (click)="onDelete(row)"
                [attr.data-testid]="'project-delete-' + row?.name"
              >
                <mat-icon
                  aria-label="Delete"
                  tooltipPosition="center"
                  [prosumerTooltip]="'projects.project_delete'"
                  >delete_forever</mat-icon
                >
              </button>
              <mat-spinner
                *ngIf="row?.deleting"
                diameter="40"
                class="eyes-absolute-top"
                color="accent"
              >
              </mat-spinner>
            </div>

            <div
              style="position: relative"
              *ngIf="
                (hasAccess$(row, 'CAN_SHARE') | async) === true;
                else cantShare
              "
            >
              <button
                mat-icon-button
                type="button"
                color="primary"
                [attr.data-testid]="'share-project-' + row?.name"
                [disabled]="row?.sharing || row?.error"
                (click)="onShare(row)"
              >
                <mat-icon
                  aria-label="Share"
                  tooltipPosition="center"
                  eyesTooltip="Share Project"
                  class="prosumer-share-icon"
                  matBadgeColor="warn"
                  matBadgeSize="small"
                  [matBadge]="row?.sharedTo?.length"
                  [matBadgeHidden]="
                    row?.sharedTo?.length === 0 ||
                    (hasAccess$(row, 'CAN_SHARE') | async) !== true
                  "
                  >person_add</mat-icon
                >
              </button>
              <mat-spinner
                *ngIf="row?.sharing"
                diameter="40"
                class="eyes-absolute-top"
                color="accent"
              >
              </mat-spinner>
            </div>
            <ng-template #cantShare>
              <div style="position: relative">
                <button
                  mat-icon-button
                  type="button"
                  color="primary"
                  [attr.data-testid]="'share-project-' + row?.name"
                  [disabled]="row?.sharing || row?.error || row?.unsharing"
                  (click)="onUnshare(row)"
                >
                  <!-- (click)="onShare(row)" -->
                  <mat-icon
                    aria-label="Share"
                    tooltipPosition="center"
                    eyesTooltip="Share Project"
                    class="material-icons-outlined"
                    >link_off</mat-icon
                  >
                  <!-- class="prosumer-share-icon" -->
                </button>
                <mat-spinner
                  *ngIf="row?.unsharing"
                  diameter="40"
                  class="eyes-absolute-top"
                  color="accent"
                >
                </mat-spinner>
              </div>
            </ng-template>
          </ng-template>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *cdkHeaderRowDef="['name', 'owner', 'createdDate', 'actions']"
      ></mat-header-row>
      <mat-row
        *cdkRowDef="
          let row;
          columns: ['name', 'owner', 'createdDate', 'actions']
        "
        [@rowAnimation]
      ></mat-row>
    </mat-table>

    <prosumer-table-message
      [loading]="loadingList$ | async"
      [dataLength]="projectDataLength$ | async"
      [filteredDataLength]="filteredDataLength$ | async"
      [filter]="filter$ | async"
    >
    </prosumer-table-message>

    <mat-paginator
      *ngIf="(projects$ | async)?.length > 50"
      hidePageSize
      showFirstLastButtons
      pageSize="50"
    ></mat-paginator>
  </prosumer-progress-card>
</prosumer-page>
