import { Injectable } from '@angular/core';
import { Chart } from 'chart.js';
import C2S from 'canvas2svg';
import * as XLSX from 'xlsx';

@Injectable()
export class ChartExporterService {
  exportAsSvg(chart: Chart, fileName: string, showLegend: boolean) {
    if (!!!chart) {
      return;
    }

    chart = this.initChartForDownload(chart, showLegend);
    const base64Image = chart.toBase64Image();
    const { width, height } = chart;
    const ctx = new C2S(width, height);
    const image = new Image(width, height);
    image.onload = () => {
      // convert svg source to URI data scheme.
      ctx.drawImage(image, 0, 0, width, height);
      const svg = ctx.getSvg();
      const serializer = new XMLSerializer();
      let source = serializer.serializeToString(svg);
      if (
        !source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)
      ) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns="http://www.w3.org/2000/svg"',
        );
      }
      if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns:xlink="http://www.w3.org/1999/xlink"',
        );
      }
      source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
      const url =
        'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source);
      this.save(url, fileName, 'svg');
    };
    image.src = base64Image;
    this.removeLegend(chart);
  }

  exportAsPng(chart: Chart, fileName: string, showLegend: boolean) {
    if (!!!chart) {
      return;
    }
    chart = this.initChartForDownload(chart, showLegend);
    this.save(chart.toBase64Image(), fileName, 'png');
    this.removeLegend(chart);
  }

  exportAsWorkbook(
    data: string[][],
    fileName: string,
    bookType: XLSX.BookType,
  ) {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
    this.writeToFile(ws, fileName, bookType);
  }

  private save(data: string, name: string, type: string) {
    const a = document.createElement('a');
    a.href = data;
    a.download = `${name}.${type}`;
    a.click();
  }

  private initChartForDownload(chart: Chart, showLegend: boolean): Chart {
    chart.config.options.plugins.legend.display = showLegend;
    chart.update('none');
    return chart;
  }

  private removeLegend(chart: Chart): void {
    chart.config.options.plugins.legend.display = false;
    chart.update('none');
  }

  private writeToFile(
    ws: XLSX.WorkSheet,
    fileName: string,
    bookType: XLSX.BookType,
  ) {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName.substring(0, 30)); // max sheet char is 30
    XLSX.writeFile(wb, `${fileName}.${bookType}`, { bookType });
  }
}
