import {
  fadeInAnimation,
  FormFieldOption,
  InputErrorMessageMap,
  PageMode,
} from 'prosumer-app/libs/eyes-shared';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { NameValidator } from 'prosumer-app/shared';
import { Case } from '../../models';

@Component({
  selector: 'prosumer-case-form',
  templateUrl: './case-form.component.html',
  styleUrls: ['./case-form.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseFormComponent {
  nodeTypeOptions: Array<FormFieldOption<string>> = [
    { name: 'Single', value: 'single' },
    { name: 'Multiple', value: 'multiple' },
  ];

  errorMessages: InputErrorMessageMap = {
    wacc: {
      required: this._translate.instant('Case.messages.wacc.required'),
    },
  };

  @Input() loading: boolean;

  @Input() set saving(saving: boolean) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    saving
      ? this.caseForm.disable({ emitEvent: false })
      : this.caseForm.enable({ emitEvent: false });
    this._saving = saving;
  }

  get saving(): boolean {
    return this._saving;
  }

  @Input() canEdit: boolean;
  @Input() owner: string;
  @Input() mode: PageMode;
  @Input() set data(data: any) {
    this._data = data;
    if (data && (this.mode === 'update' || this.mode === 'read')) {
      this.caseForm.patchValue(this._data);
    }
  }

  @Output() save = new EventEmitter<Case>();
  @Output() cancel = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();

  caseForm = this._formBuilder.group({
    name: ['', [NameValidator.validWithCore()]],
    description: '',
    // Default to single
    nodeType: this.nodeTypeOptions[0].value,
    wacc: '0.0',
    createdDate: '',
    id: '',
    projectId: '',
  });

  _saving: boolean;
  _data: Case;

  constructor(
    private _formBuilder: FormBuilder,
    private _translate: TranslateService,
  ) {}

  onSubmit(): void {
    if (this.caseForm.valid) {
      this.save.emit(this.caseForm.value);
    }
  }

  onCancel() {
    if (this.mode === 'update') {
      this.caseForm.reset(this._data);
    }
    this.cancel.emit();
  }

  onEdit() {
    this.edit.emit();
  }
}
