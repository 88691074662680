<form [formGroup]="vehicleStationForm" class="form">
  <h2 class="mat-title" mat-dialog-title>
    {{ data?.mode | titlecase }}
    {{
      'Scenario.labels.wizard.mobility.headers.stationVehicleAssociation'
        | translate
    }}
  </h2>
  <div class="mat-body" mat-dialog-content fxLayoutGap="10px">
    <div fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px">
        <div fxLayoutGap="10px" fxFlex="50">
          <prosumer-select
            id="vehicleId"
            label="{{
              'Scenario.labels.wizard.mobility.labels.vehicle' | translate
            }}"
            placeholder="{{
              'Scenario.placeholders.mobility.vehicleStationAssoc.vehicleId'
                | translate
            }}"
            [control]="vehicleStationForm?.controls?.vehicleId"
            [options]="vehicleOptions"
            [errorMessageMap]="errorMessages?.vehicle"
            [required]="true"
            data-testid="vehicle-technology"
          ></prosumer-select>
        </div>
        <div fxLayoutGap="10px" fxFlex="50">
          <prosumer-select
            id="stationId"
            label="{{
              'Scenario.labels.wizard.mobility.labels.station' | translate
            }}"
            placeholder="{{
              'Scenario.placeholders.mobility.vehicleStationAssoc.stationId'
                | translate
            }}"
            [control]="vehicleStationForm?.controls?.stationId"
            [options]="stationOptions"
            [errorMessageMap]="errorMessages?.station"
            [required]="true"
            data-testid="station"
          ></prosumer-select>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="space-around stretch"
      >
        <!-- Vehicle Name Input -->
        <prosumer-generic-filterchip-component
          fxFlex="100%"
          label="{{
            'Scenario.labels.wizard.mobility.labels.vehicleStationAssoc.vehicleName'
              | translate
          }}"
          placeholder="{{
            'Scenario.placeholders.mobility.vehicleStationAssoc.vehicleName'
              | translate
          }}"
          [enableSelectAll]="true"
          [tooltip]="
            'wizard_mobility.wizard_mobility_station_vehicle_vehicle_name'
          "
          [required]="true"
          [genericOptions]="vehicleNameOptions"
          formControlName="vehicleNames"
          [errorMessage]="errorMessages?.vehicleName"
          [setSubmitted]="submitted$ | async"
          [setCustomErrorMessage]="vehicleNameErrorMessage$ | async"
          data-testid="vehicle-name"
        >
        </prosumer-generic-filterchip-component>
      </div>

      <div
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="space-around stretch"
      >
        <!-- Station Node Input -->
        <prosumer-generic-filterchip-component
          *ngIf="data?.isMultiNode; else single"
          fxFlex="100%"
          label="{{
            'Scenario.labels.wizard.mobility.labels.vehicleStationAssoc.stationNode'
              | translate
          }}"
          placeholder="{{
            'Scenario.placeholders.mobility.vehicleStationAssoc.stationNode'
              | translate
          }}"
          [enableSelectAll]="true"
          [tooltip]="
            'wizard_mobility.wizard_mobility_station_vehicle_station_node'
          "
          [required]="true"
          [genericOptions]="stationNodeOptions"
          formControlName="stationNodes"
          [errorMessage]="errorMessages?.stationNode"
          [setSubmitted]="submitted$ | async"
          [setCustomErrorMessage]="stationNodeErrorMessage$ | async"
          data-testid="station-node"
        >
        </prosumer-generic-filterchip-component>
        <ng-template #single>
          <div fxFlex="100%"></div>
        </ng-template>
      </div>
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutGap="8px">
    <prosumer-entity-upsert-button
      *ngIf="!isViewOnly"
      [id]="data?.id"
      [disabled]="vehicleStationForm?.pristine"
      [data]="getFormValues()"
      [mode]="data?.mode"
      [valid]="vehicleStationForm?.valid"
      (ok)="onClose()"
      (attempt)="onSaveAttempt()"
    ></prosumer-entity-upsert-button>
    <button
      mat-flat-button
      type="button"
      data-testid="station-vehicle-form-cancel-btn"
      color=""
      (click)="onClose()"
    >
      <span *ngIf="!isViewOnly; else closeButton">{{
        data?.close || ('Generic.labels.cancel' | translate)
      }}</span>
      <ng-template #closeButton>
        <span>{{ 'Generic.labels.close' | translate }}</span>
      </ng-template>
    </button>
  </div>
</form>
