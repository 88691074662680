<div
  *ngIf="data && data.length > 0"
  fxLayout="column"
  fxLayoutAlign="start center"
  fxLayoutGap="30px"
>
  <mat-card-title>
    {{ chartTitle }}
    <prosumer-tooltip-anchor
      [message]="'output_main.output_main_avg_daily_production'"
    ></prosumer-tooltip-anchor>
  </mat-card-title>
  <ngx-charts-area-chart-stacked
    [results]="data"
    [view]="chartDimension"
    [xAxis]="chartShowXAxis"
    [showXAxisLabel]="chartShowXAxisLabel"
    [xAxisLabel]="chartXAxisLabel"
    [xAxisTickFormatting]="chartXAxisFormatFunction"
    [yAxis]="chartShowYAxis"
    [showYAxisLabel]="chartShowYAxisLabel"
    [yAxisLabel]="chartYAxisLabel"
    [legend]="chartShowLegend"
    [legendPosition]="chartLegendPosition"
    [timeline]="chartHasTimeline"
    [showGridLines]="chartHasGrid"
    [scheme]="chartScheme"
    (select)="onSelect($event)"
  >
    <ng-template #seriesTooltipTemplate let-model="model">
      <prosumer-series-tooltip
        [currentSeriesData]="model[0]"
        [seriesDataList]="model"
        [unit]="chartYAxisLabel"
        [titleFormat]="chartTooltipTitleFormat"
        format="1.1-1"
      >
      </prosumer-series-tooltip>
    </ng-template>
    <ng-template #tooltipTemplate let-model="model">
      <prosumer-series-tooltip
        [currentSeriesData]="model"
        [seriesDataList]="data | seriesDataList : model : chartScheme"
        [unit]="chartYAxisLabel"
        [titleFormat]="chartTooltipTitleFormat"
        format="1.1-1"
      >
      </prosumer-series-tooltip>
    </ng-template>
  </ngx-charts-area-chart-stacked>
</div>
