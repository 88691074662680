import { Component, Input, OnInit } from '@angular/core';
import { PlotlyData } from './sankey-chart.model';
import { SankeyResult } from '@prosumer/results/models/flow-results.model';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PerceptionMap } from '@prosumer/results/components/case-results-perception';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'prosumer-sankey-chart',
  templateUrl: './sankey-chart.component.html',
  styleUrls: ['./sankey-chart.component.scss'],
})
export class SankeyChartComponent implements OnInit {
  @Input() set sankeyData(data: SankeyResult) {
    if (!!!data) {
      return;
    }
    this.data$.next(data);
  }

  @Input() set colors(data: PerceptionMap) {
    if (!!!data) {
      return;
    }
    this.colors$.next(data);
  }

  data$ = new BehaviorSubject<SankeyResult>(undefined);
  colors$ = new BehaviorSubject<PerceptionMap>(undefined);
  plotlyData: PlotlyData;
  plotlyRevision = 0;

  constructor() {}

  ngOnInit(): void {
    this.subcribeToChange();
  }

  private subcribeToChange() {
    combineLatest([this.data$, this.colors$])
      .pipe(filter(([data, color]) => !!data || !!color))
      .subscribe(([data, color]) => {
        this.plotlyData = this.mapDataToPlotly(data, color);
        this.plotlyRevision++;
      });
  }

  private mapDataToPlotly(
    data: SankeyResult,
    color: PerceptionMap,
  ): PlotlyData {
    if (!!!data || !!!color) {
      return undefined;
    }

    const uniqueLabels = data.nodes.map((node) => node.name);
    const colorMap = uniqueLabels.map((label) => color[label]);

    return {
      data: [
        {
          type: 'sankey',
          orientation: 'h',
          valueformat: '.2f',
          valuesuffix: ' unit',
          node: {
            pad: 15,
            thickness: 10,
            line: { color: 'black', width: 0.5 },
            label: uniqueLabels,
            color: colorMap,
          },
          link: {
            source: data.links.map((d) => uniqueLabels.indexOf(d.source)),
            target: data.links.map((d) => uniqueLabels.indexOf(d.target)),
            value: data.links.map((d) => d.value),
            color: data.links.map(
              (d) => colorMap[uniqueLabels.indexOf(d.source)] + '66',
            ),
          },
        },
      ],
      layout: {
        height: 800,
      },
    };
  }
}
