<div fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start" fxFlex>
      <ng-content></ng-content>
    </div>

    <div fxLayout="row" fxLayoutAlign="end stretch" fxLayoutGap="8px">
      <nav mat-tab-nav-bar *ngrxLet="urlTail$ as tail">
        <a
          mat-tab-link
          data-testid="toInputs"
          [active]="tail === 'update' || tail === 'view'"
          [disabled]="inputDisabled()"
          (click)="onToInputs()"
        >
          Input Data
        </a>
        <button
          class="details-export-btn"
          data-testid="download-input-data"
          mat-icon-button
          type="button"
          color="primary"
          [class.spinner]="inputsing$ | ngrxPush"
          [disabled]="inputsing$ | ngrxPush"
          (click)="onDownloadInputData($event)"
        >
          <mat-icon
            class="material-icons-outlined"
            aria-label="Download Input File"
            tooltipPosition="center"
            eyesTooltip="Download Input File"
          >
            save_alt
          </mat-icon>
        </button>
        <a
          mat-tab-link
          data-testid="toResults"
          [active]="tail === 'results'"
          [disabled]="outputDisabled()"
          (click)="onToResults()"
        >
          Results
        </a>
        <button
          class="details-export-btn"
          data-testid="scenario-download-output"
          mat-icon-button
          type="button"
          color="primary"
          [class.spinner]="resultsing$ | ngrxPush"
          [disabled]="resultsing$ | ngrxPush"
          [disabled]="outputDisabled()"
          (click)="onDownloadOutput($event)"
        >
          <mat-icon
            aria-label="Download Output File"
            tooltipPosition="center"
            eyesTooltip="Download Output File"
            class="material-icons-outlined"
          >
            save_alt
          </mat-icon>
        </button>
      </nav>
      <mat-divider vertical></mat-divider>
      <button data-testid="actions" mat-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu
        #menu="matMenu"
        xPosition="before"
        yPosition="below"
        overlapTrigger
      >
        <prosumer-scenario-actions
          [scenario]="scenario$ | ngrxPush"
          [canDelete]="true"
        ></prosumer-scenario-actions>
      </mat-menu>
    </div>
  </div>
</div>
