<mat-chip-list [selectable]="selectable$ | async" [multiple]="true">
  <mat-chip
    *ngFor="let result of results"
    fxLayout="row"
    fxLayoutAlign="center center"
    [style.background-color]="(colors$ | async)[result?.name]"
    [selected]="selection?.isSelected(getUniqueId(result))"
    [disabled]="
      (minSelectionReached$ | async) &&
      selection?.isSelected(getUniqueId(result))
    "
    (click)="onSelectChip(getUniqueId(result))"
  >
    <span
      class="prosumer-scenario-name"
      data-testid="compare-scenario--name"
      [attr.data-testid]="'compare-select-' + result?.name"
      >{{ result?.name }}</span
    >
    <span
      *ngIf="result?.id === referenceId"
      class="prosumer-reference-indicator"
    >
      <mat-icon inline>stars</mat-icon>
    </span>
  </mat-chip>
</mat-chip-list>
