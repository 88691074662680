import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
} from '@angular/forms';
import { EnergyGridLimitInterval } from 'prosumer-app/+scenario/models';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import { BaseFormComponent } from 'prosumer-app/libs/eyes-shared';
import { take } from 'rxjs/operators';
import {
  EnergyGridLimitIntervalDialogComponent,
  EnergyGridLimitIntervalDialogModel,
} from '../energy-grid-limit-interval-dialog';

@Component({
  selector: 'prosumer-energy-grid-limit-interval',
  templateUrl: './energy-grid-limit-interval.component.html',
  styleUrls: ['./energy-grid-limit-interval.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyGridLimitIntervalComponent
  extends BaseFormComponent
  implements AfterViewInit
{
  _startYear: number;
  @Input() set startYear(value: number) {
    this._startYear = value;
  }
  get startYear(): number {
    return this._startYear;
  }

  _endYear: number;
  @Input() set endYear(value: number) {
    this._endYear = value;
  }
  get endYear(): number {
    return this._endYear;
  }

  get intervalsFormArray(): FormArray {
    return this.form.controls.intervals as FormArray;
  }

  @Output() hasInvalidInterval = new EventEmitter<boolean>();

  selected = new FormControl(0); // The control representing the selected or active tab
  invalidIntervals: Set<number> = new Set();

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: FormBuilder,
    private _dialogService: DialogService,
  ) {
    super(ngControl, changeDetector, formBuilder);
  }

  defineForm() {
    return {
      intervals: this.formBuilder.array([]),
    };
  }

  writeValue(energyGridLimit: Array<EnergyGridLimitInterval>): void {
    if (!!energyGridLimit) {
      this.initForm(energyGridLimit);
    } else {
      this.addInterval(null, this.startYear, this.endYear);
    }
  }

  setDisabledState(disabled: boolean) {}

  ngAfterViewInit() {
    this.initIntervalsChangeHandler();
  }

  initIntervalsChangeHandler(): void {
    this.intervalsFormArray.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() => {
        const intervals = this.intervalsFormArray.getRawValue();
        this.markValidity(intervals);
        this.onChange(intervals);
      });
  }

  private markValidity(intervals: Array<EnergyGridLimitInterval>) {
    if (!intervals) {
      return;
    }

    this.invalidIntervals.clear();
    intervals.forEach((interval, index) => {
      if (
        !interval.minPower &&
        !interval.maxPower &&
        !interval.minEnergy &&
        !interval.maxEnergy &&
        !interval.minCapacity &&
        !interval.maxCapacity
      ) {
        this.invalidIntervals.add(index);
      }
    });

    this.hasInvalidInterval.emit(!!this.invalidIntervals.size);
  }

  private initForm(
    intervals?: Array<EnergyGridLimitInterval>,
    selected: number = 0,
  ): void {
    this.clearIntervals();
    intervals.forEach((interval) => this.addInterval(interval));
    this.selected.patchValue(selected);
  }

  private clearIntervals(): void {
    while (this.intervalsFormArray.length) {
      this.intervalsFormArray.removeAt(0);
    }
  }

  addInterval(
    interval: EnergyGridLimitInterval,
    startYear?: number,
    endYear?: number,
  ) {
    this.intervalsFormArray.push(
      this.createFormGroup(interval, startYear, endYear),
    );
  }

  private createFormGroup(
    interval: EnergyGridLimitInterval,
    startYear?: number,
    endYear?: number,
  ): FormGroup {
    const formGroup = this.formBuilder.group({
      startYear: this.formBuilder.control(startYear || null),
      endYear: this.formBuilder.control(endYear || null),
      minPower: this.formBuilder.control(undefined),
      maxPower: this.formBuilder.control(undefined),
      minEnergy: this.formBuilder.control(undefined),
      maxEnergy: this.formBuilder.control(undefined),
      minCapacity: this.formBuilder.control(undefined),
      maxCapacity: this.formBuilder.control(undefined),
    });

    if (!!interval) {
      formGroup.patchValue(
        {
          ...interval,
        },
        { emitEvent: false },
      );
    }

    return formGroup;
  }

  onEditInterval(): void {
    this._dialogService
      .openDialog(EnergyGridLimitIntervalDialogComponent, {
        startYear: this.startYear,
        endYear: this.endYear,
        intervals: this.intervalsFormArray.value,
        width: 800,
        disableClose: true,
      } as EnergyGridLimitIntervalDialogModel)
      .pipe<Array<EnergyGridLimitInterval>>(take(1))
      .subscribe((intervals) => {
        if (!!intervals) {
          const activeIndex = this.calculateActiveIndex(
            this.intervalsFormArray.value,
            intervals,
          );
          this.initForm(intervals, activeIndex);
        }
        this.markForCheck();
      });
  }

  private calculateActiveIndex(
    prevIntervals: Array<EnergyGridLimitInterval>,
    nextIntervals: Array<EnergyGridLimitInterval>,
  ) {
    let activeIndex = nextIntervals.length - 1;

    if (nextIntervals.length > prevIntervals.length) {
      const previous = prevIntervals.map((interval) => interval.startYear);
      nextIntervals
        .map((interval) => interval.startYear)
        .some((year, idx) => {
          if (previous.indexOf(year) === -1) {
            activeIndex = idx;
            return true;
          }
        });
    }

    return activeIndex;
  }

  onChangeSelectedTab(index: number) {
    this.selected.patchValue(index);
  }

  isInvalid(index: number) {
    return this.invalidIntervals.has(index);
  }
}
