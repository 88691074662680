<span class="sparkline-chart-displayed-values" [title]="displayedValues[0]">{{
  displayedValues[0] | number : '1.0-2'
}}</span>
<prosumer-charts-sparkline
  [view]="[chartWidth$ | async, chartHeight$ | async]"
  [results]="chartData"
  [scheme]="'fire'"
  [curve]="lineCurve"
>
</prosumer-charts-sparkline>
<span class="sparkline-chart-displayed-values" [title]="displayedValues[1]">{{
  displayedValues[1] | number : '1.0-2'
}}</span>
