import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared';

import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveComponentModule } from '@ngrx/component';

import {
  BarVertical2dStackedComponent,
  CheckboxComponent,
  DuplicateDialogComponent,
  EfficiencyFormArrayComponent,
  EfficiencyMatrixComponent,
  EnhancedLoadsInputComponent,
  EVToggleGroupComponent,
  FilterChipsComponent,
  IconButtonToggleComponent,
  ItemLinkComponent,
  MenuButtonComponent,
  OwnerResolverComponent,
  PolicyDialogComponent,
  RangeInputComponent,
  ShareDialogComponent,
  SimulationStatusComponent,
  SlideToggleComponent,
  SparklineTableComponent,
  TooltipMessageComponent,
  XlsxDataframeExtractorModule,
  YearlyChartDialogComponent,
  YearlyChartInputComponent,
  YearlyLineChartComponent,
  YearlyLoadsComponent,
  YearlyLoadsIntervalComponent,
  YearlyLoadsIntervalDialogComponent,
  YearlySparklineComponent,
  YearSliderComponent,
} from './components';
import { PermissionPickerComponent } from './components/dialogs/share-dialog/permission-picker/permission-picker.component';
import { FileUploadModule } from './components/file-upload-drag-drop/file-upload-drag-drop.module';
import { ProjectShareModule } from './components/project-share';
import { TooltipButtonComponent } from './components/tooltip-button/tooltip-button.component';
import {
  ChartsModule,
  ComboLineChartModule,
  GenericFilterchipModule,
  NodeFilterchipModule,
  SystemVisualizationModule,
  ViewLogsModule,
} from './modules';
import { UserSurveyFormModule } from './modules/user-survey-form';
import {
  DatesToHourPipe,
  EllipsesizePipe,
  EnergyVectorIconPipe,
  EnergyVectorNamePipe,
  MinsTimeConvertPipe,
} from './pipes';
import { TheMetricPipe } from './pipes/the-metric.pipe';

const dialogs = [
  DuplicateDialogComponent,
  ShareDialogComponent,
  PolicyDialogComponent,
  YearlyChartDialogComponent,
  YearlyLoadsIntervalDialogComponent,
];

const components = [
  FilterChipsComponent,
  EfficiencyFormArrayComponent,
  EfficiencyMatrixComponent,
  EVToggleGroupComponent,
  MenuButtonComponent,
  BarVertical2dStackedComponent,
  YearlyChartInputComponent,
  YearlyLineChartComponent,
  YearlySparklineComponent,
  RangeInputComponent,
  SparklineTableComponent,
  YearlyLoadsIntervalComponent,
  YearlyLoadsComponent,
  EnhancedLoadsInputComponent,
  SlideToggleComponent,
  TooltipMessageComponent,
  CheckboxComponent,
  PermissionPickerComponent,
  ItemLinkComponent,
  SimulationStatusComponent,
  OwnerResolverComponent,
  IconButtonToggleComponent,
  TooltipButtonComponent,
  YearSliderComponent,
];

const pipes = [
  EnergyVectorIconPipe,
  EnergyVectorNamePipe,
  DatesToHourPipe,
  MinsTimeConvertPipe,
  TheMetricPipe,
  EllipsesizePipe,
];

const moduleMembers = [...dialogs, ...components, ...pipes];

@NgModule({
  imports: [
    EyesSharedModule.forFeature(),
    ChartsModule,
    SystemVisualizationModule,
    ComboLineChartModule,
    NodeFilterchipModule,
    GenericFilterchipModule,
    ScrollingModule,
    UserSurveyFormModule,
    ViewLogsModule,
    CommonModule,
    OverlayModule,
    NgxSkeletonLoaderModule,
    MatIconModule,
    ProjectShareModule,
    MatDividerModule,
    ReactiveComponentModule,
    FileUploadModule,
    XlsxDataframeExtractorModule,
  ],
  declarations: [...moduleMembers],
  exports: [
    ...moduleMembers,
    ChartsModule,
    SystemVisualizationModule,
    ComboLineChartModule,
    NodeFilterchipModule,
    GenericFilterchipModule,
    UserSurveyFormModule,
    ViewLogsModule,
    FileUploadModule,
  ],
  entryComponents: [...dialogs],
})
export class SharedModule {}
