import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared';
import { SharedModule } from 'prosumer-app/shared';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { ReactiveComponentModule } from '@ngrx/component';
import { ScenarioDetailsDuplicateDialogComponent } from './scenario-details-duplicate-dialog.component';

@NgModule({
  declarations: [ScenarioDetailsDuplicateDialogComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    TranslateModule,
    FlexLayoutModule,
    EyesSharedModule.forFeature(),
    SharedModule,
    ReactiveComponentModule,
  ],
  exports: [ScenarioDetailsDuplicateDialogComponent],
})
export class ScenarioDetailsDuplicateDialogModule {}
