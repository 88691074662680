import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveComponentModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { EgcUpsertTriggerComponent } from './egc-upsert-trigger.component';

@NgModule({
  declarations: [EgcUpsertTriggerComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    ReactiveComponentModule,
    TranslateModule,
  ],
  exports: [EgcUpsertTriggerComponent],
})
export class EgcUpsertTriggerModule {}
