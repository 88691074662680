<mat-form-field
  [ngClass]="class"
  [appearance]="appearance"
  [hideRequiredMarker]="hideRequired"
  [floatLabel]="floatLabel"
  [hintLabel]="hintLabel"
>
  <mat-label>{{ label }}</mat-label>
  <input
    #input
    matInput
    [attr.aria-label]="placeholder"
    [formControl]="control"
    [placeholder]="placeholder"
    [type]="type"
    [required]="required"
    [matAutocomplete]="auto"
    [maxlength]="maxLength"
  />
  <mat-icon
    *ngIf="prefixIcon"
    matPrefix
    [ngClass]="prefixIcon?.class"
    [inline]="prefixIcon?.inline"
    [color]="prefixIcon?.color"
    (click)="prefixIcon?.click?.emit($event)"
  >
    {{ prefixIcon?.icon }}
  </mat-icon>
  <mat-icon
    *ngIf="suffixIcon"
    matSuffix
    [ngClass]="suffixIcon?.class"
    [inline]="suffixIcon?.inline"
    [color]="suffixIcon?.color"
    (click)="suffixIcon?.click?.emit($event)"
  >
    {{ suffixIcon?.icon }}
  </mat-icon>
  <mat-hint>{{ hint }}</mat-hint>
  <mat-error
    *ngFor="let error of getErrors(control?.errors)"
    [innerHtml]="errorMessageMap[error]"
  >
  </mat-error>
  <mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    [displayWith]="autocompleteDisplay"
  >
    <mat-option
      *ngFor="let option of filteredOptions$ | async"
      [value]="option"
    >
      {{ displayField ? option[displayField] : option }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
