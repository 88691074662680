import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
  CustomValidators,
  FormDialogComponent,
  fadeInAnimation,
} from 'prosumer-app/libs/eyes-shared';
import { of } from 'rxjs';

import { EnergyVector } from 'prosumer-app/+scenario/models';
import { DIRECTIONS } from 'prosumer-app/app.references';

import { Coerce } from 'prosumer-app/core/utils/coercion.util';
import { ReserveNetworkStore } from 'prosumer-app/stores/reserve-network';
import { provideUpserter } from 'prosumer-app/stores/scenario-detail';
import { ReserveNetworkFormDialogData } from './network-form-dialog.model';

@Component({
  selector: 'prosumer-reserve-network-form-dialog',
  templateUrl: './network-form-dialog.component.html',
  styleUrls: ['./network-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
  providers: [provideUpserter(ReserveNetworkStore)],
})
export class ReserveNetworkFormDialogComponent
  extends FormDialogComponent<
    ReserveNetworkFormDialogComponent,
    ReserveNetworkFormDialogData
  >
  implements AfterViewInit, OnInit
{
  energyVectorOptions: Array<EnergyVector> = [];

  sortedDirections = Object.keys(DIRECTIONS)
    .map((dirKey) => ({
      key: dirKey,
      value: DIRECTIONS[dirKey],
    }))
    .sort();

  get energyVectorIdControl() {
    return this.form.controls.energyVectorId;
  }

  get directionControl() {
    return this.form.controls.direction;
  }

  get requirementControl() {
    return this.form.controls.requirement;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReserveNetworkFormDialogData,
    public changeDetector: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ReserveNetworkFormDialogComponent>,
    public formBuilder: FormBuilder,
  ) {
    super(null, changeDetector, dialogRef, formBuilder);
  }

  defineForm() {
    return {
      energyVectorId: ['', Validators.required],
      direction: ['up', Validators.required],
      requirement: ['0.0', [Validators.required, Validators.min(0)]],
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.energyVectorOptions = this.data.energyVectorOptions || [];
    this.updateValidators();
  }

  ngAfterViewInit() {
    setTimeout(() =>
      this.form.patchValue(Coerce.toObject(this.data.currentReserveNetwork)),
    );
  }

  updateValidators() {
    this.energyVectorIdControl.setAsyncValidators([
      CustomValidators.dataExist(
        !!this.data.currentReserveNetworkList &&
          this.data.currentReserveNetworkList.length > 0
          ? of(this.data.currentReserveNetworkList)
          : null,
        'energyVectorId',
        this.data.currentReserveNetwork,
        {
          direction: this.directionControl as FormControl,
        },
      ),
    ]);

    this.directionControl.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() => this.energyVectorIdControl.updateValueAndValidity());
  }

  onSaveSuccess(): void {
    this.dialogRef.close();
  }

  onSaveAttempt(): void {
    this.changeDetector.detectChanges();
  }
}
