import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import * as XLSX from 'xlsx';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// to be separated for reusability of base component
interface YearlyListNumbers {
  [year: string]: number[];
}

const SPINNER_DIAMETER = 30;
const DEFAULT_ERROR_STATE_DURATION_MS = 2000;
const DEFAULT_NULL_VALUE_REPLACEMENT = 0;
const XLSX_FORMAT =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

@UntilDestroy()
@Component({
  selector: 'prosumer-xlsx-dataframe-extractor',
  templateUrl: './xlsx-dataframe-extractor.component.html',
  styleUrls: ['./xlsx-dataframe-extractor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XlsxDataframeExtractorComponent implements OnInit {
  @Output() extractedValuesEvent = new EventEmitter<YearlyListNumbers>();
  @Input() private readonly opts: Record<string, any>;
  @Input() private readonly errorStateDurationMs =
    DEFAULT_ERROR_STATE_DURATION_MS;
  @Input() private readonly nullValReplacement: string | number =
    DEFAULT_NULL_VALUE_REPLACEMENT;
  @Input() readonly tooltipMessage: string;
  @Input() readonly disabled: boolean = false;

  private readonly fileRawJsonData$ = new BehaviorSubject<any[]>(null);
  private readonly fileRawJsonDataObservable$ =
    this.fileRawJsonData$.asObservable();
  readonly hasError$ = new BehaviorSubject<boolean>(false);
  readonly isLoading$ = new BehaviorSubject<boolean>(false);
  readonly spinnerDiameter = SPINNER_DIAMETER;
  readonly fileFormat = XLSX_FORMAT;

  constructor(private readonly notifService: NotificationsService) {}

  ngOnInit(): void {
    this.subToRawJsonDataChange();
  }

  // TODO: needs unit test
  xlsxInputChange(event) {
    this.isLoading$.next(true);
    const f = event.target.files[0];
    const fr = new FileReader();
    fr.onload = (e: any) => {
      const wb = XLSX.read(e.target.result, { type: 'binary' });
      const ws = wb.Sheets[wb.SheetNames[0]];
      this.fileRawJsonData$.next(
        XLSX.utils.sheet_to_json(ws, {
          header: 1,
          blankrows: false,
          defval: this.nullValReplacement,
        }),
      );
    };
    fr.readAsArrayBuffer(f);
  }

  private subToRawJsonDataChange() {
    this.fileRawJsonDataObservable$
      .pipe(
        untilDestroyed(this),
        filter((d) => !!d),
        switchMap((d) =>
          of(d).pipe(
            filter((d) => this.validateRawJsonData(d, this.opts)), // to be separated for reusability of base component
            map((d) => this.transformRawJsonData(d, this.opts)), // to be separated for reusability of base component
            catchError((er) => this.handleError(er)),
          ),
        ),
      )
      .subscribe((td: YearlyListNumbers) => {
        this.isLoading$.next(false);
        this.extractedValuesEvent.emit(td);
      });
  }

  // to be separated for reusability of base component
  private validateRawJsonData(
    d: any[],
    opts: Record<string, any> = null,
  ): boolean {
    const columnLength = opts.columnLength;
    const startYear = opts.startYear;
    const endYear = opts.endYear;
    const columnTitleList = d[0];
    if (!d.length) {
      throw new Error('The xlsx file is empty');
    } else if (d.length !== columnLength + 1) {
      throw new Error(`There must be ${columnLength} values for each year`);
    } else if (
      columnTitleList.some(
        (year) => Number(year) < startYear || Number(year) > endYear,
      )
    ) {
      throw new Error(
        'The defined years should be in range of the scenario duration',
      );
    }
    return true;
  }

  // to be separated for reusability of base component
  private transformRawJsonData(
    d: any[][],
    opts: Record<string, any> = null,
  ): YearlyListNumbers {
    const header: any[] = d.shift();
    const yl = {};
    header.forEach((column, columnIndex) => {
      yl[column] = [];
      d.forEach((row) => {
        yl[column].push(row[columnIndex]);
      });
    });
    return yl;
  }

  private handleError(msg: string) {
    this.notifService.showError(msg);
    this.hasError$.next(true);
    this.isLoading$.next(false);
    setTimeout(() => this.hasError$.next(false), this.errorStateDurationMs);
    return of();
  }
}
