<mat-form-field
  [ngClass]="class"
  [appearance]="appearance"
  [hideRequiredMarker]="hideRequired"
  [floatLabel]="floatLabel"
  [hintLabel]="hintLabel"
>
  <mat-label>{{ label }}</mat-label>
  <input
    type="hidden"
    [matDatepicker]="datepicker"
    [formControl]="datepickerDate"
  />
  <prosumer-date-input-control
    [attr.aria-label]="placeholder"
    [placeholder]="placeholder"
    [required]="required"
    [formControl]="control"
  >
  </prosumer-date-input-control>
  <mat-icon
    *ngIf="prefixIcon"
    matPrefix
    [ngClass]="prefixIcon?.class"
    [inline]="prefixIcon?.inline"
    [color]="prefixIcon?.color"
    (click)="prefixIcon?.click?.emit($event)"
  >
    {{ prefixIcon?.icon }}
  </mat-icon>
  <mat-datepicker-toggle
    matSuffix
    [hidden]="!hasDatePicker"
    [for]="datepicker"
    [disabled]="control?.disabled"
  >
  </mat-datepicker-toggle>
  <mat-datepicker #datepicker></mat-datepicker>
  <mat-hint>{{ hint }}</mat-hint>
  <mat-error
    *ngFor="let error of getErrors(control?.errors)"
    [innerHtml]="errorMessageMap[error]"
  >
  </mat-error>
</mat-form-field>
