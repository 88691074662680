import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

import { Node } from 'prosumer-app/+scenario/models';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { EnergyGridConnectionInfo } from 'prosumer-app/stores/energy-grid-connection';
import {
  MarketLimit,
  MarketLimitBE,
  MarketLimitDuplicateDialogData,
  MarketLimitDuplicateParams,
  MarketLimitQuery,
  MarketLimitStore,
} from 'prosumer-app/stores/market-limit';
import { DetailEntityDuplicator } from 'prosumer-scenario/directives/detail-duplicator';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  startWith,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EgcDuplicateDialogComponent } from '../commodities-form/egc-duplicate-dialog';

import { FormFieldErrorMessageMap } from 'prosumer-app/libs/eyes-shared';
import { DuplicationValidators } from 'prosumer-app/shared/validators/duplication';
import { MarketLimitDuplicateDialogService } from './market-limit-duplicate-dialog.service';

@UntilDestroy()
@Component({
  selector: 'prosumer-market-limit-duplicate-dialog',
  templateUrl: './market-limit-duplicate-dialog.component.html',
  styleUrls: ['./market-limit-duplicate-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketLimitDuplicateDialogComponent
  extends DetailEntityDuplicator<MarketLimit>
  implements OnInit
{
  readonly duplicableForm = new FormGroup({
    market: new FormControl(this.data.origin.market),
    generics: new FormControl(
      this.formatInitGenericsFormValue(this.data.origin.nodes),
      Validators.required,
    ),
  });

  submitted$ = new BehaviorSubject<boolean>(false);
  errorMessages: FormFieldErrorMessageMap = {
    node: {
      required: this._translate.instant('Scenario.messages.node.required'),
    },
  };

  readonly markets$ = this.selectMarkets();
  readonly nodes$ = this.selectNodeOptions();

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: MarketLimitDuplicateDialogData,
    readonly dialog: MatDialogRef<EgcDuplicateDialogComponent>,
    readonly store: MarketLimitStore,
    readonly query: MarketLimitQuery,
    readonly notifs: NotificationsService,
    private readonly service: MarketLimitDuplicateDialogService,
    private _translate: TranslateService,
  ) {
    super(data, store, query, dialog, notifs);
    this.subscribeToFormValidityForErrorSetting();
  }

  ngOnInit() {
    this.subOnMarketChangeToCleanNodes();
  }

  private subOnMarketChangeToCleanNodes() {
    this.duplicableForm
      .get('market')
      .valueChanges.pipe(
        tap((_) => {
          if (this.data.isMultiNode) {
            this.duplicableForm
              .get('generics')
              .patchValue(this.formatInitGenericsFormValue([]));
          }
        }),
      )
      .subscribe();
  }

  private formatInitGenericsFormValue(value: string[]) {
    return { generics: value };
  }

  onOk(): void {
    if (this.doInputsCreateAUniqueCombination()) {
      this.onDuplicate(this.getFormInParams());
      this.submitted$.next(true);
    }
  }

  toBE(data: MarketLimit): MarketLimitBE {
    return { marketId: data.market, nodeId: { nodeIds: data.nodes } };
  }

  private getFormInParams(): MarketLimitDuplicateParams {
    const { market, generics } = this.duplicableForm.value;
    return { market, nodes: generics?.generics };
  }

  private subscribeToFormValidityForErrorSetting(): void {
    this.duplicableForm.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(() => {
        this.setErrorsBasedOnValidity();
        this.checkNodesValidity();
      });
  }

  private checkNodesValidity() {
    const control = this.duplicableForm.get('generics');
    if (!control.value?.generics?.length) {
      control.setErrors({ invalid: true });
    }
  }

  private setErrorsBasedOnValidity(): void {
    if (this.doInputsCreateAUniqueCombination()) {
      this.setErrorsForAllControls(null);
    } else {
      this.setErrorsForAllControls({ invalid: true });
    }
  }

  private doInputsCreateAUniqueCombination(): boolean {
    return DuplicationValidators.isMarketLimitUnique(
      this.getFormInParams(),
      this.data.existing,
    );
  }

  private setErrorsForAllControls(errors: unknown): void {
    Object.values(this.duplicableForm.controls).forEach((control) => {
      control.setErrors(errors);
      control.markAsDirty();
    });
  }

  private selectMarkets(): Observable<EnergyGridConnectionInfo[]> {
    return this.service.selectMarketOptions();
  }

  private selectNodeOptions(): Observable<Node[]> {
    return this.selectMarketChanges().pipe(
      withLatestFrom(this.service.selectNodeOptions()),
      map(([market, nodes]) => this.service.onlyNodesOfMarket(nodes, market)),
    );
  }

  private selectMarketChanges(): Observable<string> {
    const marketName = this.duplicableForm.get('market');
    return marketName.valueChanges.pipe(startWith(marketName.value));
  }
}
