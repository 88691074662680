import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveComponentModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { MarketLimitUpsertTriggerComponent } from './market-limit-upsert-trigger.component';

@NgModule({
  declarations: [MarketLimitUpsertTriggerComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    ReactiveComponentModule,
    TranslateModule,
  ],
  exports: [MarketLimitUpsertTriggerComponent],
})
export class MarketLimitUpsertTriggerModule {}
