import { User } from '../models';
import {
  mapProjectDetailsScenarioListToFrontEnd,
  ProjectState,
} from './project-state.model';
import * as ProjectActions from './project.actions';
import { projectStateFactory } from './project.factory';

export function projectReducer(
  state: ProjectState = projectStateFactory.initialState,
  action: ProjectActions.All,
): ProjectState {
  switch (action.type) {
    // Copy
    case ProjectActions.ActionTypes.COPY_PROJECT:
      return projectStateFactory.adapter.upsertOne(
        { ...action.payload.data, copying: true, error: undefined },
        state,
      );
    case ProjectActions.ActionTypes.COPY_PROJECT_FAILURE:
      return projectStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          copying: false,
          error: action.payload.message,
        },
        state,
      );
    case ProjectActions.ActionTypes.COPY_PROJECT_SUCCESS:
      return projectStateFactory.adapter.upsertOne(
        { ...action.payload.data, copying: false },
        state,
      );

    // Share
    case ProjectActions.ActionTypes.SHARE_PROJECT:
      return projectStateFactory.adapter.upsertOne(
        { ...action.payload.data, sharing: true, error: undefined },
        state,
      );
    case ProjectActions.ActionTypes.SHARE_PROJECT_FAILURE:
      return projectStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          sharing: false,
          error: action.payload.message,
        },
        state,
      );
    case ProjectActions.ActionTypes.SHARE_PROJECT_SUCCESS:
      return projectStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          sharing: false,
          sharedTo: (action.payload.userIds || []).map(
            ({ id, permissions }: User) => ({ id, permissions }),
          ),
        },
        state,
      );

    // Unshare
    case ProjectActions.ActionTypes.UNSHARE_PROJECT:
      return projectStateFactory.adapter.upsertOne(
        { ...action.payload.data, unsharing: true, error: undefined },
        state,
      );
    case ProjectActions.ActionTypes.UNSHARE_PROJECT_FAILURE:
      return projectStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          unsharing: false,
          error: action.payload.message,
        },
        state,
      );
    case ProjectActions.ActionTypes.UNSHARE_PROJECT_SUCCESS:
      return projectStateFactory.adapter.removeOne(
        action.payload.data.id,
        state,
      );

    // Remove
    case ProjectActions.ActionTypes.REMOVE_PROJECT:
      return projectStateFactory.adapter.removeOne(action.payload.id, state);

    // Get project list with details
    case ProjectActions.ActionTypes.GET_PROJECT_LIST_WITH_DETAILS:
      return { ...state, loading: true, error: undefined };
    case ProjectActions.ActionTypes.GET_PROJECT_LIST_WITH_DETAILS_SUCCESS:
      const stateIds: any[] = state.ids;
      return projectStateFactory.adapter.upsertMany(
        action.payload.results
          .filter((item) => !stateIds.includes(item.id))
          .map((item) => ({
            ...mapProjectDetailsScenarioListToFrontEnd(item),
            loading: false,
            loaded: true,
            error: undefined,
          })),
        Object.assign(
          {},
          projectStateFactory.adapter.removeOne(undefined, state),
          {
            loading: false,
            loaded: true,
          },
        ),
      );
    case ProjectActions.ActionTypes.GET_PROJECT_LIST_WITH_DETAILS_FAILED:
      return projectStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          loading: false,
          error: action.payload.message,
        },
        state,
      );

    /* Default */
    default:
      return projectStateFactory.createReducer(state, action);
  }
}
