import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

import { MaterialModule, TooltipModule } from 'prosumer-app/libs/eyes-shared';

import { GenericFilterchipComponent } from './generic-filterchip.component';

const modules = [
  MatChipsModule,
  MatIconModule,
  MatAutocompleteModule,
  MatFormFieldModule,
  CommonModule,
  MaterialModule,
  FormsModule,
  ReactiveFormsModule,
  TooltipModule,
];

@NgModule({
  imports: [...modules],
  declarations: [GenericFilterchipComponent],
  exports: [GenericFilterchipComponent, ...modules],
  entryComponents: [GenericFilterchipComponent],
})
export class GenericFilterchipModule {}
