<mat-form-field appearance="outline">
  <mat-label>{{ filterTitle }}</mat-label>
  <input
    #dataInput
    matInput
    [attr.aria-label]="filterTitle"
    [placeholder]="placeholder"
    [formControl]="control"
    [matAutocomplete]="auto"
    (keydown)="onKeydown($event)"
    (blur)="onBlur()"
  />
  <mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    (optionSelected)="selected($event)"
    [displayWith]="displayFn"
  >
    <mat-option
      *ngFor="let data of filteredData | async"
      [value]="data?.value || data"
      >{{ isReference ? data?.name : data }}</mat-option
    >
  </mat-autocomplete>
</mat-form-field>
<mat-chip-list #chipList>
  <mat-chip
    [color]="chipColor"
    *ngFor="let filter of selectedFilterOptions; let indx = index"
    selected
    [selectable]="selectable"
    [removable]="removable"
    [disabled]="control?.disabled"
    (removed)="remove(filter, indx)"
  >
    {{
      isReference
        ? (filter?.value | reference : dataSource : referenceField)?.name
        : filter
    }}
    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
  </mat-chip>
</mat-chip-list>
<!-- <pre>{{selectedFilter|json}}</pre> -->
