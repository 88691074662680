import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { FormBuilder, NgControl } from '@angular/forms';

import {
  EnergyVector,
  EnergyVectorTemporaryModel,
} from 'prosumer-app/+scenario';
import { BaseControlComponent } from 'prosumer-app/libs/eyes-shared';

@Component({
  selector: 'prosumer-ev-toggle-group',
  templateUrl: './energy-vector-toggle-group.html',
  styleUrls: ['./energy-vector-toggle-group.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EVToggleGroupComponent
  extends BaseControlComponent
  implements OnInit
{
  @Input() isViewOnly = false;
  @Input() width = 170;
  @Input() energyVectors: Array<EnergyVector> = [];
  get _energyVectors() {
    return this.energyVectors || [];
  }
  @Input() evOptions: Array<any> = [];
  @Input() layout = 'column';
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() select: EventEmitter<string> = new EventEmitter<string>(null);
  @Output() remove: EventEmitter<string> = new EventEmitter<string>(null);
  @Output() replace = new EventEmitter<any>();

  mainSelected = '';

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: FormBuilder,
  ) {
    super(ngControl, changeDetector);
  }

  defineForm() {
    return {
      mainEnergyVector: this.formBuilder.control(null),
    };
  }

  onValChange(value: any) {
    this.select.emit(value);
  }

  onDelete(energyVector: EnergyVector) {
    this.remove.emit(energyVector.value);
  }

  onReplace(newEv: EnergyVector, oldEv: EnergyVectorTemporaryModel) {
    this.replace.emit({ newEv, oldEv });
  }
}
