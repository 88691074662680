import cytoscape from 'cytoscape';
import popper from 'cytoscape-popper';
import {
  DetailsOverlayRef,
  DetailsOverlayService,
} from 'prosumer-shared/modules/details-overlay';
import { Observable, Subject } from 'rxjs';
import { pluck, switchMap } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Asset, AssetLink, FluidConfig, NodeViewData } from '../../models';
import { DiagramPositioner, SystemVisualizationService } from '../../services';
import { VizDiagramUtils } from '../../utils/viz-diagram.utils';
import { BaseDiagramComponent } from '../base-diagram.component';

cytoscape.use(popper);

@UntilDestroy()
@Component({
  selector: 'prosumer-node-view-diagram',
  templateUrl: './node-view-diagram.component.html',
  styleUrls: ['./node-view-diagram.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NodeViewDiagramComponent extends BaseDiagramComponent<NodeViewData> {
  readonly onDiagramEvent = new Subject();
  detailsPanel: DetailsOverlayRef;

  @Input() clickReset: Observable<void>;
  @Input() fluidConfig: FluidConfig = {};
  @Output() pngDataChange = new EventEmitter<string>();
  @Output() positionReset = new EventEmitter<void>();

  constructor(
    public elementRef: ElementRef,
    public overlayService: DetailsOverlayService,
    public systemVisualizationService: SystemVisualizationService,
    private positioner: DiagramPositioner,
  ) {
    super(elementRef, systemVisualizationService);

    this.subscribeToDragFreeOnForPositionRemembering();
    this.subscribeToDragFreeOnForPngDataPropagation();
    this.subscribeToDiagramCoreForInitialPNGDataPropagation();
  }

  addEventListeners() {
    // Applies cursor styling when hovering on elements
    this.addOnNodeTapHandler();
    this.addOnEdgeTapHandler();
    this.addPointerHandlers();
    this.addOnResetHandler();
    this.addOnZoomHandler();
    this.addOnDragPanHandler();
    this.addOnDragFreeOnHandler();
  }

  mapDataToDiagramOptions(data: NodeViewData): cytoscape.CytoscapeOptions {
    if (!data || !data.assetLinks || !data.assets) {
      return this.getDiagramOptions();
    }
    const savedPositions = this.getPositionsConfig();
    return {
      elements: {
        nodes: this.assetsToNodeDefs(data.assets),
        edges: this.linksToEdgeDefs(data.assetLinks),
      },
      style: this.getStyles(),
      // add layout property if savedPositions is not empty
      ...(!!Object.keys(savedPositions).length && {
        layout: { name: 'preset', positions: savedPositions },
      }),
    };
  }

  private subscribeToDragFreeOnForPngDataPropagation(): void {
    this.onDiagramEvent
      .pipe(untilDestroyed(this))
      .subscribe(() => this.pngDataChange.emit(this.getPNGInFull()));
  }

  private subscribeToDragFreeOnForPositionRemembering(): void {
    this.onDiagramEvent
      .pipe(
        switchMap(() => this.selectScenarioIDFromSysVizData()),
        untilDestroyed(this),
      )
      .subscribe((scenario) => {
        this.positioner.storeNodeView(
          scenario,
          this.systemVisualizationService.getVariationID(),
          this.systemVisualizationService.getSelectedNode(),
          this.getDiagramJSON(),
        );
      });
  }

  private selectScenarioIDFromSysVizData(): Observable<string> {
    return this.systemVisualizationService.vizData$.pipe(pluck('scenarioId'));
  }

  private addOnDragFreeOnHandler(): void {
    this.getDiagram().on('dragfreeon', () => this.onDiagramEvent.next());
  }

  private addOnEdgeTapHandler(): void {
    this.getDiagram().on('tap', (event) => {
      const element: cytoscape.CollectionReturnValue = event.target;
      if (
        !this.isBackground(element) &&
        element.isEdge() &&
        !element.data().hidden
      ) {
        this.closeDetailsPanel();
        const edgeId = element.id();
        const sourceId = element.data('source');
        const targetId = element.data('target');
        this.getDiagram().elements().addClass('faded');
        this.getDiagram()
          .elements(
            `node[id = '${sourceId}'], [id = '${targetId}'], [id = '${edgeId}']`,
          )
          .removeClass('faded');
        this.fadeAllInClass();
        this.focusOnElement([sourceId, targetId]);
      } else if (this.isBackground(element)) {
        this.getDiagram().elements().removeClass('faded');
        this.removeFocusOnClass();
        this.closeDetailsPanel();
      }
    });
  }

  private addOnNodeTapHandler(): void {
    this.getDiagram().on('tap', (evt) => {
      const element: cytoscape.CollectionReturnValue = evt.target;
      if (
        !this.isBackground(element) &&
        element.isNode() &&
        !element.data().hidden
      ) {
        this.closeDetailsPanel();
        this.getDiagram().elements().addClass('faded');
        element.removeClass('faded');
        this.fadeAllInClass();
        this.focusOnElement([element.data().id]);
        this.openOverlay(evt);
      } else if (this.isBackground(element)) {
        this.getDiagram().elements().removeClass('faded');
        this.removeFocusOnClass();
        this.closeDetailsPanel();
      }
    });
  }

  private addOnZoomHandler(): void {
    this.getDiagram().on('zoom', () => this.onDiagramEvent.next());
  }

  private addOnDragPanHandler(): void {
    this.getDiagram().on('dragpan', () => this.onDiagramEvent.next());
  }

  private addOnResetHandler(): void {
    this.clickReset
      .pipe(
        switchMap(() => this.selectScenarioIDFromSysVizData()),
        untilDestroyed(this),
      )
      .subscribe((scenario) => {
        this.positioner.storeNodeView(
          scenario,
          this.systemVisualizationService.getVariationID(),
          this.systemVisualizationService.getSelectedNode(),
          null,
        );
        this.positionReset.emit();
      });
  }

  private openOverlay(event: any): void {
    {
      const data = JSON.parse(JSON.stringify(event.target.data()));
      const name = data.name;
      delete data.name;
      this.detailsPanel = this.overlayService.open({
        data: {
          name,
          rawData: { ...data.rawData },
          metaData: [
            { label: 'Type', key: 'type' },
            { label: 'Node', key: 'node' },
            { label: 'Fluid', key: 'fluid' },
            { label: 'Main input fluid', key: 'main input fluid' },
            { label: 'Main output fluid', key: 'main output fluid' },
            { label: 'Size [kW]', key: 'size [kw]' },
            { label: 'Size [kWh]', key: 'size [kwh]' },
            {
              label: 'Initial Capex [kEUR]',
              key: 'initial capex [keur]',
              format: '1.1-1',
            },
            {
              label: 'Initial Opex [kEUR]',
              key: 'initial opex [keur]',
              format: '1.1-1',
            },
            {
              label: 'Total Cost [kEUR]',
              key: 'total cost [keur]',
              format: '1.1-1',
            },
            {
              label: 'Yearly Production [kWh]',
              key: 'yearly production [kwh]',
              format: '1.0-0',
            },
            {
              label: 'Yearly Consumption [kWh]',
              key: 'yearly consumption [kwh]',
              format: '1.0-0',
            },
            {
              label: 'Yearly Buy [kWh]',
              key: 'yearly buy [kwh]',
              format: '1.0-0',
            },
            {
              label: 'Yearly Sell [kWh]',
              key: 'yearly sell [kwh]',
              format: '1.0-0',
            },
          ],
        },
      });

      this.detailsPanel.beforeClose().subscribe(() => {
        this.getDiagram().elements().removeClass('faded');
        this.removeFocusOnClass();
      });
    }
  }

  private determineEdgeColor(link: AssetLink): string {
    return this.fluidConfig[link.fluid].color;
  }

  private getStyles(): cytoscape.Stylesheet[] {
    return [
      VizDiagramUtils.getNodeStylesheet(),
      VizDiagramUtils.getEdgeStylesheet(),
      VizDiagramUtils.getFadedEdgeStylesheet(),
      VizDiagramUtils.getFadedNodeStylesheet(),
      VizDiagramUtils.getHiddenEdgeStyle(),
      VizDiagramUtils.getHiddenNodeStyle(),
      VizDiagramUtils.getSelectedNodeStylesheet(),
      VizDiagramUtils.getSelectedEdgeStylesheet(),
    ];
  }

  private assetsToNodeDefs(assets: Asset[]): cytoscape.NodeDefinition[] {
    return assets.map((asset) => VizDiagramUtils.nodeToDiagramNode(asset));
  }

  private linksToEdgeDefs(links: AssetLink[]): cytoscape.EdgeDefinition[] {
    return links.map((link) =>
      VizDiagramUtils.connectorToDiagramEdge(
        link,
        this.determineEdgeColor(link),
      ),
    );
  }

  private subscribeToDiagramCoreForInitialPNGDataPropagation(): void {
    this.diagram$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.pngDataChange.emit(this.getPNGInFull()));
  }

  private getPNGInFull(): string {
    return this.getDiagram().png({ full: true });
  }

  getSavedViewConfig(): cytoscape.CytoscapeOptions {
    return (
      this.positioner.getNodeView(
        this.systemVisualizationService.getScenarioID(),
        this.systemVisualizationService.getVariationID(),
        this.systemVisualizationService.getSelectedNode(),
      ) || {}
    );
  }
}
