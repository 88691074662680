<div [ngClass]="{ 'row-toggle': layout === 'row' }" [fxLayout]="layout">
  <div *ngIf="showToggle">
    <section class="toggle-section">
      <mat-slide-toggle
        class="toggle-margin"
        [color]="color"
        [checked]="checked"
        (click)="onChange()"
      >
      </mat-slide-toggle>
    </section>
  </div>
  <div
    fxLayoutAlign="center center"
    fxLayout="column"
    fxLayoutGap="10px"
    matRipple
    matRippleColor="rgb(0, 170, 255, 0.1)"
    class="icon-button-toggle"
    [ngClass]="{
      'selected mat-elevation-z2': selected,
      disabled: disabled,
      readonly: readonly,
      waitingResponse: waitingResponse[value]
    }"
    [matRippleDisabled]="disabled || readonly"
    [tabindex]="readonly || disabled ? -1 : 0"
    (click)="onClick($event)"
    (keyup)="onKeyUp($event)"
  >
    <mat-spinner
      *ngIf="waitingResponse[value]"
      [color]="spinnerColor"
      [diameter]="spinnerDiameter"
      data-testId="spinner"
    ></mat-spinner>
    <mat-icon [svgIcon]="icon"></mat-icon>
    <span>{{ label | titlecase }}</span>
  </div>
</div>
