import {
  fadeInAnimation,
  FormFieldErrorMessageMap,
  getKeys,
} from 'prosumer-app/libs/eyes-shared';
import { FileInputName, NameValidator } from 'prosumer-shared/validators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { ScenarioStore } from 'prosumer-app/stores/scenario';
import { Scenario, VALID_SCENARIO_NAME_PATTERN } from '../../models';

const DEFAULT_FILE_EXTS = ['xls', 'xlsx'];

@Component({
  selector: 'prosumer-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadFormComponent implements OnInit {
  allowedFileExtns = DEFAULT_FILE_EXTS;

  inputNameErrorMessages(): FormFieldErrorMessageMap {
    return {
      required: this.translate.instant('Scenario.messages.name.required'),
      invalidCharacter: this.translate.instant(
        'Scenario.messages.name.invalidCharacter',
      ),
      whiteSpaces: this.translate.instant('Scenario.messages.name.whiteSpaces'),
    };
  }

  uploadFormErrorMessages(): FormFieldErrorMessageMap {
    return {
      required: this.translate.instant(
        'Scenario.messages.scenarioUploadAttachment.required',
      ),
      invalidCharacter: this.translate.instant(
        'Scenario.messages.scenarioUploadAttachment.invalidCharacter',
      ),
    };
  }

  uploadForm: FormGroup = this.formBuilder.group({
    name: ['', [Validators.required, NameValidator.validWithCore()]],
    description: '',
    scenarioType: 'created_by_upload',
    fileInput: [''],
  });

  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<Scenario>();
  @Output() saveClone = new EventEmitter<Scenario>();
  @Input() isLoading = false;
  @Input() isCloneMode = false;
  displayWarning = false;

  submitBtnName: string = undefined;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private readonly scenarioStoreNgrx: ScenarioStore,
  ) {}

  ngOnInit() {
    this.uploadForm.controls.fileInput.setValidators([
      FileInputName.invalidCharacters(VALID_SCENARIO_NAME_PATTERN),
    ]);

    this.uploadForm.get('fileInput').valueChanges.subscribe((value: File) => {
      this.displayWarning = this.isCloneMode && !!value;
      const name = this.uploadForm.get('name');
      const fileName = value ? value.name.replace(/(?:.xlsx?)$/gi, '') : '';
      if (!name.value) {
        name.setValue(fileName);
      }
    });

    this.uploadForm
      .get('name')
      .valueChanges.subscribe((scenarioName: string) => {
        if (scenarioName && scenarioName.match(VALID_SCENARIO_NAME_PATTERN)) {
          this.uploadForm.controls.name.setErrors({ invalidCharacter: true });
        }
      });

    this.submitBtnName = this.isCloneMode ? 'Clone' : 'Simulate';
  }

  onSubmit(): void {
    if (this.uploadForm.valid) {
      if (this.isCloneMode) {
        this.uploadForm.patchValue({
          scenarioType: 'cloned_by_upload',
        });
        this.saveClone.emit(this.uploadForm.value);
        return;
      }
      this.save.emit(this.uploadForm.value);
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  getXlsxlTemplate(): void {
    this.scenarioStoreNgrx.downloadXlsxlTemplate('getXlsxTemplate');
  }

  /**
   * Collects form error
   *
   * @param errorObj - form control error objects
   */
  getErrors(errorObj: any) {
    return errorObj ? getKeys(errorObj) : [];
  }
}
