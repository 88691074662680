import { Injectable } from '@angular/core';
import { FlowResult } from '@prosumer/results/models/flow-results.model';
import { ResultsExtractorService } from '@prosumer/results/state';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FlowResultsService {
  constructor(private results: ResultsExtractorService) {}

  flow$ = new BehaviorSubject<FlowResult>(undefined);

  getFlowOutput() {
    this.getResultDataStream().subscribe((data) => this.flow$.next(data));
  }

  private getResultDataStream(): Observable<FlowResult> {
    const resultData = this.results
      .getFlowStream()
      .pipe(map((result) => result[0]));
    return resultData;
  }
}
