/* eslint-disable @typescript-eslint/naming-convention */
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MessagesLogService } from 'prosumer-app/+scenario/services';
import { ColumnDefinition } from 'prosumer-app/libs/eyes-shared';
import { MessagesLogCombinedVesions } from 'prosumer-app/shared/models/message-logs.model';
import {
  MESSAGES_LOGS_COLUMN_DEF_V_1_1,
  MESSAGES_LOGS_FILTERS_V_1_1,
} from './messages-log-table.tokens';
@Component({
  selector: 'prosumer-messages-log-table',
  templateUrl: './messages-log-table.component.html',
  styleUrls: ['./messages-log-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagesLogTableComponent implements OnChanges {
  protected _filtersSelected: Record<string, string[]> = {};
  _messages: Array<MessagesLogCombinedVesions> = [];

  @Input() loading: boolean;
  @Input() hideFilters = false;
  @Input() version: string;
  @Input() messages: any = [];
  @Input() filtersData = MESSAGES_LOGS_FILTERS_V_1_1;
  @Input() tableMetaData: ColumnDefinition = MESSAGES_LOGS_COLUMN_DEF_V_1_1;

  get tableMessages() {
    return {
      noRecords: 'Scenario.messages.logs.noRecords',
      noResults: 'Scenario.messages.logs.noResults',
      error: 'Scenario.messages.logs.noRecords',
      loading: 'Scenario.messages.logs.loading',
    };
  }

  constructor(public messagesLogService: MessagesLogService) {}

  ngOnChanges(changes: SimpleChanges) {
    this._messages = this.messagesLogService.mapMessagesToVersion(
      changes.messages?.currentValue,
      this.version,
    );
  }

  handleFilterChange(filterValues: string[], filterName: string) {
    this._filtersSelected[filterName] = filterValues;
    const allMessages = this.messagesLogService.mapMessagesToVersion(
      this.messages,
      this.version,
    );
    this._messages = this.messagesLogService.applyFilters(
      allMessages,
      this._filtersSelected,
    );
  }
}
