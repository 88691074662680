import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { BillingApiService } from '../services';
import * as BillingActions from './billing.actions';

@Injectable()
export class BillingEffects {
  @Effect()
  getComputingHours$: Observable<BillingActions.All> = this._actions$.pipe(
    ofType<BillingActions.GetComputingTime>(
      BillingActions.ActionTypes.GET_COMPUTING_TIME,
    ),
    mergeMap(() =>
      this._billingApi.getComputingTime().pipe(
        map(
          (response) =>
            new BillingActions.GetComputingTimeSuccess({
              data: response,
              message: this._translate.instant(
                'Billing.messages.getComputingTimeSuccess',
              ),
            }),
        ),
        catchError((error) =>
          of(
            new BillingActions.GetComputingTimeFailure({
              message:
                error ||
                this._translate.instant(
                  'Billing.messages.getComputingTimeFailure',
                ),
            }),
          ),
        ),
      ),
    ),
  );

  @Effect({ dispatch: false })
  getComputingHoursFailure$: Observable<BillingActions.All> =
    this._actions$.pipe(
      ofType<BillingActions.GetComputingTimeFailure>(
        BillingActions.ActionTypes.GET_COMPUTING_TIME_FAILURE,
      ),
      mergeMap((action) =>
        this._notification
          .showError(action.payload.message, 'Retry')
          .onAction()
          .pipe(
            tap(() => this._billingApi.getComputingTime()),
            map(() => action),
          ),
      ),
    );

  @Effect({ dispatch: false })
  getComputingHoursSuccess$: Observable<BillingActions.All> =
    this._actions$.pipe(
      ofType<BillingActions.GetComputingTimeSuccess>(
        BillingActions.ActionTypes.GET_COMPUTING_TIME_SUCCESS,
      ),
      // tap(action => this._notification.showSuccess(action.payload.message))
    );

  @Effect()
  getStorage$: Observable<BillingActions.All> = this._actions$.pipe(
    ofType<BillingActions.GetStorage>(BillingActions.ActionTypes.GET_STORAGE),
    mergeMap(() =>
      this._billingApi.getStorage().pipe(
        map(
          (response) =>
            new BillingActions.GetStorageSuccess({
              data: response,
              message: this._translate.instant(
                'Billing.messages.getStorageSuccess',
              ),
            }),
        ),
        catchError((error) =>
          of(
            new BillingActions.GetStorageFailure({
              message:
                error ||
                this._translate.instant('Billing.messages.getStorageFailure'),
            }),
          ),
        ),
      ),
    ),
  );

  @Effect({ dispatch: false })
  getStorageFailure$: Observable<BillingActions.All> = this._actions$.pipe(
    ofType<BillingActions.GetStorageFailure>(
      BillingActions.ActionTypes.GET_STORAGE_FAILURE,
    ),
    mergeMap((action) =>
      this._notification
        .showError(action.payload.message, 'Retry')
        .onAction()
        .pipe(
          tap(() => this._billingApi.getStorage()),
          map(() => action),
        ),
    ),
  );

  @Effect({ dispatch: false })
  getStorageSuccess$: Observable<BillingActions.All> = this._actions$.pipe(
    ofType<BillingActions.GetStorageSuccess>(
      BillingActions.ActionTypes.GET_STORAGE_SUCCESS,
    ),
    // tap(action => this._notification.showSuccess(action.payload.message))
  );

  constructor(
    private _actions$: Actions,
    private _billingApi: BillingApiService,
    private _notification: NotificationsService,
    private _translate: TranslateService,
  ) {}
}
