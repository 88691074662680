<prosumer-progress-card [showProgress]="loading$ | async">
  <form (ngSubmit)="onSubmit()" [formGroup]="createScenarioForm">
    <h3 class="mat-title" mat-dialog-title>
      <b>{{ 'Scenario.labels.main.create' | translate }}</b>
    </h3>

    <div class="mat-body" mat-dialog-content>
      <div fxLayout="column" fxLayoutGap="10px">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'Scenario.labels.general.name' | translate
          }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="name"
            data-testid="scenario-create-dialog-form-input-name"
            (click)="$event.stopPropagation()"
          />
          <mat-error
            *ngIf="createScenarioForm?.controls?.name?.errors?.required"
            [innerHtml]="'Scenario.messages.name.required' | translate"
          >
          </mat-error>
          <mat-error
            *ngIf="createScenarioForm?.controls?.name?.errors?.invalidCharacter"
            [innerHtml]="'Scenario.messages.name.invalidCharacter' | translate"
          >
          </mat-error>
          <mat-error
            *ngIf="createScenarioForm?.controls?.name?.errors?.dataExist"
            [innerHtml]="'Scenario.messages.name.dataExist' | translate"
          >
          </mat-error>
          <mat-error
            *ngIf="createScenarioForm?.controls?.name?.errors?.whiteSpaces"
            [innerHtml]="'Scenario.messages.name.whiteSpaces' | translate"
          >
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div mat-dialog-actions fxLayout="row">
      <button
        mat-flat-button
        type="submit"
        color="primary"
        [disabled]="isFormInvalid || (loading$ | async)"
        data-testid="scenario-create-dialog-form-submit"
      >
        <span>{{ 'Generic.labels.create' | translate }}</span>
      </button>

      <button
        mat-flat-button
        type="button"
        (click)="onCancel()"
        data-testid="scenario-create-dialog-form-cancel"
      >
        <span>{{ 'Generic.labels.cancel' | translate }}</span>
      </button>
    </div>
  </form>
</prosumer-progress-card>
