<prosumer-dialog-div
  [title]="
    'Scenario.messages.cascadeTitle'
      | translate
        : {
            type: translatedType | titlecase,
            action: translatedAction | titlecase
          }
  "
  [okLabel]="'continue'"
  (ok)="onOk()"
  (cancel)="onCancel()"
>
  <span class="cascade-confirmation-msg">
    {{
      'Scenario.messages.cascadeConfirmation'
        | translate : { type: translatedType, action: translatedAction }
    }}
  </span>
</prosumer-dialog-div>
