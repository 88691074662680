import { Observable, Observer } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

import { ComponentType } from '@angular/cdk/portal';
import { forwardRef, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

function reduce<T>(list: T[], key: string): { [key: string]: T } {
  return list.reduce((acc, curr) => {
    acc[curr[key]] = curr;
    return acc;
  }, {});
}

function filterOutUndefined<T>($: Observable<T>): Observable<T> {
  return $.pipe(filter((data) => !!data));
}

function filterOutNullUndefined<T>($: Observable<T>): Observable<T> {
  return $.pipe(filter((data) => data !== null && data !== undefined));
}

function filterOutEmpty<T>($: Observable<T[]>): Observable<T[]> {
  return $.pipe(filter((data) => !!data.length));
}

function filterOutEmptyString<T>($: Observable<string>): Observable<string> {
  return $.pipe(filter((data) => data !== ''));
}

function resolveToEmptyObject<T>(alleged: T): T {
  return alleged || ({} as T);
}

function resolveToZero(alleged: number): number {
  return alleged || 0;
}

function resolveToEmptyArray<T>(alleged: T[]): T[] {
  return alleged || [];
}

function removeDuplicates<T>(from: T[]): T[] {
  return Array.from(new Set(from));
}

function removeKeyDuplicates<T>(from: T[], key: string): T[] {
  const vals = from.map((itm: T) => itm[key]);
  return from.filter((itm: T, idx: number) => idx === vals.indexOf(itm[key]));
}

function removeEmptyStrings(from: string[]): string[] {
  return from.filter((str) => !!resolveToEmptyString(str).trim());
}

function resolveToEmptyString(alleged: string): string {
  return alleged || '';
}

function resolveToUndefinedString(alleged: string): string {
  return alleged || undefined;
}

function blobToString(blob$: Observable<Blob>): Observable<string> {
  return blob$.pipe(
    mergeMap(
      (blob) =>
        new Observable<string>((observer: Observer<any>) => {
          const readerGoneWild = new FileReader();
          readerGoneWild.onloadend = () => {
            observer.next(readerGoneWild.result);
            observer.complete();
          };
          readerGoneWild.readAsText(blob);
        }),
    ),
  );
}

function parseYearlyValuesFromBE(value) {
  try {
    if (typeof value === 'string') {
      value = JSON.parse(value);
    }
    if (typeof value === 'number') {
      value = value.toString();
    }
    return value;
  } catch (e) {}
  return;
}

/**
 *
 * @param array of objects
 * @returns flattened array of objects
 *
 * @example
 * const array = [{
 *  keyA: {value: 1}
 * }, {
 *  keyB: {value: 2}
 * }, {
 *  keyC: {value: 3}
 * }];
 *
 * const result = flattenArrayOfObject(array);
 * console.log(result)
 * {
 *  keyA: {value: 1}
 *  keyB: {value: 2}
 *  keyC: {value: 3}
 * }
 */

function flattenArrayOfObject<T, Y>(array: T[]): { [key: string]: Y } {
  return array
    ? array.reduce(
        (acc, curr) => ({
          ...acc,
          ...curr,
        }),
        {},
      )
    : {};
}

function provideCVA(type: ComponentType<unknown>): Provider {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => type),
    multi: true,
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Utils = {
  provideCVA,
  reduce,
  resolveToEmptyObject,
  removeDuplicates,
  removeKeyDuplicates,
  resolveToZero,
  resolveToEmptyArray,
  removeEmptyStrings,
  resolveToEmptyString,
  resolveToUndefinedString,
  flattenArrayOfObject,
  parseYearlyValuesFromBE,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PipeUtils = {
  filterOutUndefined,
  filterOutNullUndefined,
  filterOutEmpty,
  filterOutEmptyString,
  blobToString,
};
