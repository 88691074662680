import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveComponentModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { EntityUpsertButtonComponent } from './entity-upsert-button.component';
@NgModule({
  declarations: [EntityUpsertButtonComponent],
  imports: [
    CommonModule,
    ReactiveComponentModule,
    MatButtonModule,
    TranslateModule,
  ],
  exports: [EntityUpsertButtonComponent],
})
export class EntityUpsertButtonModule {}
