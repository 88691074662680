import moment from 'moment';
import { Options } from 'ng5-slider';
import { DAY_FORMAT, MONTH_FORMAT } from 'prosumer-app/app.references';
import { BaseSliderComponent, contains } from 'prosumer-app/libs/eyes-shared';

import { coerceNumberProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

const DEFAULT_YEAR_SLIDER_OPTIONS = {
  floor: 1,
  ceil: 365,
  step: 1,
  minRange: 0,
  maxRange: 30,
  draggableRange: true,
  showTicks: true,
};

@Component({
  selector: 'prosumer-year-slider',
  templateUrl: './year-slider.component.html',
  styleUrls: ['./year-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearSliderComponent extends BaseSliderComponent {
  @Input() calculated = false;
  @Input() initStart = 1;
  @Input() initEnd = 30;
  @Input() type = 'raw';

  @Input() set year(value: string) {
    const year = coerceNumberProperty(value);
    this.setSliderOptions(this.generateSliderOptions(year));
  }

  constructor() {
    super();

    this.setSliderOptions(DEFAULT_YEAR_SLIDER_OPTIONS);
  }

  generateSliderOptions(year: number): Options {
    const monthIndexes = Array.from(Array(12).keys());

    let accumulatedDays = 0;
    const sliderTicks = monthIndexes.map((value) => {
      const daysInMonth = moment().year(year).month(value).daysInMonth();
      const monthName = moment().month(value).format(MONTH_FORMAT);
      const midDayInMonth = daysInMonth / 2;
      const position = accumulatedDays + midDayInMonth;
      accumulatedDays += daysInMonth;
      return {
        value: accumulatedDays,
        legend: monthName,
        position: position.toFixed(0),
      };
    });

    return {
      ...DEFAULT_YEAR_SLIDER_OPTIONS,
      maxRange:
        this.type === 'daily' || this.type === 'weekly' ? accumulatedDays : 30,
      ceil: accumulatedDays,
      translate: (value: number): string => {
        const date = moment()
          .year(year)
          .dayOfYear(value)
          .format(`${MONTH_FORMAT} ${DAY_FORMAT}`);
        return date;
      },
      getLegend: (value: number): string => {
        const positions = sliderTicks.map((tick) => tick.position);
        if (contains(positions, '' + value)) {
          return sliderTicks.find((tick) => tick.position === '' + value)
            .legend;
        }
        return '';
      },
    };
  }
}
