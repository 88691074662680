import {
  ConfigService,
  RouterStore,
  UserFacadeService,
} from 'prosumer-app/libs/eyes-core';
import {
  ActionTypes,
  doNothing,
  GetSuccess,
  StateEffects,
} from 'prosumer-app/libs/eyes-shared';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { ProsumerRoutePathService } from 'prosumer-core';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  mapTo,
  mergeMap,
  take,
  tap,
  toArray,
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { Project } from '../models';
import { ProjectApiService } from '../services';
import { ProjectFacadeService } from './project-facade.service';
import { ProjectState } from './project-state.model';
import * as ProjectActions from './project.actions';
import { projectFeature, projectStateFactory } from './project.factory';

@Injectable()
export class ProjectEffects extends StateEffects<ProjectState, Project> {
  // Base Effects (Workaround)
  @Effect() getProject$ = this.get$;
  @Effect({ dispatch: false }) getProjectFailure$ = this.getFailure$;
  @Effect() getProjectList$ = this.getList$;
  @Effect({ dispatch: false }) getProjectListFailure$ = this.getListFailure$;
  @Effect({ dispatch: false }) getProjectListSuccess$ = this.getListSuccess$;
  @Effect() createProject$ = this.create$;
  @Effect({ dispatch: false }) createProjectFailure$ = this.createFailure$;
  @Effect({ dispatch: false }) createProjectSuccess$ = this.createSuccess$.pipe(
    tap((action) =>
      this._routerFacade.go(
        this._routePath.projectDetail(action.payload.data.id),
      ),
    ),
  );
  @Effect() updateProject$ = this.update$;
  @Effect({ dispatch: false }) updateProjectFailure$ = this.updateFailure$;
  @Effect({ dispatch: false }) updateProjectSuccess$ = this.updateSuccess$;
  @Effect() deleteProject$ = this.delete$;
  @Effect({ dispatch: false }) deleteProjectFailure$ = this.deleteFailure$;
  @Effect({ dispatch: false }) deleteProjectSuccess$ = this.deleteSuccess$;

  // Copy
  @Effect()
  copyProject: Observable<ProjectActions.All> = this._actions$.pipe(
    ofType<ProjectActions.CopyProject>(ProjectActions.ActionTypes.COPY_PROJECT),
    mergeMap((action) =>
      this._projectApi.copy(action.payload.data, action.payload.name).pipe(
        map(
          (response) =>
            new ProjectActions.CopyProjectSuccess({
              name: action.payload.name,
              data: action.payload.data,
              message:
                response.message ||
                this._translate.instant('Project.messages.copySuccess'),
            }),
        ),
        catchError((message) =>
          of(
            new ProjectActions.CopyProjectFailure({
              data: action.payload.data,
              name: action.payload.name,
              message:
                message ||
                this._translate.instant('Project.messages.copyFailure'),
            }),
          ),
        ),
      ),
    ),
  );

  @Effect({ dispatch: false })
  getProjectSuccess$: Observable<ProjectActions.All> = this._actions$.pipe(
    ofType(ActionTypes(projectFeature).GET_SUCCESS),
    tap((action: GetSuccess<Project>) =>
      this._userFacade.get(action.payload.resultData.owner),
    ),
  );

  @Effect({ dispatch: false })
  copyProjectFailure$: Observable<ProjectActions.All> = this._actions$.pipe(
    ofType<ProjectActions.CopyProjectFailure>(
      ProjectActions.ActionTypes.COPY_PROJECT_FAILURE,
    ),
    mergeMap((action) =>
      this._notification
        .showError(action.payload.message, 'Retry')
        .onAction()
        .pipe(
          tap(() =>
            this._projectFacade.copy(action.payload.data, action.payload.name),
          ),
          map(() => action),
        ),
    ),
  );

  @Effect({ dispatch: false })
  copyProjectSuccess$: Observable<ProjectActions.All> = this._actions$.pipe(
    ofType<ProjectActions.CopyProjectSuccess>(
      ProjectActions.ActionTypes.COPY_PROJECT_SUCCESS,
    ),
    tap((action) => this._notification.showSuccess(action.payload.message)),
  );

  // Share
  @Effect()
  shareProject$: Observable<ProjectActions.All> = this._actions$.pipe(
    ofType<ProjectActions.ShareProject>(
      ProjectActions.ActionTypes.SHARE_PROJECT,
    ),
    mergeMap((action) =>
      this._projectApi.share(action.payload.data, action.payload.userIds).pipe(
        map(
          (response) =>
            new ProjectActions.ShareProjectSuccess({
              data: action.payload.data,
              userIds: action.payload.userIds,
              message:
                response.message ||
                this._translate.instant('Project.messages.shareSuccess'),
            }),
        ),
        catchError((message) =>
          of(
            new ProjectActions.ShareProjectFailure({
              data: action.payload.data,
              userIds: action.payload.userIds,
              message:
                message ||
                this._translate.instant('Project.messages.shareFailure'),
            }),
          ),
        ),
      ),
    ),
  );

  @Effect({ dispatch: false })
  shareProjectFailure$: Observable<ProjectActions.All> = this._actions$.pipe(
    ofType<ProjectActions.ShareProjectFailure>(
      ProjectActions.ActionTypes.SHARE_PROJECT_FAILURE,
    ),
    mergeMap((action) =>
      this._notification
        .showError(action.payload.message, 'Retry')
        .onAction()
        .pipe(
          tap(() =>
            this._projectFacade.share(
              action.payload.data,
              action.payload.userIds,
            ),
          ),
          map(() => action),
        ),
    ),
  );

  @Effect({ dispatch: false })
  shareProjectSuccess$: Observable<ProjectActions.All> = this._actions$.pipe(
    ofType<ProjectActions.ShareProjectSuccess>(
      ProjectActions.ActionTypes.SHARE_PROJECT_SUCCESS,
    ),
    tap((action) => this._notification.showSuccess(action.payload.message)),
  );

  // Unshare
  @Effect()
  unshareProject$: Observable<ProjectActions.All> = this._actions$.pipe(
    ofType<ProjectActions.UnshareProject>(
      ProjectActions.ActionTypes.UNSHARE_PROJECT,
    ),
    mergeMap((action) =>
      this._projectApi
        .unshare(action.payload.data, action.payload.userIds)
        .pipe(
          map(
            (response) =>
              new ProjectActions.UnshareProjectSuccess({
                data: action.payload.data,
                userIds: action.payload.userIds,
                message:
                  response.message ||
                  this._translate.instant('Project.messages.unshareSuccess'),
              }),
          ),
          catchError((message) =>
            of(
              new ProjectActions.UnshareProjectFailure({
                data: action.payload.data,
                userIds: action.payload.userIds,
                message:
                  message ||
                  this._translate.instant('Project.messages.unshareFailure'),
              }),
            ),
          ),
        ),
    ),
  );

  @Effect({ dispatch: false })
  unshareProjectFailure$: Observable<ProjectActions.All> = this._actions$.pipe(
    ofType<ProjectActions.UnshareProjectFailure>(
      ProjectActions.ActionTypes.UNSHARE_PROJECT_FAILURE,
    ),
    mergeMap((action) =>
      this._notification
        .showError(action.payload.message, 'Retry')
        .onAction()
        .pipe(
          tap(() =>
            this._projectFacade.unshare(
              action.payload.data,
              action.payload.userIds,
            ),
          ),
          map(() => action),
        ),
    ),
  );

  @Effect({ dispatch: false })
  unshareProjectSuccess$: Observable<ProjectActions.All> = this._actions$.pipe(
    ofType<ProjectActions.UnshareProjectSuccess>(
      ProjectActions.ActionTypes.UNSHARE_PROJECT_SUCCESS,
    ),
    tap((action) => this._notification.showSuccess(action.payload.message)),
  );

  // Get list with details
  @Effect()
  getListWithDetails$: Observable<ProjectActions.All> = this.actions$.pipe(
    ofType<ProjectActions.GetProjectListWithDetails>(
      ProjectActions.ActionTypes.GET_PROJECT_LIST_WITH_DETAILS,
    ),
    mergeMap(() =>
      this._projectApi.getListWithDetails().pipe(
        map(
          (data) =>
            new ProjectActions.GetProjectListWithDetailsSuccess({
              results: data,
              message: this._translate.instant(
                this.getMessageMap().getListSuccess,
              ),
            }),
          catchError((messages) =>
            of(
              new ProjectActions.GetProjectListWithDetailsFailure({
                message:
                  messages ||
                  this._translate.instant(this.getMessageMap().getListFailure),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  @Effect({ dispatch: false })
  getListWithDetailsSuccess$: Observable<ProjectActions.All> =
    this.actions$.pipe(
      ofType<ProjectActions.GetProjectListWithDetailsSuccess>(
        ProjectActions.ActionTypes.GET_PROJECT_LIST_WITH_DETAILS_SUCCESS,
      ),
      mergeMap((action) => {
        let userIds = [];
        this._userFacade.dataIds$.subscribe((ids) => (userIds = ids));
        return this._projectFacade.dataList$.pipe(
          take(1),
          mergeMap((dataList) =>
            from(dataList || []).pipe(
              mergeMap((item) => of({ ...item, loading: false, loaded: true })),
              tap((item) => {
                if (!userIds.includes(item['owner'])) {
                  this._userFacade.get(item['owner']);
                }
              }),
              toArray(),
            ),
          ),
          mapTo(action),
        );
      }),
      tap((action) =>
        action.payload.notify
          ? this.notification.showSuccess(action.payload.message)
          : doNothing(),
      ),
    );

  @Effect({ dispatch: false })
  getListWithDetailsFailure$: Observable<ProjectActions.All> =
    this.actions$.pipe(
      ofType<ProjectActions.GetProjectListWithDetailsFailure>(
        ProjectActions.ActionTypes.GET_PROJECT_LIST_WITH_DETAILS_FAILED,
      ),
      tap((action) => this._notification.showError(action.payload.message)),
    );

  constructor(
    private _actions$: Actions,
    private _config: ConfigService,
    private _notification: NotificationsService,
    private _projectApi: ProjectApiService,
    private _projectFacade: ProjectFacadeService,
    private _routePath: ProsumerRoutePathService,
    private _routerFacade: RouterStore,
    private _translate: TranslateService,
    private _userFacade: UserFacadeService,
  ) {
    super(
      _actions$,
      _projectApi,
      _notification,
      _projectFacade,
      projectStateFactory,
      _translate,
    );
  }

  getMessageMap() {
    return {
      createSuccess: 'Project.messages.saveSuccess',
      createFailure: 'Project.messages.saveFailure',
      getSuccess: 'Project.messages.getSuccess',
      getFailure: 'Project.messages.getFailure',
      getListSuccess: 'Project.messages.getAllSuccess',
      getListFailure: 'Project.messages.getAllFailure',
      updateSuccess: 'Project.messages.updateSuccess',
      updateFailure: 'Project.messages.updateFailure',
      deleteSuccess: 'Project.messages.deleteSuccess',
      deleteFailure: 'Project.messages.deleteFailure',
      retry: 'Generic.messages.retry',
    };
  }
}
