<ng-container *ngrxLet="scenario$ as scenario">
  <mat-icon
    *ngIf="mode === 'icon'; else text"
    color="primary"
    class="clickable material-icons-outlined"
    [attr.data-testid]="'scenario-list-logIcon-' + scenario.id"
    [class.disabled-icon]="disabled$ | ngrxPush"
    (click)="onClick(); $event.stopPropagation()"
    >assignment</mat-icon
  >
  <ng-template #text>
    <button
      mat-menu-item
      color="primary"
      (click)="onClick()"
      [attr.data-testid]="'scenario-list-logsText-' + scenario.id"
      [disabled]="disabled$ | ngrxPush"
      [class.scenario-action-inactive]="disabled$ | ngrxPush"
      [class.scenario-action-link-active]="!(disabled$ | ngrxPush)"
    >
      <mat-icon color="primary" class="material-icons-outlined"
        >assignment</mat-icon
      >
      {{ 'Case.labels.viewLogsSummary' | translate }}
    </button>
  </ng-template>
</ng-container>
