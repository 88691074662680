import { Environment } from 'prosumer-app/app.model';

import { endpoints, version } from './environment-commons';

export const environment: Environment = {
  name: 'production',
  production: true,
  cognito: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_cUulHNZFr',
      userPoolWebClientId: 'efli9k9h1luucjuoeaspu192d',
      userPoolWebClientSecret: '',
      oauth: {
        domain: 'prosumer-production.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://production.prosumer.te-ded.com/dashboard',
        redirectSignOut: 'https://production.prosumer.te-ded.com/home',
        responseType: 'code',
        options: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          AdvancedSecurityDataCollectionFlag: true,
        },
        identityProvider: 'prosumer-production-idp',
      },
    },
  },
  http: {
    timeoutMs: 300000,
    maxRetryAttempts: 2,
    retryScalingDuration: 1000,
    retryIncludedStatusCodes: [401],
    refreshTokenIncludedStatusCodes: [401],
  },
  api: {
    baseUrl: 'https://api.production.prosumer.te-ded.com/',
    endpoints,
  },
  version,
  websocket: {
    url: 'wss://wss.production.prosumer.te-ded.com',
    keepAlive: true,
    keepAliveIntervalMs: 480000,
    retryOnClose: true,
    retryOnError: true,
    retryScalingDuration: 5000,
  },
  sentryDsn:
    'https://98a6e1632c7c4a53bc986823f0550206@o258615.ingest.sentry.io/1455718',
  sentryRelease: `prosumer-${version.version}`,
  homeUrl:
    'https://engie.sharepoint.com/sites/TETDataViz/Shared%20Documents/Prosumer%20Website/Prod/index.aspx',
  faqUrl:
    'https://engie.sharepoint.com/sites/TETDataViz/Shared%20Documents/Prosumer%20FAQ/Prod/index.aspx',
  trainingUrl:
    'https://engie.sharepoint.com/sites/TETDataViz/Shared%20Documents/Prosumer%20Video/Prod/index.aspx',
  updatesUrl:
    'https://engie.sharepoint.com/sites/TETDataViz/Shared%20Documents/Prosumer%20Updates/Prod/index.aspx',
  amplitudeAPIKey: '1af19f6a0e9675450b33fb616812edc8',
};
