import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';

import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { GamsUploadFormComponent } from 'prosumer-app/+scenario/components/upload-form/gams-upload-form';
import { LoggerService } from 'prosumer-app/libs/eyes-core';
import { BaseComponent } from 'prosumer-app/libs/eyes-shared';
import { filter, map, startWith, take } from 'rxjs/operators';
import { ScenarioFacadeService } from '../../state';

@Component({
  selector: 'prosumer-scenario-upload-page',
  templateUrl: './scenario-upload-page.component.html',
  styleUrls: ['./scenario-upload-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioUploadPageComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild(GamsUploadFormComponent) gamsUploadComponent;

  projectId: string;
  caseId: string;

  params$ = this._route.params.pipe(take(1), this.takeUntilShare());
  routeMode$ = this._route.data.pipe(
    take(1),
    filter((data) => !!data),
    map((data) => data.mode),
    this.takeUntilShare(),
  );
  constructor(
    private _logger: LoggerService,
    private _route: ActivatedRoute,
    private _location: Location,
    public scenarioFacade: ScenarioFacadeService,
  ) {
    super();
  }

  uploading$ = this.scenarioFacade.uploading$.pipe(
    startWith(false),
    this.takeUntilShare(),
  );

  ngOnInit(): void {
    this.params$.subscribe((params) => {
      if (params) {
        this.projectId = params.projectId;
        this.caseId = params.caseId;
      }
    });
  }

  onCancel(): void {
    this._location.back();
  }

  onCreate(data: any): void {
    this._logger.info(data);
    const gamsFiles = this.gamsUploadComponent.managedData.getListData();

    this.scenarioFacade.upload(
      {
        ...data,
        projectId: this.projectId,
        caseId: this.caseId,
      },
      data.fileInput,
      gamsFiles,
    );
  }

  onClone(data: any): void {
    this._logger.info(data);
    this.scenarioFacade.uploadClone(
      {
        ...data,
        projectId: this.projectId,
        caseId: this.caseId,
      },
      data.fileInput,
    );
  }
}
