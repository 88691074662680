import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  FormFieldErrorMessageMap,
  getKeys,
} from 'prosumer-app/libs/eyes-shared';

@Component({
  selector: 'prosumer-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SlideToggleComponent),
      multi: true,
    },
  ],
})
export class SlideToggleComponent implements OnInit, ControlValueAccessor {
  @Output() valueChanged = new EventEmitter<boolean>();
  @Input() loading = false;
  @Input() label: string;
  @Input() tooltipMessage = '';
  @Input() control: AbstractControl = new FormControl();
  _isViewOnly: boolean;
  get isViewOnly() {
    return this._isViewOnly;
  }
  @Input() set isViewOnly(value: boolean) {
    this._isViewOnly = value;
    if (this._isViewOnly) {
      this.control.disable({ onlySelf: true, emitEvent: false });
      return;
    }
    this.control.enable({ onlySelf: true, emitEvent: false });
  }

  @Input() set errorMessageMap(errorMessageMap: FormFieldErrorMessageMap) {
    this._errorMessageMap = errorMessageMap;
    if (!this._errorMessageMap) {
      this._errorMessageMap = {};
    }
  }
  _errorMessageMap: FormFieldErrorMessageMap = {};

  @Input() set incomingControlValue(value: boolean) {
    if (value === true || value === false) {
      this.control.patchValue(value, { emitEvent: false, onlySelf: true });
      this._cachedValue = value;
    }
  }
  private _cachedValue = false;

  @Input() set disabled(value: boolean) {
    if (value === true) {
      this.control.disable({ emitEvent: false, onlySelf: true });
    } else if (value === false) {
      this.control.enable({ emitEvent: false, onlySelf: true });
    }
  }

  @Input() set resetCachedValue(value: any) {
    if (value) {
      this.control.patchValue(this._cachedValue, {
        emitEvent: false,
        onlySelf: true,
      });
    }
  }

  ngOnInit() {
    this._subscribeToControlValueChange();
  }

  private _subscribeToControlValueChange() {
    this.control.valueChanges.subscribe((v) => {
      this.onChange(v);
    });
  }

  onChange = (value: boolean) => {
    this.valueChanged.emit(value);
  };

  writeValue(value: any): void {
    this.control.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  getErrors(errorObj: any) {
    return errorObj ? getKeys(errorObj) : [];
  }
}
