<input
  #fileUpload
  type="file"
  class="prosumer-file-upload-input"
  hidden
  data-testid="file-upload-input"
  [accept]="formats"
  (change)="onFileSelected($event)"
/>

<div
  class="prosumer-file-upload-container"
  fxLayout="column"
  fxLayoutAlign="center center"
  [ngClass]="{ dragover: dragover }"
  (drop)="onDrop($event)"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  (click)="onBrowse()"
>
  <mat-icon color="primary">{{ icon }}</mat-icon>

  <h2 class="prosumer-file-upload-header">Drag & Drop</h2>

  <p class="prosumer-file-upload-details">
    Your file here or
    <a class="eyes-href-bold custom-download-template-link">browse</a>
    to attach it
  </p>

  <p class="prosumer-file-upload-hint">
    {{ hint }}
  </p>
</div>

<div
  *ngIf="!hideStatus"
  class="prosumer-file-upload-status"
  [ngClass]="{ 'prosumer-file-upload-status-success': !!file }"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="0.1rem"
>
  <mat-icon *ngIf="!!file" inline>done_all</mat-icon>
  <span>{{ file?.name || 'No file attached.' }}</span>
</div>
