<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
  <mat-icon
    [matMenuTriggerFor]="menu"
    [svgIcon]="icon"
    class="profile-icon"
    data-testid="profile-icon"
  >
  </mat-icon>
  <mat-menu
    class="profile-menu-container"
    #menu="matMenu"
    [overlapTrigger]="false"
  >
    <div class="profile-details">
      <mat-icon [svgIcon]="icon" class="profile-icon"> </mat-icon>
      <div class="profile-name-plan">
        <p class="profile-name" data-testid="full-name">{{ fullName }}</p>

        <ng-container *ngIf="loading; else userPlan">
          <ngx-skeleton-loader
            class="eyes-maximize-width"
            [theme]="{ 'margin-bottom': '0', height: '20px' }"
          ></ngx-skeleton-loader>
        </ng-container>
      </div>
    </div>
    <button
      class="profile-menu-user-preferences"
      mat-menu-item
      [routerLink]="resolveUserPreferencesPath()"
      data-testid="profile-menu-user-preferences"
    >
      <mat-icon>{{ userPreferencesIcon }}</mat-icon>
      <span>{{ userPreferencesName }}</span>
    </button>
    <button
      class="profile-menu-feedback"
      mat-menu-item
      (click)="onClickFeedback()"
      data-testid="profile-menu-feedback"
    >
      <mat-icon>feedback</mat-icon>Feedback
    </button>
    <button
      class="profile-menu-logout"
      mat-menu-item
      (click)="onClickLogout()"
      data-testid="profile-menu-logout"
    >
      <mat-icon>power_settings_new</mat-icon>Logout
    </button>
  </mat-menu>
</div>

<ng-template #userPlan>
  <p class="profile-plan" [ngStyle]="planStyle" data-testid="license-plan">
    <mat-icon>stars</mat-icon>{{ plan }}
  </p>
</ng-template>
