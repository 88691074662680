import { coerceNumberProperty } from '@angular/cdk/coercion';
import { getKeys } from 'prosumer-app/libs/eyes-shared';
import { LineData, NodeData, OverviewData } from '../models';

// Overview JSON Property Definition
export const OVERVIEW_JSON_PROPS = {
  line: {
    name: 'name',
    fluid: 'Fluid',
    originId: 'origin_id',
    destinationId: 'destination_id',
    yearlyFlowDestToOrig: 'Yearly flow Dest to Orig [kWh]',
    yearlyFlowOrigToDest: 'Yearly flow Orig to Dest [kWh]',
    sizeKw: 'Size [kw]',
  },
  node: {
    name: 'name',
  },
};

/**
 * Checks if overview line is hideable when showing only optimized lines
 *
 * @param line - the line data
 */
export const isOverviewLineHideable = (line: any) =>
  !!line[OVERVIEW_JSON_PROPS.line.sizeKw] &&
  coerceNumberProperty(line[OVERVIEW_JSON_PROPS.line.sizeKw]) === 0;

/**
 * Maps the JSON data parameter to the OverviewModel
 *
 * @param data - the JSON data
 */
export const mapToOverviewModel = (data: any): OverviewData => ({
  // Lines Mapping
  lines: getKeys(data.lines).map(
    (lineId) =>
      ({
        id: lineId,
        name: data.lines[lineId][OVERVIEW_JSON_PROPS.line.name],
        energyVector: data.lines[lineId][OVERVIEW_JSON_PROPS.line.fluid],
        sourceId: data.lines[lineId][OVERVIEW_JSON_PROPS.line.originId],
        targetId: data.lines[lineId][OVERVIEW_JSON_PROPS.line.destinationId],
        rawData: data.lines[lineId],
        hideable: isOverviewLineHideable(data.lines[lineId]),
      } as LineData),
  ),
  // Nodes Mapping
  nodes: getKeys(data.nodes).map(
    (nodeId) =>
      ({
        id: nodeId,
        name: data.nodes[nodeId][OVERVIEW_JSON_PROPS.node.name],
        rawData: data.nodes[nodeId],
      } as NodeData),
  ),
});
