<div style="padding: 24px">
  <h1 class="mat-title" mat-dialog-title>
    <div [innerHTML]="data?.title || 'Confirmation'"></div>
  </h1>
  <div class="mat-body" mat-dialog-content>
    <div
      [innerHTML]="data?.message || 'Are you sure you want to do this?'"
    ></div>
  </div>
  <div mat-dialog-actions fxLayout="row">
    <button mat-flat-button color="primary" (click)="onConfirm()">
      {{ data?.confirm || 'OK' }}
    </button>
    <button
      *ngIf="!data?.nonCancellable"
      mat-flat-button
      color="undefined"
      [ngClass]="{ hideButton: data?.close === undefined }"
      (click)="onClose()"
    >
      {{ data?.close || 'Cancel' }}
    </button>
  </div>
</div>
