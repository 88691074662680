<prosumer-page>
  <div fxLayout="column" fxLayoutGap="10px">
    <prosumer-progress-card
      [showProgress]="(updating$ | async) || (caseLoadingList$ | async)"
    >
      <prosumer-project-form
        [loading]="loading$ | async"
        [saving]="updating$ | async"
        [mode]="mode$ | async"
        [data]="formData$ | async"
        [owner]="owner$ | async"
        [canEdit]="canUpdate$ | async"
        (edit)="onEdit()"
        (save)="onUpdate($event)"
        (cancel)="onCancel()"
      >
        <div fxLayout="column" fxLayoutGap="10px">
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="10px"
            class="filter"
          >
            <prosumer-filter-input
              (filter)="onFilter($event)"
              placeholdermsg="Search for cases"
              label="Search for cases"
              class="eyes-maximize-width"
            ></prosumer-filter-input>
            <div fxFlex></div>
            <a
              mat-button
              type="button"
              color="primary"
              [routerLink]="getCreateRouterLink() | async"
              [disabled]="(canUpdate$ | async) !== true"
              data-testid="create-case-link"
            >
              <mat-icon matPrefix data-testid="project-details-new-case-icon"
                >add</mat-icon
              ><b data-testid="project-details-new-case-text">NEW CASE</b>
            </a>
          </div>

          <mat-table
            data-testid="project-details-table"
            matSort
            class="eyes-table"
            [dataSource]="casesDataSource$ | async"
          >
            <!-- Name column -->
            <ng-container cdkColumnDef="name">
              <mat-header-cell
                *cdkHeaderCellDef
                mat-sort-header
                data-testid="case-name-header-cell"
              >
                Case Name
                <prosumer-tooltip-anchor
                  [message]="'cases.case_name'"
                  tooltipPosition="right"
                ></prosumer-tooltip-anchor>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row">
                <ngx-skeleton-loader
                  *ngIf="
                    row?.loading && !row?.loaded && !row?.error;
                    else loaded
                  "
                  class="eyes-maximize-width"
                ></ngx-skeleton-loader>
                <ng-template #loaded>
                  <a
                    [routerLink]="
                      !row?.deleting && !row?.error
                        ? routePath?.caseDetailRelative(row?.id)
                        : []
                    "
                    >{{ row.name || '-' }}</a
                  >
                </ng-template>
              </mat-cell>
            </ng-container>

            <!-- Node Type column -->
            <ng-container cdkColumnDef="nodeType">
              <mat-header-cell
                *cdkHeaderCellDef
                mat-sort-header
                data-testid="node-type-header-cell"
              >
                Node Type
                <!--                <prosumer-tooltip-anchor [message]="'cases.case_node_type'" tooltipPosition="right"></prosumer-tooltip-anchor>-->
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row">
                <ngx-skeleton-loader
                  *ngIf="
                    row?.loading && !row?.loaded && !row?.error;
                    else loaded
                  "
                  class="eyes-maximize-width"
                ></ngx-skeleton-loader>
                <ng-template #loaded>
                  {{ row.nodeType | titlecase }}
                </ng-template>
              </mat-cell>
            </ng-container>

            <!-- Created By column -->
            <ng-container cdkColumnDef="owner">
              <mat-header-cell
                *cdkHeaderCellDef
                mat-sort-header
                data-testid="created-by-header-cell"
              >
                Created By<prosumer-tooltip-anchor
                  [message]="'cases.case_created_by'"
                  tooltipPosition="right"
                ></prosumer-tooltip-anchor>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let case">
                <prosumer-owner-resolver
                  class="eyes-maximize-width"
                  [data]="case"
                >
                </prosumer-owner-resolver>
              </mat-cell>
            </ng-container>

            <!-- Created On column -->
            <ng-container cdkColumnDef="createdDate">
              <mat-header-cell
                *cdkHeaderCellDef
                mat-sort-header
                data-testid="created-on-header-cell"
              >
                Created On<prosumer-tooltip-anchor
                  [message]="'cases.case_created_on'"
                  tooltipPosition="right"
                ></prosumer-tooltip-anchor>
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row">
                <ngx-skeleton-loader
                  *ngIf="
                    row?.loading && !row?.loaded && !row?.error;
                    else loaded
                  "
                  class="eyes-maximize-width"
                ></ngx-skeleton-loader>
                <ng-template #loaded>
                  {{ (row.createdDate | dateFormat) || '-' }}
                </ng-template>
              </mat-cell>
            </ng-container>

            <!-- Actions column -->
            <ng-container cdkColumnDef="actions">
              <mat-header-cell
                *cdkHeaderCellDef
                data-testid="actions-header-cell"
                >Actions</mat-header-cell
              >
              <mat-cell *cdkCellDef="let row">
                <ngx-skeleton-loader
                  *ngIf="
                    row?.loading && !row?.loaded && !row?.error;
                    else loaded
                  "
                  class="eyes-maximize-width"
                ></ngx-skeleton-loader>
                <ng-template #loaded>
                  <div style="position: relative">
                    <button
                      mat-icon-button
                      type="button"
                      color="primary"
                      [disabled]="
                        row?.copying ||
                        row?.copyStatus ||
                        row?.error ||
                        row?.deleting ||
                        (canUpdate$ | async) !== true
                      "
                      (click)="onCopy(row)"
                      [attr.data-testid]="'case-duplicate-' + row?.name"
                    >
                      <mat-icon
                        aria-label="Copy"
                        tooltipPosition="center"
                        eyesTooltip="Duplicate Case"
                        >file_copy
                      </mat-icon>
                    </button>
                    <mat-spinner
                      *ngIf="row?.copying"
                      diameter="40"
                      class="eyes-absolute-top"
                      color="accent"
                    >
                    </mat-spinner>
                  </div>

                  <div style="position: relative">
                    <button
                      mat-icon-button
                      type="button"
                      color="primary"
                      (click)="onDelete(row)"
                      [disabled]="
                        row?.copying ||
                        row?.deleting ||
                        row?.copyStatus ||
                        row?.error ||
                        (canUpdate$ | async) !== true
                      "
                      [attr.data-testid]="'case-delete-' + row?.name"
                    >
                      <mat-icon
                        aria-label="Delete"
                        tooltipPosition="center"
                        [prosumerTooltip]="'cases.case_delete'"
                        >delete_forever</mat-icon
                      >
                    </button>
                    <mat-spinner
                      *ngIf="row?.deleting"
                      diameter="40"
                      class="eyes-absolute-top"
                      color="accent"
                    >
                    </mat-spinner>
                  </div>
                </ng-template>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *cdkHeaderRowDef="[
                'name',
                'nodeType',
                'owner',
                'createdDate',
                'actions'
              ]"
            ></mat-header-row>
            <mat-row
              *cdkRowDef="
                let row;
                columns: ['name', 'nodeType', 'owner', 'createdDate', 'actions']
              "
              [@rowAnimation]
            ></mat-row>
          </mat-table>

          <prosumer-table-message
            [loading]="caseLoadingList$ | async"
            [dataLength]="caseDataLength$ | async"
            [filteredDataLength]="filteredDataLength$ | async"
            [filter]="filter$ | async"
          >
          </prosumer-table-message>

          <mat-paginator
            *ngIf="(cases$ | async)?.length > 50"
            hidePageSize
            showFirstLastButtons
            pageSize="50"
          >
          </mat-paginator>
        </div>
      </prosumer-project-form>
    </prosumer-progress-card>
  </div>
</prosumer-page>
