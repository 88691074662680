import {
  ActivatedRouteSnapshot,
  PRIMARY_OUTLET,
  RouterStateSnapshot,
} from '@angular/router';
import {
  ROUTER_CANCEL,
  ROUTER_ERROR,
  ROUTER_NAVIGATION,
  RouterStateSerializer,
  SerializedRouterStateSnapshot,
} from '@ngrx/router-store';
import {
  MemoizedSelector,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import { Breadcrumb, Feature } from '../../models';
import {
  ROUTER_ACTIVE,
  RouterActionsExtended,
  routerFeatureName,
} from './router.actions';
import { RouterStateExtended, RouterStateUrl } from './router.model';

export const getBreadcrumbs = (
  root: ActivatedRouteSnapshot,
  url: string = '',
  breadcrumbs: Array<Breadcrumb> = [],
): Array<Breadcrumb> => {
  const children: ActivatedRouteSnapshot[] = root.children;
  if (children.length === 0) {
    return breadcrumbs;
  }
  for (const child of children) {
    if (child.outlet !== PRIMARY_OUTLET) {
      continue;
    }
    if (!child.data.hasOwnProperty('breadcrumb')) {
      return getBreadcrumbs(child, url, breadcrumbs);
    }
    if (child.url.length > 0) {
      const routeURL: string = child.url
        .map((segment) => segment.path)
        .join('/');
      url += `/${routeURL}`;

      const label =
        child.data.reference && child.data.referenceKey
          ? child.params[child.data.referenceKey]
          : child.data.breadcrumb;
      const breadcrumb: Breadcrumb = {
        ...child.data,
        params: child.params,
        label,
        url,
      };
      breadcrumbs.push(breadcrumb);
      return getBreadcrumbs(child, url, breadcrumbs);
    }
  }
  return breadcrumbs;
};

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams },
    } = routerState;
    const breadcrumbs = (getBreadcrumbs(route.root) || []).map(
      (breadcrumb) => ({
        ...breadcrumb,
        url: generateBreadcrumbUrl(breadcrumb, url),
      }),
    );
    const params = route.params;
    const data = route.data as Feature;

    return { url, params, queryParams, data, breadcrumbs };
  }
}

export const generateBreadcrumbUrl = (
  breadcrumb: Breadcrumb,
  currentUrl?: string,
) => {
  if (breadcrumb.forceCurrentUrl) {
    return currentUrl;
  }
  if (breadcrumb.trim && !!breadcrumb.trimPath) {
    return breadcrumb.url.substring(
      0,
      breadcrumb.url.indexOf(breadcrumb.trimPath),
    );
  }
  return breadcrumb.url;
};

export const routerReducerExtended = (
  state: RouterStateExtended,
  action: RouterActionsExtended<SerializedRouterStateSnapshot>,
): RouterStateExtended => {
  switch (action.type) {
    case ROUTER_ACTIVE: {
      return {
        ...state,
        activeRoute: action.payload.routerState,
      };
    }
    case ROUTER_NAVIGATION:
    case ROUTER_ERROR:
    case ROUTER_CANCEL:
      return {
        activeRoute:
          state && typeof state.activeRoute !== 'undefined'
            ? state.activeRoute
            : null,
        state: action.payload.routerState as any,
        navigationId: action.payload.event.id,
      };
    default: {
      return state;
    }
  }
};

export const routerFeature: MemoizedSelector<any, RouterStateExtended> =
  createFeatureSelector<RouterStateExtended>(routerFeatureName);

export const routerSelectors: { [key: string]: MemoizedSelector<any, any> } = {
  state: routerFeature,
  activeRoute: createSelector(
    routerFeature,
    (reducerState: RouterStateExtended) =>
      reducerState && reducerState.activeRoute,
  ),
  desiredRoute: createSelector(
    routerFeature,
    (reducerState: RouterStateExtended) => reducerState && reducerState.state,
  ),
  params: createSelector(
    routerFeature,
    (reducerState: RouterStateExtended) =>
      reducerState && reducerState.state && reducerState.state.params,
  ),
  queryParams: createSelector(
    routerFeature,
    (reducerState: RouterStateExtended) =>
      reducerState && reducerState.state && reducerState.state.queryParams,
  ),
  url: createSelector(
    routerFeature,
    (reducerState: RouterStateExtended) =>
      reducerState && reducerState.state && reducerState.state.url,
  ),
  data: createSelector(
    routerFeature,
    (reducerState: RouterStateExtended) =>
      reducerState && reducerState.state && reducerState.state.data,
  ),
  breadcrumbs: createSelector(
    routerFeature,
    (reducerState: RouterStateExtended) =>
      reducerState && reducerState.state && reducerState.state.breadcrumbs,
  ),
};
