import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { from } from 'rxjs';

@Injectable()
export class UpdateNotificationService {
  constructor(private _update: SwUpdate) {}

  availableUpdate$ = this._update.available;
  activatedUpdate$ = this._update.activated;

  forceUpdate() {
    return from(this._update.activateUpdate());
  }

  checkUpdate() {
    return from(this._update.checkForUpdate());
  }
}
