<div fxLayout="column" fxLayoutGap="8px" fxAlign="start">
  <prosumer-slide-toggle
    fxFlex="100%"
    label="tdb.label.generic.toggleBtn"
    data-testid="egce-dialog-tdb-toggle-button"
    [isViewOnly]="isTdbApiResponseLoading$ | async"
    [incomingControlValue]="isTdbModuleEnabled | async"
    (valueChanged)="toggleTDBModule($event)"
  ></prosumer-slide-toggle>

  <span *ngIf="!!profileFilters" class="reference-info">
    <span>*Reference: </span>
    <span class="data">{{ profileFilters | referenceStr }}</span>
  </span>

  <form [formGroup]="tdbFiltersForm">
    <div
      *ngIf="isTdbModuleEnabled | async"
      fxLayout="row wrap"
      fxLayoutGap="16px"
      fxAlign="start"
    >
      <!-- Transversal Database -->
      <prosumer-select-new
        fxFlex="31%"
        [options]="tdbCategoryOptions$ | async"
        [disabled]="isTdbApiResponseLoading$ | async"
        [control]="tdbFiltersForm?.controls?.category"
        [placeholder]="
          'tdb.placeholder.emissionFactor.emissionCategory' | translate
        "
        [label]="'tdb.label.emissionFactor.emissionCategory' | translate"
        data-testid="tdb-emission-module-select-category"
      >
      </prosumer-select-new>
      <prosumer-select-new
        fxFlex="31%"
        [options]="tdbItemOptions$ | async"
        [disabled]="isTdbFiltersLoading$ | async"
        [control]="tdbFiltersForm?.controls?.item"
        [placeholder]="'tdb.placeholder.emissionFactor.item' | translate"
        [label]="'tdb.label.emissionFactor.item' | translate"
        data-testid="tdb-emission-module-select-item"
      >
      </prosumer-select-new>
      <prosumer-select-new
        fxFlex="31%"
        [options]="tdbGeographyOptions$ | async"
        [disabled]="isTdbFiltersLoading$ | async"
        [control]="tdbFiltersForm?.controls?.geography"
        [placeholder]="'tdb.placeholder.generic.geography' | translate"
        [label]="'tdb.label.generic.geography' | translate"
        data-testid="tdb-emission-module-select-geography"
      >
      </prosumer-select-new>
      <prosumer-select-new
        fxFlex="31%"
        [options]="tdbScenarioOptions$ | async"
        [disabled]="isTdbFiltersLoading$ | async"
        [control]="tdbFiltersForm?.controls?.scenario"
        [placeholder]="'tdb.placeholder.generic.scenario' | translate"
        [label]="'tdb.label.generic.scenario' | translate"
        data-testid="tdb-emission-module-select-scenario"
      >
      </prosumer-select-new>
      <prosumer-select-new
        fxFlex="31%"
        [options]="tdbScopeOptions$ | async"
        [disabled]="isTdbFiltersLoading$ | async"
        [control]="tdbFiltersForm?.controls?.scope"
        [placeholder]="'tdb.placeholder.emissionFactor.scope' | translate"
        [label]="'tdb.label.emissionFactor.scope' | translate"
        data-testid="tdb-emission-module-select-scope"
      >
      </prosumer-select-new>
      <prosumer-select-new
        fxFlex="31%"
        [options]="tdbUnitOptions$ | async"
        [disabled]="isTdbFiltersLoading$ | async"
        [control]="tdbFiltersForm?.controls?.unit"
        [placeholder]="'tdb.placeholder.generic.unit' | translate"
        [label]="'tdb.label.generic.unit' | translate"
        data-testid="tdb-emission-module-select-unit"
      >
      </prosumer-select-new>
      <!-- <prosumer-select-new
        fxFlex="24%"
        class="select-input"
        *ngFor="let filter of tdbFilterOptions$ | ngrxPush"
        [attr.data-testid]="'tdb-secondary-filters-select-' + filter.key"
        [control]="tdbFiltersForm.controls[filter.key]"
        [disabled]="isTdbApiResponseLoading$ | ngrxPush"
        [label]="filter.key | camelToText | titlecase"
        [tooltip]="'tdb_filters.' + filter.key"
        [options]="filter.values"
      >
      </prosumer-select-new> -->
    </div>
    <!-- <div
      fxLayout="row wrap"
      fxLayoutGap="10px"
      fxAlign="start"
      class="container-select-inputs"
    >
      <prosumer-select-new
        fxFlex="24%"
        class="select-input"
        *ngFor="let filter of filterDataOptionsMapped$ | ngrxPush"
        [options]="filter.values"
        [control]="secondaryFiltersForm.controls[filter.key]"
        [disabled]="isLoading"
        [label]="filter.key | camelToText | titlecase"
        [tooltip]="'tdb_filters.' + filter.key"
        [attr.data-testid]="'tdb-secondary-filters-select-' + filter.key"
      >
      </prosumer-select-new>
    </div> -->
    <!-- <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-title">
            {{ 'Scenario.labels.tdb.filter' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_commodities.wizard_tdb_title'"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel> -->
  </form>
</div>
