import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConfigService } from 'prosumer-app/libs/eyes-core';
import { ApiHandler, ApiMappable } from 'prosumer-app/libs/eyes-shared';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ComputingTime, Storage, Usage } from '../models';

@Injectable()
export class BillingApiService
  extends ApiHandler
  implements ApiMappable<Usage>
{
  constructor(private _http: HttpClient, private _config: ConfigService) {
    super(_config.api.baseUrl);
  }

  getComputingTime(): Observable<Usage> {
    return this._http
      .get<any>(
        this.endpoint(this._config.api.endpoints.billing.getComputingTime),
      )
      .pipe(
        map((response) => this.mapDataToFrontend(response)),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  getStorage(): Observable<Usage> {
    return this._http
      .get<any>(this.endpoint(this._config.api.endpoints.billing.getStorage))
      .pipe(
        map((response) => this.mapDataToFrontend(response)),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  getUsedStorage(): Observable<Storage> {
    return this._http
      .get<any>(this.endpoint(this._config.api.endpoints.billing.getStorage))
      .pipe(
        map((response) => this.mapDataToFrontend(response)),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  mapDataToFrontend(data: any): Usage | ComputingTime | Storage {
    return {
      limit: data.limit,
      used: this.mapConsumptionUsage(data),
      reserved: data.reserved,
      licensePlan: data.plan || (data.limit === 0 ? 'unlimited' : 'silver'),
    };
  }

  /**
   * the used field coming from the backend will vary
   * For Computing Time, it is a single Number Field.
   * For Storage. It is represented as a JSON object that has
   * a field for binary, and database. If it is represented as JSON
   * we sum the two.
   */
  mapConsumptionUsage(data: any) {
    const used = data.used;
    if (typeof used === 'object' && 'database' in used && 'binary' in used) {
      return used.database + used.binary;
    }
    return used;
  }

  mapDataToBackend(data: Usage) {
    return {
      limit: data.limit,
      used: data.used,
      reserved: data.reserved,
      plan: (data as ComputingTime).licensePlan,
    };
  }
}
