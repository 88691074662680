import { Injectable } from '@angular/core';
import {
  ResultNameValue,
  ResultYearValue,
  VisualizerData,
} from '@prosumer/results/components/results-visualizer';
import {
  COST_TYPE,
  EmissionResult,
  MainOutputResult,
  SIZING_AND_COST_KEYS,
} from '@prosumer/results/models/main-output-results.model';
import { ResultsExtractorService } from '@prosumer/results/state';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  SizingAndCostVisualizerData,
  TypedSizingAndCostVisualizerData,
} from './main-output-results.model';

@Injectable()
export class MainOutputResultsService {
  discountedCost$ = new BehaviorSubject<VisualizerData[]>([]);
  yearlyTotalEmissions$ = new BehaviorSubject<VisualizerData[]>([]);
  sizingAndCostData$ = new BehaviorSubject<TypedSizingAndCostVisualizerData[]>(
    [],
  );

  constructor(private results: ResultsExtractorService) {}

  getMainOutput() {
    this.getResultDataStream()
      .pipe(take(1))
      .subscribe((data) => {
        this.discountedCost$.next([
          this.getDiscountedCostData(data, COST_TYPE.CAPEX),
          this.getDiscountedCostData(data, COST_TYPE.OPEX),
        ]);
        this.yearlyTotalEmissions$.next(this.mapEmissionData(data.emissions));
        const result = [];
        for (const key of Object.values(SIZING_AND_COST_KEYS)) {
          result.push(this.buildSizingAndCostVisualizerData(data, key));
          this.sizingAndCostData$.next(result);
        }
      });
  }

  private getDiscountedCostData(
    data: MainOutputResult,
    costType: string,
  ): VisualizerData {
    return this.mapRawDataToCumulative(data, costType);
  }

  private buildSizingAndCostVisualizerData(
    rawData: MainOutputResult,
    filterType: string,
  ): TypedSizingAndCostVisualizerData {
    const sizing = this.getSizing(rawData[filterType]);
    const capex = this.getCost(rawData[filterType], COST_TYPE.CAPEX);
    const opex = this.getCost(rawData[filterType], COST_TYPE.OPEX);
    const output = {
      name: filterType,
      value: { sizing, capex, opex } as SizingAndCostVisualizerData,
    };
    return output;
  }

  private mapEmissionData(raw: EmissionResult[]): VisualizerData[] {
    const result = [];
    raw.map((emission) => {
      result.push({
        name: String(emission.year),
        series: emission.values,
      } as VisualizerData);
    });

    return result;
  }

  private filterZeroValues(raw: any): any {
    return raw.filter((data) => !!data['value']);
  }

  private getSizing(rawData: MainOutputResult): ResultNameValue[] {
    const result = this.mapDataToNameValue(rawData['sizing'], 'size_kw');
    return this.filterZeroValues(result);
  }

  private getCost(
    rawData: MainOutputResult,
    costType: string,
  ): ResultNameValue[] {
    const result = this.mapDataToNameValue(rawData[costType], costType);
    return this.filterZeroValues(result);
  }

  private mapDataToNameValue(raw: any, valueKey: string): ResultNameValue[] {
    return raw.map((data) => ({ name: data['name'], value: data[valueKey] }));
  }

  private getResultDataStream(): Observable<MainOutputResult> {
    const resultData = this.results.getMainOutputStream().pipe(
      map(
        (result) =>
          ({
            firstYear: result[0].firstYear,
            lastYear: result[0].lastYear,
            total: result[0].total,
            emissions: result[0].emissions,
            opex: result[0].opex,
            capex: result[0].capex,
          } as MainOutputResult),
      ),
    );
    return resultData;
  }

  private mapRawDataToCumulative(raw: any, name: string): VisualizerData {
    const result = {
      name: name.toUpperCase(),
      series: this.listYearValues(raw[name] as ResultYearValue),
    };
    if (result.series.every((yearValue) => !!!yearValue.value)) {
      return {} as VisualizerData;
    }
    return result;
  }

  private listYearValues(yearValue: ResultYearValue): ResultNameValue[] {
    return Object.entries(yearValue).map(([year, value]) => ({
      name: String(year),
      value,
    }));
  }
}
