import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { SankeyChartModule } from 'prosumer-shared/modules/charts/components/sankey-chart';
import { FlowResultsComponent } from './flow-results.component';

@NgModule({
  declarations: [FlowResultsComponent],
  imports: [
    CommonModule,
    SankeyChartModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  exports: [FlowResultsComponent],
})
export class FlowResultsModule {}
