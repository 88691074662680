import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  ValidationErrors,
  Validator,
} from '@angular/forms';

import { PageMode } from '../../models/index';
import { BaseControlComponent } from '../base-control/index';

@Directive()
export abstract class BaseFormComponent
  extends BaseControlComponent
  implements OnInit, Validator
{
  get controls() {
    return (this.form || ({} as FormGroup)).controls || {};
  }

  form: FormGroup;
  submitted = false;

  @Input() mode: PageMode;

  _loading: boolean;
  @Input() set loading(loading: boolean) {
    if (this.hasForm() && !this.saving) {
      if (loading) {
        this.form.disable({ emitEvent: false });
      } else {
        this.form.enable({ emitEvent: false });
      }
    }
    this._loading = loading;
    this.stateChanges.next();
  }

  get loading(): boolean {
    return this._loading;
  }

  _saving: boolean;
  @Input() set saving(saving: boolean) {
    if (this.hasForm() && !this.loading) {
      if (saving) {
        this.form.disable({ emitEvent: false });
      } else {
        this.form.enable({ emitEvent: false });
      }
    }
    this._saving = saving;
    this.stateChanges.next();
  }

  get saving(): boolean {
    return this._saving;
  }

  abstract defineForm(): any;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: FormBuilder,
  ) {
    super(ngControl, changeDetector);

    this.form = this.formBuilder.group({
      ...this.defineForm(),
    });
  }

  ngOnInit() {
    this.enableValidator();
    this.initFormListener();
  }

  enableValidator(emitEvent?: boolean) {
    if (this.hasControl()) {
      this.ngControl.control.setValidators([this.validate.bind(this)]);
      this.ngControl.control.updateValueAndValidity({ emitEvent });
    }
  }

  initFormListener() {
    if (this.hasForm()) {
      this.form.valueChanges
        .pipe(this.takeUntilShare())
        .subscribe((value) => this.onChange(value));
    }
  }

  writeValue(value: any) {
    if (this.hasForm()) {
      this.form.patchValue(value || {});
    }
    super.writeValue(value);
  }

  setDisabledState(disabled: boolean) {
    if (this.hasForm()) {
      if (disabled) {
        this.form.disable({ emitEvent: false });
      } else {
        this.form.enable({ emitEvent: false });
      }
    }
    super.setDisabledState(disabled);
  }

  isErrorState(control: FormControl | FormGroup) {
    return !!(
      control &&
      control.invalid &&
      ((control.touched && control.dirty) || this.submitted)
    );
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.form.invalid ? { invalidForm: control.value } : null;
  }

  markAsPristine() {
    if (this.hasControl()) {
      this.ngControl.control.markAsPristine();
    }
  }

  hasForm() {
    return !!this.form;
  }

  hasControl() {
    return !!this.ngControl && !!this.ngControl.control;
  }
}
