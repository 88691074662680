import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { FormService, I18NService } from '../../services';
import { SupportApiService } from './services';
import { EyesSupportPageComponent } from './support-page.component';
import { SupportRoutingModule } from './support-routing.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    SupportRoutingModule,
    MatProgressBarModule,
    MatInputModule,
    MatSelectModule,
    FlexLayoutModule,
    MatButtonModule,
  ],
  declarations: [EyesSupportPageComponent],
  providers: [SupportApiService, TranslatePipe, FormService, I18NService],
  entryComponents: [EyesSupportPageComponent],
})
export class EyesSupportModule {}
