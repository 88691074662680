<mat-button-toggle-group
  data-testid="directionToggler"
  [formControl]="selected"
>
  <mat-button-toggle
    *ngFor="let direction of directions"
    [attr.data-testid]="direction"
    [value]="direction"
  >
    <div
      class="direction-toggle"
      [class.direction-toggle-production]="direction === 'production'"
      [class.direction-toggle-consumption]="direction === 'consumption'"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
    >
      <mat-icon [inline]="true">{{ iconBag[direction] }}</mat-icon>
      <span>
        {{ direction }}
      </span>
    </div>
  </mat-button-toggle>
</mat-button-toggle-group>
