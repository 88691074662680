<div class="container-component">
  <prosumer-tooltip-anchor
    class="tooltip-xlsx"
    [message]="tooltipMessage"
    data-testid="xlsx-dataframe-extractor-tooltip"
  ></prosumer-tooltip-anchor>
  <button
    mat-flat-button
    color="primary"
    type="button"
    [class.error]="hasError$ | async"
    [class.loading]="isLoading$ | async"
    [disabled]="disabled"
    (click)="xlsxInput.click()"
    data-testid="xlsx-dataframe-extractor-btn"
  >
    <mat-icon>upload-file</mat-icon>
    <span>Upload File</span>
  </button>
  <input
    #xlsxInput
    hidden
    type="file"
    [accept]="fileFormat"
    (change)="xlsxInputChange($event)"
    data-testid="xlsx-dataframe-extractor-input"
  />
  <mat-spinner
    *ngIf="isLoading$ | async"
    class="spinner-over"
    [diameter]="spinnerDiameter"
  ></mat-spinner>
</div>
