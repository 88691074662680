import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared';
import { SharedModule } from 'prosumer-app/shared';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReactiveComponentModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';

import { TdbCommodityPricesComponent } from './tdb-commodity-prices.component';

@NgModule({
  declarations: [TdbCommodityPricesComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatExpansionModule,
    ReactiveFormsModule,
    ReactiveComponentModule,
    MatTooltipModule,
    EyesSharedModule,
    SharedModule,
  ],
  exports: [TdbCommodityPricesComponent],
})
export class TdbCommodityPricesModule {}
