import { Case } from 'prosumer-app/+case/models';
import { ScenarioInfoService } from 'prosumer-app/+scenario/services/scenario-info';
import { CustomValidators } from 'prosumer-app/libs/eyes-shared';
import { NameValidator } from 'prosumer-app/shared';
import { ScenarioGenericQuery } from 'prosumer-app/stores/scenario-generic/scenario-generic.query';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NotificationsService } from 'prosumer-app/shared/services/notification';

type DialogData = {
  caseData$: BehaviorSubject<Case>;
  existingData$: Observable<unknown[]>;
};

@UntilDestroy()
@Component({
  selector: 'prosumer-scenario-create-dialog',
  templateUrl: './scenario-create-dialog.component.html',
  styleUrls: ['./scenario-create-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioCreateDialogComponent implements OnInit {
  readonly error$ = new BehaviorSubject(undefined);
  readonly loading$: Observable<boolean>;

  createScenarioForm: FormGroup;

  get isFormInvalid() {
    return this.createScenarioForm.invalid;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public info: ScenarioInfoService,
    private query: ScenarioGenericQuery,
    private formBuilder: FormBuilder,
    public matDialogRef: MatDialogRef<ScenarioCreateDialogComponent>,
    public _notifications: NotificationsService,
  ) {
    this.createScenarioForm = this.initForm();
    this.loading$ = this.query.selectLoading();
  }

  ngOnInit(): void {
    this.setValidators();
    this.createScenarioForm.controls.name.updateValueAndValidity();
  }

  setValidators(): void {
    this.createScenarioForm.controls.name.setAsyncValidators(
      CustomValidators.dataExist(this.data.existingData$, 'name'),
    );
  }

  initForm(): FormGroup {
    return this.formBuilder.group({
      name: ['', [Validators.required, NameValidator.validWithCore()]],
    });
  }

  onSubmit(): void {
    if (this.createScenarioForm.invalid) {
      return;
    }
    const scenarioName = this.createScenarioForm.get('name').value;
    this.info
      .createScenario(scenarioName, this.data.caseData$.getValue())
      .subscribe({
        next: (res) => this.matDialogRef.close({ scenario: res }),
        error: (error) => this._notifications.showError(error.message),
      });
  }

  onCancel(): void {
    this.matDialogRef.close({});
  }
}
