<div fxLayout="column" fxLayoutGap="16px" fxAlign="start">
  <form [formGroup]="primaryFiltersForm">
    <div *ngrxLet="isTdbApiResLoading$ as loading">
      <div fxLayout="row wrap" fxLayoutGap="16px" fxAlign="start">
        <!-- Transversal Database Category -->
        <prosumer-select-new
          fxFlex
          [options]="categoryOptions$ | ngrxPush"
          (selectionChange)="
            handleControlChange(['subsubcategory', 'subcategory'])
          "
          [control]="primaryFiltersForm.controls.category"
          [tooltip]="'tdb_filters.category'"
          [placeholder]="'Scenario.placeholders.tdb.category' | translate"
          [label]="'Scenario.labels.tdb.category' | translate"
          [disabled]="loading"
          data-testid="tdb-filters-select-category"
        >
        </prosumer-select-new>
        <!-- Transversal Database SubCategory -->
        <prosumer-select-new
          fxFlex
          [options]="subcategoryOptions$ | ngrxPush"
          (selectionChange)="handleControlChange(['subsubcategory'])"
          [control]="primaryFiltersForm.controls.subcategory"
          [tooltip]="'tdb_filters.subcategory'"
          [placeholder]="'Scenario.placeholders.tdb.subcategory' | translate"
          [label]="'Scenario.labels.tdb.subcategory' | translate"
          [disabled]="loading || checkForUnselectedControls(['category'])"
          data-testid="tdb-filters-select-subcategory"
        >
        </prosumer-select-new>
        <!-- Transversal Database SubSubCategory -->
        <prosumer-select-new
          fxFlex
          [options]="subsubcategoryOptions$ | ngrxPush"
          (selectionChange)="handleControlChange([])"
          [control]="primaryFiltersForm.controls.subsubcategory"
          [tooltip]="'tdb_filters.subsubcategory'"
          [placeholder]="'Scenario.placeholders.tdb.subsubcategory' | translate"
          [label]="'Scenario.labels.tdb.subsubcategory' | translate"
          [disabled]="loading || checkForUnselectedControls(['subcategory'])"
          data-testid="tdb-filters-select-subsubcategory"
        >
        </prosumer-select-new>
      </div>
      <!-- Secondary filters -->
      <prosumer-tdb-technology-secondary-filters
        *ngIf="!checkForUnselectedControls(['category'])"
        [filtersData]="secondaryFilters$ | ngrxPush"
        [filterSequence]="filterSequence"
        [isLoading]="loading"
        (secondaryFormSubmit)="handleSecondarySubmit($event)"
        data-testid="tdb-secondary-filters-component"
      ></prosumer-tdb-technology-secondary-filters>

      <prosumer-sparkline-table
        *ngIf="!checkForUnselectedControls(['category'])"
        [data]="tdbData$ | ngrxPush"
        [columnsDef]="columnsDef"
        [loading]="loading"
        [hideAdd]="true"
        [hideEdit]="true"
        [hideDelete]="true"
        (selectionChanged)="onSelect($event)"
        data-testid="tdb-technology-filters-sparkline-table"
        maxHeight="300px"
      >
      </prosumer-sparkline-table>
    </div>
  </form>
</div>
