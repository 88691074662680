import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ColorHelper } from '@swimlane/ngx-charts';

import { ENGIE_DEFAULT_SCHEME } from 'prosumer-app/app.references';
import { BaseComponent, contains } from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { addBallotBox, updateLegendFilter } from '../../shared/helper';

import { CO2EmissionsService } from '../co2-emissions.service';
@Component({
  selector: 'prosumer-scope-type-visualization',
  templateUrl: './scope-type-visualization.component.html',
  styleUrls: ['./scope-type-visualization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScopeTypeVisualizationComponent
  extends BaseComponent
  implements OnInit
{
  @Input() chartDimension = [1000, 480];
  @Input() showXAxis = true;
  @Input() showYAxis = true;
  @Input() gradient = false;
  @Input() showLegend = true;
  @Input() showXAxisLabel = true;
  @Input() xAxisLabel = 'year';
  @Input() showYAxisLabel = true;
  @Input() yAxisLabel = 'Emission [kgCO2]';
  @Input() tooltipUnit = 'kgCO2';
  @Input() animations = true;
  @Input() colorScheme = ENGIE_DEFAULT_SCHEME;

  data$: Observable<any>;
  tooltipData: Array<any> = [];
  legendFilter$ = new BehaviorSubject<Array<string>>([]);

  @Input() xAxisFormatting = (label: string) =>
    this._titleCase.transform(label).substring(0, 4);
  @Input() tooltipTitleFormat = (value: any) =>
    this._titleCase.transform(value.series);

  constructor(
    private _titleCase: TitleCasePipe,
    private _co2EmissionService: CO2EmissionsService,
  ) {
    super();
  }

  ngOnInit() {
    this.data$ = combineLatest([
      this._co2EmissionService.scope$,
      this.legendFilter$,
    ]).pipe(
      map(([data, legendFilter]) =>
        data.map((d) => ({
          ...d,
          series: d.series.map((s) => ({
            name: addBallotBox(legendFilter, s.name),
            value: contains(legendFilter, s.name) ? 0 : s.value,
          })),
        })),
      ),
    );
  }

  getSeriesMembers(seriesModel: any) {
    this.data$.forEach((yearSeries) => {
      const filtered = yearSeries.filter(
        (data) => data.name === seriesModel.value.series,
      );
      if (filtered && filtered[0] && filtered[0].series) {
        this.tooltipData = filtered[0].series.map((item) => ({
          series: item.name,
          value: item.value,
          name: filtered[0].name,
          current: seriesModel.value.name === filtered[0].name,
          color: new ColorHelper(
            this.colorScheme,
            'ordinal',
            filtered[0].series.map((series) => series.name),
          ).getColor(item.name),
        }));
      }
    });
  }

  onSelect(selected: string) {
    updateLegendFilter(this.legendFilter$, selected);
  }
}
