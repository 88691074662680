<div fxLayout="column" fxLayoutGap="16px" fxAlign="start">
  <prosumer-slide-toggle
    fxFlex="100%"
    label="Scenario.labels.tdb.toggleBtn"
    data-testid="egc-dialog-tdb-toggle-button"
    [isViewOnly]="isTdbApiResponseLoading$ | async"
    [incomingControlValue]="isTdbModuleEnabled | async"
    (valueChanged)="toggleTDBModule($event)"
  ></prosumer-slide-toggle>

  <span *ngIf="!!profileFilters" class="reference-info">
    <span>*Reference:</span>
    <span class="data">{{ profileFilters }}</span>
  </span>

  <form
    [formGroup]="tdbCommodityPricesForm"
    *ngrxLet="tdbCommodityOptions$ as tdbCommodityOptions"
  >
    <mat-expansion-panel *ngIf="isTdbModuleEnabled | async" [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-title">
            {{ 'Scenario.labels.tdb.filter' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_commodities.wizard_tdb_title'"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="row wrap" fxLayoutGap="16px" fxAlign="start">
        <!-- Transversal Database Commodities -->
        <prosumer-select
          fxFlex="31%"
          [required]="true"
          [placeholder]="
            'Scenario.placeholders.energyGridConnections.tdbCommodity'
              | translate
          "
          [tooltip]="'wizard_commodities.wizard_tdb_commodity'"
          [label]="
            'Scenario.labels.energyGridConnections.tdbCommodity' | translate
          "
          [options]="tdbCommodityOptions"
          [control]="tdbCommodityPricesForm?.controls?.commodity"
          [validateInOptions]="true"
          [emitPatchValue]="true"
          [value]="initValueCommodityOption"
          [disabled]="isTdbApiResponseLoading$ | async"
          data-testid="tdb-commodity-module-select-commodity"
        >
        </prosumer-select>
        <!-- Transversal Database Geography -->
        <prosumer-select
          fxFlex="31%"
          [placeholder]="
            'Scenario.placeholders.energyGridConnections.tdbGeography'
              | translate
          "
          [tooltip]="'wizard_commodities.wizard_tdb_geography'"
          [label]="
            'Scenario.labels.energyGridConnections.tdbGeography' | translate
          "
          [control]="tdbCommodityPricesForm?.controls?.geography"
          [options]="tdbGeographyOptions$ | async"
          [disabled]="isTdbFiltersDisabled$ | async"
          data-testid="tdb-commodity-module-select-geography"
        >
        </prosumer-select>
        <!-- Transversal Database Nominal Scenarios -->
        <prosumer-select
          fxFlex="31%"
          [placeholder]="
            'Scenario.placeholders.energyGridConnections.tdbNominalScenario'
              | translate
          "
          [tooltip]="'wizard_commodities.wizard_tdb_nominal_scenario'"
          [label]="
            'Scenario.labels.energyGridConnections.tdbNominalScenario'
              | translate
          "
          [control]="tdbCommodityPricesForm?.controls?.scenario"
          [options]="tdbNominalScenarioOptions$ | async"
          [disabled]="isTdbFiltersDisabled$ | async"
          data-testid="tdb-commodity-module-select-nominal-scenario"
        >
        </prosumer-select>
        <!-- Transversal Database Units -->
        <prosumer-select
          fxFlex="31%"
          [placeholder]="
            'Scenario.placeholders.energyGridConnections.tdbUnit' | translate
          "
          [tooltip]="'wizard_commodities.wizard_tdb_unit'"
          [label]="'Scenario.labels.energyGridConnections.tdbUnit' | translate"
          [control]="tdbCommodityPricesForm?.controls?.unit"
          [options]="tdbUnitOptions$ | async"
          [disabled]="isTdbFiltersDisabled$ | async"
          data-testid="tdb-commodity-module-select-unit"
        >
        </prosumer-select>
        <!-- Transversal Database Granularity -->
        <prosumer-select
          fxFlex="31%"
          [placeholder]="
            'Scenario.placeholders.energyGridConnections.tdbGranularity'
              | translate
          "
          [tooltip]="'wizard_commodities.wizard_tdb_granularity'"
          [label]="
            'Scenario.labels.energyGridConnections.tdbGranularity' | translate
          "
          [control]="tdbCommodityPricesForm?.controls?.granularity"
          [options]="tdbGranularityOptions$ | async"
          [disabled]="isTdbFiltersDisabled$ | async"
          data-testid="tdb-commodity-module-select-granularity"
        >
        </prosumer-select>
      </div>
    </mat-expansion-panel>
  </form>
</div>
