import { API_CONFIG } from 'prosumer-app/libs/eyes-core';
import { ApiConfig } from 'prosumer-app/libs/eyes-shared';

import { Inject, Injectable } from '@angular/core';

import {
  Endpoints,
  ListSubResourcesParams,
  ScenarioParentage,
  SingleSubResourceParams,
} from './endpoint-provider.models';

const template = require('url-template');

@Injectable({ providedIn: 'root' })
export class EndpointProvider {
  constructor(@Inject(API_CONFIG) private readonly api: ApiConfig) {}

  forSubResources(params: ListSubResourcesParams): string {
    return template
      .parseTemplate(this.getSubResourcesTemplate())
      .expand(params);
  }

  forSubResource(params: SingleSubResourceParams): string {
    return template.parseTemplate(this.getSubResourceTemplate()).expand(params);
  }

  forCoherenceCheck(params: ScenarioParentage): string {
    return template
      .parseTemplate(this.getCoherenceCheckTemplate())
      .expand(params);
  }

  forUserPreferences(): string {
    return this.getUserPreferencesEndpoint();
  }

  forLinkWithTDB(): string {
    return this.buildEndpoint('transversal', 'generic');
  }

  private getUserPreferencesEndpoint(): string {
    return this.buildEndpoint('user', 'userPreferences');
  }

  private getCoherenceCheckTemplate(): string {
    return this.buildEndpoint('scenario', 'coherenceCheck');
  }

  private getSubResourceTemplate(): string {
    return this.buildEndpoint('scenario', 'subDetail');
  }

  private getSubResourcesTemplate(): string {
    return this.buildEndpoint('scenario', 'subDetails');
  }

  private buildEndpoint(key: string, subKey: string): string {
    return `${this.baseUrl}${this.endpoints[key][subKey]}`;
  }

  private get baseUrl(): string {
    return this.api.baseUrl;
  }

  private get endpoints(): Endpoints {
    return this.api.endpoints;
  }
}
