import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { BatchScenariosResponse } from 'prosumer-app/stores/batch';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Scenario } from '../../models';
import {
  DeleteScenariosComponent,
  DeleteScenariosData,
} from '../delete-scenarios';
import { ScenarioBatchAction } from '../scenario-batch';

@Injectable()
export class ScenarioListService {
  private readonly deleteSuccess = new Subject<boolean>();
  deleteSuccess$ = this.deleteSuccess.asObservable();

  constructor(
    private readonly dialog: MatDialog,
    private readonly snacky: NotificationsService,
  ) {}

  batchScenarios(action: ScenarioBatchAction, scenarios: Scenario[]): void {
    switch (action) {
      case ScenarioBatchAction.delete:
        this.deleteScenarios(scenarios);
        break;
    }
  }

  private deleteScenarios(scenarios: Scenario[]): void {
    this.dialog
      .open(DeleteScenariosComponent, {
        data: this.buildDeleteScenariosData(scenarios),
        width: '30vw',
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((success) =>
        this.conditionallyHandleDeleteCompletion(success),
      );
  }

  private conditionallyHandleDeleteCompletion(
    response?: BatchScenariosResponse,
  ): void {
    const success = this.isSuccess(response);
    this.deleteSuccess.next(success);
    if (success) {
      this.snacky.showSuccess(this.getSuccessMsg(response));
    } else {
      this.snacky.showError(this.getErrorMsg());
    }
  }

  private isSuccess(response?: BatchScenariosResponse): boolean {
    return [
      !!response,
      response.totalItems > 0,
      response.totalSkipped < response.totalItems,
    ].every(Boolean);
  }

  private getSuccessMsg(response: BatchScenariosResponse): string {
    const { totalItems, totalSkipped } = response;
    return `${
      totalItems - totalSkipped
    } of the ${totalItems} selected scenarios have been successfully deleted.`;
  }

  private getErrorMsg(): string {
    return 'Something went wrong while deleting the selected scenarios.';
  }

  private buildDeleteScenariosData(scenarios: Scenario[]): DeleteScenariosData {
    return { scenarios };
  }
}
