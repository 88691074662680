import { LoadsCompletion } from 'prosumer-app/+scenario/services/completion-strategies/loads-completion.strategy';
import {
  ScenarioCompletionService,
  ScenarioWizardStep,
} from 'prosumer-app/+scenario/services/scenario-completion';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  Button,
  ColumnDefinition,
  FilterConfig,
  FormFieldOption,
  StepFormComponent,
  containsSubstring,
  fadeInAnimation,
  generateShortUID,
  toObject,
} from 'prosumer-app/libs/eyes-shared';
import { mapProfilesToDuplicateAndPatchToCloneControl } from 'prosumer-shared';
import { map, take } from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { FormBuilder, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { PipeUtils, Utils } from 'prosumer-app/core';
import { DuplicationStarter } from 'prosumer-app/services/duplication-starter';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { ScenarioDetailType } from 'prosumer-app/stores';
import { EnergyVectorQuery } from 'prosumer-app/stores/energy-vector';
import { LoadInfo, LoadQuery, LoadStore } from 'prosumer-app/stores/load';
import { NodeQuery } from 'prosumer-app/stores/node';
import { ScenarioVariationQuery } from 'prosumer-app/stores/scenario-variation';
import { combineLatest } from 'rxjs';
import { Load, Netting, Profile } from '../../models';
import {
  LoadFormDialogData,
  LoadFormDialogType,
  LoadsDialogComponent,
} from './loads-dialog';

@UntilDestroy()
@Component({
  selector: 'prosumer-loads-form',
  templateUrl: './loads-form.component.html',
  styleUrls: ['./loads-form.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadsFormComponent
  extends StepFormComponent
  implements OnInit, AfterViewInit
{
  @Input() startYear: number;
  @Input() endYear: number;
  @Input() isViewOnly: boolean;
  @Input() loadFormData: ScenarioWizardStep;
  @Input() scenarioIdentity: {
    projectId: string;
    caseId: string;
    scenarioId: string;
  };

  _currentNetting: Array<Netting> = [];
  @Input() set currentNetting(netting: Array<Netting>) {
    this._currentNetting = netting;
    this.setReferences();
  }
  get currentNetting(): Array<Netting> {
    return this._currentNetting;
  }

  _scenarioVariationOptions: Array<FormFieldOption<string>> = [];
  @Input() set scenarioVariationOptions(
    options: Array<FormFieldOption<string>>,
  ) {
    this._scenarioVariationOptions = options;
  }

  get scenarioVariationOptions(): Array<FormFieldOption<string>> {
    return this._scenarioVariationOptions;
  }

  filterDefinition: FilterConfig;

  tableMetaData: ColumnDefinition;

  references$ = this.setReferences();

  showGraphEmitter: EventEmitter<any> = new EventEmitter<any>();

  endUseLoads: any;

  _evOptions: Array<FormFieldOption<string>> = [
    { name: 'Electricity', value: 'e0000' },
  ];

  _nodeOptions: Array<FormFieldOption<string>> = [];

  addEmitter: EventEmitter<any> = new EventEmitter<any>();

  deleteEmitter: EventEmitter<any> = new EventEmitter<any>();

  editEmitter: EventEmitter<any> = new EventEmitter<any>();

  tableActionButtons: Array<Button> = [
    {
      label: 'New',
      color: 'primary',
      behavior: 'button',
      iconPrefix: 'add',
      eyesClick: this.addEmitter,
    },
  ];

  loadTypeOptions: Array<FormFieldOption<LoadFormDialogType>> = [
    { name: 'Library', value: 'library' },
    { name: 'Custom', value: 'custom' },
  ];

  persistedProfilesOnEdit: Array<Profile> = [];
  persistedProfilesOnWriteValue: Array<Profile> = [];
  @Input() isMultiNode: boolean;

  @Input() set nodeOptions(nodeOptions: Array<FormFieldOption<string>>) {
    this._nodeOptions = nodeOptions;
  }

  get nodeOptions(): Array<FormFieldOption<string>> {
    return this._nodeOptions;
  }

  @Input() set evOptions(evOptions: Array<FormFieldOption<string>>) {
    this._evOptions = evOptions;
  }
  get evOptions(): Array<FormFieldOption<string>> {
    return this._evOptions;
  }

  loads$ = this.loads.selectActiveLoads().pipe(untilDestroyed(this));

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: FormBuilder,
    private dialogService: DialogService,
    private completion: ScenarioCompletionService,
    private readonly loadStore: LoadStore,
    private readonly loads: LoadQuery,
    private readonly nodesQuery: NodeQuery,
    private readonly evQuery: EnergyVectorQuery,
    private readonly variationQuery: ScenarioVariationQuery,
    private readonly notification: NotificationsService,
    private readonly dupStarter: DuplicationStarter,
  ) {
    super(ngControl, changeDetector, formBuilder);
    this.subscribeToFormCompletionTracking();
  }

  defineForm() {
    return {
      loads: [],
      binToDelete: [[]],
      binToClone: [[]],
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.generateMetaData();
  }

  ngAfterViewInit() {
    this.addEmitter.subscribe((data: Load) => this.onAddLoads(data));
    this.editEmitter.subscribe((data: Load) => this.onEditLoads(data));
    this.deleteEmitter.subscribe((data: Load) => this.onDeleteLoads(data));
  }

  /**
   * On edit, collect the initial load profiles
   *
   * @param load - the current load
   */
  fillPersistedData = (load: Load) => {
    if (load) {
      const temp = [];
      temp.push(...load.profiles);
      if (temp) {
        this.persistedProfilesOnEdit = temp;
      }
    }
  };

  /**
   * launches the add load dialog,
   * adds a new load entry to list
   */
  onAddLoads(data: Load) {
    return this.dialogService
      .openDialog(LoadsDialogComponent, {
        ...this.generateInitialLoadData(data),
        isViewOnly: false,
      } as LoadFormDialogData)
      .pipe(take(1))
      .subscribe((formData) => {
        if (!!formData) {
          const mappedProfiles = mapProfilesToDuplicateAndPatchToCloneControl(
            formData.profiles,
            this.form.get('binToClone'),
          );
        }
      });
  }

  /**
   * For table searh filter, find matching energy vector from list
   */
  loadsSearchPredicate = (data: Load, filter: string) => {
    const energyVector = this.evOptions.find(
      (e) => e.value === data.energyVector,
    ).name;
    return containsSubstring(energyVector, filter);
  };

  /**
   * Launches the edit load dialog,
   * returns an updated value of the load
   *
   * @param data - the load data to be updated
   */
  onEditLoads(data: Load) {
    this.fillPersistedData(data); // get the load's original profiles
    this.dialogService
      .openDialog(LoadsDialogComponent, {
        ...this.generateDialogMetaData('edit'),
        ...data,
        scenarioIdentity: this.scenarioIdentity,
        endUseLoad: data,
        isViewOnly: this.isReadOnly(),
      } as LoadFormDialogData)
      .pipe(take(1))
      .subscribe((formData) => {
        if (!!!formData) {
          return;
        }
        // this.handleBinaryForDeletion(formData, 'edit'); // missing profiles deemed as deleted, patch to bin delete
      });
  }

  /**
   * From the table, the user intends to delete the whole
   * load info from the scenario
   *
   * @param data - the load to be deleted
   */
  onDeleteLoads(data: Load) {
    if (!data) {
      return;
    }
    this.loadStore.deleteLoad(data.id).subscribe({
      error: (error) => {
        const errObj = Utils.resolveToEmptyObject(error.error);
        this.notification.showError(errObj['error'] ?? error.message);
      },
    });
    // this.handleBinaryForDeletion({ ...data }, 'delete');
  }

  onDuplicate(data: Load): void {
    this.dupStarter
      .start(ScenarioDetailType.load, data)
      .pipe(PipeUtils.filterOutNullUndefined)
      .subscribe(() => {});
  }

  getAddOrDuplicateDialogMode(data: Load): string {
    let dialogMode = 'add';
    if (data !== undefined) {
      dialogMode = 'duplicate';
    }
    return dialogMode;
  }

  getLoadIdByDialogMode(data: Load, mode: string) {
    return mode === 'edit' && data !== undefined ? data.id : generateShortUID();
  }

  /**
   * Generated the intial load data to be passed
   * to the add/edit load dialog
   */
  generateInitialLoadData = (data: Load) => {
    const mode = this.getAddOrDuplicateDialogMode(data);
    return {
      scenarioIdentity: this.scenarioIdentity,
      endUseLoad: {
        name: '',
        energyVector: '',
        nodes: this.resolveDefaultNode(),
        loadType: 'library',
        library: '',
        yearlyLoad: 1,
        ...data,
        id: this.getLoadIdByDialogMode(data, mode),
      },
      ...this.generateDialogMetaData(mode),
    };
  };

  private resolveDefaultNode(): string[] {
    const nodes = this.nodesQuery.getNodes();
    return nodes.length === 1 ? [nodes[0].nodeId] : [];
  }

  generateDialogMetaData(mode: string) {
    return {
      loadTypeOptions: this.loadTypeOptions,
      energyVectorOptions: this.evOptions,
      nodeOptions: this.nodeOptions,
      scenarioVariationOptions: this.scenarioVariationOptions,
      width: '90%',
      disableClose: true,
      endUseLoads$: this.loads$,
      isMultiNode: this.isMultiNode,
      startYear: this.startYear,
      endYear: this.endYear,
      persistedProfiles: this.persistedProfilesOnEdit,
      currentNetting: this.currentNetting,
      mode,
    };
  }

  /**
   * table metadata and filter definiton
   */
  generateMetaData = () => {
    this.tableMetaData = {
      name: {
        name: 'Load Name',
        sortable: true,
      },
      energyVector: {
        name: 'Energy Vector',
        type: 'reference',
        referenceKey: 'energyVectors',
        toolTip: 'wizard_loads.wizard_loards_energy_vector',
        sortable: true,
      },
      nodes: {
        name: 'Nodes',
        type: 'referenceList',
        referenceKey: 'nodes',
        toolTip: 'wizard_loads.wizard_loads_node',
        sortable: true,
      },
      scenarioVariation: {
        name: 'Variation',
        type: 'reference',
        referenceKey: 'scenarioVariations',
        sortable: true,
      },
      actions: {
        name: 'Actions',
        type: 'action',
      },
    };
  };

  private setReferences() {
    return this.selectActiveReferences();
  }

  isReadOnly() {
    return this.isViewOnly || this.mode === 'read';
  }

  private subscribeToFormCompletionTracking(): void {
    const strategy = new LoadsCompletion();
    this.loads$.pipe(untilDestroyed(this)).subscribe((loadsArray) => {
      const form = this.buildLoadForm(loadsArray);
      this.completion.setForStep(
        ScenarioWizardStep.loads,
        strategy.determineStatus(form),
      );
    });
  }

  private buildLoadForm(loadsArray: LoadInfo[]) {
    return { loads: loadsArray };
  }

  private selectActiveReferences() {
    return combineLatest([
      this.evQuery.selectActiveVectors(),
      this.nodesQuery.selectActiveNodes(),
      this.variationQuery.selectActiveVariation(),
    ]).pipe(
      map(([vectors, nodes, variations]) => ({
        energyVectors: this.objectify(vectors),
        nodes: this.objectify(nodes),
        scenarioVariations: this.objectify(variations),
      })),
    );
  }

  private objectify(data: unknown[]) {
    return toObject(data, 'value', 'name');
  }
}
