import { BehaviorSubject } from 'rxjs';

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

const selector = 'prosumer-file-upload-drag-drop';

@Component({
  selector,
  templateUrl: './file-upload-drag-drop.component.html',
  styleUrls: ['./file-upload-drag-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadDragAndDropComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileUploadDragAndDropComponent),
      multi: true,
    },
  ],
})
export class FileUploadDragAndDropComponent
  implements ControlValueAccessor, Validator
{
  @ViewChild('fileUpload', { read: ElementRef }) fileUpload:
    | ElementRef
    | undefined;
  @HostBinding('class') classList: string = selector;

  @Input() icon = 'attachment';
  @Input() hint: string | undefined;
  @Input() hideStatus = false;

  protected disabled$ = new BehaviorSubject<boolean>(false);
  @Input() set disabled(value: boolean) {
    this.disabled$.next(coerceBooleanProperty(value));
    this.changeDetector.markForCheck();

    if (this.disabled) {
      this.classList = `${
        this.defaultClassList || ''
      } ${selector} ${selector}-disabled`.trim();
    } else {
      this.classList = `${this.defaultClassList || ''} ${selector}`.trim();
    }
  }
  get disabled() {
    return this.disabled$.value;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('class') defaultClassList: string | null | undefined;

  formats = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  @Input() set acceptedFormats(value: Array<string>) {
    if (!!value) {
      this.formats = value.join(',');
    }
  }

  file: File | undefined;
  dragover = false;

  constructor(private changeDetector: ChangeDetectorRef) {}

  onChange = (_: File | undefined) => {};
  onTouch = () => {};

  registerOnChange(fn: (_: File | undefined) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(file: File): void {
    this.file = file;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(_: AbstractControl): ValidationErrors | null {
    return !this.file ? { invalid: true } : null;
  }

  onBrowse() {
    if (!!this.fileUpload && !this.disabled) {
      this.fileUpload.nativeElement.click();
    }
  }

  onFileSelected(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    if (this.isFileValid(target)) {
      this.applyChanges(target.files[0]);
    }
  }

  onDragOver(event: DragEvent) {
    this.callDragEvents(event);
    this.dragover = true;
  }

  onDragLeave(event: DragEvent) {
    this.callDragEvents(event);
    this.dragover = false;
  }

  onDrop(event: DragEvent) {
    this.callDragEvents(event);
    this.dragover = false;

    if (!this.disabled && this.isFileValid(event.dataTransfer)) {
      const file = event.dataTransfer?.files?.[0];
      if (this.formats.includes(file?.type)) {
        this.applyChanges(file);
      }
    }
  }

  protected isFileValid(target: HTMLInputElement | DataTransfer | null) {
    return !!target && !!target.files && target.files.length > 0;
  }

  protected callDragEvents(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  protected applyChanges(file: File) {
    this.file = file;
    this.onChange(this.file);
    this.onTouch();
  }
}
