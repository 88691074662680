import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MtxGridModule } from '@ng-matero/extensions';
import { ReactiveComponentModule } from '@ngrx/component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedModule } from 'prosumer-app/shared';
import { ValueModule } from 'prosumer-shared/components/value';
import { ScenarioActionsTriggerModule } from '../scenario-actions-trigger/scenario-actions-trigger.module';
import { ScenarioBatchModule } from '../scenario-batch';
import { ScenarioLogsModule } from '../scenario-logs';
import { ScenarioNameModule } from '../scenario-name';
import { ScenarioStatusModule } from '../scenario-status';
import { ScenarioListComponent } from './scenario-list.component';
import {
  ScenarioListConfig,
  SCENARIO_LIST_CONFIG,
} from './scenario-list.tokens';

@NgModule({
  declarations: [ScenarioListComponent],
  imports: [
    CommonModule,
    MtxGridModule,
    ReactiveComponentModule,
    SharedModule,
    MatIconModule,
    MatButtonModule,
    ScenarioBatchModule,
    ScenarioActionsTriggerModule,
    NgxSkeletonLoaderModule,
    FlexLayoutModule,
    ValueModule,
    ScenarioStatusModule,
    ScenarioLogsModule,
    ScenarioNameModule,
  ],
  exports: [ScenarioListComponent],
})
export class ScenarioListModule {
  static forChild(
    config: ScenarioListConfig,
  ): ModuleWithProviders<ScenarioListModule> {
    return {
      ngModule: ScenarioListModule,
      providers: [{ provide: SCENARIO_LIST_CONFIG, useValue: config }],
    };
  }
}
