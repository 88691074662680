import { Scenario } from 'prosumer-app/+scenario/models';
import { ScenarioFacadeService } from 'prosumer-app/+scenario/state';
import { EXECUTION_STATUS_SUCCESS } from 'prosumer-app/app.references';
import { isSimulationSuccessful } from 'prosumer-app/shared';
import { ResultStore } from 'prosumer-app/stores';
import { Observable } from 'rxjs';
import { filter, take, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ResultsExtractorService } from '@prosumer/results/state';
import { Utils } from 'prosumer-app/core';

@Injectable()
export class SimulationResultResolverService implements Resolve<any> {
  constructor(
    private _resultStore: ResultStore,
    private _extractorService: ResultsExtractorService,
    private scenarios: ScenarioFacadeService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): void {
    const { variationId } = Utils.resolveToEmptyObject(route.queryParams);

    this.selectTruthySelectedScenario()
      .pipe(
        withLatestFrom(
          this._resultStore.currentID$,
          this._resultStore.currentVariationID$,
        ),
        take(1),
      )
      .subscribe(([scenario, previousId, previousVariationId]) => {
        if (
          !this.isCurrentScenarioSameAsPrevious(scenario, previousId) ||
          this.isBasecaseSuccessAndLastResultWasVariation(
            scenario,
            previousVariationId,
          )
        ) {
          this._resultStore.clearResults();
          this.fetchResults(scenario);
        }
        this.getSucceededVariations(scenario, previousVariationId, variationId);
      });
  }

  private isCurrentScenarioSameAsPrevious(
    current: Scenario,
    previousId: string,
  ): boolean {
    return current.id === previousId;
  }

  private selectTruthySelectedScenario(): Observable<Scenario> {
    return this.scenarios.selectedData$.pipe(
      filter((scenario) => !!scenario),
      filter((scenario) => scenario.loaded),
    );
  }

  private fetchResults(scenario: Scenario) {
    this._resultStore.latestScenarioVersion$
      .pipe(take(1))
      .subscribe((latestScenarioVersion) => {
        if (isSimulationSuccessful(scenario.run)) {
          if (
            !!latestScenarioVersion &&
            scenario.run.outputJsonVersion === latestScenarioVersion
          ) {
            this._extractorService.getResults(
              scenario.projectId,
              scenario.caseId,
              scenario.id,
            );
          } else {
            this._extractorService.migrateResults(
              scenario.projectId,
              scenario.caseId,
              scenario.id,
            );
          }
        }
      });
  }

  private getSucceededVariations(
    { id, projectId, caseId, run }: Scenario,
    previousVariationId: string,
    variationIdParam: string,
  ) {
    this.scenarios
      .getScenarioVariations(id, {
        projectId,
        caseId,
        status: EXECUTION_STATUS_SUCCESS,
      })
      .pipe(take(1))
      .subscribe((data) => {
        if (!!variationIdParam) {
          if (previousVariationId !== variationIdParam) {
            this._resultStore.clearResults();
            this._resultStore.getMainResults(
              projectId,
              caseId,
              id,
              variationIdParam,
            );
          }
        } else {
          if (!isSimulationSuccessful(run)) {
            const variations = data['payload'].datalist || [];
            if (
              variations.length > 0 &&
              previousVariationId !== variations[0].variationId
            ) {
              this._resultStore.clearResults();
              this._resultStore.getMainResults(
                projectId,
                caseId,
                id,
                variations[0].variationId,
              );
            }
          }
        }
      });
  }

  private isBasecaseSuccessAndLastResultWasVariation = (
    scenario,
    previousVariationId,
  ): boolean => isSimulationSuccessful(scenario) && !!previousVariationId;
}
