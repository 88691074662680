<div
  class="simple-chips-container"
  fxLayout="column"
  fxLayoutAlign="start stretch"
>
  <mat-form-field appearance="outline" class="ease-on-interact full-width">
    <mat-label>{{ label }}</mat-label>
    <input
      #chipInput
      matInput
      [formControl]="chipInputCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      (keydown.enter)="onKeydownEnter()"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      autoActiveFirstOption
      (optionSelected)="selected($event)"
    >
      <mat-option
        *ngFor="let option of filteredOptions$ | async"
        [value]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-chip-list #chipList>
    <div
      class="active-chips-list eased"
      [class.invisible]="(activeChips$ | async)?.length === 0"
    >
      <mat-chip
        *ngFor="let chip of activeChips$ | async"
        class="mat-chip-selected"
        selected
        [removable]="removable"
        (removed)="onChipRemove(chip)"
        [value]="chip"
        color="primary"
      >
        {{ chip }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
    </div>
  </mat-chip-list>
</div>
