<div class="eyes-maximize-width">
  <div class="loads-input--label">
    <div class="loads-input--label-text">
      <mat-icon
        matSuffix
        class="eyes-input-tooltip-anchor"
        [prosumerTooltip]="tooltipMessage | translate"
        [attr.data-testid]="'enhanced-loads-input-tooltip'"
      >
        help
      </mat-icon>
      <mat-label>
        {{ panelLabel | translate }}
      </mat-label>
    </div>
    <div class="loads-input--label-icons">
      <mat-icon
        class="prosumer-enhanced-load-anchor"
        matSuffix
        *ngIf="(canUpload$ | async) !== true"
        color="primary"
        (click)="onClearValues()"
        matButton
        [prosumerTooltip]="'Scenario.enhancedLoadsInput.clearData' | translate"
      >
        delete_sweep
      </mat-icon>
      <mat-icon
        class="prosumer-enhanced-load-anchor copy"
        matSuffix
        *ngIf="(canUpload$ | async) !== true"
        color="primary"
        (click)="onCopy()"
        matButton
        [prosumerTooltip]="
          (isCopied$ | async)
            ? 'Copied!'
            : ('Scenario.enhancedLoadsInput.copyData' | translate)
        "
      >
        file_copy
      </mat-icon>
      <prosumer-tooltip
        class="copied-tooltip"
        *ngIf="isCopied$ | async"
        text="Copied"
        isTop="true"
      >
      </prosumer-tooltip>
    </div>
  </div>
  <div class="drop-target">
    <div *ngIf="validating$ | async">
      {{ 'Scenario.enhancedLoadsInput.parsingData' | translate }}
    </div>
  </div>

  <section
    class="dataset-upload"
    data-testid="dataset-upload"
    [ngClass]="{ 'drag-over': dragOver }"
    *ngIf="canUpload$ | async"
  >
    <div>
      <div class="single-value-input">
        <input
          #singleLoadValue
          class="title-paste"
          placeholder="{{
            'Scenario.enhancedLoadsInput.enterSingleValue' | translate
          }}"
          (focus)="inputCopyPasteFocusOut($event)"
          (keydown.enter)="onSingleValueEnter($event, singleLoadValue.value)"
          [formControl]="loadPasteControl"
          data-testid="single-value-input"
        />
        <button (click)="onSingleValueEnter($event, singleLoadValue.value)">
          <i class="arrow-right" data-testid="single-value-button"></i>
        </button>
      </div>
      <div class="drop-target" (paste)="onPaste($event)">
        <div data-testid="load-paste-data-label">
          {{ 'Scenario.enhancedLoadsInput.pasteData' | translate }}
        </div>
        <div data-testid="load-or-label">
          {{ 'Scenario.enhancedLoadsInput.or' | translate }}
        </div>
        <span data-testid="load-dragdrop-label">{{
          'Scenario.enhancedLoadsInput.dragAndDrop' | translate
        }}</span>
        <div class="csv-icon" data-testid="csv-icon">
          <svg
            height="60px"
            width="30px"
            fill="#A2A2A2"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 100 125"
            version="1.1"
            x="0px"
            y="0px"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <path
                d="M96,125 L4,125 C1.790861,125 0,123.209139 0,121 L0,4 C0,1.790861 1.790861,0 4,0 L72.375,0 L72.375,22.785 C72.375,25.4580582 74.5419418,27.625 77.215,27.625 L100,27.625 L100,121 C100,123.209139 98.209139,125 96,125 Z M28.484,81.396 C32.939,81.396 35.579,79.812 37.955,77.271 L34.721,74.004 C32.906,75.654 31.289,76.71 28.649,76.71 C24.689,76.71 21.95,73.41 21.95,69.45 L21.95,69.384 C21.95,65.424 24.755,62.19 28.649,62.19 C30.959,62.19 32.774,63.18 34.556,64.797 L37.79,61.068 C35.645,58.956 33.038,57.504 28.682,57.504 C21.587,57.504 16.637,62.883 16.637,69.45 L16.637,69.516 C16.637,76.149 21.686,81.396 28.484,81.396 Z M50.231,81.33 C55.214,81.33 58.712,78.756 58.712,74.169 L58.712,74.103 C58.712,70.077 56.072,68.394 51.386,67.173 C47.393,66.15 46.403,65.655 46.403,64.137 L46.403,64.071 C46.403,62.949 47.426,62.058 49.373,62.058 C51.32,62.058 53.333,62.916 55.379,64.335 L58.019,60.507 C55.676,58.626 52.805,57.57 49.439,57.57 C44.72,57.57 41.354,60.342 41.354,64.533 L41.354,64.599 C41.354,69.186 44.357,70.473 49.01,71.661 C52.871,72.651 53.663,73.311 53.663,74.598 L53.663,74.664 C53.663,76.017 52.409,76.842 50.33,76.842 C47.69,76.842 45.512,75.753 43.433,74.037 L40.43,77.634 C43.202,80.109 46.733,81.33 50.231,81.33 Z M70.493,81.165 L74.981,81.165 L84.32,57.9 L78.842,57.9 L72.803,74.169 L66.764,57.9 L61.154,57.9 L70.493,81.165 Z M75,21 L75,0 L100,25 L79,25 C76.790861,25 75,23.209139 75,21 Z"
                fill="#A2A2A2"
                fill-rule="nonzero"
              ></path>
            </g>
          </svg>
        </div>

        <div></div>
        <a
          data-testid="manual-input"
          (click)="onInputManually()"
          type="link"
          class="prosumer-manual-input"
          >{{ 'Scenario.enhancedLoadsInput.inputManually' | translate }}</a
        >
      </div>
    </div>
  </section>

  <div *ngIf="hasError$ | async" class="error-message-panel">
    <mat-error>
      <p class="error-reason" *ngIf="loadProfileInput?.errors?.commaExist">
        {{ errorMessageMap['input'].commaExist }}
      </p>
      <div [innerHtml]="errorMessage"></div>
    </mat-error>
  </div>

  <cdk-virtual-scroll-viewport
    itemSize="25"
    class="virtual-viewport"
    *ngIf="(canUpload$ | async) !== true"
  >
    <div>
      <ul data-testid="loads-input-list">
        <li
          class="data-line"
          *cdkVirtualFor="
            let dataLine of loadListControl$ | async;
            let i = index
          "
        >
          <span class="data-index">
            {{ dataLine.month }} {{ dataLine.day }}
          </span>
          <span class="timestamp">
            <span>{{ dataLine.hour }}</span>
            <span>{{ dataLine.period }}</span>
          </span>
          <span
            class="data-value"
            (click)="onEdit(i)"
            *ngIf="(currentEditIndex$ | async) !== i"
            >{{ transformLineValue(dataLine.value) }}</span
          >
          <mat-form-field
            *ngIf="(editMode$ | async) && (currentEditIndex$ | async) === i"
            class="eyes-maximize-width"
            appearance="outline"
            class="prosumer-enhanced-load-input"
          >
            <input
              matInput
              autofocus
              (focusout)="onFocusOut($event)"
              (keypress)="onKeyPress($event)"
              [formControl]="loadProfileInput"
              required="true"
            />

            <mat-error
              *ngFor="let error of loadProfileInput?.errors | keyvalue"
              [ngSwitch]="error.key"
            >
              <div
                *ngSwitchCase="error.key"
                [innerHtml]="errorMessageMap['input'][error.key]"
              ></div>
            </mat-error>
          </mat-form-field>
        </li>
      </ul>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
