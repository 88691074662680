<div
  class="breadcrumb-container"
  *ngIf="breadcrumbs && breadcrumbs?.length > 0"
  fxLayout="row"
>
  <div
    class="breadcrumb"
    *ngFor="let breadcrumb of breadcrumbs; let i = index"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <mat-icon *ngIf="i > 0">chevron_right</mat-icon>
    <a
      *ngIf="breadcrumb?.reference; else default"
      mat-button
      color="primary"
      [routerLink]="breadcrumb?.url"
    >
      <span
        *ngIf="
          reference[breadcrumb?.referenceKey][breadcrumb?.label];
          else loader
        "
      >
        {{ reference[breadcrumb?.referenceKey][breadcrumb?.label] }}
      </span>
      <ng-template #loader>
        <ngx-skeleton-loader
          class="breadcrumbs-loader eyes-maximize-width"
          [theme]="{ height: '7px', width: '60px', margin: '4px' }"
        ></ngx-skeleton-loader>
      </ng-template>
    </a>
    <ng-template #default>
      <a mat-button color="primary" [routerLink]="breadcrumb?.url">
        {{ breadcrumb.label }}
      </a>
    </ng-template>
  </div>
</div>
