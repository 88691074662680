/* eslint-disable @typescript-eslint/naming-convention */
import { DEFAULT_DEBOUNCE_TIME } from 'prosumer-app/app.references';
import { SystemVisualizationData } from 'prosumer-shared';
import { Observable } from 'rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import {
  ResultClearAction,
  ResultGetAction,
  ResultGetSystemVisualizationAction,
  ResultMigrateAction,
  ResultMigrateFailureAction,
  ResultsGetCashFlowAction,
  ResultsGetCO2EmissionsAction,
  ResultsGetDispatchAction,
  ResultsGetEnergyBalanceAction,
  ResultsGetEquipmentAction,
  ResultsGetFlowsAction,
  ResultsGetLatestScenarioVersion,
  ResultsGetMainAction,
  ResultsGetTopologyAction,
  UpdateChartDataAction,
} from './result.actions';
import {
  chartDataSelector,
  energyBalanceSelectors,
  lineInvestmentSelector,
  rawDispatchSelectors,
  resultSelectors,
} from './result.reducer';
import { ResultState } from './result.state';

@Injectable()
export class ResultStore {
  state$: Observable<ResultState> = this._store$.pipe(
    select(resultSelectors.state),
  );
  loading$: Observable<boolean> = this._store$.pipe(
    select(resultSelectors.loading),
  );
  data$: Observable<any> = this._store$.pipe(select(resultSelectors.data));
  error$: Observable<any> = this._store$.pipe(select(resultSelectors.error));
  currentID$: Observable<string> = this._store$.pipe(
    select(resultSelectors.currentScenarioID),
  );
  currentVariationID$: Observable<string> = this._store$.pipe(
    select(resultSelectors.currentScenarioVariationID),
  );
  isMultiNode$: Observable<boolean> = this._store$.pipe(
    select(resultSelectors.isMultiNode),
  );
  latestScenarioVersion$: Observable<string> = this._store$.pipe(
    select(resultSelectors.latestScenarioVersion),
  );

  // Observable for each result category
  derInvestments$: Observable<any> = this._store$.pipe(
    select(resultSelectors.derInvestments),
  );

  derExpenses$: Observable<any> = this._store$.pipe(
    select(resultSelectors.derExpenses),
  );

  lineInvestments$: Observable<any> = this._store$.pipe(
    select(resultSelectors.lineInvestments),
  );

  energyBalance$: Observable<any> = this._store$.pipe(
    select(resultSelectors.energyBalance),
  );

  cashflow$: Observable<any> = this._store$.pipe(
    select(resultSelectors.cashflow),
  );

  getCosts$: Observable<any> = this._store$.pipe(select(resultSelectors.costs));

  sizingData$: Observable<any> = this._store$.pipe(
    select(resultSelectors.sizingData),
  );

  emissions$: Observable<any> = this._store$.pipe(
    select(resultSelectors.emissions),
  );

  systemVisualization$: Observable<SystemVisualizationData> = this._store$.pipe(
    select(resultSelectors.systemVisualizationSelector),
  );

  isOutputSplit$: Observable<boolean> = this._store$.pipe(
    select(resultSelectors.isOutputSplit),
  );
  optimizedYears$: Observable<any> = this._store$.pipe(
    select(resultSelectors.optimizedYearsSelector),
  );
  allYears$: Observable<any> = this._store$.pipe(
    select(resultSelectors.allYearsSelector),
  );
  mainData$: Observable<any> = this._store$.pipe(
    select(resultSelectors.mainDataSelector),
  );
  equipmentData$: Observable<any> = this._store$.pipe(
    select(resultSelectors.equipmentDataSelector),
  );
  topologyData$: Observable<any> = this._store$.pipe(
    select(resultSelectors.topologyDataSelector),
  );
  energyBalanceData$: Observable<any> = this._store$.pipe(
    select(resultSelectors.energyBalanceDataSelector),
  );
  cashflowData$: Observable<any> = this._store$.pipe(
    select(resultSelectors.cashFlowDataSelector),
  );
  co2EmissionsData$: Observable<any> = this._store$.pipe(
    select(resultSelectors.co2EmissionsDataSelector),
  );
  dispatchData$: Observable<any> = this._store$.pipe(
    select(resultSelectors.dispatchDataSelector),
  );
  flowData$: Observable<any> = this._store$.pipe(
    select(resultSelectors.flowsDataSelector),
  );
  constructor(private _store$: Store<ResultState>) {}

  // Paremeterized Selector Observables
  /**
   * Filters dispatch by the specified parameters
   */
  getRawDispatchByParams$ = (
    year?: string,
    node?: string,
    energyVector?: string,
    type?: 'production' | 'consumption',
  ) =>
    this._store$.pipe(
      select(rawDispatchSelectors.getRawDispatchByParams, {
        year,
        node,
        energyVector,
        type,
      }),
      debounceTime(DEFAULT_DEBOUNCE_TIME),
    );

  getStorageOptions$ = (year?: string, node?: string, energyVector?: string) =>
    this._store$.pipe(
      select(rawDispatchSelectors.getStorageOptions, {
        year,
        node,
        energyVector,
      }),
    );

  getStorageSoc$ = (
    year?: string,
    node?: string,
    energyVector?: string,
    storage?: string,
  ) =>
    this._store$.pipe(
      select(rawDispatchSelectors.getStorageSoc, {
        year,
        node,
        energyVector,
        storage,
      }),
    );

  getStorageCharge$ = (
    year?: string,
    node?: string,
    energyVector?: string,
    storage?: string,
  ) =>
    this._store$.pipe(
      select(rawDispatchSelectors.getStorageCharge, {
        year,
        node,
        energyVector,
        storage,
      }),
    );

  getRawDispatchFilterOptions$ = (field?: 'year' | 'node' | 'energyVector') =>
    this._store$.pipe(
      select(rawDispatchSelectors.getRawDispatchFilterOptions, { field }),
    );

  getLineInvestmentFilterOptions$ = (
    field?: 'origin_node' | 'destination_node' | 'fluid',
  ) =>
    this._store$.pipe(
      select(lineInvestmentSelector.getLineInvestmentFilterOptions, { field }),
    );

  getLineInvestmentByParams$ = (
    originNodes?: Array<string>,
    destinationNodes?: Array<string>,
    fluids?: Array<string>,
  ) =>
    this._store$.pipe(
      select(lineInvestmentSelector.getLineInvestmentByParams, {
        originNodes,
        destinationNodes,
        fluids,
      }),
    );

  getEnergyBalanceByParams$ = (
    year?: Array<string>,
    node?: Array<string>,
    energyVector?: Array<string>,
    type?: 'production' | 'consumption',
  ) =>
    this._store$.pipe(
      select(energyBalanceSelectors.getEnergyBalanceByParams, {
        year,
        node,
        energyVector,
        type,
      }),
    );

  getEnergyBalanceFilterOptions$ = (field?: 'year' | 'node' | 'energyVector') =>
    this._store$.pipe(
      select(energyBalanceSelectors.getEnergyBalanceFilterOptions, { field }),
    );

  chartData$ = (chartID) =>
    this._store$.pipe(select(chartDataSelector.chartDataMapping, chartID));

  /**
   * Fetch the results for a specific scenario ID
   *
   * @param scenarioId ID of the Scenario you'd want to get the results from
   */
  getResults(
    projectId: string,
    caseId: string,
    scenarioId: string,
  ): Observable<any> {
    this._store$.dispatch(new ResultGetAction(projectId, caseId, scenarioId));
    return this.data$;
  }

  /**
   * Migrate the results for a specific scenario ID
   *
   * @param scenarioId ID of the Scenario you'd want to get the results from
   */
  migrateResults(
    projectId: string,
    caseId: string,
    scenarioId: string,
  ): Observable<any> {
    this._store$.dispatch(
      new ResultMigrateAction(projectId, caseId, scenarioId),
    );
    return this.currentID$;
  }

  /**
   * Dispatch action for failed migration
   *
   * @param message Error message
   */
  setFailedMigration(message: string) {
    this._store$.dispatch(new ResultMigrateFailureAction(message));
  }

  /**
   * Clear the results!
   */
  clearResults(): Observable<any> {
    this._store$.dispatch(new ResultClearAction());
    return this.data$;
  }

  updateChartDataMapping(chartId: string, chartData: any): Observable<any> {
    this._store$.dispatch(new UpdateChartDataAction(chartId, chartData));
    return this.chartData$(chartId);
  }

  /**
   * Fetch the main tab results for a specific scenario ID
   *
   * @param scenarioId ID of the Scenario you'd want to get the results from
   */
  getMainResults(
    projectId: string,
    caseId: string,
    scenarioId: string,
    scenarioVariationId?: string,
  ): void {
    this.mainData$
      .pipe(
        take(1),
        filter((data) => !data),
      )
      .subscribe(() => {
        this._store$.dispatch(
          new ResultsGetMainAction(
            projectId,
            caseId,
            scenarioId,
            scenarioVariationId,
          ),
        );
      });
  }

  /**
   * Fetch the system visualization results for a specific scenario ID
   *
   * @param scenarioId ID of the Scenario you'd want to get the results from
   */
  getSystemVisualizationResults(
    projectId: string,
    caseId: string,
    scenarioId: string,
    scenarioVariationId?: string,
  ): void {
    this.systemVisualization$
      .pipe(
        take(1),
        filter((data) => !data),
      )
      .subscribe(() => {
        this._store$.dispatch(
          new ResultGetSystemVisualizationAction(
            projectId,
            caseId,
            scenarioId,
            scenarioVariationId,
          ),
        );
      });
  }

  /**
   * Fetch the equipment tab results for a specific scenario ID
   *
   * @param scenarioId ID of the Scenario you'd want to get the results from
   */
  getEquipmentResults(
    projectId: string,
    caseId: string,
    scenarioId: string,
    scenarioVariationId?: string,
  ): void {
    this.equipmentData$
      .pipe(
        take(1),
        filter((data) => !data.DER_investments),
      )
      .subscribe(() => {
        this._store$.dispatch(
          new ResultsGetEquipmentAction(
            projectId,
            caseId,
            scenarioId,
            scenarioVariationId,
          ),
        );
      });
  }

  /**
   * Fetch the topology tab results for a specific scenario ID
   *
   * @param scenarioId ID of the Scenario you'd want to get the results from
   */
  getTopologyResults(
    projectId: string,
    caseId: string,
    scenarioId: string,
    scenarioVariationId?: string,
  ): void {
    this.topologyData$
      .pipe(
        take(1),
        filter((data) => !data.line_investments),
      )
      .subscribe(() => {
        this._store$.dispatch(
          new ResultsGetTopologyAction(
            projectId,
            caseId,
            scenarioId,
            scenarioVariationId,
          ),
        );
      });
  }

  /**
   * Fetch the energy balance tab results for a specific scenario ID
   *
   * @param scenarioId ID of the Scenario you'd want to get the results from
   */
  getEnergyBalanceResults(
    projectId: string,
    caseId: string,
    scenarioId: string,
    scenarioVariationId?: string,
  ): void {
    this.energyBalanceData$
      .pipe(
        take(1),
        filter((data) => !data.energy_balance),
      )
      .subscribe(() => {
        this._store$.dispatch(
          new ResultsGetEnergyBalanceAction(
            projectId,
            caseId,
            scenarioId,
            scenarioVariationId,
          ),
        );
      });
  }

  /**
   * Fetch the cashflow tab results for a specific scenario ID
   *
   * @param scenarioId ID of the Scenario you'd want to get the results from
   */
  getCashFlowResults(
    projectId: string,
    caseId: string,
    scenarioId: string,
    scenarioVariationId?: string,
  ): void {
    this.cashflowData$
      .pipe(
        take(1),
        filter(
          (data) => !data.cumulative_cashflow && !data.incremental_cashflow,
        ),
      )
      .subscribe(() => {
        this._store$.dispatch(
          new ResultsGetCashFlowAction(
            projectId,
            caseId,
            scenarioId,
            scenarioVariationId,
          ),
        );
      });
  }

  /**
   * Fetch the CO2 Emissions tab results for a specific scenario ID
   *
   * @param scenarioId ID of the Scenario you'd want to get the results from
   */
  getCO2EmissionsResults(
    projectId: string,
    caseId: string,
    scenarioId: string,
    scenarioVariationId?: string,
  ): void {
    this.co2EmissionsData$
      .pipe(
        take(1),
        filter(
          (data) => !data.cumulative_emissions && !data.incremental_emissions,
        ),
      )
      .subscribe(() => {
        this._store$.dispatch(
          new ResultsGetCO2EmissionsAction(
            projectId,
            caseId,
            scenarioId,
            scenarioVariationId,
          ),
        );
      });
  }

  /**
   * Fetch the dispatch tab results for a specific scenario ID
   *
   * @param scenarioId ID of the Scenario you'd want to get the results from
   */
  getDispatchResults(
    projectId: string,
    caseId: string,
    scenarioId: string,
    scenarioVariationId?: string,
  ): void {
    this.dispatchData$
      .pipe(
        take(1),
        filter((data) => !data),
      )
      .subscribe(() => {
        this._store$.dispatch(
          new ResultsGetDispatchAction(
            projectId,
            caseId,
            scenarioId,
            scenarioVariationId,
          ),
        );
      });
  }

  /**
   * Fetch the flow tab results for a specific scenario ID
   *
   * @param scenarioId ID of the Scenario you'd want to get the results from
   */
  getFlowsResults(
    projectId: string,
    caseId: string,
    scenarioId: string,
    scenarioVariationId?: string,
  ): void {
    this.flowData$
      .pipe(
        take(1),
        filter((data) => !data.sankey),
      )
      .subscribe(() => {
        this._store$.dispatch(
          new ResultsGetFlowsAction(
            projectId,
            caseId,
            scenarioId,
            scenarioVariationId,
          ),
        );
      });
  }

  getLatestScenarioVersion() {
    this._store$.dispatch(new ResultsGetLatestScenarioVersion());
  }
}
