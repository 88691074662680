import { Options } from 'ng5-slider';
import { ENGIE_DEFAULT_SCHEME } from 'prosumer-app/app.references';
import {
  fadeInAnimation,
  fadeOutAnimation,
} from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
} from '@angular/core';

import { FilterableLegend } from '../../base';
import { GENERATE_COLOR_SCHEME } from '../../charts.tokens';
import { ChartScheme, ChartSeriesData, ChartTooltipData } from '../../models';

@Component({
  selector: 'prosumer-stacked-area-chart',
  templateUrl: './stacked-area-chart.component.html',
  styleUrls: ['./stacked-area-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation, fadeOutAnimation],
})
export class StackedAreaChartComponent<Y, X, V> extends FilterableLegend {
  constructor(
    @Inject(GENERATE_COLOR_SCHEME)
    @Optional()
    private generateColorScheme: (
      numberOfElements: number,
      scheme?: any,
    ) => any,
  ) {
    super();
  }
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();

  /* Data */
  @Input() loading: boolean;
  @Input() set seriesDataList(seriesDataList: Array<ChartSeriesData<Y, X, V>>) {
    this.seriesDataSubject.next(seriesDataList);
    if (this.seriesDataList) {
      this.manualRefresh.emit();
      this.scheme = this.generateColorScheme
        ? this.generateColorScheme(seriesDataList.length)
        : undefined;
    }
  }
  get seriesDataList(): Array<ChartSeriesData<Y, X, V>> {
    return this.seriesDataSubject.value;
  }

  /* Charts */
  @Input() xAxisLabel: string;
  @Input() yAxisLabel: string;
  @Input() showLegend: boolean;
  private localView: [number, number];
  @Input() set view(view: [number, number]) {
    this.localView = view;
    this.width = view[0];
    this.height = view[1];
  }
  get view() {
    return this.localView;
  }
  width: number;
  height: number;

  /* Range Slider */
  @Input() hasRangeSlider: boolean;
  @Input() from: X = 3 as any;
  @Input() to: X = 6 as any;

  @Input() set floor(floor: number) {
    this.sliderOptions = { ...this.sliderOptions, floor };
  }

  @Input() set ceiling(ceiling: number) {
    this.sliderOptions = { ...this.sliderOptions, ceil: ceiling };
  }

  @Input() set minRange(minRange: number) {
    this.sliderOptions = { ...this.sliderOptions, minRange };
  }

  @Input() set maxRange(maxRange: number) {
    this.sliderOptions = { ...this.sliderOptions, maxRange };
  }

  @Input() set sliderOptions(sliderOptions: Options) {
    this._sliderOptions = sliderOptions;
  }
  get sliderOptions(): Options {
    return this._sliderOptions;
  }

  @Output() fromChange = new EventEmitter<X>();
  @Output() toChange = new EventEmitter<X>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() select = new EventEmitter<unknown>();

  private seriesDataSubject = new BehaviorSubject<ChartSeriesData<Y, X, V>[]>(
    [],
  );
  scheme: ChartScheme = ENGIE_DEFAULT_SCHEME;
  _sliderOptions: Options = {
    step: 1,
    draggableRange: true,
    showTicks: true,
    floor: 1,
    ceil: 10,
    minRange: 0,
    maxRange: 5,
  };

  @Input() tooltipTitleFormat = (tooltipData: ChartTooltipData<Y, X, V>) =>
    tooltipData ? tooltipData.name : tooltipData;
  @Input() seriesTooltipTitleFormat = (
    tooltipData: ChartTooltipData<Y, X, V>,
  ) => (tooltipData ? tooltipData.name : tooltipData);
  @Input() xAxisFormat = (data: X) => data;
  @Input() yAxisFormat = (data: V) => data;

  onFromChange(value: X) {
    this.fromChange.next(value);
  }

  onToChange(value: X) {
    this.toChange.next(value);
  }

  onSelect(data: unknown): void {
    this.select.emit(data);
    this.onLegendSelect(data);
  }

  getChartDataStream(): Observable<unknown[]> {
    return this.seriesDataSubject.asObservable();
  }
  getLegendNames(data: unknown[]): string[] {
    return (data || []).map((d) => d['name']);
  }
  mutateSingleChartDataBasedOnLegend<T>(single: T): T {
    const singleName = single['name'];
    const isShowing = this.shouldShowData(singleName);
    return {
      ...single,
      name: this.mutateNameBasedOnShowOrHide(singleName),
      series: this.resolveToEmptyArray(single['series']).map((siri) => ({
        name: siri['name'],
        value: isShowing ? siri['value'] : 0,
      })),
    };
  }

  private resolveToEmptyArray(alleged: unknown): unknown[] {
    return (alleged as unknown[]) || [];
  }
}
