import { Utils } from 'prosumer-app/core';
import { EnergyBalanceDirection, EnergyBalanceResult } from '../models';

import { BaseExtractor } from './base.extractor';

export class EnergyBalanceExtractor
  implements BaseExtractor<EnergyBalanceResult>
{
  extract(resultsJson: unknown): EnergyBalanceResult[] {
    return [].concat.apply(
      [],
      this.createFromEnergyBalanceValue(resultsJson['energy_balance']),
    );
  }

  private createFromEnergyBalanceValue(
    balanse: unknown = {},
  ): EnergyBalanceResult[] {
    return Object.entries(balanse).reduce(
      (acc, [year, yearVal]) => [
        ...acc,
        ...this.createFromYearValues(yearVal, Number(year)),
      ],
      [],
    );
  }

  private createFromYearValues(
    yearValue: unknown,
    year: number,
  ): EnergyBalanceResult[] {
    return Object.entries(yearValue).reduce(
      (acc, [node, nodeVal]) => [
        ...acc,
        this.createFromNodeValues(nodeVal, node, year),
      ],
      [],
    );
  }

  private createFromNodeValues(
    nodeValue: unknown,
    node: string,
    year: number,
  ): EnergyBalanceResult[] {
    return Object.entries(nodeValue).reduce(
      (acc, [vector, bidirection]) => [
        ...acc,
        ...this.createFromVectorValues(bidirection, vector, node, year),
      ],
      [],
    );
  }

  private createFromVectorValues(
    vectorValue: unknown,
    vector: string,
    node: string,
    year: number,
  ): EnergyBalanceResult[] {
    return Object.values(EnergyBalanceDirection).reduce(
      (acc, curr) => [
        ...acc,
        ...this.createFromDirectionValues(
          vectorValue[curr],
          curr,
          vector,
          node,
          year,
        ),
      ],
      [],
    );
  }

  private createFromDirectionValues(
    directionValue: unknown,
    direction: EnergyBalanceDirection,
    energyVector: string,
    node: string,
    year: number,
  ): EnergyBalanceResult[] {
    return Object.entries(Utils.resolveToEmptyObject(directionValue)).map(
      ([name, value]) => ({
        name,
        value,
        energyVector,
        node,
        year,
        direction,
      }),
    );
  }
}
