<div fxLayout="column" class="delete-scenarios-div">
  <h1 data-testid="title">Delete Scenarios</h1>
  <mat-list>
    <div mat-subheader>
      You are about to delete {{ scenarios.length }} scenarios:
    </div>
    <div class="delete-scenarios-list">
      <mat-list-item
        *ngFor="let scenario of scenarios"
        [attr.data-testid]="'scenario-' + scenario.id"
      >
        <mat-icon mat-list-icon color="primary">check</mat-icon>
        <div mat-line>
          {{ scenario.name }}
        </div>
        <div mat-line>
          Created at {{ scenario.createdDate | date : 'dd MMM yyyy HH:mm' }}
        </div>
      </mat-list-item>
    </div>
  </mat-list>
  <div
    fxLayout="row"
    fxLayoutGap="16px"
    class="delete-scenarios-buttons"
    *ngrxLet="deleting$ as deleting"
  >
    <button
      mat-flat-button
      data-testid="confirm"
      color="warn"
      [disabled]="deleting"
      [class.spinner]="deleting"
      (click)="onDelete()"
    >
      DELETE
    </button>
    <button
      mat-button
      color="primary"
      data-testid="cancel"
      [disabled]="deleting"
      (click)="onCancel()"
    >
      CANCEL
    </button>
  </div>
</div>
