import {
  BinaryData,
  EnergyGridConnection,
  EnergyGridConnectionsEmissions,
  EnergyGridLimit,
  Fuel,
  Profile,
} from 'prosumer-app/+scenario/models';
import { CommoditiesCompletion } from 'prosumer-app/+scenario/services/completion-strategies/commodities-completion.strategy';
import {
  ScenarioCompletionService,
  ScenarioWizardStep,
} from 'prosumer-app/+scenario/services/scenario-completion';
import { BINARY_LOCATIONS } from 'prosumer-app/app.references';
import { DialogService, RouterStore } from 'prosumer-app/libs/eyes-core';
import {
  contains,
  containsSubstring,
  fadeInAnimation,
  FormFieldOption,
  generateShortUID,
  generateUuid,
  StepFormComponent,
} from 'prosumer-app/libs/eyes-shared';
import { SparklineTableColumnDefinition } from 'prosumer-app/shared/components/sparkline-table/sparkline-table.model';

import { convertToYearlyValues } from 'prosumer-shared';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { startWith, switchMap, take } from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { FormBuilder, NgControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { PipeUtils } from 'prosumer-app/core/utils';
import { DuplicationStarter } from 'prosumer-app/services/duplication-starter';
import { ScenarioDetailType } from 'prosumer-app/stores';
import { EGCEmission } from 'prosumer-app/stores/egc-emission';
import { FuelInfo } from 'prosumer-app/stores/fuel';
import { MarketLimit } from 'prosumer-app/stores/market-limit';
import { CommoditiesFormService } from './commodities-form.service';
import { CommoditiesTableRefs } from './commodities-form.types';
import { CommoditiesReferencesBuilder } from './commodities-references.builder';
import {
  EnergyGridConnectionsEmissionsDialogData,
  EnergyGridConnectionsEmissionsFormDialogComponent,
} from './energy-grid-connections-emissions-form-dialog';
import {
  EnergyGridConnectionsDialogData,
  EnergyGridConnectionsFormDialogComponent,
} from './energy-grid-connections-form-dialog';
import {
  EnergyGridLimitDialogComponent,
  EnergyGridLimitDialogData,
} from './energy-grid-limit-form-dialog';
import {
  FuelsFormDialogComponent,
  FuelsFormDialogData,
} from './fuels-form-dialog';

@UntilDestroy()
@Component({
  selector: 'prosumer-commodities-form',
  templateUrl: './commodities-form.component.html',
  styleUrls: ['./commodities-form.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommoditiesFormComponent
  extends StepFormComponent
  implements OnInit, AfterViewInit
{
  readonly typeOptions: Array<FormFieldOption<string>> = [
    { name: 'Buy', value: 'retail' },
    { name: 'Sell', value: 'wholesale' },
  ];

  readonly scopeOptions: Array<FormFieldOption<string>> = [
    { name: 'Scope 1', value: 'scope1' },
    { name: 'Scope 2', value: 'scope2' },
    { name: 'Scope 3', value: 'scope3' },
  ];

  readonly energyTariffOptions: Array<FormFieldOption<string>> = [
    { name: 'Custom', value: 'custom' },
    { name: 'Library', value: 'library' },
  ];

  _scenarioIdentity: any;
  get scenarioIdentity(): any {
    return this._scenarioIdentity;
  }
  @Input() set scenarioIdentity(_scenarioIdentity: any) {
    this._scenarioIdentity = _scenarioIdentity;
  }

  gridConnectionsColumnDef: SparklineTableColumnDefinition = {
    marketName: {
      name: 'Market',
      toolTip: 'wizard_commodities.wizard_commodities_market',
      sortable: true,
    },
    nodes: {
      name: 'Nodes',
      type: 'referenceList',
      referenceKey: 'nodes',
      toolTip: 'wizard_commodities.wizard_commodities_node',
      sortable: true,
    },
    delivery: {
      name: 'Energy Vector',
      type: 'reference',
      referenceKey: 'energyVectors',
      toolTip: 'wizard_commodities.wizard_commodities_energy_vector',
      sortable: true,
    },
    type: {
      name: 'Type',
      type: 'reference',
      referenceKey: 'types',
      toolTip: 'wizard_commodities.wizard_commodities_type',
      sortable: true,
    },
    scenarioVariation: {
      name: 'Variation',
      type: 'reference',
      referenceKey: 'scenarioVariations',
      sortable: true,
    },
    actions: {
      name: 'Actions',
      type: 'action',
    },
  };

  gridConnectionsEmissionsColumnDef: SparklineTableColumnDefinition = {
    marketName: {
      name: 'Market',
      sortable: true,
      type: 'custom',
      referenceKey: 'marketName',
      cellTooltipReferenceKey: 'marketVariationAssociation',
    },
    node: {
      name: 'Node',
      type: 'reference',
      referenceKey: 'nodes',
      sortable: true,
    },
    scope: {
      name: 'Scope',
      type: 'reference',
      referenceKey: 'scope',
      sortable: true,
    },
    actions: {
      name: 'Actions',
      type: 'action',
    },
  };

  gridLimitColumnDef: SparklineTableColumnDefinition = {
    market: {
      name: 'Market',
      type: 'custom',
      referenceKey: 'marketName',
      toolTip: 'wizard_commodities.wizard_market_limits_market',
      cellTooltipReferenceKey: 'marketVariationAssociation',
      sortable: true,
    },
    nodes: {
      name: 'Node',
      type: 'referenceList',
      referenceKey: 'nodes',
      toolTip: 'wizard_commodities.wizard_market_limits_node',
      sortable: true,
    },
    actions: {
      name: 'Actions',
      type: 'action',
    },
  };

  fuelColumnDef: SparklineTableColumnDefinition = {
    fuel: {
      name: 'Fuel',
      type: 'reference',
      referenceKey: 'energyVectors',
      toolTip: 'wizard_commodities.wizard_commodities_fuel_vector',
      sortable: true,
    },
    nodes: {
      name: 'Nodes',
      type: 'referenceList',
      referenceKey: 'nodes',
      toolTip: 'wizard_commodities.wizard_commodities_fuel_node',
      sortable: true,
    },
    fuelCost: {
      name: 'Fuel Cost',
      type: 'chart',
      toolTip: 'wizard_commodities.wizard_commodities_fuel_cost',
    },
    co2Rate: {
      name: 'CO2 Scope 1 Factor',
      type: 'chart',
    },
    co2Scope3EmissionFactor: {
      name: 'CO2 Scope 3 Factor',
      type: 'chart',
    },
    actions: {
      name: 'Actions',
      type: 'action',
    },
  };

  @Input() startYear: number;
  @Input() endYear: number;
  @Input() isMultiNode: boolean;
  @Input() loadFormData: ScenarioWizardStep;

  nodeOptions: Array<FormFieldOption<string>> = [];
  evOptions: Array<FormFieldOption<string>> = [
    { name: 'Electricity', value: 'e0000' },
  ];
  egcs: EnergyGridConnection[] = [];
  fuels: Fuel[] = [];

  @Input() isViewOnly: boolean;

  persistedBinaries: Array<Profile> = [];
  persistedEmmisionsBinaries: Array<Profile> = [];

  projectId: string;
  caseId: string;
  scenarioId: string;
  connectionsEmissions$ = new BehaviorSubject<EnergyGridConnectionsEmissions[]>(
    [],
  );

  readonly egcEmissions$: Observable<EGCEmission[]> =
    this.service.selectEGCEmissions();
  readonly limits$: Observable<MarketLimit[]> =
    this.service.selectMarketLimits();
  readonly refs$: Observable<CommoditiesTableRefs> =
    this.refBuilder.selectRefs();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: FormBuilder,
    public dialog: DialogService,
    private router: RouterStore,
    private completion: ScenarioCompletionService,
    private readonly service: CommoditiesFormService,
    private readonly refBuilder: CommoditiesReferencesBuilder,
    private readonly dupStarter: DuplicationStarter,
  ) {
    super(ngControl, changeDetector, formBuilder);
    this.subscribeToValueChangesForCompletionTracking();
  }

  defineForm() {
    return {
      co2Price: [undefined, Validators.required],
      co2Scope2Price: [undefined, Validators.required],
      co2Scope3Price: [undefined, Validators.required],
      fuels: [],
      limits: [],
      grids: [],
      connectionsEmissions: [],
      binToDelete: [],
      binToClone: [[]],
    };
  }

  /**
   * Overriden to fix issue in dirtying of form specifically for topology form
   */
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  ngAfterViewInit() {
    this.setIds();

    combineLatest([
      this.form.controls.co2Price.valueChanges.pipe(
        startWith(this.form.controls.co2Price.value),
      ),
      this.form.controls.co2Scope2Price.valueChanges.pipe(
        startWith(this.form.controls.co2Scope2Price.value),
      ),
      this.form.controls.co2Scope3Price.valueChanges.pipe(
        startWith(this.form.controls.co2Scope3Price.value),
      ),
      this.form.controls.binToDelete.valueChanges.pipe(
        startWith(this.form.controls.binToDelete.value),
      ),
      this.form.controls.binToClone.valueChanges.pipe(
        startWith(this.form.controls.binToClone.value),
      ),
    ])
      .pipe(this.takeUntil())
      .subscribe(
        ([
          co2Price,
          co2Scope2Price,
          co2Scope3Price,
          binToDelete,
          binToClone,
        ]) => {
          const formValue = {
            co2Price,
            co2Scope2Price,
            co2Scope3Price,
            binToDelete,
            binToClone,
          };
          this.onChange(formValue);
        },
      );

    this.enableValidator(false);
    this.form
      .get('connectionsEmissions')
      .valueChanges.subscribe((emissions) => {
        this.connectionsEmissions$.next(emissions);
      });
    this.service
      .selectAcitveOptions()
      .pipe(untilDestroyed(this))
      .subscribe(([nodes, vectors, _]) => {
        this.evOptions = vectors;
        this.nodeOptions = nodes.map((node) => ({
          name: node.name,
          value: node.value,
        }));
      });
  }

  /**
   * For table searh filter, find matching grid from list
   */
  gridSearchPredicate = (data: EnergyGridConnection, filter: string) =>
    containsSubstring(data.marketName, filter);

  /**
   * For table searh filter, find matching energy connections emmisions from list
   */
  connectionsEmissionsSearchPredicate = (
    data: EnergyGridConnectionsEmissions,
    filter: string,
  ) => {
    let marketName = '';
    let nodeName = '';
    let scopeName = '';

    this.egcs.forEach((grid) => {
      if (grid.id === data.marketName) {
        marketName = grid.marketName;
      }
    });
    this.nodeOptions.forEach((node) => {
      if (node.value === data.node) {
        nodeName = node.name;
      }
    });
    this.scopeOptions.forEach((scope) => {
      if (scope.value === data.scope) {
        scopeName = scope.name;
      }
    });
    return (
      containsSubstring(marketName, filter) ||
      containsSubstring(nodeName, filter) ||
      containsSubstring(scopeName, filter)
    );
  };

  /**
   * For table searh filter, find matching limit from list
   */
  limitSearchPredicate = (data: EnergyGridLimit, filter: string) => {
    let marketName = '';
    let nodeName = '';

    this.egcs.forEach((connection) => {
      if (connection.id === data.market) {
        marketName = connection.marketName;
      }
    });
    this.nodeOptions.forEach((node) => {
      if (data.nodes.includes(node.value)) {
        nodeName = node.name;
      }
    });
    return (
      containsSubstring(marketName, filter) ||
      containsSubstring(nodeName, filter)
    );
  };

  /**
   * For table searh filter, find matching energy vector from list
   */
  fuelSearchPredicate = (data: Fuel, filter: string) => {
    const filteredEVs = this.evOptions.filter((energyVector) =>
      containsSubstring(energyVector.name, filter),
    );
    return !!filteredEVs
      ? filteredEVs.some((energyVector) => data.fuel === energyVector.value)
      : false;
  };

  setIds() {
    this.router.params$.pipe(this.takeUntil()).subscribe((data) => {
      this.projectId = data.projectId;
      this.caseId = data.caseId;
      this.scenarioId = data.scenarioId;
    });
  }

  _spreadBinaryProperties = (binaryData?: BinaryData) => ({
    localId: (binaryData || {}).localId || generateUuid().substring(-5, 5),
    location: BINARY_LOCATIONS.ENERGY_GRID_CONNECTION,
  });

  onAddGrid(): void {
    this.dialog
      .openDialog(
        EnergyGridConnectionsFormDialogComponent,
        this.generateGridData(),
      )
      .pipe(take(1))
      .subscribe();
  }

  onEditGrid(data: EnergyGridConnection): void {
    this.service
      .retrieveEGC(data.id)
      .pipe(take(1))
      .subscribe((datum) =>
        this.dialog
          .openDialog(EnergyGridConnectionsFormDialogComponent, {
            ...this.generateGridData(),
            ...datum,
            mode: 'edit',
            isViewOnly: this.isViewOnly,
            energyGridConnectionData: datum,
          })
          .pipe(
            take(1),
            PipeUtils.filterOutNullUndefined,
            switchMap(() => this.service.postUpdateEGC()),
          )
          .subscribe(),
      );
  }

  onDeleteGrid(grid: EnergyGridConnection) {
    this.service.deleteEGC(grid.id).pipe(take(1)).subscribe();
  }

  onDuplicateGrid(data: EnergyGridConnection): void {
    this.dupStarter
      .start(ScenarioDetailType.energyGridConnection, data)
      .pipe(PipeUtils.filterOutNullUndefined)
      .subscribe();
  }

  onDuplicateEmission(data: EGCEmission): void {
    this.dupStarter
      .start(ScenarioDetailType.egcEmission, {
        ...data,
        isMultiNode: this.isMultiNode,
      })
      .subscribe();
  }

  getDataWithReferenceGridAndNode(
    emissions: Array<EnergyGridConnectionsEmissions>,
    limits: Array<EnergyGridLimit>,
    grid: EnergyGridConnection,
    deletedNodes: Array<string>,
  ) {
    return {
      emissions: emissions.filter(
        (emission) =>
          emission.marketName === grid.id &&
          deletedNodes.includes(emission.node),
      ),
      limits: limits.filter(
        (limit) =>
          limit.market === grid.id &&
          limit.nodes.every((node) => contains(deletedNodes, node)),
      ),
    };
  }

  onAddConnectionsEmission(): void {
    this.dialog.openDialog(
      EnergyGridConnectionsEmissionsFormDialogComponent,
      this.generateConnectionsEmissionsData(),
    );
  }

  onEditConnectionsEmission(data: EnergyGridConnectionsEmissions) {
    this.service
      .getEmission(data.id)
      .pipe(take(1))
      .subscribe((datum) =>
        this.dialog.openDialog(
          EnergyGridConnectionsEmissionsFormDialogComponent,
          {
            ...this.buildEditEmissionsData(),
            ...datum,
          },
        ),
      );
  }

  private buildEditEmissionsData(): EnergyGridConnectionsEmissionsDialogData {
    return { ...this.generateConnectionsEmissionsData(), mode: 'edit' };
  }

  onDeleteEmission(data: EGCEmission): void {
    this.service.deleteEmission(data.id).pipe(take(1)).subscribe();
  }

  onAddEnergyGridLimit(data?: EnergyGridLimit) {
    const dialogData = { ...this.generateLimitData(), ...data };
    if (!!data) {
      dialogData['mode'] = 'duplicate';
      dialogData['editData'] = {
        ...data,
        id: '',
      };
    }

    this.dialog
      .openDialog(EnergyGridLimitDialogComponent, dialogData)
      .pipe(take(1))
      .subscribe();
  }

  onDuplicateEnergyGridLimit(data: EnergyGridLimit): void {
    this.dupStarter
      .start(ScenarioDetailType.marketLimit, {
        ...data,
        isMultiNode: this.isMultiNode,
      })
      .subscribe();
  }

  onEditEnergyGridLimit(data: EnergyGridLimit): void {
    this.service
      .getLimit(data.id)
      .pipe(take(1))
      .subscribe((datum) =>
        this.dialog
          .openDialog(EnergyGridLimitDialogComponent, {
            ...this.generateLimitData(),
            ...datum,
            mode: 'edit',
            editData: datum,
            isViewOnly: this.isViewOnly,
          })
          .pipe(take(1))
          .subscribe(),
      );
  }

  onDeleteLimit(data: EnergyGridLimit): void {
    this.service.deleteLimit(data.id).pipe(take(1)).subscribe();
  }

  onAddFuel() {
    this.dialog
      .openDialog(FuelsFormDialogComponent, this.generateFuelData())
      .pipe(take(1))
      .subscribe();
  }

  onEditFuel(data: Fuel) {
    this.dialog
      .openDialog(FuelsFormDialogComponent, {
        ...this.generateFuelData(),
        ...data,
        mode: 'edit',
        fuelData: data,
        isViewOnly: this.isViewOnly,
      } as FuelsFormDialogData)
      .pipe(take(1))
      .subscribe();
  }

  onDeleteFuel(data: FuelInfo) {
    this.service.deleteFuel(data.id).pipe(take(1)).subscribe();
  }

  generateGridData(): EnergyGridConnectionsDialogData {
    return {
      width: '90%',
      mode: 'add',
      marketName: '',
      nodes: this.isMultiNode ? [] : [this.nodeOptions[0].value],
      delivery: '',
      type: 'retail',
      capacityTariff: convertToYearlyValues(
        '0.0',
        this.startYear,
        this.endYear,
      ),
      connectionTariff: convertToYearlyValues(
        '0.0',
        this.startYear,
        this.endYear,
      ),
      energyTariffOptions: this.energyTariffOptions,
      disableClose: true,
      isMultiNode: this.isMultiNode,
      startYear: this.startYear,
      endYear: this.endYear,
      connectionsEmissions: [],
      profiles: [
        {
          startYear: this.startYear,
          endYear: this.endYear,
          forSaving: true,
          library: null,
          loadProfile: null,
          loadType: 'custom',
          localId: generateShortUID(),
          location: BINARY_LOCATIONS.ENERGY_GRID_CONNECTION,
          yearlyLoad: 1,
        },
      ],
      scenarioIdentity: this.scenarioIdentity,
      ...this.service.prepOptionsForEGCDialog(),
    };
  }

  generateConnectionsEmissionsData(): EnergyGridConnectionsEmissionsDialogData {
    return {
      isViewOnly: this.isViewOnly,
      width: '90%',
      mode: 'add',
      isMultiNode: this.isMultiNode,
      startYear: this.startYear,
      endYear: this.endYear,
      marketName: '',
      node: '',
      scope: '',
      profiles: [
        {
          startYear: this.startYear,
          endYear: this.endYear,
          forSaving: true,
          library: null,
          loadProfile: null,
          loadType: 'custom',
          localId: generateShortUID(),
          location: BINARY_LOCATIONS.ENERGY_GRID_CONNECTIONS_EMISSIONS,
          yearlyLoad: 1,
        },
      ],
      scenarioIdentity: this.scenarioIdentity,
      ...this.service.prepForEmissionsDialogData(),
    };
  }

  generateLimitData(): EnergyGridLimitDialogData {
    return {
      mode: 'add',
      width: '60%',
      isMultiNode: this.isMultiNode,
      startYear: this.startYear,
      endYear: this.endYear,
      editData: {
        nodes:
          this.nodeOptions.length === 1
            ? [this.nodeOptions[0].value]
            : undefined,
      },
      disableClose: true,
      ...this.service.prepForLimitDialogData(),
    };
  }

  generateFuelData(): FuelsFormDialogData {
    return {
      width: 600,
      mode: 'add',
      fuel: '',
      nodes:
        this.nodeOptions.length === 1 ? [this.nodeOptions[0].value] : undefined,
      fuelCost: convertToYearlyValues('0.0', this.startYear, this.endYear),
      co2Rate: convertToYearlyValues('0.0', this.startYear, this.endYear),
      co2Scope3EmissionFactor: convertToYearlyValues(
        '0.0',
        this.startYear,
        this.endYear,
      ),
      disableClose: true,
      isMultiNode: this.isMultiNode,
      startYear: this.startYear,
      endYear: this.endYear,
      ...this.service.prepOptionsForFuelDialog(),
    };
  }

  private subscribeToValueChangesForCompletionTracking(): void {
    const strategy = new CommoditiesCompletion();
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((form) => {
      this.completion.setForStep(
        ScenarioWizardStep.commodities,
        strategy.determineStatus(form),
      );
    });
  }
}
