import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  FlowResult,
  SankeyResult,
} from '@prosumer/results/models/flow-results.model';

import {
  PerceptionMap,
  ResultsPerceptionService,
} from '../case-results-perception';
import { FlowResultsService } from './flow-results.service';

@Component({
  selector: 'prosumer-flow-results',
  templateUrl: './flow-results.component.html',
  styleUrls: ['./flow-results.component.scss'],
  providers: [FlowResultsService],
})
export class FlowResultsComponent implements OnInit {
  @Input() caseId: string;
  @Input() scenarioName: string;

  colors$: Observable<PerceptionMap>;
  result$: Observable<FlowResult>;
  years$: Observable<string[]>;
  yearOptions: Array<string>;
  yearSelectControl = new FormControl();
  selectedData$ = new BehaviorSubject<SankeyResult>(undefined);

  constructor(
    public results: FlowResultsService,
    private perception: ResultsPerceptionService,
  ) {}

  ngOnInit(): void {
    this.colors$ = this.perception.getPerceptionMapStream(this.caseId);
    this.results.getFlowOutput();
    this.subscribeToResult();
    this.subscribeToChanges();
  }

  private subscribeToResult() {
    this.result$ = this.results.flow$.pipe();
    this.years$ = this.results.flow$.pipe(
      filter((data) => !!data.sankey),
      map((data) => {
        this.yearSelectControl.patchValue(Object.keys(data.sankey)[0]);
        return Object.keys(data.sankey);
      }),
    );
  }

  private subscribeToChanges() {
    this.colors$.subscribe();
    combineLatest([this.result$, this.yearSelectControl.valueChanges])
      .pipe(
        filter(([result]) => !!result.sankey),
        filter(([result, year]) => !!result || !!year),
        map(([result, year]) => result.sankey[year]),
        filter((result) => !!result),
      )
      .subscribe((data) => {
        this.selectedData$.next(data);
        this.perception.registerLegendNames(
          this.caseId,
          data.nodes.map((node) => node.name),
        );
      });
  }
}
