import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  CascadableEntity,
  CascadeAction,
} from 'prosumer-app/services/cascade-controller/cascade-controller.types';
import { DETAIL_TYPE_NAMES } from 'prosumer-app/stores/scenario-detail/scenario-detail.state';
import { Coerce } from 'prosumer-core/utils';

@Component({
  selector: 'prosumer-cascade-confirmation',
  templateUrl: './cascade-confirmation.component.html',
  styleUrls: ['./cascade-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CascadeConfirmationComponent {
  readonly translatedType = this.getTranslatedDetailType();
  readonly translatedAction = this.data.action;

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: CascadableEntity,
    private readonly dialogRef: MatDialogRef<unknown>,
  ) {}

  onOk(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  private getTranslatedDetailType(): string {
    const type = this.resolveDetailType();
    return Coerce.toString(DETAIL_TYPE_NAMES[type], type);
  }

  private resolveDetailType(): string {
    return Coerce.toString(Coerce.toObject(this.data).type);
  }
}
