<form [formGroup]="stationForm">
  <div fxLayout="column" fxLayoutGap="10px">
    <!-- General -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 data-testid="gen-param-title" class="mat-title">
            {{ 'Scenario.labels.general.parameters' | translate }}
            <prosumer-tooltip-anchor
              data-testid="gen-param-tooltip"
              [message]="'wizard_station.wizard_station_general_parameters'"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <!-- General Parameters -->
      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-input
            fxFlex="25%"
            [placeholder]="'Scenario.placeholders.equipments.name' | translate"
            [label]="'Scenario.labels.equipments.name' | translate"
            [control]="stationForm?.controls?.name"
            [required]="true"
            [errorMessageMap]="errorMessages?.name"
            [inputTooltip]="'wizard_station.wizard_station_name'"
            data-testid="station-name"
          ></prosumer-input>
          <prosumer-select
            fxFlex="25%"
            [placeholder]="
              'Scenario.placeholders.general.associatedEnergyVector' | translate
            "
            [label]="
              'Scenario.labels.general.associatedEnergyVector' | translate
            "
            [options]="outputEnergyVectorOptions"
            [control]="stationForm?.controls?.outputEnergyVector"
            [required]="true"
            [errorMessageMap]="errorMessages?.energyVector"
            [tooltip]="'wizard_station.wizard_station_associated_vector'"
            data-testid="associated-vector"
          ></prosumer-select>
          <!-- Scenario Variation -->
          <prosumer-select
            fxFlex="25%"
            *ngIf="scenarioVariationOptions.length > 1"
            [placeholder]="
              'Scenario.placeholders.equipments.scenarioVariation' | translate
            "
            [label]="'Scenario.labels.equipments.scenarioVariation' | translate"
            [options]="scenarioVariationOptions"
            [control]="stationForm?.controls?.scenarioVariation"
            [errorMessageMap]="errorMessages['scenarioVariation']"
            data-testid="station-variation"
          ></prosumer-select>
        </div>
        <prosumer-node-filterchip-component
          *ngIf="data.isMultiNode"
          [required]="true"
          [nodeOptions]="nodeOptions"
          formControlName="nodes"
          [errorMessage]="errorMessages?.node"
          [setSubmitted]="submitted$ | async"
          data-testid="station-node"
        ></prosumer-node-filterchip-component>

        <div
          class="eyes-radio-button-container"
          fxLayout="row"
          fxLayoutGap="15px"
          fxLayoutAlign="start center"
          data-testid="station-source"
        >
          <label>
            {{ 'Scenario.labels.general.source' | translate }}
            <prosumer-tooltip-anchor
              [message]="'wizard_station.wizard_station_source'"
            >
            </prosumer-tooltip-anchor>
          </label>

          <mat-radio-group
            class="load-type"
            fxLayout="row"
            fxLayoutGap="15px"
            formControlName="sourceType"
            data-testid="station-source-options"
          >
            <mat-radio-button
              *ngFor="let option of profileOptions"
              [value]="option?.value"
              [disabled]="true"
            >
              {{ option?.name }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </mat-expansion-panel>

    <!-- Technical -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 data-testid="tech-param-title" class="mat-title">
            {{ 'Scenario.labels.technical.parameters' | translate }}
            <prosumer-tooltip-anchor
              data-testid="tech-param-tooltip"
              [message]="'wizard_station.wizard_station_technical_parameters'"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            fxFlex="25%"
            formControlName="yearlyCapacityLoss"
            inputLabel="Scenario.labels.technical.capacityLossKW"
            errorMessage="Scenario.messages.der.capacityLoss"
            [contextHelpMsg]="'wizard_station.wizard_station_capacity_loss'"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="yearly-capacity-loss"
          >
          </prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            fxFlex="25%"
            formControlName="numberOfConnections"
            inputLabel="Scenario.labels.technical.numberOfConnections"
            errorMessage="Scenario.messages.der.numberOfConnections"
            [contextHelpMsg]="
              'wizard_station.wizard_station_number_of_connections_per_pole'
            "
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="num-of-connections"
          >
          </prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            fxFlex="25%"
            formControlName="powerChargingPerConnection"
            inputLabel="Scenario.labels.technical.powerChargingPerConnection"
            errorMessage="Scenario.messages.der.powerChargingPerConnection"
            [contextHelpMsg]="
              'wizard_station.wizard_station_power_charging_per_connection'
            "
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="power-charging-per-connection"
          >
          </prosumer-yearly-chart-input>
          <div fxFlex="25%"></div>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            fxFlex="25%"
            formControlName="timeSlots"
            inputLabel="Scenario.labels.technical.timeslots"
            errorMessage="Scenario.messages.der.timeSlots"
            [contextHelpMsg]="'wizard_station.wizard_station_timeslots'"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="time-slots"
          >
          </prosumer-yearly-chart-input>
          <prosumer-checkbox
            fxFlex="25%"
            [label]="'Scenario.labels.technical.vehicleToGrid' | translate"
            [control]="stationForm?.controls?.vehicleToGrid"
            [tooltip]="'wizard_station.wizard_station_vehicle_to_grid'"
            [errorMessageMap]="errorMessages?.vehicleToGrid"
            data-testid="vehicle-to-grid"
          >
          </prosumer-checkbox>
          <div fxFlex="25%"></div>
          <div fxFlex="25%"></div>
        </div>
      </div>
    </mat-expansion-panel>

    <!-- Scope3 Emissions Parameters -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3
            data-testid="emissions-param-title"
            class="mat-title"
            mat-dialog-title
          >
            {{ 'Scenario.labels.co2Emissions.parameters' | translate }}
            <prosumer-tooltip-anchor
              data-testid="emissions-param-tooltip"
              [message]="
                'wizard_station.wizard_station_scope3emissions_parameters'
              "
            >
            </prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutGap="10px">
          <!-- Build emissions kW -->
          <prosumer-yearly-chart-input
            formControlName="yearlyBuildEmissionsKw"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [errorMessage]="'Scenario.messages.der.buildEmissionsKw'"
            [inputLabel]="
              'Scenario.labels.co2Emissions.buildEmissionsKw' | translate
            "
            fxFlex="25%"
            [contextHelpMsg]=""
            data-testid="yearly-build-emission-kw"
          >
          </prosumer-yearly-chart-input>
          <!-- Build emissions indivisible -->
          <prosumer-yearly-chart-input
            formControlName="yearlyBuildEmissionsIndivisible"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            [errorMessage]="'Scenario.messages.der.buildEmissionsIndivisible'"
            [inputLabel]="
              'Scenario.labels.co2Emissions.buildEmissionsIndivisible'
                | translate
            "
            fxFlex="25%"
            [contextHelpMsg]=""
            data-testid="yearly-build-emissions-indivisible"
          >
          </prosumer-yearly-chart-input>
          <div fxFlex="25%"></div>
          <div fxFlex="25%"></div>
        </div>
      </div>
    </mat-expansion-panel>

    <!-- Sizing -->
    <mat-expansion-panel [expanded]="true" class="sizing-parameters">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 data-testid="sizing-param-title" class="mat-title">
            {{ 'Scenario.labels.sizing.parameters' | translate }}
            <prosumer-tooltip-anchor
              data-testid="sizing-param-tooltip"
              [message]="'wizard_station.wizard_station_sizing_parameters'"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            fxFlex="25%"
            formControlName="yearlyMinPower"
            inputLabel="Scenario.labels.technical.minPower"
            errorMessage="Scenario.messages.der.minPower"
            [contextHelpMsg]="'wizard_station.wizard_station_minimum_power'"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="yearly-min-power"
          >
          </prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            fxFlex="25%"
            formControlName="yearlyMaxPower"
            inputLabel="Scenario.labels.technical.maxPower"
            errorMessage="Scenario.messages.der.maxPower"
            [contextHelpMsg]="'wizard_station.wizard_station_maximum_power'"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="yearly-max-power"
          >
          </prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            fxFlex="25%"
            formControlName="minConnections"
            inputLabel="Scenario.labels.sizing.minConnections"
            errorMessage="Scenario.messages.der.minConnections"
            [contextHelpMsg]="'wizard_station.wizard_station_min_connections'"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="min-connections"
          >
          </prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            fxFlex="25%"
            formControlName="maxConnections"
            inputLabel="Scenario.labels.sizing.maxConnections"
            errorMessage="Scenario.messages.der.maxConnections"
            [contextHelpMsg]="'wizard_station.wizard_station_max_connections'"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="max-connections"
          >
          </prosumer-yearly-chart-input>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <!-- Forced Investment -->
          <prosumer-checkbox
            fxFlex="25%"
            [label]="'Scenario.labels.sizing.forcedInvestment' | translate"
            [control]="stationForm?.controls?.forcedInvestment"
            [tooltip]="'wizard_station.wizard_station_forced_investment'"
            [errorMessageMap]="errorMessages?.forcedInvestment"
            data-testid="forced-investment"
          >
          </prosumer-checkbox>
          <!-- Existing Asset -->
          <prosumer-checkbox
            fxFlex="25%"
            [label]="'Scenario.labels.sizing.existingAsset' | translate"
            [control]="stationForm?.controls?.existingAsset"
            [tooltip]="'wizard_station.wizard_station_existing_asset'"
            [errorMessageMap]="errorMessages?.existingAsset"
            data-testid="existing-asset"
          >
          </prosumer-checkbox>
          <!-- Capacity Expansion -->
          <prosumer-checkbox
            fxFlex="25%"
            [label]="'Scenario.labels.sizing.capacityExpansion' | translate"
            [control]="stationForm?.controls?.capacityExpansion"
            [tooltip]="'wizard_station.wizard_station_capacity_expansion'"
            [errorMessageMap]="errorMessages?.capacityExpansion"
            data-testid="capacity-expansion"
          >
          </prosumer-checkbox>
          <div fxFlex="25%"></div>
        </div>
      </div>
    </mat-expansion-panel>

    <!-- Economical -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 data-testid="economical-param-title" class="mat-title">
            {{ 'Scenario.labels.economical.parameters' | translate }}
            <prosumer-tooltip-anchor
              data-testid="economical-param-tooltip"
              [message]="'wizard_station.wizard_station_economical_parameters'"
            ></prosumer-tooltip-anchor>
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            fxFlex="25%"
            formControlName="yearlyBuildCost"
            inputLabel="Scenario.labels.technical.buildCost"
            errorMessage="Scenario.messages.der.buildCost"
            [contextHelpMsg]="'wizard_station.wizard_station_build_cost_kW'"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="yearly-build-cost"
          >
          </prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            fxFlex="25%"
            formControlName="yearlyIndivisibleCost"
            inputLabel="Scenario.labels.technical.indivisibleCost"
            errorMessage="Scenario.messages.der.indivisibleCost"
            [contextHelpMsg]="'wizard_station.wizard_station_indivisible_cost'"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="yearly-indivisible-cost"
          >
          </prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            fxFlex="25%"
            formControlName="connectionCost"
            inputLabel="Scenario.labels.economical.connectionCost"
            errorMessage="Scenario.messages.der.connectionCost"
            [contextHelpMsg]="'wizard_station.wizard_station_connection_cost'"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="connection-cost"
          >
          </prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            fxFlex="25%"
            formControlName="yearlyFOAndMCharge"
            inputLabel="Scenario.labels.economical.fOAndMCharge"
            errorMessage="Scenario.messages.der.fOAndMCharge"
            [contextHelpMsg]="'wizard_station.wizard_station_fom_charge_kW'"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="yearly-FOAndMCharge"
          >
          </prosumer-yearly-chart-input>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-yearly-chart-input
            fxFlex="25%"
            formControlName="yearlyFOAndMInstall"
            inputLabel="Scenario.labels.economical.fOAndMPerInstall"
            errorMessage="Scenario.messages.der.fOAndMPerInstall"
            [contextHelpMsg]="'wizard_station.wizard_station_fom_per_install'"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="yearly-FOAndMInstall"
          >
          </prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            fxFlex="25%"
            formControlName="opexPerHour"
            inputLabel="Scenario.labels.economical.opexPerHour"
            errorMessage="Scenario.messages.der.opexPerHour"
            [contextHelpMsg]="'wizard_station.wizard_station_opex_per_hour'"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="opex-per-hour"
          >
          </prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            fxFlex="25%"
            formControlName="yearlyTechnicalLife"
            inputLabel="Scenario.labels.technical.technicalLife"
            errorMessage="Scenario.messages.der.technicalLife"
            [contextHelpMsg]="'wizard_station.wizard_station_technical_life'"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="yearly-tech-life"
          >
          </prosumer-yearly-chart-input>
          <prosumer-yearly-chart-input
            fxFlex="25%"
            formControlName="yearlyDepreciationTime"
            inputLabel="Scenario.labels.technical.depreciationTime"
            errorMessage="Scenario.messages.der.depreciationTime"
            [contextHelpMsg]="'wizard_station.wizard_station_depreciation_time'"
            [startYear]="data?.startYear"
            [endYear]="data?.endYear"
            data-testid="yearly-depreciation-time"
          >
          </prosumer-yearly-chart-input>
        </div>
      </div>
    </mat-expansion-panel>
  </div>

  <button
    data-testid="station-submit"
    #submit
    type="submit"
    [hidden]="true"
  ></button>
</form>
