<div fxLayout="column" fxLayoutGap="20px" class="energy-bal-card">
  <div fxLayout="row" fxLayoutGap="20px">
    <!-- Year -->
    <prosumer-filter-chips
      filterBy="year"
      [dataSource]="consumptions"
      filterTitle="Year"
      (filters)="handleYearChange($event)"
    ></prosumer-filter-chips>

    <prosumer-filter-chips
      filterBy="node"
      [dataSource]="consumptions"
      filterTitle="Node"
      (filters)="handleNodeChange($event)"
    ></prosumer-filter-chips>

    <prosumer-filter-chips
      filterBy="energyVector"
      [dataSource]="consumptions"
      filterTitle="Energy Vector"
      (filters)="handleVectorChange($event)"
    ></prosumer-filter-chips>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-evenly stretch">
    <div fxLayout="column" *ngIf="productionData">
      <div fxLayoutAlign="center center">
        <strong> {{ productionData.name + ' ' + productionData.range }}</strong>
      </div>
      <div fxLayoutAlign="center center">
        {{ 'Total: ' }} {{ productionData.total | number : '1.0-0'
        }}{{ ' ' + unit }}
      </div>
      <div fxLayoutAlign="center center">
        <ngx-charts-pie-chart
          [view]="view"
          [results]="productionData.results"
          [scheme]="colorScheme"
          [gradient]="false"
          [legend]="true"
          [legendPosition]="position"
          [labels]="true"
          [labelFormatting]="productionData.formatting"
          [trimLabels]="false"
          [arcWidth]="arcWidth"
          (select)="onSelect($event, productionData.name)"
        >
          <ng-template #tooltipTemplate let-model="model">
            <h3 style="color: #fff">
              {{ model.name }}: {{ model.value | number : '1.0-0'
              }}{{ ' ' + unit }}({{
                (model.value / productionData.total) * 100 | number : '1.0-0'
              }}%)
            </h3>
          </ng-template>
        </ngx-charts-pie-chart>
      </div>
    </div>

    <div fxLayout="column" *ngIf="consumptionData">
      <div fxLayoutAlign="center center">
        <strong>
          {{ consumptionData.name + ' ' + consumptionData.range }}</strong
        >
      </div>
      <div fxLayoutAlign="center center">
        {{ 'Total: ' }} {{ consumptionData.total | number : '1.0-0'
        }}{{ ' ' + unit }}
      </div>
      <div fxLayoutAlign="center center">
        <ngx-charts-pie-chart
          [view]="view"
          [results]="consumptionData.results"
          [scheme]="colorScheme"
          [gradient]="false"
          [legend]="true"
          [legendPosition]="position"
          [labels]="true"
          [labelFormatting]="consumptionData.formatting"
          [trimLabels]="false"
          [arcWidth]="arcWidth"
          (select)="onSelect($event, consumptionData.name)"
        >
          <ng-template #tooltipTemplate let-model="model">
            <h3 style="color: #fff">
              {{ model.name }}: {{ model.value | number : '1.0-0'
              }}{{ ' ' + unit }}({{
                (model.value / consumptionData.total) * 100 | number : '1.0-0'
              }}%)
            </h3>
          </ng-template>
        </ngx-charts-pie-chart>
      </div>
    </div>
  </div>
</div>
