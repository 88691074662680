import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipList } from '@angular/material/chips';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'prosumer-filter-chips',
  templateUrl: './filter-chips.component.html',
  styleUrls: ['./filter-chips.component.scss'],
})
export class FilterChipsComponent implements OnInit {
  @ViewChild('chipList') chipList: MatChipList;
  @ViewChild('dataInput') dataInput: ElementRef;

  @Input() control = new FormControl();
  @Input() chipColor = 'primary';
  @Input() isReference: boolean;
  @Input() referenceField: string;
  @Input() filterBy: any;
  _dataSource: Array<any> = [];
  @Input() set dataSource(dataSource: Array<any>) {
    this._dataSource = [...dataSource];
  }
  get dataSource(): Array<any> {
    return this._dataSource;
  }
  @Input() filterTitle: string;
  @Input() placeholder: string;

  @Output() selectedDataSource: EventEmitter<any> = new EventEmitter();
  @Output() filters: EventEmitter<any> = new EventEmitter();

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes = ['Enter'];

  filterOptions: Array<any>;
  filteredData: Observable<string[]>;
  selectedFilterOptions: Array<any> = [];
  selectedFilter: Array<any> = [];

  constructor() {}

  ngOnInit() {
    if (!this.filterTitle) {
      this.filterTitle = 'Select...';
    } else {
      this.filterTitle = `Filter by ${this.filterTitle}`;
    }
    this.filterBy = Array.isArray(this.filterBy)
      ? this.filterBy
      : [this.filterBy];
    this.filterOptions = this.getFilterOptions();
    this.filteredData = this.control.valueChanges.pipe(
      startWith(null as string),
      map((data: string | null) =>
        data ? this._filter(data) : this.filterOptions.slice(),
      ),
    );
  }

  add(value: any): void {
    if (value) {
      if (this.isReference) {
        this.selectedFilter.push(value);
        if (this.filterOptions.find(value)) {
          this.selectedFilterOptions.push(value);
        }
        this.filterOptions = this.filterOptions.filter(
          (entry) => entry !== value,
        );
      } else {
        [...this.dataSource].forEach((data) => {
          if (
            this.filterBy.some(
              (filterK) => data[filterK].toLowerCase() === value.toLowerCase(),
            )
          ) {
            this.selectedFilter.push(data);
          }
        });
        if (this.filterOptions.includes(value.toLowerCase())) {
          this.selectedFilterOptions.push(value.toLowerCase());
        }
        this.filterOptions = this.filterOptions.filter(
          (entry) => entry.toLowerCase() !== value.toLowerCase(),
        );
      }

      this.control.setValue(null);
      this.selectedDataSource.emit(this.selectedFilter);
      this.filters.emit(this.selectedFilterOptions);
    }
  }

  remove(filterEntry: any, index: number): void {
    this.selectedFilterOptions.splice(index, 1);
    this.filterOptions.push(filterEntry);
    let filterTemp = [];
    if (this.isReference) {
      filterTemp = this.selectedFilter.filter((data) => filterEntry === data);
    } else {
      this.selectedFilter.forEach((data) => {
        if (
          this.filterBy.every(
            (filterK) =>
              data[filterK].toLowerCase() !== filterEntry.toLowerCase(),
          )
        ) {
          filterTemp.push(data);
        }
      });
    }
    this.selectedFilter = filterTemp;
    this.control.setValue(null);
    this.selectedDataSource.emit(this.selectedFilter);
    this.filters.emit(this.selectedFilterOptions);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let selectedEntry = event.option.value;
    if (this.isReference) {
      selectedEntry = this.dataSource.find(
        (item) => item[this.referenceField] === selectedEntry,
      );
      this.selectedFilter.push(selectedEntry);
    } else {
      [...this.dataSource].forEach((data) => {
        if (
          this.filterBy.some(
            (filterK) =>
              data[filterK].toLowerCase() === selectedEntry.toLowerCase(),
          )
        ) {
          this.selectedFilter.push(data);
        }
      });
    }
    this.selectedFilterOptions.push(selectedEntry);
    this.dataInput.nativeElement.value = '';
    this.filterOptions = this.filterOptions.filter(
      (entry) => entry !== selectedEntry,
    );
    this.control.setValue(null);
    this.selectedDataSource.emit(this.selectedFilter);
    this.filters.emit(this.selectedFilterOptions);
  }

  getFilterOptions(): any[] {
    if (this.isReference) {
      return this.dataSource;
    }
    let dataTemp = [];
    [...this.dataSource].forEach((data) => {
      this.filterBy.forEach((filterK) => dataTemp.push(data[filterK]));
    });
    dataTemp = dataTemp.filter(
      (data, index, arr) => index === arr.indexOf(data),
    );
    return dataTemp;
  }

  onKeydown(event: KeyboardEvent): void {
    if (this.separatorKeysCodes.includes(event.key)) {
      this.add(this.control.value);
    }
  }

  onBlur(): void {
    if (this.addOnBlur) {
      this.add(this.control.value);
    }
  }

  private _filter(value: any): any[] {
    if (this.isReference) {
      return this.filterOptions.filter((data) =>
        data && data.name
          ? data.name.toLowerCase().includes(value.toLowerCase())
          : undefined,
      );
    }
    return this.filterOptions.filter((data) =>
      data.toLowerCase().includes(value.toLowerCase()),
    );
  }

  displayFn(value: any) {
    if (this.isReference) {
      return value.name;
    }
    return value;
  }
}
