import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService, HttpService } from 'prosumer-app/libs/eyes-core';
import { generateEndpoint } from 'prosumer-app/libs/eyes-shared';

import { NotificationsService } from 'prosumer-app/shared/services/notification';
import {
  ScenarioBinActionTypes,
  ScenarioBinGetAction,
  ScenarioBinGetFailureAction,
  ScenarioBinGetSuccessAction,
  ScenarioBinSaveAction,
  ScenarioBinSaveFailureAction,
  ScenarioBinSaveSuccessAction,
  ScenarioBinStoreActions,
  ScenarioDeleteAllBinAction,
  ScenarioDeleteAllBinFailureAction,
  ScenarioDeleteAllBinSuccessAction,
  ScenarioDeleteBinAction,
  ScenarioDeleteBinFailureAction,
  ScenarioDeleteBinSuccessAction,
} from './scenarioBin-store.actions';
import { ScenarioBinData } from './scenarioBin-store.model';
import { ScenarioBinStore } from './scenarioBin-store.service';

@Injectable()
export class ScenarioBinStoreEffects {
  @Effect()
  get$: Observable<ScenarioBinStoreActions> = this._actions$.pipe(
    ofType(ScenarioBinActionTypes.GET_SCENARIO_BIN),
    mergeMap((action: ScenarioBinGetAction) =>
      this._scenarioBinStore.cachedData$.pipe(
        take(1),
        mergeMap((cachedData: Map<string, ScenarioBinData>) => {
          if (
            cachedData[
              action.payload.location.concat('-', action.payload.localId)
            ] !== undefined
          ) {
            return of(
              new ScenarioBinGetSuccessAction(
                cachedData[
                  action.payload.location.concat('-', action.payload.localId)
                ],
                'Cache Success',
                false,
                true,
              ),
            );
          } else {
            let endpoint: string;
            if (action.payload.isCustom) {
              endpoint =
                this._config.api.endpoints['scenario']['getLoadsCustom'];
            } else {
              endpoint = this._config.api.endpoints['scenario']['getLoadsLib'];
            }
            return this._http
              .get(
                generateEndpoint(
                  this._config.api.baseUrl,
                  endpoint,
                  action.payload.projectId,
                  action.payload.caseId,
                  action.payload.scenarioId,
                  action.payload.location,
                  action.payload.localId,
                ),
              )
              .pipe(
                map(
                  (response) =>
                    new ScenarioBinGetSuccessAction(
                      response,
                      'HTTP Success',
                      false,
                      false,
                    ),
                ),
                catchError((response: HttpErrorResponse) =>
                  of(
                    new ScenarioBinGetFailureAction(
                      action.payload.projectId,
                      action.payload.caseId,
                      action.payload.scenarioId,
                      action.payload.location,
                      action.payload.localId,
                      action.payload.isCustom,
                      (response && response.error && response.error.error) ||
                        this._translate.instant(
                          'Scenario.messages.loads.getFailure',
                        ),
                    ),
                  ),
                ),
              );
          }
        }),
      ),
    ),
  );

  @Effect({ dispatch: false })
  getFailure$: Observable<ScenarioBinStoreActions> = this._actions$.pipe(
    ofType(ScenarioBinActionTypes.GET_SCENARIO_BIN_FAILURE),
    mergeMap((action: ScenarioBinGetFailureAction) =>
      this._notification
        .showError(action.payload.error, 'Retry')
        .onAction()
        .pipe(
          tap(() =>
            this._scenarioBinStore.get(
              action.payload.projectId,
              action.payload.caseId,
              action.payload.scenarioId,
              action.payload.location,
              action.payload.localId,
              action.payload.isCustom,
            ),
          ),
        ),
    ),
  );

  @Effect({ dispatch: false })
  getSuccess$: Observable<ScenarioBinStoreActions> = this._actions$.pipe(
    ofType(ScenarioBinActionTypes.GET_SCENARIO_BIN_SUCCESS),
    map((action: ScenarioBinGetSuccessAction) => {
      const payload = action.payload;
      if (payload.notify) {
        this._notification.showSuccess(action.payload.message);
      }
    }),
  );

  @Effect()
  delete$: Observable<ScenarioBinStoreActions> = this._actions$.pipe(
    ofType(ScenarioBinActionTypes.DELETE_SCENARIO_BIN),
    mergeMap((action: ScenarioDeleteBinAction) =>
      this._http
        .delete(
          generateEndpoint(
            this._config.api.baseUrl,
            this._config.api.endpoints['scenario']['deleteBinary'],
            action.payload.projectId,
            action.payload.caseId,
            action.payload.scenarioId,
            action.payload.location,
            action.payload.localId,
          ),
        )
        .pipe(
          map(
            (response) =>
              new ScenarioDeleteBinSuccessAction(
                action.payload.projectId,
                action.payload.caseId,
                action.payload.scenarioId,
                this._translate.instant(
                  'Scenario.messages.loads.deleteSuccess',
                ),
                false,
              ),
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              new ScenarioDeleteBinFailureAction(
                action.payload.projectId,
                action.payload.caseId,
                action.payload.scenarioId,
                action.payload.location,
                action.payload.localId,
                (response && response.error && response.error.error) ||
                  this._translate.instant(
                    'Scenario.messages.loads.deleteFailure',
                  ),
              ),
            ),
          ),
        ),
    ),
  );

  @Effect({ dispatch: false })
  deleteSuccess$: Observable<ScenarioBinStoreActions> = this._actions$.pipe(
    ofType(ScenarioBinActionTypes.DELETE_SCENARIO_BIN_SUCCESS),
    tap((action: ScenarioDeleteBinSuccessAction) => {
      const payload = action.payload;
      if (payload.notify) {
        this._notification.showSuccess(payload.message);
      }
    }),
  );

  @Effect({ dispatch: false })
  deleteFailure$: Observable<ScenarioBinStoreActions> = this._actions$.pipe(
    ofType(ScenarioBinActionTypes.DELETE_SCENARIO_BIN_FAILURE),
    tap((action: ScenarioDeleteBinFailureAction) =>
      this._notification.showError(action.payload.error),
    ),
  );

  @Effect()
  save$: Observable<ScenarioBinStoreActions> = this._actions$.pipe(
    ofType(ScenarioBinActionTypes.SAVE_SCENARIO_BIN),
    mergeMap((action: ScenarioBinSaveAction) => {
      const endpoint = this._config.api.endpoints['scenario']['createBinary'];
      return this._http
        .put(
          generateEndpoint(
            this._config.api.baseUrl,
            endpoint,
            action.payload.projectId,
            action.payload.caseId,
            action.payload.scenarioId,
            action.payload.location,
            action.payload.localId,
          ),
          action.payload.data,
        )
        .pipe(
          map(
            (response) =>
              new ScenarioBinSaveSuccessAction(
                response,
                this._translate.instant('Scenario.messages.loads.saveSuccess'),
                false,
              ),
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              new ScenarioBinSaveFailureAction(
                action.payload.projectId,
                action.payload.caseId,
                action.payload.scenarioId,
                action.payload.location,
                action.payload.localId,
                (response && response.error && response.error.error) ||
                  this._translate.instant(
                    'Scenario.messages.loads.saveFailure',
                  ),
              ),
            ),
          ),
        );
    }),
  );

  @Effect({ dispatch: false })
  saveSuccess$: Observable<ScenarioBinStoreActions> = this._actions$.pipe(
    ofType(ScenarioBinActionTypes.SAVE_SCENARIO_BIN_SUCCESS),
    tap((action: ScenarioBinSaveSuccessAction) => {
      if (action.payload.notify) {
        this._notification.showSuccess(action.payload.message);
      }
    }),
  );

  @Effect({ dispatch: false })
  saveFailure$: Observable<ScenarioBinStoreActions> = this._actions$.pipe(
    ofType(ScenarioBinActionTypes.SAVE_SCENARIO_BIN_FAILURE),
    tap((action: ScenarioBinSaveFailureAction) =>
      this._notification.showError(action.payload.error),
    ),
  );

  @Effect()
  deleteAll$: Observable<ScenarioBinStoreActions> = this._actions$.pipe(
    ofType(ScenarioBinActionTypes.DELETE_ALL_SCENARIO_BIN),
    mergeMap((action: ScenarioDeleteAllBinAction) => {
      const body = {
        command: 'delete',
        locations: action.payload.data,
      };
      return this._http
        .patch(
          generateEndpoint(
            this._config.api.baseUrl,
            this._config.api.endpoints['scenario']['deleteAllBinary'],
            action.payload.projectId,
            action.payload.caseId,
            action.payload.scenarioId,
          ),
          body,
        )
        .pipe(
          map(
            (response) =>
              new ScenarioDeleteAllBinSuccessAction(
                action.payload.projectId,
                action.payload.caseId,
                action.payload.scenarioId,
                action.payload.data,
                this._translate.instant(
                  'Scenario.messages.loads.deleteAllSuccess',
                ),
                false,
              ),
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              new ScenarioDeleteAllBinFailureAction(
                action.payload.projectId,
                action.payload.caseId,
                action.payload.scenarioId,
                action.payload.data,
                (response && response.error && response.error.error) ||
                  this._translate.instant(
                    'Scenario.messages.loads.deleteAllFailure',
                  ),
              ),
            ),
          ),
        );
    }),
  );

  @Effect({ dispatch: false })
  deleteAllSuccess$: Observable<ScenarioBinStoreActions> = this._actions$.pipe(
    ofType(ScenarioBinActionTypes.DELETE_SCENARIO_BIN_SUCCESS),
    tap((action: ScenarioDeleteAllBinSuccessAction) => {
      const payload = action.payload;
      if (payload.notify) {
        this._notification.showSuccess(payload.message);
      }
    }),
  );

  @Effect({ dispatch: false })
  deleteAllFailure$: Observable<ScenarioBinStoreActions> = this._actions$.pipe(
    ofType(ScenarioBinActionTypes.DELETE_ALL_SCENARIO_BIN_FAILURE),
    tap((action: ScenarioDeleteAllBinFailureAction) =>
      this._notification.showError(action.payload.error),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _config: ConfigService,
    private _http: HttpService,
    private _scenarioBinStore: ScenarioBinStore,
    private _notification: NotificationsService,
    private _translate: TranslateService,
  ) {}
}
