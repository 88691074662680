<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="16px">
  <!-- filters and dispatch controls -->
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="32px">
    <div fxLayout="row" fxLayoutGap="8px" [formGroup]="filterForm">
      <prosumer-select
        [placeholder]="'Scenario.labels.simulationResults.year' | translate"
        [label]="'Scenario.labels.simulationResults.year' | translate"
        [control]="filterForm?.controls?.year"
        [options]="yearOptions$ | async"
        [attr.data-testid]="'dispatch-filter-year'"
      >
      </prosumer-select>
      <prosumer-select
        [placeholder]="'Scenario.labels.simulationResults.node' | translate"
        [label]="'Scenario.labels.simulationResults.node' | translate"
        [control]="filterForm?.controls?.node"
        [options]="nodeOptions$ | async"
        [attr.data-testid]="'dispatch-filter-node'"
      >
      </prosumer-select>
      <prosumer-select
        [placeholder]="
          'Scenario.labels.simulationResults.energyVector' | translate
        "
        [label]="'Scenario.labels.simulationResults.energyVector' | translate"
        [control]="filterForm?.controls?.energyVector"
        [options]="energyVectorOptions$ | async"
        [attr.data-testid]="'dispatch-filter-energy-vector'"
      >
      </prosumer-select>
    </div>
    <mat-button-toggle-group
      aria-label="Dispatch Options"
      [value]="dispatchType$ | async"
      [attr.data-testid]="'dispatch-options'"
    >
      <mat-button-toggle
        *ngFor="let dispatch of dispatchOptions$ | async"
        [value]="dispatch"
        (change)="onValueChange($event)"
        [attr.data-testid]="'dispatch-option-selector-' + dispatch"
      >
        {{ dispatch }}
        <prosumer-tooltip-anchor
          *ngIf="dispatch === 'Raw'"
          [message]="'output_dispatch.output_dispatch_raw'"
          [attr.data-testid]="'dispatch-raw-tooltip'"
          [tooltipPosition]="'left'"
        ></prosumer-tooltip-anchor>
        <prosumer-tooltip-anchor
          *ngIf="dispatch === 'Avg. Day'"
          [message]="'output_dispatch.output_dispatch_avg_day'"
          [attr.data-testid]="'dispatch-daily-tooltip'"
          [tooltipPosition]="'left'"
        ></prosumer-tooltip-anchor>
        <prosumer-tooltip-anchor
          *ngIf="dispatch === 'Avg. Week'"
          [message]="'output_dispatch.output_dispatch_weekly'"
          [attr.data-testid]="'dispatch-weekly-tooltip'"
          [tooltipPosition]="'left'"
        ></prosumer-tooltip-anchor>
        <prosumer-tooltip-anchor
          *ngIf="dispatch === 'Monthly'"
          [message]="'output_dispatch.output_dispatch_monthly'"
          [attr.data-testid]="'dispatch-monthly-tooltip'"
          [tooltipPosition]="'left'"
        ></prosumer-tooltip-anchor>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <!-- charts and sharts -->
  <div fxLayout="column" fxLayoutAlign="start stretch">
    <div
      *ngIf="
        (loaded$ | async) &&
        (productionHasData$ | async) === false &&
        (consumptionHasData$ | async) === false
      "
      class="prosumer-dispatch-message"
    >
      <strong>{{ 'Result.messages.noChartsToDisplay' | translate }}</strong>
    </div>
    <div *ngIf="loading$ | async" class="prosumer-dispatch-spinner">
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <mat-spinner color="accent" diameter="25"></mat-spinner>
        <span>{{ 'Result.messages.loadingChart' | translate }}...</span>
      </div>
    </div>
    <div
      *ngIf="animationEnded$ | async"
      [ngSwitch]="dispatchType$ | async"
      class="prosumer-dispatch-container"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="32px"
    >
      <ng-container *ngSwitchCase="'Raw'">
        <div fxLayout="row" fxLayoutAlign="space-between stretch">
          <prosumer-raw-dispatch
            fxFlex
            type="production"
            [chartTitle]="
              'Scenario.labels.simulationResults.types.production' | translate
            "
            [caseId]="caseId"
            (dataLoaded)="onDataLoaded($event, 'production')"
            [attr.data-testid]="'dispatch-raw-production-graph'"
            scenarioName="{{ scenarioName }}"
          >
          </prosumer-raw-dispatch>
          <prosumer-raw-dispatch
            fxFlex
            type="consumption"
            [chartTitle]="
              'Scenario.labels.simulationResults.types.consumption' | translate
            "
            [caseId]="caseId"
            (dataLoaded)="onDataLoaded($event, 'consumption')"
            [attr.data-testid]="'dispatch-raw-consumption-graph'"
            scenarioName="{{ scenarioName }}"
          >
          </prosumer-raw-dispatch>
        </div>
        <mat-divider> </mat-divider>

        <prosumer-storage-raw-dispatch
          [caseId]="caseId"
          [storageOptions]="storageOptions$ | async"
          (dataLoaded)="onDataLoaded($event, 'storage')"
          [attr.data-testid]="'dispatch-raw-storage-graph'"
          scenarioName="{{ scenarioName }}"
        >
        </prosumer-storage-raw-dispatch>
        <div
          class="prosumer-slider"
          *ngIf="
            (loading$ | async) === false &&
            (productionHasData$ | async) &&
            (consumptionHasData$ | async)
          "
        >
          <ng5-slider
            [options]="sliderOptions$ | async"
            [value]="sliderInitMinValue"
            [highValue]="sliderInitMaxValue"
            (valueChange)="onMinChange($event)"
            (highValueChange)="onMaxChange($event)"
            [attr.data-testid]="'dispatch-raw-slider'"
          >
          </ng5-slider>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'Avg. Day'">
        <div fxLayout="row" fxLayoutAlign="space-between stretch">
          <prosumer-daily-dispatch
            fxFlex
            type="production"
            [chartTitle]="
              'Scenario.labels.simulationResults.types.production' | translate
            "
            [caseId]="caseId"
            (dataLoaded)="onDataLoaded($event, 'production')"
            [attr.data-testid]="'dispatch-daily-production-graph'"
            scenarioName="{{ scenarioName }}"
          >
          </prosumer-daily-dispatch>
          <prosumer-daily-dispatch
            fxFlex
            type="consumption"
            [chartTitle]="
              'Scenario.labels.simulationResults.types.consumption' | translate
            "
            [caseId]="caseId"
            (dataLoaded)="onDataLoaded($event, 'consumption')"
            [attr.data-testid]="'dispatch-daily-consumption-graph'"
            scenarioName="{{ scenarioName }}"
          >
          </prosumer-daily-dispatch>
        </div>
        <mat-divider> </mat-divider>

        <prosumer-storage-daily-dispatch
          [caseId]="caseId"
          [storageOptions]="storageOptions$ | async"
          (dataLoaded)="onDataLoaded($event, 'storage')"
          [attr.data-testid]="'dispatch-daily-storage-graph'"
          scenarioName="{{ scenarioName }}"
        >
        </prosumer-storage-daily-dispatch>

        <div
          class="prosumer-slider"
          *ngIf="
            (loading$ | async) === false &&
            (productionHasData$ | async) &&
            (consumptionHasData$ | async)
          "
        >
          <ng5-slider
            [options]="sliderOptions$ | async"
            [value]="sliderInitMinValue"
            [highValue]="sliderInitMaxValue"
            (valueChange)="onMinChange($event)"
            (highValueChange)="onMaxChange($event)"
            [attr.data-testid]="'dispatch-daily-slider'"
          >
          </ng5-slider>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'Avg. Week'">
        <div fxLayout="row" fxLayoutAlign="space-between stretch">
          <prosumer-weekly-dispatch
            fxFlex
            type="production"
            [chartTitle]="
              'Scenario.labels.simulationResults.types.production' | translate
            "
            [caseId]="caseId"
            (dataLoaded)="onDataLoaded($event, 'production')"
            [attr.data-testid]="'dispatch-weekly-production-graph'"
            scenarioName="{{ scenarioName }}"
          >
          </prosumer-weekly-dispatch>
          <prosumer-weekly-dispatch
            fxFlex
            type="consumption"
            [chartTitle]="
              'Scenario.labels.simulationResults.types.consumption' | translate
            "
            [caseId]="caseId"
            (dataLoaded)="onDataLoaded($event, 'consumption')"
            [attr.data-testid]="'dispatch-weekly-consumption-graph'"
            scenarioName="{{ scenarioName }}"
          >
          </prosumer-weekly-dispatch>
        </div>
        <mat-divider> </mat-divider>

        <prosumer-storage-weekly-dispatch
          [caseId]="caseId"
          [storageOptions]="storageOptions$ | async"
          (dataLoaded)="onDataLoaded($event, 'storage')"
          [attr.data-testid]="'dispatch-weekly-storage-graph'"
          scenarioName="{{ scenarioName }}"
        >
        </prosumer-storage-weekly-dispatch>

        <div
          class="prosumer-slider"
          *ngIf="
            (loading$ | async) === false &&
            (productionHasData$ | async) &&
            (consumptionHasData$ | async)
          "
        >
          <ng5-slider
            [options]="sliderOptions$ | async"
            [value]="sliderInitMinValue"
            [highValue]="sliderInitMaxValue"
            (valueChange)="onMinChange($event)"
            (highValueChange)="onMaxChange($event)"
            [attr.data-testid]="'dispatch-weekly-slider'"
          >
          </ng5-slider>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'Monthly'">
        <div fxLayout="row" fxLayoutAlign="space-between stretch">
          <prosumer-monthly-dispatch
            fxFlex
            type="production"
            [chartTitle]="
              'Scenario.labels.simulationResults.types.production' | translate
            "
            [caseId]="caseId"
            (dataLoaded)="onDataLoaded($event, 'production')"
            [attr.data-testid]="'dispatch-monthly-production-graph'"
            scenarioName="{{ scenarioName }}"
          >
          </prosumer-monthly-dispatch>
          <prosumer-monthly-dispatch
            fxFlex
            type="consumption"
            [chartTitle]="
              'Scenario.labels.simulationResults.types.consumption' | translate
            "
            [caseId]="caseId"
            (dataLoaded)="onDataLoaded($event, 'consumption')"
            [attr.data-testid]="'dispatch-monthly-consumption-graph'"
            scenarioName="{{ scenarioName }}"
          >
          </prosumer-monthly-dispatch>
        </div>
        <mat-divider> </mat-divider>

        <prosumer-storage-monthly-dispatch
          [caseId]="caseId"
          [storageOptions]="storageOptions$ | async"
          (dataLoaded)="onDataLoaded($event, 'storage')"
          [attr.data-testid]="'dispatch-monthly-storage-graph'"
          scenarioName="{{ scenarioName }}"
        >
        </prosumer-storage-monthly-dispatch>
      </ng-container>
    </div>
  </div>
</div>
