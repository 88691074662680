<div fxLayout="column" fxLayoutGap="30px" class="equipment-card">
  <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-around">
    <!-- Filter-chip input per filter type -->
    <prosumer-filter-chips
      *ngFor="let filterMeta of filterMetaData"
      fxFlex="32%"
      [filterTitle]="filterMeta.filterName"
      [filterBy]="filterMeta.filterKey"
      [dataSource]="data"
      (selectedDataSource)="updateDataByFilter($event, filterMeta)"
    >
    </prosumer-filter-chips>
  </div>
  <div fxLayout="row" fxLayoutGap="20px">
    <div fxLayoutGap="20px" class="prosumer-equipment-property-types">
      <!-- Buttons -->
      <mat-button-toggle-group
        #group="matButtonToggleGroup"
        name="fontStyle"
        aria-label="Visualization Options"
        [value]="selectedVisualOption$ | async"
        vertical="true"
        fxLayout="row"
        fxLayout.gt-xs="column"
      >
        <mat-button-toggle
          *ngFor="let option of visualOptions$ | async"
          [value]="option"
          (change)="onVisualizationTypeChange($event)"
        >
          {{ option }}
          <prosumer-tooltip-anchor [message]="'TBD'"></prosumer-tooltip-anchor>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="32px">
      <strong>{{ _equipmentService?.chartTitle$ | async }}</strong>
      <prosumer-cumulative-visualization
        fxFlex
        *ngIf="isCumulativeGraphSelected$ | async; else incremental"
      ></prosumer-cumulative-visualization>
      <ng-template #incremental>
        <prosumer-incremental-visualization
          fxFlex
        ></prosumer-incremental-visualization>
      </ng-template>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="end end">
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="4px">
      <div class="inc-cumu-toggle">
        <mat-label ngClass="slider-left-label">{{ 'Incremental' }}</mat-label>
        <mat-slide-toggle
          (change)="onGraphTypeChange($event)"
          color="primary"
          >{{ 'Cumulative' }}</mat-slide-toggle
        >
      </div>

      <div>
        <mat-label ngClass="slider-left-label">{{ 'All Years' }}</mat-label>
        <mat-slide-toggle
          (change)="onYearsTypeToShowChange($event)"
          color="primary"
          >{{ 'Optimized Years' }}</mat-slide-toggle
        >
      </div>
    </div>
  </div>
</div>
