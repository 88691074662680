<h3 class="energy-vector-list-title" *ngIf="hasCustomEV$ | async">
  {{ name }}
</h3>
<div
  *ngIf="hasCustomEV$ | async"
  class="energy-vector-list"
  fxLayout="row wrap"
  fxLayoutGap="10px"
  fxLayoutAlign="start center"
  [ngClass]="{ disabled: disabled }"
  [attr.data-testid]="'custom-energy-vector-list'"
>
  <div
    *ngFor="let energyVector of energyVectors"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="5px"
    class="energy-vector-item"
    [tabindex]="readonly ? -1 : 0"
    (mouseover)="onShowDelete(energyVector)"
    (mouseout)="onHideDelete(energyVector)"
    (focus)="onShowDelete(energyVector)"
  >
    <mat-icon
      [svgIcon]="energyVector?.type | lowercase"
      class="energy-vector-icon"
      [attr.data-testid]="'custom-energy-vector-icon-' + energyVector?.name"
    ></mat-icon>
    <h4 [attr.data-testid]="'custom-energy-vector-label-' + energyVector?.name">
      {{ energyVector?.name }}
    </h4>
    <div class="button-container" [hidden]="readonly">
      <button
        *ngIf="!waitingResponse[energyVector.value]"
        mat-icon-button
        class="delete-button"
        type="button"
        color="warn"
        [ngClass]="{
          visible: deleteVisibility[energyVector?.name + energyVector?.type]
        }"
        [disabled]="disabled"
        (click)="onDelete(energyVector)"
        (focusout)="onHideDelete(energyVector)"
        [attr.data-testid]="'custom-energy-vector-delete-' + energyVector?.name"
      >
        <mat-icon aria-label="delete">close</mat-icon>
      </button>

      <div
        *ngIf="waitingResponse[energyVector.value]"
        class="mat-spinner-container"
      >
        <mat-spinner
          [color]="spinnerColor"
          [diameter]="spinnerDiameter"
          data-testId="spinner"
        ></mat-spinner>
      </div>
    </div>
  </div>
</div>
