import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Scenario } from 'prosumer-app/+scenario/models';
import {
  ActionTypes,
  ScenarioFacadeService,
} from 'prosumer-app/+scenario/state';
import { Utils } from 'prosumer-app/core';
import { isScenarioEditable, NameValidator } from 'prosumer-app/shared';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

type InfoStyle = 'header' | 'content';

@UntilDestroy()
@Component({
  selector: 'prosumer-scenario-name',
  templateUrl: './scenario-name.component.html',
  styleUrls: ['./scenario-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioNameComponent {
  @Input() scenario: Scenario | undefined;
  @Input() editable = false;
  readonly nameControl = new FormControl('', this.getScenarioNameValidators());
  readonly updating$ = this.selectCurrentScenarioUpdating();
  clicked = false;
  updating = false;
  @Input() infoStyle: InfoStyle = 'header';
  @Input() hovering = false;
  @Output() scenarioNameClick = new EventEmitter();
  @Input() allowScenarioNameClick = false;

  constructor(private readonly scenarios: ScenarioFacadeService) {
    this.subscribeToCurrentScenarioUpdatingForFormDisabling();
    this.subscribeToUpdateSuccessForEditViewSwitching();
  }

  @HostListener('document:click', ['$event'])
  documentClick() {
    if (!this.updating) {
      this.onSwitchToView();
    }
  }

  isEditable() {
    return isScenarioEditable({
      status: this.scenario.status,
      scenarioType: this.scenario.scenarioType,
    });
  }

  confirmChanges(): void {
    this.scenarios.updateScenarioFromCaseView(this.spreadScenarioWithNewName());
  }

  onClickScenarioName() {
    this.scenarioNameClick.emit();
    if (this.infoStyle === 'header') {
      this.switchToEdit();
    }
  }

  switchToEdit(): void {
    if (!this.editable) {
      return;
    }
    this.nameControl.patchValue(this.scenario.name);
    this.clicked = true;
  }

  private onSwitchToView(): void {
    this.clicked = false;
    this.hovering = false;
    this.nameControl.markAsPristine();
  }

  private getScenarioNameValidators(): ValidatorFn[] {
    return [Validators.required, NameValidator.validWithCore()];
  }

  private subscribeToUpdateSuccessForEditViewSwitching(): void {
    this.selectUpdateScenarioFromCaseViewSuccessAction()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.onSwitchToView();
      });
  }

  private selectUpdateScenarioFromCaseViewSuccessAction(): Observable<unknown> {
    return this.scenarios.actionSubject$.pipe(
      filter(
        (action) =>
          action.type === ActionTypes.UPDATE_SCENARIO_FROM_CASE_VIEW_SUCCESS,
      ),
    );
  }

  private spreadScenarioWithNewName(): Scenario {
    return { ...this.scenario, name: this.nameControl.value };
  }

  private subscribeToCurrentScenarioUpdatingForFormDisabling(): void {
    this.selectCurrentScenarioUpdating()
      .pipe(untilDestroyed(this))
      .subscribe((updating) => this.onUpdating(updating));
  }

  private onUpdating(updating: boolean): void {
    this.updating = updating;
    if (updating) {
      this.nameControl.disable();
    } else {
      this.nameControl.enable();
    }
  }

  private selectCurrentScenarioUpdating(): Observable<boolean> {
    return this.scenarios.selectedData$.pipe(
      map((scenario) => Utils.resolveToEmptyObject(scenario).updating),
    );
  }
}
