import { CashflowData, YEARLY_DETAILS_REGEX_PATTERN } from '../models';
import { BaseExtractor, YearlyDetailsExtractor } from './base.extractor';

export class CashflowExtractor
  extends YearlyDetailsExtractor<CashflowData>
  implements BaseExtractor<CashflowData>
{
  extract(resultsJson: unknown): CashflowData[] {
    return !!resultsJson
      ? [
          ...this.extractCumulative(resultsJson, 'cumulative_cashflow'),
          ...this.extractIncremental(resultsJson, 'incremental_cashflow'),
        ]
      : [];
  }

  protected parseDetails(details: string): Partial<CashflowData> {
    const detailList = details.match(YEARLY_DETAILS_REGEX_PATTERN);
    const cleanedUp = detailList.map((detail) =>
      detail.replace(/\'/, '').replace(/\'/, ''),
    );
    return {
      type: this.getElement(0, cleanedUp),
      name: this.getElement(1, cleanedUp),
      node: this.getElement(2, cleanedUp),
      category: this.lowercaseCategory(this.getElement(3, cleanedUp)),
    };
  }
}
