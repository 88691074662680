import { EnergyGridConnection } from 'prosumer-app/+scenario/models';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { WithProfilesCreator } from '../creator';
import { ProfileStore } from '../profile';
import { FilterCriteria, TDBDataQuery } from '../tdb-data';
import { EnergyGridConnectionInfo } from './energy-grid-connection.state';
import { EnergyGridConnectionStore } from './energy-grid-connection.store';

@Injectable({ providedIn: 'root' })
export class EGCCreator extends WithProfilesCreator<EnergyGridConnection> {
  constructor(
    readonly egcs: EnergyGridConnectionStore,
    readonly profiles: ProfileStore,
    readonly keeper: ActiveKeeperService,
    private readonly _tdbQuery: TDBDataQuery,
  ) {
    super(profiles, keeper);
  }

  createEntity(data: EnergyGridConnection): Observable<unknown> {
    return this.egcs.createEGC(this._injectTdbFilters(data));
  }

  updateEntity(
    data: EnergyGridConnection,
  ): Observable<EnergyGridConnectionInfo> {
    return this.egcs.updateEGC(this._injectTdbFilters(data));
  }

  private _injectTdbFilters(egc: EnergyGridConnection) {
    const tdbFilters = this.getProfileFilters(egc.id);
    if (JSON.stringify(tdbFilters) === '{}') return egc;
    return { ...egc, tdbFilters };
  }

  private getProfileFilters(id: string): FilterCriteria {
    const tdbFilters = { ...this._tdbQuery.getActiveFilters() };
    return this._purifyFilters(tdbFilters);
  }

  private _purifyFilters(tdbFilters: FilterCriteria): FilterCriteria {
    const { energyVector, commodity, geography, scenario, unit, granularity } =
      tdbFilters;
    return {
      commodity: energyVector || commodity,
      geography,
      scenario,
      unit,
      granularity,
    };
  }
}
