import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { FileUploadDragAndDropComponent } from './file-upload-drag-drop.component';

@NgModule({
  declarations: [FileUploadDragAndDropComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, FlexLayoutModule],
  exports: [FileUploadDragAndDropComponent],
})
export class FileUploadModule {}
