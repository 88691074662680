<form [formGroup]="gamsUploadForm" class="mat-card custom-card">
  <div class="custom-card-title">
    {{ 'Scenario.upload.gams.formTitle' | translate }}
  </div>

  <mat-label>{{ 'Scenario.upload.gams.label' | translate }}</mat-label>

  <prosumer-file-upload-drag-drop
    ngDefaultControl
    [formControl]="gamsUploadForm?.controls?.filesInput"
    [hint]="'Scenario.upload.gams.hint' | translate"
    [acceptedFormats]="fileFormats"
    [hideStatus]="true"
  >
  </prosumer-file-upload-drag-drop>
  <mat-error
    *ngFor="let error of getErrors(filesInputCtrl.errors)"
    [innerHtml]="gamsUploadErrorMessages()[error]"
  >
  </mat-error>

  <prosumer-sparkline-table
    class="custom-sparkline-table"
    [columnsDef]="columnDef"
    [data]="gamsFiles$ | async"
    [hideAdd]="true"
    [hideEdit]="true"
    [hideDelete]="false"
    [hideView]="true"
    [searchInput]="false"
    (delete)="onDelete($event)"
    [hideMessages]="true"
    [hideHeader]="true"
  >
  </prosumer-sparkline-table>
</form>
