import { Directive, TemplateRef, ViewChild } from '@angular/core';
import { MtxGridColumn } from '@ng-matero/extensions';
import { ScenarioListColumn, ScenarioListConfig } from './scenario-list.tokens';

@Directive()
export class ScenarioListBase {
  @ViewChild('name', { static: true }) nameTemplate: TemplateRef<unknown>;
  @ViewChild('run', { static: true }) runTemplate: TemplateRef<unknown>;
  @ViewChild('launchDate', { static: true })
  launchDateTemplate: TemplateRef<unknown>;
  @ViewChild('duration', { static: true })
  durationTemplate: TemplateRef<unknown>;
  @ViewChild('actions', { static: true }) actionsTemplate: TemplateRef<unknown>;
  @ViewChild('status', { static: true }) statusTemplate: TemplateRef<unknown>;
  @ViewChild('createdDate', { static: true })
  createdDateTemplate: TemplateRef<unknown>;
  @ViewChild('case', { static: true }) caseTemplate: TemplateRef<unknown>;
  @ViewChild('project', { static: true }) projectTemplate: TemplateRef<unknown>;
  @ViewChild('tco', { static: true }) tcoTemplate: TemplateRef<unknown>;
  @ViewChild('co2FinalYear', { static: true })
  co2FinalYearTemplate: TemplateRef<unknown>;
  @ViewChild('log', { static: true }) logTemplate: TemplateRef<unknown>;

  private get columnDefBag(): Record<ScenarioListColumn, MtxGridColumn> {
    return {
      [ScenarioListColumn.name]: this.createNameColumn(),
      [ScenarioListColumn.runHistory]: this.createRunColumn(),
      [ScenarioListColumn.launchDate]: this.createLaunchColumn(),
      [ScenarioListColumn.duration]: this.createDurationColumn(),
      [ScenarioListColumn.actions]: this.createActionsColumn(),
      [ScenarioListColumn.status]: this.createStatusColumn(),
      [ScenarioListColumn.createdDate]: this.createCreatedDateColumn(),
      [ScenarioListColumn.log]: this.createLogColumn(),
      [ScenarioListColumn.case]: this.createCaseColumn(),
      [ScenarioListColumn.project]: this.createProjectColumn(),
      [ScenarioListColumn.tco]: this.createTCOColumn(),
      [ScenarioListColumn.co2FinalYear]: this.createCO2FinalYearColumn(),
    };
  }

  createMtxGridColumns(config: ScenarioListConfig): MtxGridColumn[] {
    return config.columns.map((column) => this.columnDefBag[column]);
  }

  private createTCOColumn(): MtxGridColumn {
    return {
      field: 'globalTotalCost',
      header: 'TCO',
      cellTemplate: this.tcoTemplate,
      sortable: true,
    };
  }

  private createCO2FinalYearColumn(): MtxGridColumn {
    return {
      field: 'globalFinalCO2Emissions',
      header: 'CO2 (Final Year)',
      cellTemplate: this.co2FinalYearTemplate,
      sortable: true,
    };
  }

  private createNameColumn(): MtxGridColumn {
    return {
      field: 'name',
      header: 'Name',
      cellTemplate: this.nameTemplate,
      description: 'scenarios.scenario_name',
      sortable: true,
    };
  }

  private createRunColumn(): MtxGridColumn {
    return {
      field: 'run',
      header: 'Status',
      cellTemplate: this.runTemplate,
      description: 'cases.case_run',
    };
  }

  private createLaunchColumn(): MtxGridColumn {
    return {
      field: 'launchDate',
      header: 'Launch Date',
      cellTemplate: this.launchDateTemplate,
      description: 'scenarios.scenario_launch_date',
      sortable: true,
    };
  }

  private createDurationColumn(): MtxGridColumn {
    return {
      field: 'duration',
      header: 'Duration',
      cellTemplate: this.durationTemplate,
      description: 'scenarios.scenario_duration',
      sortable: true,
    };
  }

  private createActionsColumn(): MtxGridColumn {
    return {
      field: 'actions',
      header: 'Actions',
      cellTemplate: this.actionsTemplate,
    };
  }

  private createStatusColumn(): MtxGridColumn {
    return {
      field: 'status',
      header: 'Status',
      cellTemplate: this.statusTemplate,
      description: 'scenarios.scenario_status',
      sortable: true,
    };
  }

  private createCreatedDateColumn(): MtxGridColumn {
    return {
      field: 'createdDate',
      header: 'Created Date',
      cellTemplate: this.createdDateTemplate,
    };
  }

  private createLogColumn(): MtxGridColumn {
    return {
      field: 'log',
      header: 'Log',
      description: 'scenarios.scenario_log_file',
      cellTemplate: this.logTemplate,
    };
  }

  private createCaseColumn(): MtxGridColumn {
    return {
      field: 'caseName',
      header: 'Case',
      cellTemplate: this.caseTemplate,
      sortable: true,
    };
  }

  private createProjectColumn(): MtxGridColumn {
    return {
      field: 'projectName',
      header: 'Project',
      cellTemplate: this.projectTemplate,
      sortable: true,
    };
  }
}
