import {
  Optional,
  Self,
  ChangeDetectorRef,
  Input,
  OnInit,
  EventEmitter,
  Output,
  Directive,
} from '@angular/core';
import { NgControl, FormBuilder } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

import { BaseFormComponent } from '../base-form/index';

@Directive()
export abstract class StepFormComponent
  extends BaseFormComponent
  implements OnInit
{
  @Input() previousDisabled: boolean;
  @Input() previousColor: ThemePalette;
  @Input() previousLabel: string;

  @Input() nextDisabled: boolean;
  @Input() nextColor: ThemePalette;
  @Input() nextLabel: string;

  @Input() reverseButtons: boolean;

  @Output() previous = new EventEmitter<any>();
  @Output() next = new EventEmitter<any>();

  // Expansion Panel
  collapsedHeight = '35px';
  expandedHeight = '35px';
  expanded = true;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: FormBuilder,
  ) {
    super(ngControl, changeDetector, formBuilder);
  }

  abstract defineForm(): any;

  onClickPrevious() {
    this.previous.emit({
      valid: (this.form || ({} as any)).valid,
      value: (this.form || ({} as any)).value,
    });
  }

  onClickNext() {
    this.submitted = true;
    if (this.form) {
      this.next.emit({
        valid: (this.form || ({} as any)).valid,
        value: (this.form || ({} as any)).value,
      });
    }
  }
}
