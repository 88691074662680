import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { RouterData } from './router.model';
import {
  RouterActionTypes,
  RouterGoAction,
  RouterActions,
} from './router.actions';

@Injectable()
export class RouterEffects {
  @Effect({ dispatch: false })
  routerGo$: Observable<RouterActions> = this._actions$.pipe(
    ofType(RouterActionTypes.GO),
    map((action: RouterGoAction) => action.payload),
    tap(({ path, query: queryParams, extras }: RouterData) =>
      this._router.navigate(path, { queryParams, ...extras }),
    ),
  );

  @Effect({ dispatch: false })
  routerBack$: Observable<RouterActions> = this._actions$.pipe(
    ofType(RouterActionTypes.BACK),
    tap(() => this._location.back()),
  );

  @Effect({ dispatch: false })
  routerForward$: Observable<RouterActions> = this._actions$.pipe(
    ofType(RouterActionTypes.FORWARD),
    tap(() => this._location.forward()),
  );

  constructor(
    private _actions$: Actions,
    private _location: Location,
    private _router: Router,
  ) {}
}
