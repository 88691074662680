import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormFieldErrorMessageMap, FormFieldOption } from '../components';
import { getKeys } from '../utilities';
import { I18NService } from './i18n.service';

@Injectable()
export class FormService {
  constructor(private _formBuilder: FormBuilder, private _i18n: I18NService) {}

  initForm(config: any, extra?: any): FormGroup {
    return this._formBuilder.group(config, extra);
  }

  convertToFormFieldOptions(obj: {
    [key: string]: string;
  }): Array<FormFieldOption<any>> {
    return getKeys(obj).map((key) => ({ name: obj[key], value: key }));
  }

  getErrorMessageMap(path: string): FormFieldErrorMessageMap {
    const translation = this._i18n.translate(path);
    return translation || {};
  }
}
