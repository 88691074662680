import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { generateEndpoint } from 'prosumer-app/libs/eyes-shared';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { ConfigService, HttpService } from '../../services/index';
import {
  NotificationStoreActionTypes,
  NotificationStoreActions,
  NotificationStoreClearAction,
  NotificationStoreClearListAction,
  NotificationStoreClearListFailureAction,
  NotificationStoreClearListSuccessAction,
  NotificationStoreFetchListAction,
  NotificationStoreFetchListFailureAction,
  NotificationStoreFetchListSuccessAction,
  NotificationStoreReadAction,
  NotificationStoreReadFailureAction,
  NotificationStoreReadSuccessAction,
  NotificationStoreRemoveAction,
  NotificationStoreRemoveFailureAction,
  NotificationStoreRemoveSuccessAction,
} from './notification-store.actions';
import { NotificationStoreService } from './notification-store.service';

@Injectable()
export class NotificationStoreEffects {
  @Effect({ dispatch: false })
  downloadInputFileSuccess$: Observable<any> = this._actions$.pipe(
    ofType(NotificationStoreActionTypes.CLEAR),
    tap((action: NotificationStoreClearAction) => {}),
  );

  @Effect()
  clearNotifications$: Observable<any> = this._actions$.pipe(
    ofType(NotificationStoreActionTypes.CLEAR_NOTIFICATIONS),
    mergeMap((action: NotificationStoreClearListAction) =>
      this._http
        .delete(
          generateEndpoint(
            this._config.api.baseUrl,
            this._config.api.endpoints.notification.list + '/',
          ),
        )
        .pipe(
          map(
            (response) =>
              new NotificationStoreClearListSuccessAction(
                this._translate.instant(
                  'Notification.messages.clearAllSuccess',
                ),
                true,
              ),
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              new NotificationStoreClearListFailureAction(
                (response && response.error && response.error.error) ||
                  this._translate.instant(
                    'Notification.messages.clearAllFailure',
                  ),
              ),
            ),
          ),
        ),
    ),
  );

  @Effect({ dispatch: false })
  clearNotificationsSuccess$: Observable<NotificationStoreActions> =
    this._actions$.pipe(
      ofType(NotificationStoreActionTypes.CLEAR_NOTIFICATIONS_SUCCESS),
      tap((action: NotificationStoreClearListSuccessAction) => {
        const payload = action.payload;
        if (payload.notify) {
          this._notification.showSuccess(payload.message);
        }
      }),
    );

  @Effect({ dispatch: false })
  clearNotificationsFailure$: Observable<NotificationStoreActions> =
    this._actions$.pipe(
      ofType(NotificationStoreActionTypes.CLEAR_NOTIFICATIONS_FAILURE),
      tap((action: NotificationStoreClearListFailureAction) =>
        this._notification.showError(action.payload.error),
      ),
    );

  @Effect()
  fetchNotifications$: Observable<NotificationStoreActions> =
    this._actions$.pipe(
      ofType(NotificationStoreActionTypes.FETCH_NOTIFICATIONS),
      mergeMap((action: NotificationStoreFetchListAction) =>
        this._http
          .get(
            generateEndpoint(
              this._config.api.baseUrl,
              this._config.api.endpoints.notification.list,
            ),
          )
          .pipe(
            map(
              (response) =>
                new NotificationStoreFetchListSuccessAction(
                  response,
                  this._translate.instant(
                    'Notification.messages.getAllSuccess',
                  ),
                  false,
                ),
            ),
            catchError((response: HttpErrorResponse) =>
              of(
                new NotificationStoreFetchListFailureAction(
                  (response && response.error && response.error.error) ||
                    this._translate.instant(
                      'Notification.messages.getAllFailure',
                    ),
                ),
              ),
            ),
          ),
      ),
    );

  @Effect({ dispatch: false })
  fetchNotificationsFailure$: Observable<NotificationStoreActions> =
    this._actions$.pipe(
      ofType(NotificationStoreActionTypes.FETCH_NOTIFICATIONS_FAILURE),
      mergeMap((action: NotificationStoreFetchListFailureAction) =>
        this._notification
          .showError(action.payload.error, 'Retry')
          .onAction()
          .pipe(tap(() => this._notificationStore.fetchNotifications())),
      ),
    );

  @Effect()
  removeNotification$: Observable<NotificationStoreActions> =
    this._actions$.pipe(
      ofType(NotificationStoreActionTypes.REMOVE_NOTIFICATION),
      mergeMap((action: NotificationStoreRemoveAction) =>
        this._http
          .delete(
            generateEndpoint(
              this._config.api.baseUrl,
              this._config.api.endpoints.notification.delete,
              action.payload.notification.id,
            ),
          )
          .pipe(
            map(
              (response) =>
                new NotificationStoreRemoveSuccessAction(
                  action.payload.notification,
                  'Successfully deleted notification',
                  false,
                ),
            ),
            catchError((response: HttpErrorResponse) =>
              of(
                new NotificationStoreRemoveFailureAction(
                  action.payload.notification,
                  response.error,
                ),
              ),
            ),
          ),
      ),
    );

  @Effect({ dispatch: false })
  removeNotificationSuccess$: Observable<NotificationStoreActions> =
    this._actions$.pipe(
      ofType(NotificationStoreActionTypes.REMOVE_NOTIFICATION_SUCCESS),
      tap((action: NotificationStoreRemoveSuccessAction) => {
        const payload = action.payload;
        if (payload.notify) {
          this._notification.showSuccess(payload.message);
        }
      }),
    );

  @Effect({ dispatch: false })
  removeNotificationFailure$: Observable<NotificationStoreActions> =
    this._actions$.pipe(
      ofType(NotificationStoreActionTypes.REMOVE_NOTIFICATION_FAILURE),
      tap((action: NotificationStoreRemoveFailureAction) =>
        this._notification.showError(action.payload.error),
      ),
    );

  @Effect()
  readNotification$: Observable<NotificationStoreActions> = this._actions$.pipe(
    ofType(NotificationStoreActionTypes.READ_NOTIFICATION),
    mergeMap((action: NotificationStoreReadAction) =>
      this._http
        .post(
          generateEndpoint(
            this._config.api.baseUrl,
            this._config.api.endpoints.notification.read,
          ),
          action.payload.notification_uuids,
        )
        .pipe(
          map(
            () =>
              new NotificationStoreReadSuccessAction(
                action.payload.notification_uuids,
                this._translate.instant('Notification.messages.readSuccess'),
              ),
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              new NotificationStoreReadFailureAction(
                action.payload.notification_uuids,
                response.error,
              ),
            ),
          ),
        ),
    ),
  );

  @Effect({ dispatch: false })
  readNotificationsSuccess$: Observable<NotificationStoreActions> =
    this._actions$.pipe(
      ofType(NotificationStoreActionTypes.READ_NOTIFICATION_SUCCESS),
      tap((action: NotificationStoreReadSuccessAction) => {
        const payload = action.payload;
      }),
    );

  @Effect({ dispatch: false })
  readNotificationsFailure$: Observable<NotificationStoreActions> =
    this._actions$.pipe(
      ofType(NotificationStoreActionTypes.REMOVE_NOTIFICATION_FAILURE),
      tap((action: NotificationStoreReadFailureAction) =>
        this._notification.showError(action.payload.error),
      ),
    );

  constructor(
    private _actions$: Actions,
    private _http: HttpService,
    private _config: ConfigService,
    private _translate: TranslateService,
    private _notification: NotificationsService,
    private _notificationStore: NotificationStoreService,
  ) {}
}
