import { Coerce } from 'prosumer-app/core/utils';
import { BehaviorSubject } from 'rxjs';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ANY_VALUE } from '../';
import { mapToFormSelect } from '../../mappers';

type FiltersDataObj = Record<string, string[] | number[]>;

@Component({
  selector: 'prosumer-tdb-technology-secondary-filters',
  templateUrl: './tdb-technology-secondary-filters.component.html',
  styleUrls: ['./tdb-technology-secondary-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TdbTechnologySecondaryFiltersComponent implements OnChanges {
  @Output() secondaryFormSubmit = new EventEmitter();
  @Input() filterSequence = [];
  @Input() isLoading = false;
  @Input() filtersData: FiltersDataObj;

  secondaryFiltersForm: FormGroup;
  filterDataOptionsMapped$ = new BehaviorSubject([]);

  constructor(private readonly _formBuilder: FormBuilder) {}

  handleSubmitBtnClick(): void {
    this.secondaryFormSubmit.emit(this.secondaryFiltersForm.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isValidChange(changes)) {
      const filtersData = changes.filtersData.currentValue;
      const filterSeq = this.getFilterSequence(filtersData);
      this.updateFiltersOptions(filterSeq, filtersData);
      this.initSecondaryFilterForm(filterSeq);
    }
  }

  private isValidChange(changes: SimpleChanges): boolean {
    const filtersData = Coerce.toObject(changes.filtersData);
    return [
      this.isNonEmptyObject(filtersData.currentValue),
      this.isLoading === false,
    ].every(Boolean);
  }
  private isNonEmptyObject(obj: Record<string, unknown>): boolean {
    return !!Object.keys(Coerce.toObject(obj)).length;
  }
  private isNonEmptyArray(arr: string[] | number[]): boolean {
    return !!Coerce.toArray<unknown>(arr).length;
  }

  // private parseInputFilterData(_filtersData): void {
  //   let formData = {};
  //   let filtersDataWithOptionsMapped = {};
  //   Object.keys(_filtersData).forEach((key) => {
  //     filtersDataWithOptionsMapped[key] = mapToFormSelect(_filtersData[key]);
  //     formData[key] = ANY_VALUE;
  //   });
  //   this.filterDataOptionsMapped$.next(filtersDataWithOptionsMapped);
  //   this.initSecondaryFilterForm(formData);
  // }

  private getFilterSequence(filtersData: FiltersDataObj): string[] {
    return Coerce.toArray(this.filterSequence).filter((key) =>
      this.isNonEmptyArray(filtersData[key]),
    );
  }
  private updateFiltersOptions(seqArr: string[], data: FiltersDataObj): void {
    const filters = seqArr.map((key) => ({
      values: mapToFormSelect(data[key]),
      key,
    }));
    this.filterDataOptionsMapped$.next(filters);
  }
  private initSecondaryFilterForm(seqArr: string[]): void {
    const formData: Record<string, string> = seqArr.reduce(
      (acc, seqKey) => ({ ...acc, [seqKey]: ANY_VALUE }),
      {},
    );
    this.secondaryFiltersForm = this._formBuilder.group(formData);
  }
}
