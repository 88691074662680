<button mat-icon-button [matMenuTriggerFor]="eyesNotif">
  <mat-icon
    matBadgeColor="warn"
    [matBadge]="unreadNotifCount"
    [matBadgeHidden]="!isBadgeVisible"
    >notifications_none
  </mat-icon>
</button>
<mat-menu
  #eyesNotif="matMenu"
  class="eyes-notification"
  [overlapTrigger]="false"
>
  <mat-card class="notifications-parent">
    <div fxLayoutAlign="space-between">
      <mat-card-header>
        <mat-card-title class="notif-header-main"
          >Notifications ({{ unreadNotifCount }})</mat-card-title
        >
      </mat-card-header>
      <a
        [ngClass]="{
          'active-link': unreadNotifCount,
          link: unreadNotifCount,
          'disable-link': !unreadNotifCount
        }"
        (click)="markAllAsRead($event, notifsToShow)"
        >Mark all as read</a
      >
    </div>
    <div class="notifications-list" *ngIf="totalNotifCount; else noNotifs">
      <ng-container *ngFor="let notif of notifsToShow">
        <mat-card class="notif-default">
          <mat-card-header>
            <div fxLayoutAlign="space-between ! important" fxLayoutGap="150px">
              <div fxLayout="row">
                <div fxLayoutAlign="flex-start" fxLayout="column">
                  <mat-card-title class="notif-header">{{
                    notif?.subject
                  }}</mat-card-title>
                  <mat-card-subtitle class="notif-subheader"
                    >{{ notif?.dateTimeReceived | dateTimeFormat }}
                  </mat-card-subtitle>
                </div>
              </div>
            </div>
          </mat-card-header>
          <mat-card-content *ngIf="unreadNotifCount; else noUnread">
            <div
              *ngIf="
                notif?.generatedBy === 'simulation_hook';
                else defaultMessage
              "
            >
              <p>
                Project
                <a
                  [ngClass]="{ 'eyes-href-bold': true, link: true }"
                  (click)="markAsRead($event, notif, notif?.projectId)"
                  >{{ notif?.projectName }}</a
                >
                of Case
                <a
                  [ngClass]="{ 'eyes-href-bold': true, link: true }"
                  (click)="markAsRead($event, notif, notif?.caseId)"
                  >{{ notif?.caseName }}</a
                >
                simulation result: <br />
              </p>

              <div
                *ngIf="notif?.runStatus === 'succeeded'; else failedSimulation"
              >
                {{ notif?.body }} for Scenario
                <a
                  [ngClass]="{ 'eyes-href-bold': true, link: true }"
                  (click)="markAsRead($event, notif, notif?.scenarioId)"
                  >{{ notif?.scenarioName }}
                  {{
                    notif?.data?.variationName
                      ? '(' + notif?.data?.variationName + ')'
                      : ''
                  }}</a
                >
              </div>
              <ng-template #failedSimulation>
                {{ notif?.body }} for Scenario {{ notif?.scenarioName }}
              </ng-template>
            </div>
            <ng-template #defaultMessage>
              <p [innerText]="notif?.body"></p>
              <div *ngIf="notif?.generatedBy === 'duplication_hook'">
                <p>Exclusion count: {{ notif?.exclusionCount }}</p>
              </div>
              <div *ngIf="notif?.generatedBy === 'sharing_hook'">
                <p>Project Detail:</p>
                <p>
                  <a
                    [ngClass]="{ 'eyes-href-bold': true, link: true }"
                    (click)="markAsRead($event, notif, notif?.projectId)"
                    >{{ notif?.projectName }}</a
                  >
                </p>
              </div>
            </ng-template>
          </mat-card-content>
          <ng-template #noUnread>
            <mat-card-content>{{ notif?.body }}</mat-card-content>
          </ng-template>
        </mat-card>
      </ng-container>
    </div>
    <ng-template #noNotifs>
      <div class="notifications-list">
        <mat-card class="notif-default">
          <mat-card-header>
            <mat-card-title class="notif-header">System Info</mat-card-title>
            <mat-card-subtitle class="notif-subheader">{{
              dateTimeCurrent | dateTimeFormat
            }}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p>You have no new notifications at this moment.</p>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-template>
    <div fxLayoutAlign="center" *ngIf="totalNotifCount">
      <button
        type="button"
        class="notif-button"
        mat-flat-button
        color="primary"
        (click)="goToNotifsList($event)"
      >
        SEE ALL
      </button>
    </div>
  </mat-card>
</mat-menu>
