import { ResultStore } from 'prosumer-app/stores';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import {
  CashflowData,
  EmissionsData,
  EnergyBalanceResult,
  EquipmentResult,
  TopologyResult,
} from '../models';
import { FlowResult } from '../models/flow-results.model';
import { MainOutputResult } from '../models/main-output-results.model';
import { BaseExtractor } from './base.extractor';
import { CashflowExtractor } from './cashflow.extractor';
import { EmissionsExtractor } from './emissions.extractor';
import { EnergyBalanceExtractor } from './energy-balance.extractor';
import { EquipmentExtractor } from './equipment.extractor';
import { FlowExtractor } from './flow.extractor';
import { MainOutputExtractor } from './main-output.extractor';
import { TopologyExtractor } from './topology.extractor';
import { AllYearsExtractor, OptimizedYearsExtractor } from './years.extractor';

@Injectable({ providedIn: 'root' })
export class ResultsExtractorService {
  private dataSubject = new BehaviorSubject<unknown>(undefined);

  constructor(private resultStore: ResultStore) {}

  migrateResults(projectId: string, caseId: string, scenarioId: string): void {
    this.resultStore.migrateResults(projectId, caseId, scenarioId);

    this.subscribeQuicklyToTruthyResultsForLocalStoring();
  }

  getResults(projectId: string, caseId: string, scenarioId: string): void {
    this.resultStore.getMainResults(projectId, caseId, scenarioId);

    this.subscribeQuicklyToTruthyResultsForLocalStoring();
  }

  private subscribeQuicklyToTruthyResultsForLocalStoring(): void {
    this.selectTruthyResults()
      .pipe(take(1))
      .subscribe((data) => this.dataSubject.next(data));
  }

  private selectTruthyResults(): Observable<unknown> {
    return this.resultStore.data$.pipe(filter((data) => !!data));
  }

  getAllYearsStream(): Observable<number[]> {
    return this.extractFromDataStream('allYears');
  }

  getOptimizedYearsStream(): Observable<number[]> {
    return this.extractFromDataStream('optimizedYears');
  }

  getCashflowStream(): Observable<CashflowData[]> {
    return this.extractFromDataStream('cashflow');
  }

  getTopologyStream(): Observable<TopologyResult[]> {
    return this.extractFromDataStream('topology');
  }

  getEmissionsStream(): Observable<EmissionsData[]> {
    return this.extractFromDataStream('emissions');
  }

  getEnergyBalanceStream(): Observable<EnergyBalanceResult[]> {
    return this.extractFromDataStream('energyBalance');
  }

  getEquipmentStream(): Observable<EquipmentResult[]> {
    return this.extractFromDataStream('equipment');
  }

  getMainOutputStream(): Observable<MainOutputResult[]> {
    return this.extractFromDataStream('mainOutput');
  }

  getFlowStream(): Observable<FlowResult[]> {
    return this.extractFromDataStream('flow');
  }

  private extractFromDataStream<T>(extractorKey: string): Observable<T[]> {
    const extractor = this.getExtractor<T>(extractorKey);
    return this.resultStore.isOutputSplit$.pipe(
      switchMap((isSplit) => {
        if (isSplit) {
          return this.getSplittedDataStream(extractorKey).pipe(
            filter((data) => !!data),
            map((data) => extractor.extract(data)),
          );
        }
        return this.getDataStream().pipe(
          map((data) => extractor.extract(data)),
        );
      }),
    );
  }

  private getDataStream(): Observable<unknown> {
    return this.dataSubject.asObservable().pipe(filter((data) => !!data));
  }

  private getSplittedDataStream(key: string): Observable<any> {
    const data = {
      cashflow: this.resultStore.cashflowData$,
      topology: this.resultStore.topologyData$,
      emissions: this.resultStore.co2EmissionsData$,
      energyBalance: this.resultStore.energyBalanceData$,
      allYears: this.resultStore.allYears$,
      optimizedYears: this.resultStore.optimizedYears$,
      equipment: this.resultStore.equipmentData$,
      mainOutput: this.resultStore.mainData$,
      flow: this.resultStore.flowData$,
    };
    return data[key];
  }

  private getExtractor<T>(key: string): BaseExtractor<T> {
    const extractors = {
      cashflow: CashflowExtractor,
      topology: TopologyExtractor,
      emissions: EmissionsExtractor,
      energyBalance: EnergyBalanceExtractor,
      allYears: AllYearsExtractor,
      optimizedYears: OptimizedYearsExtractor,
      equipment: EquipmentExtractor,
      mainOutput: MainOutputExtractor,
      flow: FlowExtractor,
    };
    return new extractors[key]();
  }
}
