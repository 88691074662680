<form [formGroup]="nodeForm" class="node-form">
  <h2 class="mat-title" mat-dialog-title>{{ data?.mode | titlecase }} Node</h2>
  <div class="mat-body" mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="10px">
      <div
        fxLayout="row"
        fxLayoutGap="20px"
        fxLayoutAlign="space-around stretch"
        class="input-container"
      >
        <!-- Node Name -->
        <prosumer-input
          fxFlex="50%"
          type="string"
          class="eyes-maximize-width"
          [placeholder]="'Scenario.placeholders.topology.node.name' | translate"
          [label]="'Scenario.labels.wizard.topology.nodes.name' | translate"
          [control]="nameCtrl"
          [required]="true"
          [errorMessageMap]="errormessages['name']"
        >
        </prosumer-input>
        <!-- Node Surface -->
        <prosumer-input
          fxFlex="50%"
          type="number"
          class="eyes-maximize-width"
          [placeholder]="
            'Scenario.placeholders.topology.node.surface' | translate
          "
          [label]="'Scenario.labels.wizard.topology.nodes.surface' | translate"
          [control]="surfaceCtrl"
          [inputTooltip]="'wizard_topology.wizard_topology_surface'"
          [errorMessageMap]="errormessages['surface']"
        >
        </prosumer-input>
      </div>
      <div mat-dialog-actions fxLayout="row" fxLayoutGap="8px">
        <prosumer-entity-upsert-button
          [disabled]="!isFormValid || isFormPristine"
          [mode]="data.mode"
          [valid]="isFormValid"
          (ok)="onClose()"
          [id]="data.nodeData?.nodeId"
          [data]="nodeForm.value"
        >
          <!-- (attempt)="onSaveAttempt()" -->
        </prosumer-entity-upsert-button>
        <button
          data-testid="cancel-button"
          mat-flat-button
          type="button"
          color=""
          (click)="onClose()"
        >
          <span>{{ 'Generic.labels.cancel' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>
