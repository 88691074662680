import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  ProfileComputationType,
  RenewableProfileComputation,
  TrackingType,
} from 'prosumer-app/+renewableprofile/models/renewable-profile.model';
import { GISCoordinatesService } from 'prosumer-app/+renewableprofile/services/gis-coordinates.service';
import { RenewableProfileFacadeService } from 'prosumer-app/+renewableprofile/state';
import { createRenewableProfileSubmitData } from 'prosumer-app/+renewableprofile/utils/helpers';
import {
  BaseComponent,
  FormFieldErrorMessageMap,
  FormFieldOption,
  fadeInAnimation,
} from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'prosumer-solar-tab',
  templateUrl: './solar-tab.component.html',
  styleUrls: ['./solar-tab.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolarTabComponent extends BaseComponent implements OnInit {
  location$: Observable<{ longitude: number; latitude: number }>;

  clickedSubmitted$ = new BehaviorSubject<boolean>(false);

  solarForm: FormGroup;

  trackingTypeOptions: Array<FormFieldOption<any>> = [
    { name: TrackingType.FIXED, value: TrackingType.FIXED },
    {
      name: TrackingType.AZIMUTH_TRACKING,
      value: TrackingType.AZIMUTH_TRACKING,
    },
    {
      name: TrackingType.TILTED_NS_TRACKING,
      value: TrackingType.TILTED_NS_TRACKING,
    },
    { name: TrackingType.FULL_TRACKING, value: TrackingType.FULL_TRACKING },
  ];

  errorMessages: FormFieldErrorMessageMap = {
    panelTilt: {
      required: this.translate.instant(
        'RenewableProfile.messages.panelTilt.required',
      ),
      invalid: this.translate.instant(
        'RenewableProfile.messages.panelTilt.invalid',
      ),
      min: this.translate.instant('RenewableProfile.messages.panelTilt.min'),
      max: this.translate.instant('RenewableProfile.messages.panelTilt.max'),
    },
    panelOrientation: {
      required: this.translate.instant(
        'RenewableProfile.messages.panelOrientation.required',
      ),
      invalid: this.translate.instant(
        'RenewableProfile.messages.panelOrientation.invalid',
      ),
      min: this.translate.instant(
        'RenewableProfile.messages.panelOrientation.min',
      ),
      max: this.translate.instant(
        'RenewableProfile.messages.panelOrientation.max',
      ),
    },
    // criticalSnowDepth: {
    //   required: this.translate.instant('RenewableProfile.messages.criticalSnowDepth.required'),
    // },
  };

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: FormBuilder,
    public coordinates: GISCoordinatesService,
    public translate: TranslateService,
    public renewableProfileFacade: RenewableProfileFacadeService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.solarForm = this.formBuilder.group(this.defaultForm());
    this.location$ = combineLatest([
      this.coordinates.longitude$,
      this.coordinates.latitude$,
    ]).pipe(
      filter(([longitude, latitude]) => !!longitude || !!latitude),
      map(
        ([longitude, latitude]) => ({
          longitude: Number(longitude),
          latitude: Number(latitude),
        }),
        this.takeUntilShare(),
      ),
    );

    this.location$.subscribe(({ longitude, latitude }) => {
      this.solarForm.controls.longitude.setValue(longitude);
      this.solarForm.controls.latitude.setValue(latitude);
    });

    this.solarForm.valueChanges.subscribe((v) => {
      this.clickedSubmitted$.next(false);
      this.solarForm.markAsDirty();
    });
  }

  computationHandler(computation: RenewableProfileComputation) {
    if (computation.type === ProfileComputationType.HISTORICAL) {
      this.solarForm.controls.historicalYears.setValidators([
        Validators.required,
      ]);
      this.solarForm.controls.percentiles.clearValidators();
      this.solarForm.controls.numberOfYears.clearValidators();
      this.solarForm.controls.lastHistoricalYear.clearValidators();
    }
    if (computation.type === ProfileComputationType.PERCENTILE) {
      this.solarForm.controls.historicalYears.clearValidators();
      this.solarForm.controls.percentiles.setValidators([Validators.required]);
      this.solarForm.controls.numberOfYears.clearValidators();
      this.solarForm.controls.lastHistoricalYear.clearValidators();
    }
    if (computation.type === ProfileComputationType.TMY) {
      this.solarForm.controls.historicalYears.clearValidators();
      this.solarForm.controls.percentiles.clearValidators();
      this.solarForm.controls.numberOfYears.setValidators([
        Validators.required,
      ]);
      this.solarForm.controls.lastHistoricalYear.setValidators([
        Validators.required,
      ]);
    }
    this.solarForm.controls.computeType.setValue(computation.type);
    this.solarForm.controls.numberOfYears.setValue(computation.numberOfYears);
    this.solarForm.controls.lastHistoricalYear.setValue(
      computation.lastHistoricalYear,
    );
    this.solarForm.controls.historicalYears.setValue(
      !!!computation.historicalYears ? undefined : computation.historicalYears,
    );
    this.solarForm.controls.percentiles.setValue(
      !!!computation.percentiles ? undefined : computation.percentiles,
    );
  }

  onSubmit() {
    this.clickedSubmitted$.next(true);
    if (this.solarForm.valid) {
      const submitData = createRenewableProfileSubmitData(
        this.solarForm,
        'solar',
        'MW',
      );
      this.renewableProfileFacade.createRenewableProfile(submitData);
      this.solarForm.markAsPristine();
    }
  }

  private defaultForm() {
    return {
      trackingType: [TrackingType.FIXED, Validators.required],
      panelTilt: [
        25,
        [Validators.min(0), Validators.max(90), Validators.required],
      ],
      panelOrientation: [
        180,
        [Validators.min(0), Validators.max(360), Validators.required],
      ],
      computeType: [undefined, Validators.required],
      numberOfYears: undefined,
      lastHistoricalYear: undefined,
      historicalYears: [undefined, Validators.required],
      percentiles: undefined,
      longitude: [undefined, Validators.required],
      latitude: [undefined, Validators.required],
    };
  }
}
