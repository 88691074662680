import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ReactiveComponentModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { ScenarioLogsModule } from '../scenario-logs';
import { ScenarioActionsComponent } from './scenario-actions.component';

@NgModule({
  declarations: [ScenarioActionsComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    TranslateModule,
    ReactiveComponentModule,
    ScenarioLogsModule,
  ],
  exports: [ScenarioActionsComponent],
})
export class ScenarioActionsModule {}
