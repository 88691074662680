import moment from 'moment';
import {
  generateShadedScheme,
  RESULTS_DEFAULT_SCHEME,
} from 'prosumer-app/app.references';
import { LoggerService } from 'prosumer-app/libs/eyes-core';
import { BaseComponent, contains } from 'prosumer-app/libs/eyes-shared';
import { ResultStore } from 'prosumer-app/stores';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColorHelper } from '@swimlane/ngx-charts';

import {
  addBallotBox,
  removeBallotBox,
  updateLegendFilter,
} from '../../shared/helper';

@Component({
  selector: 'prosumer-average-daily-prod',
  templateUrl: './average-daily-prod.component.html',
  styleUrls: ['./average-daily-prod.component.scss'],
  providers: [CurrencyPipe],
})
export class AverageDailyProdComponent extends BaseComponent implements OnInit {
  constructor(
    private _resultStore: ResultStore,
    private _logger: LoggerService,
    private _translate: TranslateService,
    public decimalPipe: CurrencyPipe,
  ) {
    super();
  }

  data: Array<any>;
  year: string;
  node: string;
  energyVector: string;
  chartTitle: string;
  defaultEnergyVector = 'electricity';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly CHART_ID = 'averageDaily';

  legendFilter$ = new BehaviorSubject<Array<string>>([]);

  @Input() chartDimension = [650, 450];
  @Input() chartHasLegend = true;
  @Input() chartHasTimeline = false;
  @Input() chartHasGrid = true;
  @Input() chartScheme = RESULTS_DEFAULT_SCHEME;
  @Input() chartShowXAxis = true;
  @Input() chartShowXAxisLabel = true;
  @Input() chartShowYAxis = true;
  @Input() chartShowYAxisLabel = true;
  @Input() chartShowLegend = true;
  @Input() chartLegendPosition = 'right';
  @Input() chartXAxisLabel = 'Hours';
  @Input() chartYAxisLabel = 'kW';

  @Input() chartXAxisFormatFunction = (value: any) => value;
  @Input() chartTooltipTitleFormat = (obj: any) =>
    `${this._translate.instant('Result.labels.hour')} ${(obj || {}).name}`;

  ngOnInit() {
    combineLatest([
      this._resultStore.getRawDispatchFilterOptions$('year').pipe(
        take(1),
        filter((yearOptions) => yearOptions && yearOptions.length > 0),
        map((yearOptions) => yearOptions[0].value),
        tap((year) => (this.year = year)),
        takeUntil(this.componentDestroyed$),
      ),
      this._resultStore.getRawDispatchFilterOptions$('node').pipe(
        take(1),
        filter((nodeOptions) => nodeOptions && nodeOptions.length > 0),
        map((nodeOptions) => nodeOptions[0].value),
        tap((node) => (this.node = node)),
        takeUntil(this.componentDestroyed$),
      ),
      this._resultStore.getRawDispatchFilterOptions$('energyVector').pipe(
        take(1),
        filter(
          (energyVectorOptions) =>
            energyVectorOptions && energyVectorOptions.length > 0,
        ),
        map((energyVectorOptions) => energyVectorOptions[0].value),
        takeUntil(this.componentDestroyed$),
      ),
      this._resultStore.chartData$(this.CHART_ID).pipe(take(1)),
      this.legendFilter$,
    ])
      .pipe(
        switchMap(([year, node, energyVector, cachedData, legendFilter]) =>
          this._resultStore
            .getRawDispatchByParams$(
              year,
              node,
              this.defaultEnergyVector,
              'production',
            )
            .pipe(
              switchMap((data) => {
                if (!data || data.length === 0) {
                  return this._resultStore
                    .getRawDispatchByParams$(
                      year,
                      node,
                      energyVector,
                      'production',
                    )
                    .pipe(tap(() => (this.energyVector = energyVector)));
                }
                return of(data).pipe(
                  tap(() => (this.energyVector = this.defaultEnergyVector)),
                );
              }),
              map((filteredData) => {
                if (cachedData) {
                  return cachedData.map((c) => ({
                    name: addBallotBox(legendFilter, removeBallotBox(c.name)),
                    series: contains(legendFilter, c.name) ? [] : c.series,
                  }));
                } else {
                  if (!filteredData || filteredData.length === 0) {
                    return undefined;
                  }
                  const chartData = filteredData.map((filteredDataItem) => {
                    const dataSet = !filteredDataItem.values
                      ? []
                      : (filteredDataItem.values as Array<any>).map(
                          (value, index) => ({
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            hour: moment({
                              y: +year,
                              M: 0,
                              d: 1,
                              h: 0,
                              m: 0,
                              s: 0,
                              ms: 0,
                            })
                              .hour(index)
                              .get('h'),
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            name: moment({
                              y: +year,
                              M: 0,
                              d: 1,
                              h: 0,
                              m: 0,
                              s: 0,
                              ms: 0,
                            })
                              .hour(index)
                              .toISOString(),
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            moment: moment({
                              y: +year,
                              M: 0,
                              d: 1,
                              h: 0,
                              m: 0,
                              s: 0,
                              ms: 0,
                            }).hour(index),
                            value,
                          }),
                        );
                    let entryData = {
                      name: filteredDataItem.der,
                      series: undefined,
                    };
                    if (
                      dataSet &&
                      dataSet instanceof Array &&
                      dataSet.length > 0
                    ) {
                      const series = [];
                      Array.from(Array(24).keys()).forEach((hour) => {
                        const hourDataSet = dataSet.filter(
                          (data) => data && data.hour === hour,
                        );
                        const hourData = hourDataSet.reduce((prev, curr) => ({
                          ...curr,
                          name: '' + (hour + 1),
                          value: prev.value + curr.value,
                        }));
                        series.push({
                          ...hourData,
                          value: hourData.value / (hourDataSet.length || 1),
                        });
                      });
                      entryData = {
                        name: addBallotBox(legendFilter, filteredDataItem.der),
                        series: contains(legendFilter, filteredDataItem.der)
                          ? []
                          : series,
                      };
                    }
                    return entryData;
                  });
                  return chartData;
                }
              }),
              take(1),
              tap((chartData) => {
                if (!cachedData && legendFilter.length === 0) {
                  this._resultStore.updateChartDataMapping(
                    this.CHART_ID,
                    chartData,
                  );
                }
              }),
              takeUntil(this.componentDestroyed$),
            ),
        ),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe((data) => {
        this.data = data;
        this.chartScheme = generateShadedScheme(data.length);
      });

    this.chartTitle = `Average Daily Production (${this.energyVector} - ${this.node} - ${this.year})`;
  }

  calculateTotal(seriesData: Array<any>): number {
    if (!seriesData) {
      return 0;
    }
    if (seriesData.length > 1) {
      return seriesData.reduce(
        (previousSeries, currentSeries) =>
          (previousSeries.value || 0) + (currentSeries.value || 0),
      );
    }
    return seriesData[0].value;
  }

  getSeriesList(dataList: Array<any>, currentSeries: any) {
    const seriesList = [];
    if (!dataList || !currentSeries) {
      return seriesList;
    }
    dataList.forEach((data) => {
      const seriesLookup = data.series.find(
        (series) => series.name === currentSeries.name,
      );
      if (seriesLookup) {
        seriesList.push({
          series: data.name,
          value: seriesLookup.value,
          current: data.name === currentSeries.series,
          color: new ColorHelper(
            RESULTS_DEFAULT_SCHEME,
            'ordinal',
            dataList.map((d) => d.name),
          ).getColor(data.name),
        });
      }
    });
    return seriesList;
  }

  onSelect(selected: string) {
    this._logger.debug(selected);
    updateLegendFilter(this.legendFilter$, selected);
  }

  formatValue(num: any) {
    if (num) {
      return this.decimalPipe.transform(num, '', '', '1.0-0');
    }
    return num;
  }
}
