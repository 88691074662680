<form [formGroup]="form" (submit)="addItem()">
  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px">
    <button
      *ngIf="(havePoints$ | async) !== true; else removePoints"
      mat-button
      type="button"
      color="primary"
      (click)="initializePoints()"
      data-testid="efficiency-curve-add"
    >
      <mat-icon matPrefix>scatter_plot</mat-icon><i> ADD EFFICIENCY POINTS</i>
    </button>
    <div
      fxLayout="row wrap"
      fxLayoutGap="4px"
      class="energy-vectors-container eyes-spacer-top-half"
    >
      <div
        class="container-x"
        formArrayName="items"
        *ngFor="let item of getFormArray().controls; let i = index"
      >
        <mat-card class="efficiency-card" [formGroupName]="i">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
            <div [ngClass]="{ fixHeight: !isHiddenBtn(i) }">
              Point {{ i + 1 }}
            </div>
            <div fxFlex></div>
            <a
              *ngIf="isHiddenBtn(i)"
              class="remove-efficiency"
              color="warn"
              mat-icon-button
              type="button"
              (click)="removeItem(i)"
              [@fadeIn]
              data-testid="efficiency-curve-remove-point"
            >
              <mat-icon>clear</mat-icon>
            </a>
          </div>
          <mat-form-field
            class="eyes-maximize-width"
            appearance="outline"
            [@fadeIn]
            data-testid="efficiency-curve-percentage-capacity"
          >
            <mat-label>Percentage of Capacity [p.u.]</mat-label>
            <input
              matInput
              class="eyes-input"
              formControlName="percentageOfCapacity"
              placeholder="0.0"
              type="number"
            />
            <mat-icon
              class="eyes-input-tooltip-anchor"
              matSuffix
              tooltipPosition="left"
              [prosumerTooltip]="
                'wizard_generator.wizard_generator_capacity_percentage'
              "
              >help
            </mat-icon>
            <mat-error
              [innerHTML]="
                'Scenario.messages.der.efficiencyPercentage.required'
                  | translate
              "
            >
            </mat-error>
          </mat-form-field>
          <mat-form-field
            class="eyes-maximize-width eyes-spacer-top-half"
            appearance="outline"
            [@fadeIn]
            data-testid="efficiency-curve-consumption"
          >
            <mat-label>Consumption [kW/kWi]</mat-label>
            <input
              matInput
              class="eyes-input"
              formControlName="consumption"
              placeholder="0.0"
              type="number"
            />
            <mat-icon
              class="eyes-input-tooltip-anchor"
              matSuffix
              tooltipPosition="left"
              [prosumerTooltip]="
                'wizard_generator.wizard_generator_consumption'
              "
              >help
            </mat-icon>
            <mat-error
              [innerHTML]="
                'Scenario.messages.der.efficiencyConsumption.required'
                  | translate
              "
            >
            </mat-error>
          </mat-form-field>
        </mat-card>
      </div>
      <button
        *ngIf="addOneVisibility$ | async"
        mat-icon-button
        class="add-custom-vector-button"
        color="primary"
        data-testid="efficiency-curve-add-point"
      >
        <mat-icon matPrefix class="add-custom-vector-icon">add_circle</mat-icon>
      </button>
    </div>
  </div>
</form>

<!-- TEMPLATES -->
<ng-template #removePoints>
  <button
    mat-button
    type="button"
    color="warn"
    (click)="clearFormArray()"
    data-testid="efficiency-curve-remove-all"
  >
    <mat-icon matPrefix>delete_sweep</mat-icon>
    <i> REMOVE ALL EFFICIENCY POINTS</i>
  </button>
</ng-template>
