import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnergyVector, Equipment, Load, Node } from 'prosumer-app/+scenario';
import {
  BaseComponent,
  CustomValidators,
  FormFieldErrorMessageMap,
  FormFieldOption,
  FormService,
  doNothing,
} from 'prosumer-app/libs/eyes-shared';
import { NameValidator } from 'prosumer-app/shared';
import { provideUpserter } from 'prosumer-app/stores';
import { NettingStore } from 'prosumer-app/stores/netting';
import { VariationFinder } from 'prosumer-scenario/services';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, startWith, throttleTime } from 'rxjs/operators';
import { NettingFormDialogData } from './netting-form-dialog.model';

@Component({
  selector: 'prosumer-netting-form-dialog',
  templateUrl: './netting-form-dialog.component.html',
  styleUrls: ['./netting-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TitleCasePipe, provideUpserter(NettingStore)],
})
export class NettingFormDialogComponent
  extends BaseComponent
  implements OnInit
{
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: NettingFormDialogData,
    public dialogRef: MatDialogRef<NettingFormDialogComponent>,
    private _formBuilder: FormBuilder,
    private _formService: FormService,
    private _varFinder: VariationFinder,
  ) {
    super();
    this.nettingForm = this.initForm();
  }
  submitted$ = new BehaviorSubject<boolean>(false);
  private all = 'ALL';

  get nameCtrl() {
    return this.nettingForm.controls.name;
  }
  get timePartitionCtrl() {
    return this.nettingForm.controls.timePartition;
  }
  get energyVectorCtrl() {
    return this.nettingForm.controls.energyVectorId;
  }
  get nodesCtrl() {
    return this.nettingForm.controls.nodes;
  }
  get equipmentsCtrl() {
    return this.nettingForm.controls.equipments;
  }
  get loadsCtrl() {
    return this.nettingForm.controls.loads;
  }
  get isFormValid() {
    return this.nettingForm.valid;
  }
  get isFormPristine() {
    return this.nettingForm.pristine;
  }

  isMultiNode: boolean;
  energyVectorOptions: Array<EnergyVector>;
  nodeOptions: Array<Node> = [];

  equipmentsOptions: Array<FormFieldOption<string>> = [];
  loadsOptions: Array<FormFieldOption<string>> = [];

  nettingForm: FormGroup;
  isViewOnly: boolean;

  errorMessages: FormFieldErrorMessageMap =
    this._formService.getErrorMessageMap('Scenario.messages');
  timePartitionOptions: Array<FormFieldOption<string>> = [
    { name: 'Hourly', value: 'hourly' },
    { name: 'Yearly', value: 'yearly' },
  ];

  formatFormValues() {
    const values = this.nettingForm.getRawValue();
    const { name, timePartition, energyVectorId, equipments, loads, nodes } =
      values;
    return {
      name,
      timePartition,
      energyVectorId,
      nodes: this.isMultiNode ? nodes['nodes'] || nodes || [] : nodes,
      equipments: !!equipments ? equipments['generics'] || equipments : [],
      loads: !!loads ? loads['generics'] || loads : [],
    };
  }

  private filterOptionsByNodes(selectedNodes, options) {
    if (
      selectedNodes?.includes(this.all) ||
      selectedNodes?.length === this.data.nodeOptions.length
    ) {
      return true;
    }
    return !!selectedNodes?.length && !!options?.nodes
      ? options.nodes.some(
          (node) =>
            selectedNodes?.indexOf(node) >= 0 ||
            options.nodes.indexOf(this.all) >= 0,
        )
      : false;
  }

  onSaveSuccess(): void {
    this.dialogRef.close();
  }

  onSaveAttempt() {
    this.submitted$.next(true);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  initData() {
    this.energyVectorOptions = this.data.energyVectorOptions;
    this.nodeOptions = this.data.nodeOptions;
    this.isMultiNode = this.data.isMultiNode;
    this.isViewOnly = this.data.isViewOnly;

    if (!this.isMultiNode || this.data.mode === 'edit') {
      this.nodesCtrl.patchValue(this.data.endUseLoad.nodes, {
        emitEvent: false,
      });
    }

    if (!!this.data.endUseLoad) {
      const { nodes, energyVectorId } = this.data.endUseLoad;
      this.loadsOptions = this.filterLoadOptions(nodes, energyVectorId);
      this.equipmentsOptions = this.filterEquipmentOptions(
        nodes,
        energyVectorId,
      );
      this.energyVectorCtrl.patchValue(this.data.endUseLoad.energyVectorId);
      this.nameCtrl.patchValue(this.data.endUseLoad.name, { emitEvent: false });
      this.timePartitionCtrl.patchValue(this.data.endUseLoad.timePartition, {
        emitEvent: false,
      });
      this.equipmentsCtrl.patchValue({
        generics: this.data.endUseLoad.equipments || [],
      });
      this.loadsCtrl.patchValue(
        { generics: this.data.endUseLoad.loads || [] },
        { emitEvent: false },
      );
    }

    this.initValidators();
  }

  initForm() {
    return this._formBuilder.group({
      name: ['', [Validators.required, NameValidator.validWithCore()]],
      timePartition: ['', Validators.required],
      energyVectorId: ['', Validators.required],
      nodes: [[], Validators.required],
      equipments: [[], Validators.required],
      loads: [[]],
    });
  }

  initValidators() {
    this.nameCtrl.setAsyncValidators(
      CustomValidators.dataExist(
        this.data.endUseLoads$,
        'name',
        this.data.endUseLoad,
      ),
    );
    combineLatest([
      this.equipmentsCtrl.valueChanges,
      this.loadsCtrl.valueChanges,
    ])
      .pipe(this.takeUntil())
      .subscribe(() => {
        this.loadsCtrl.setErrors(null);
        this.loadsCtrl.clearAsyncValidators();
      });
  }

  initOptions() {
    if (this.data.nodeOptions) {
      this.data.nodeOptions.forEach((option) =>
        option ? this.nodeOptions.push(option) : doNothing,
      );
    }

    // Every changes in selected nodes and energy vector,
    // we filter out available equipment and load options
    combineLatest([
      this.nodesCtrl.valueChanges.pipe(
        startWith(
          !this.data.isMultiNode ? { nodes: [this.all] } : { nodes: [] },
        ),
        filter((data) => !!data),
        map((data) => ({ nodes: data.nodes || data })),
      ),
      this.energyVectorCtrl.valueChanges.pipe(
        startWith([]),
        filter((data) => !!data),
      ),
    ])
      .pipe(
        throttleTime(500),
        this.takeUntil(),
        map(([nodesData, energyVector]) => ({
          selectedNodes: nodesData.nodes,
          energyVector,
        })),
      )
      .subscribe((data) => {
        const { selectedNodes, energyVector } = data;
        this.equipmentsCtrl.setValue([]);
        this.loadsCtrl.setValue([]);
        this.equipmentsOptions = this.filterEquipmentOptions(
          selectedNodes,
          energyVector,
        );
        this.loadsOptions = this.filterLoadOptions(selectedNodes, energyVector);
        // manually trigger equipment error message on edit mode only
        if (this.data.mode === 'edit') {
          this.equipmentsCtrl.setErrors({ required: true });
          this.submitted$.next(true);
        }
      });
  }

  /**
   * filter options for equipment by given
   * array of nodes and energy vector
   *
   * @param selectedNodes - Array of selected node ids
   * @param energyVector - Id of associated energy vector
   */
  filterEquipmentOptions(
    selectedNodes: string[],
    energyVector: string,
  ): Array<FormFieldOption<string>> {
    if (!selectedNodes || !selectedNodes.length || !energyVector) {
      return [];
    }
    // filter equipments associated with the selected EV
    return this.data.equipments
      .filter((equipment) => {
        // get all properties of equipment that has energy vector ID
        const { outputEnergyVector, inputEnergyVector, efficiencyMatrix } =
          (equipment || {}) as any;
        const { mainEnergyVectorInput, mainEnergyVectorOutput } =
          (efficiencyMatrix || {}) as any;
        const equipmentEnergyVector = [
          outputEnergyVector,
          inputEnergyVector,
          mainEnergyVectorInput,
          mainEnergyVectorOutput,
        ];
        return equipmentEnergyVector.includes(energyVector);
      })
      .filter((equipments) =>
        this.filterOptionsByNodes(selectedNodes, equipments),
      )
      .map((eq) => ({ name: eq.name, value: eq.id, object: eq }))
      .map((eq) => this.toWithVariationInName(eq));
  }

  /**
   * filter options for loads by given
   * nodes and energy vector
   *
   * @param selectedNodes - Array of selected node ids
   * @param energyVector - Id of associated energy vector
   */
  filterLoadOptions(
    selectedNodes: string[],
    energyVector: string,
  ): Array<FormFieldOption<string>> {
    if (!selectedNodes || !selectedNodes.length || !energyVector) {
      return [];
    }
    return this.data.loads
      .filter((loads) => {
        const { energyVector: loadEv } = loads;
        return [loadEv].includes(energyVector);
      })
      .filter((loads) => this.filterOptionsByNodes(selectedNodes, loads))
      .map((eq) => ({ name: eq.name, value: eq.id, object: eq }))
      .map((eq) => this.toWithVariationInName(eq));
  }

  ngOnInit(): void {
    this.initOptions();
    this.initData();
  }

  private toWithVariationInName(
    here: FormFieldOption<string> & { object?: unknown },
  ): FormFieldOption<string> {
    return {
      ...here,
      name: this.concatenateVariationName(here.name, here.object),
    };
  }

  private concatenateVariationName(name: string, eq: Equipment | Load): string {
    return `${name} (${this._varFinder.getVariationName(
      eq.scenarioVariation,
    )})`;
  }
}
