<form [formGroup]="form">
  <mat-tab-group
    #tabGroup
    formArrayName="intervals"
    [selectedIndex]="selected?.value"
    (selectedIndexChange)="onChangeSelectedTab($event)"
  >
    <mat-tab
      *ngFor="let interval of intervalsFormArray?.controls; let i = index"
      [disabled]="loading"
      [formGroupName]="i"
    >
      <ng-template matTabLabel>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          [ngClass]="{ 'eyes-warn': isInvalid(i) }"
        >
          <b>{{ interval?.controls?.startYear?.value || 'Start Year' }}</b>
          <mat-icon>chevron_right</mat-icon>
          <b>{{ interval?.controls?.endYear?.value || 'End Year' }}</b>
          <span *ngIf="isInvalid(i)" class="eyes-warn">*</span>
        </div>
      </ng-template>

      <ng-template matTabContent>
        <div fxLayout="row" fxLayoutGap="10px" class="tax-subsidy-inputs">
          <mat-form-field class="eyes-maximize-width" appearance="outline">
            <mat-label>{{
              'Scenario.labels.taxAndSubsidies.generationTariffSubsidy'
                | translate
            }}</mat-label>
            <input
              matInput
              formControlName="generationTariffSubsidy"
              type="number"
              [placeholder]="
                'Scenario.placeholders.taxAndSubsidies.generationTariffSubsidy'
                  | translate
              "
              data-testid="tax-subsidy-generation-tariff-subsidy"
            />
            <mat-icon
              matSuffix
              tooltipPosition="left"
              class="eyes-input-tooltip-anchor"
              [prosumerTooltip]="
                'wizard_netting.wizard_generation_tariff' | translate
              "
              data-testid="tax-subsidy-generation-tariff-subsidy-help"
            >
              help
            </mat-icon>
          </mat-form-field>
          <mat-form-field class="eyes-maximize-width" appearance="outline">
            <mat-label>{{
              'Scenario.labels.taxAndSubsidies.autoConsumptionTax' | translate
            }}</mat-label>
            <input
              matInput
              formControlName="autoConsumptionTax"
              type="number"
              [placeholder]="
                'Scenario.placeholders.taxAndSubsidies.autoConsumptionTax'
                  | translate
              "
              data-testid="tax-subsidy-auto-consumption-tax"
            />
            <mat-icon
              matSuffix
              tooltipPosition="left"
              class="eyes-input-tooltip-anchor"
              [prosumerTooltip]="
                'wizard_netting.wizard_autoconsumption' | translate
              "
              data-testid="tax-subsidy-auto-consumption-help"
            >
              help
            </mat-icon>
          </mat-form-field>
          <mat-form-field class="eyes-maximize-width" appearance="outline">
            <mat-label>{{
              'Scenario.labels.taxAndSubsidies.feedInTariffSubsidy' | translate
            }}</mat-label>
            <input
              matInput
              formControlName="feedInTariffSubsidy"
              type="number"
              [placeholder]="
                'Scenario.placeholders.taxAndSubsidies.feedInTariffSubsidy'
                  | translate
              "
              data-testid="tax-subsidy-feed-in-tariff-subsidy"
            />
            <mat-icon
              matSuffix
              tooltipPosition="left"
              class="eyes-input-tooltip-anchor"
              [prosumerTooltip]="
                'wizard_netting.wizard_feed_in_tariff' | translate
              "
              data-testid="tax-subsidy-feed-in-tariff-help"
            >
              help
            </mat-icon>
          </mat-form-field>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab disabled>
      <ng-template mat-tab-label>
        <button
          [disabled]="disabled || loading"
          mat-flat-button
          color="accent"
          (click)="onEditInterval()"
        >
          <mat-icon>edit</mat-icon>
          <span>Interval</span>
        </button>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <button id="btn" [hidden]="true"></button>
</form>
