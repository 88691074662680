<button
  *ngIf="!hideDownloadFiles"
  data-testid="download-output"
  data-testid="download-output"
  mat-menu-item
  type="button"
  color="primary"
  (click)="onDownloadOutput()"
  [disabled]="disabledDownloadOutput() | async"
  [ngClass]="{
    'scenario-action-inactive': disabledDownloadOutput() | async,
    'scenario-action-link-active': (disabledDownloadOutput() | async) !== true
  }"
>
  <mat-icon
    tooltipPosition="center"
    eyesTooltip="Download Output File"
    aria-label="Download Output File"
    class="material-icons-outlined"
    data-testid="scenario-action-menu-download-output"
    >output</mat-icon
  >
  <span>{{ 'Case.labels.downloadOutputFile' | translate }}</span>
</button>

<button
  *ngIf="!hideDownloadFiles"
  class="scenario-action-link-active"
  data-testid="download-input"
  data-testid="download-input"
  mat-menu-item
  type="button"
  color="primary"
  (click)="onDownloadInput()"
  [disabled]="disabledDownloadInput() | async"
  [ngClass]="{
    'scenario-action-inactive': disabledDownloadInput() | async,
    'scenario-action-link-active': (disabledDownloadInput() | async) !== true
  }"
>
  <mat-icon
    tooltipPosition="center"
    eyesTooltip="Download Input File"
    aria-label="Download Input File"
    data-testid="scenario-action-menu-download-input"
    >input</mat-icon
  >
  <span> {{ 'Case.labels.downloadInputFile' | translate }}</span>
</button>

<button
  *ngIf="simulating$ | ngrxPush; else summary"
  class="scenario-action-link-active"
  data-testid="view-realtime-logs"
  data-testid="view-realtime-logs"
  mat-menu-item
  color="primary"
  (click)="onViewRealTime()"
>
  <mat-icon class="material-icons-outlined">pending_actions</mat-icon>
  {{ 'Case.labels.viewRealtimeLogs' | translate }}
</button>
<ng-template #summary>
  <prosumer-scenario-logs
    [scenario]="scenario$ | ngrxPush"
    mode="text"
  ></prosumer-scenario-logs>
</ng-template>

<button
  class="scenario-action-link-active"
  data-testid="duplicate"
  data-testid="duplicate"
  mat-menu-item
  color="primary"
  (click)="onDuplicate()"
  [disabled]="disabledDuplicate() | async"
  [ngClass]="{
    'scenario-action-inactive': disabledDuplicate() | async,
    'scenario-action-link-active': (disabledDuplicate() | async) !== true
  }"
>
  <mat-icon
    class="material-icons-outlined"
    data-testid="scenario-action-menu-duplicate-scenario"
    >content_copy</mat-icon
  >
  {{ 'Case.labels.duplicateScenario' | translate }}
</button>

<button
  data-testid="delete"
  data-testid="delete"
  mat-menu-item
  color="primary"
  (click)="onDelete()"
  [disabled]="disabledDelete() | async"
  [ngClass]="{ 'scenario-action-inactive': disabledDelete() | async }"
>
  <mat-icon
    color="warn"
    class="material-icons-outlined"
    data-testid="scenario-action-menu-delete-scenario"
  >
    delete
  </mat-icon>
  {{ 'Case.labels.deleteScenario' | translate }}
</button>
