<div fxLayout="column" fxLayoutAlign="start stretch">
  <div fxLayout="row">
    <div fxFlex="2%"></div>
    <h2 class="self-center text-center" fxFlex="96%">
      {{ 'Result.labels.tableOfValuesPerYear' | translate }}
    </h2>
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      class="self-end mat-button hover-opacity"
      fxFlex="2%"
      data-testid="tabulator-export"
    >
      <mat-icon mat-icon-button type="button" color="primary"
        >save_alt</mat-icon
      >
    </button>
    <mat-menu #menu="matMenu" data-testid="tabulator-export-menu">
      <button
        mat-menu-item
        class="text-center"
        data-testid="tabulator-export-xlsx"
        [exportTable]="table"
        format="xlsx"
        [name]="exportName"
      >
        Export as xlsx
      </button>
      <button
        mat-menu-item
        class="text-center"
        data-testid="tabulator-export-csv"
        [exportTable]="table"
        format="csv"
        [name]="exportName"
      >
        Export as csv
      </button>
    </mat-menu>
  </div>
  <div class="tabulator-container">
    <table
      mat-table
      #table
      [dataSource]="dataSource$ | async"
      matSort
      data-testid="tabulator"
    >
      <ng-container matColumnDef="name" sticky class="tabulator-sticky-name">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="tabulator-sticky-name tabulator-sticky-header"
        >
          {{ 'Result.labels.name' | translate }}
        </th>
        <td mat-cell *matCellDef="let data" class="tabulator-sticky-name">
          {{ data?.name }}
        </td>
        <td
          mat-footer-cell
          *matFooterCellDef
          class="tabulator-sticky-footer tabulator-sticky-name tabulator-sticky-total"
        >
          ∑
        </td>
      </ng-container>

      <ng-container
        *ngFor="let year of yearColumns$ | async"
        [matColumnDef]="year"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          class="tabulator-sticky-header"
          mat-sort-header
        >
          {{ year }}
        </th>
        <td mat-cell *matCellDef="let data">
          {{ ((data || {})[year] | number : '1.0-2') || '-' }}
        </td>
        <td mat-footer-cell *matFooterCellDef class="tabulator-sticky-footer">
          {{ (totalValues$ | async)[year] | number : '1.0-2' }}
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="columnDef$ | async; sticky: true"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: columnDef$ | async"></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="columnDef$ | async; sticky: true"
      ></tr>
    </table>
  </div>
</div>
