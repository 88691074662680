<prosumer-progress-card [showProgress]="inProgress$ | async">
  <h2 data-testid="latest-solar-profiles-title">
    {{ 'RenewableProfile.labels.latestSolarProfiles' | translate }}
    <prosumer-tooltip-anchor
      tooltipPosition="right"
      [message]="'renewable_profile.latest_solar_profile_section'"
      data-testid="renewable-profile-latest-solar-profiles-tooltip"
    >
    </prosumer-tooltip-anchor>
  </h2>
  <div
    class="table-container"
    class="eyes-table-sticky"
    [style.maxHeight.px]="300"
  >
    <mat-table
      matSort
      class="eyes-table"
      [dataSource]="renewableProfilesDataSource$ | async"
      data-testid="latest-solar-profiles-table"
    >
      <ng-container cdkColumnDef="trackingType">
        <mat-header-cell
          *cdkHeaderCellDef
          mat-sort-header
          data-testid="header-cell-tracking-type"
        >
          <span>
            {{ 'RenewableProfile.labels.trackingType' | translate }}
          </span>
        </mat-header-cell>
        <mat-cell
          *cdkCellDef="let row"
          tooltipPosition="center"
          [prosumerTooltip]="row?.trackingType"
          tooltipPanelClass="eyes-width-fit"
          class="mat-cell-name"
        >
          <ngx-skeleton-loader
            *ngIf="row?.loading && !row?.loaded && !row?.error; else loaded"
            class="eyes-maximize-width"
          ></ngx-skeleton-loader>
          <ng-template #loaded> {{ row.trackingType || '-' }} </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="panelTilt">
        <mat-header-cell
          *cdkHeaderCellDef
          mat-sort-header
          data-testid="header-cell-panel-tilt"
          >{{
            'RenewableProfile.labels.panelTilt' | translate
          }}</mat-header-cell
        >
        <mat-cell
          *cdkCellDef="let row"
          tooltipPosition="center"
          [prosumerTooltip]="row?.panelTilt"
          tooltipPanelClass="eyes-width-fit"
          class="mat-cell-name"
        >
          <ngx-skeleton-loader
            *ngIf="row?.loading && !row?.loaded && !row?.error; else loaded"
            class="eyes-maximize-width"
          ></ngx-skeleton-loader>
          <ng-template #loaded>
            {{ row.panelTilt || '-' }}
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="panelOrientation">
        <mat-header-cell
          *cdkHeaderCellDef
          mat-sort-header
          data-testid="header-cell-panel-orientation"
          >{{
            'RenewableProfile.labels.panelOrientation' | translate
          }}</mat-header-cell
        >
        <mat-cell
          *cdkCellDef="let row"
          tooltipPosition="center"
          [prosumerTooltip]="row?.panelOrientation"
          tooltipPanelClass="eyes-width-fit"
          class="mat-cell-name"
        >
          <ngx-skeleton-loader
            *ngIf="row?.loading && !row?.loaded && !row?.error; else loaded"
            class="eyes-maximize-width"
          ></ngx-skeleton-loader>
          <ng-template #loaded>
            {{ row.panelOrientation || '-' }}
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="coordinates" class="mat-column-coordinates">
        <mat-header-cell
          *cdkHeaderCellDef
          class="mat-column-coordinates"
          data-testid="header-cell-coordinates"
          >{{
            'RenewableProfile.labels.coordinates.title' | translate
          }}</mat-header-cell
        >
        <mat-cell *cdkCellDef="let row" class="mat-column-coordinates">
          <ngx-skeleton-loader
            *ngIf="row?.loading && !row?.loaded && !row?.error; else loaded"
          ></ngx-skeleton-loader>
          <ng-template #loaded>
            <div style="position: relative">
              <button
                mat-icon-button
                type="button"
                color="primary"
                [disabled]="true"
              >
                <mat-icon
                  aria-label="row?.coordinates"
                  tooltipPosition="center"
                  [prosumerTooltip]="row?.coordinates"
                >
                  place</mat-icon
                >
              </button>
            </div>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="computeType">
        <mat-header-cell
          *cdkHeaderCellDef
          mat-sort-header
          data-testid="header-cell-computation"
          >{{
            'RenewableProfile.labels.computation' | translate
          }}</mat-header-cell
        >
        <mat-cell
          *cdkCellDef="let row"
          tooltipPosition="center"
          [prosumerTooltip]="row?.computationData"
          tooltipPanelClass="eyes-width-fit"
          class="mat-cell-name"
        >
          <ngx-skeleton-loader
            *ngIf="row?.loading && !row?.loaded && !row?.error; else loaded"
            class="eyes-maximize-width"
          ></ngx-skeleton-loader>
          <ng-template #loaded>
            {{ row.computeType || '-' }}
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="status">
        <mat-header-cell
          *cdkHeaderCellDef
          mat-sort-header
          data-testid="header-cell-status"
        >
          {{ 'RenewableProfile.labels.status' | translate }}
          <prosumer-tooltip-anchor
            [message]="'renewable_profile.solar_profile_status'"
            data-testid="renewable-profile-solar-profile-status-tooltip"
          >
          </prosumer-tooltip-anchor>
        </mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <ngx-skeleton-loader
            *ngIf="row?.loading && !row?.loaded && !row?.error; else loaded"
            class="eyes-maximize-width"
          ></ngx-skeleton-loader>
          <ng-template #loaded>
            <div
              [class]="statusMap[row?.status]?.class"
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="10px"
            >
              <mat-icon [attr.aria-label]="statusMap[row?.status]?.label">
                {{ statusMap[row?.status]?.icon }}</mat-icon
              >
              <strong>{{ statusMap[row?.status]?.label }}</strong>
            </div>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container cdkColumnDef="output">
        <mat-header-cell *cdkHeaderCellDef data-testid="header-cell-output">
          Output
          <prosumer-tooltip-anchor
            [message]="'renewable_profile.solar_profile_output'"
            data-testid="renewable-profile-solar-profile-output-tooltip"
          >
          </prosumer-tooltip-anchor>
        </mat-header-cell>

        <mat-cell *cdkCellDef="let row">
          <ngx-skeleton-loader
            *ngIf="row?.loading && !row?.loaded && !row?.error; else loaded"
            class="eyes-maximize-width"
          >
          </ngx-skeleton-loader>
          <ng-template #loaded>
            <div style="position: relative">
              <div
                *ngIf="
                  row?.status !== 'failed' && row?.status !== 'error';
                  else failedStatus
                "
              >
                <button
                  mat-icon-button
                  type="button"
                  color="primary"
                  [disabled]="
                    (inProgress$ | async) || row?.status !== 'succeeded'
                  "
                  (click)="onDownload(row)"
                >
                  <mat-icon
                    aria-label="Download Renewable Profile"
                    tooltipPosition="center"
                    eyesTooltip="Download Renewable Profile"
                    >cloud_download</mat-icon
                  >
                </button>
              </div>
              <ng-template #failedStatus>
                <button
                  mat-icon-button
                  type="button"
                  color="primary"
                  [disabled]="inProgress$ | async"
                  (click)="onDownload(row)"
                >
                  <mat-icon
                    aria-label="Download Log File"
                    tooltipPosition="center"
                    eyesTooltip="Download Log File"
                  >
                    description</mat-icon
                  >
                </button>
              </ng-template>

              <mat-spinner
                *ngIf="row?.downloading === 'input'"
                diameter="40"
                class="eyes-absolute-top"
                color="accent"
              ></mat-spinner>
            </div>
          </ng-template>
        </mat-cell>
      </ng-container>
      <mat-header-row *cdkHeaderRowDef="TABLE_COLUMNS; sticky: true">
      </mat-header-row>
      <mat-row
        *cdkRowDef="let row; columns: TABLE_COLUMNS"
        [@rowAnimation]
      ></mat-row>
    </mat-table>
    <prosumer-table-message
      noRecordsMessage="RenewableProfile.messages.noLatestSolarProfiles"
      [style.margin-left.px]="20"
      [style.margin-top.px]="5"
      [loading]="loadingList$ | async"
      [error]="error$ | async"
      [dataLength]="renewableProfileDataLength$ | async"
    >
    </prosumer-table-message>
  </div>
</prosumer-progress-card>
