<div class="legend-container {{ details.canvasId }}" *ngIf="!!details">
  <ng-container *ngFor="let item of details.legendItems; index as i">
    <span
      class="item"
      [title]="item.text"
      [attr.data-testid]="item.text"
      (click)="toggleCustomLegendItem($event, i)"
      (mouseenter)="onMouseEnter($event, i)"
      (mouseout)="onMouseLeave()"
    >
      <mat-checkbox [checked]="true" [disabled]="true"></mat-checkbox>
      <span
        class="color"
        [ngStyle]="{
          'background-color': item.fillStyle,
          'border-color': item.borderColor,
          'border-width.px': item.lineWidth
        }"
      ></span>
      <label [ngStyle]="{ 'font-color': item.fontColor }">{{
        item.text
      }}</label>
    </span>
  </ng-container>
</div>
