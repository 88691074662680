import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const INVERSE_VALID_NAME_PATTERN = /[^a-zA-Z0-9\s\-_]+/;

export class NameValidator {
  static invalidCharacters =
    (regex: RegExp): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      if (!!control && !!control.value) {
        if (!!!control.value.trim()) {
          return { whiteSpaces: true };
        }
        return control.value.match(regex) ? { invalidCharacter: true } : null;
      }

      return null;
    };

  static validWithCore(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      NameValidator.invalidCharacters(INVERSE_VALID_NAME_PATTERN)(control);
  }

  static isValidWithCore(value: string): boolean {
    return !value.match(INVERSE_VALID_NAME_PATTERN) && !!value.trim();
  }
}
