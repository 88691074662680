<div
  class="flow-container"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="16px"
>
  <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="16px">
    <mat-form-field appearance="outline" class="eyes-spacer-half">
      <mat-label data-testid="flow-result-years-mat-label">{{
        'Scenario.labels.simulationResults.year' | translate
      }}</mat-label>
      <mat-select
        [formControl]="yearSelectControl"
        required
        [placeholder]="'Scenario.labels.simulationResults.year' | translate"
        data-testid="flow-result-years-mat-select"
      >
        <mat-option
          *ngFor="let year of years$ | async"
          [value]="year"
          [attr.data-testid]="'flow-result-years-mat-select-' + year"
        >
          {{ year }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <prosumer-sankey-chart
      *ngIf="selectedData$ | async"
      [sankeyData]="selectedData$ | async"
      [colors]="colors$ | async"
    ></prosumer-sankey-chart>
  </div>
</div>
