import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { NotificationsService } from 'prosumer-app/shared/services/notification';
import {
  FetchUserByEmailAction,
  FetchUserByIDAction,
  FetchUserFailure,
  FetchUserSuccess,
  UserAcceptGDPRAction,
  UserAcceptGDPRFailureAction,
  UserAcceptGDPRSuccessAction,
  UserActionTypes,
  UserActions,
  UserGetGDPRFailureAction,
  UserGetGDPRSuccessAction,
} from './user.actions';
import { OldRetrievalType } from './user.model';
import { UserService } from './user.service';

@Injectable()
export class OldUserEffects {
  constructor(
    private _actions$: Actions,
    private _notification: NotificationsService,
    private _userService: UserService,
  ) {}

  @Effect()
  getGDPR$: Observable<UserActions> = this._actions$.pipe(
    ofType(UserActionTypes.GET_GDPR),
    switchMap(() =>
      this._userService.getGDPRPolicy().pipe(
        map(
          (response) =>
            new UserGetGDPRSuccessAction(
              response.latestVersion,
              response.text,
              response.acceptedVersion,
            ),
        ),
        catchError((response) => of(new UserGetGDPRFailureAction(response))),
      ),
    ),
  );

  @Effect({ dispatch: false })
  getGDPRFailure$: Observable<UserActions> = this._actions$.pipe(
    ofType(UserActionTypes.GET_GDPR_FAILURE),
    tap((action: UserGetGDPRFailureAction) =>
      this._notification.showError(action.payload.error),
    ),
  );

  @Effect()
  acceptGDPR$: Observable<UserActions> = this._actions$.pipe(
    ofType(UserActionTypes.ACCEPT_GDPR),
    switchMap((action: UserAcceptGDPRAction) =>
      this._userService
        .acceptGDPRPolicy(action.payload.latestVersionToAccept)
        .pipe(
          map(
            () =>
              new UserAcceptGDPRSuccessAction(
                action.payload.latestVersionToAccept,
              ),
          ),
          catchError((response) =>
            of(
              new UserAcceptGDPRFailureAction(
                action.payload.latestVersionToAccept,
                response,
              ),
            ),
          ),
        ),
    ),
  );

  @Effect({ dispatch: false })
  acceptGDPRFailure$: Observable<UserActions> = this._actions$.pipe(
    ofType(UserActionTypes.ACCEPT_GDPR_FAILURE),
    tap((action: UserAcceptGDPRFailureAction) =>
      this._notification.showError(action.payload.error),
    ),
  );

  @Effect()
  fetchUserByEmail$: Observable<UserActions> = this._actions$.pipe(
    ofType(UserActionTypes.FETCH_BY_EMAIL),
    switchMap((action: FetchUserByEmailAction) =>
      this._userService.getUserByEmail(action.payload.emailAddress).pipe(
        map(
          (response) =>
            new FetchUserSuccess(response, OldRetrievalType.EMAIL, 'SUCCESS'),
        ),
        catchError((response) =>
          of(
            new FetchUserFailure(
              action.payload.emailAddress,
              response.error.error,
            ),
          ),
        ),
      ),
    ),
  );

  @Effect()
  fetchUserById$: Observable<UserActions> = this._actions$.pipe(
    ofType(UserActionTypes.FETCH_BY_ID),
    mergeMap((action: FetchUserByIDAction) =>
      this._userService.getUserByID(action.payload.uuid).pipe(
        map(
          (response) =>
            new FetchUserSuccess(response, OldRetrievalType.ID, 'SUCCESS'),
        ),
        catchError((response) =>
          of(new FetchUserFailure(action.payload.uuid, response.error.error)),
        ),
      ),
    ),
  );

  @Effect({ dispatch: false })
  fetchUserFailure$: Observable<UserActions> = this._actions$.pipe(
    ofType(UserActionTypes.FETCH_USER_FAILURE),
    tap((action: FetchUserFailure) =>
      this._notification.showError(action.payload.error),
    ),
  );
}
