import { OptimizationCockpitCompletion } from 'prosumer-app/+scenario/services/completion-strategies';
import {
  ScenarioCompletionService,
  ScenarioWizardStep,
} from 'prosumer-app/+scenario/services/scenario-completion';
import { RouterStore } from 'prosumer-app/libs/eyes-core';
import { StepFormComponent } from 'prosumer-app/libs/eyes-shared';
import {
  ScenarioVariationInfo,
  ScenarioVariationQuery,
} from 'prosumer-app/stores/scenario-variation';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { FormBuilder, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'prosumer-optimization-cockpit-form',
  templateUrl: './optimization-cockpit-form.component.html',
  styleUrls: ['./optimization-cockpit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptimizationCockpitFormComponent
  extends StepFormComponent
  implements OnInit, AfterViewInit
{
  _scenarioIdentity: any;
  get scenarioIdentity(): any {
    return this._scenarioIdentity;
  }
  @Input() set scenarioIdentity(_scenarioIdentity: any) {
    this._scenarioIdentity = _scenarioIdentity;
  }

  @Input() startYear: number;
  @Input() endYear: number;
  @Input() isViewOnly: boolean;

  scenarioVariations$: Observable<ScenarioVariationInfo[]>;
  projectId: string;
  caseId: string;
  scenarioId: string;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: FormBuilder,
    private router: RouterStore,
    private completion: ScenarioCompletionService,
    private readonly _variationQuery: ScenarioVariationQuery,
  ) {
    super(ngControl, changeDetector, formBuilder);
    this.subscribeToFormForCompletionTracking();
  }

  defineForm() {
    return {
      limits: {},
      options: {},
      customOptions: [],
    };
  }

  ngOnInit() {
    this.initScenarioVariationsObservable();
  }

  ngAfterViewInit() {
    this.setIds();
  }

  setIds() {
    this.router.params$.pipe(this.takeUntil()).subscribe((data) => {
      this.projectId = data.projectId;
      this.caseId = data.caseId;
      this.scenarioId = data.scenarioId;
    });
  }

  private subscribeToFormForCompletionTracking(): void {
    const strategy = new OptimizationCockpitCompletion();
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((form) => {
      this.completion.setForStep(
        ScenarioWizardStep.optimizationCockpit,
        strategy.determineStatus(form),
      );
    });
  }

  private initScenarioVariationsObservable() {
    this.scenarioVariations$ = this._variationQuery
      .selectAllForActiveScenario()
      .pipe(filter((list) => this.filterOutLoadingStoreVariationList(list)));
  }

  private filterOutLoadingStoreVariationList(
    list: ScenarioVariationInfo[],
  ): boolean {
    return !list.some((variation) => variation['loading'] === true);
  }
}
