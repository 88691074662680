import { EmissionsData, YEARLY_DETAILS_REGEX_PATTERN } from '../models';
import { BaseExtractor, YearlyDetailsExtractor } from './base.extractor';

export class EmissionsExtractor
  extends YearlyDetailsExtractor<EmissionsData>
  implements BaseExtractor<EmissionsData>
{
  extract(resultsJson: unknown): EmissionsData[] {
    return !!resultsJson
      ? [
          ...this.extractCumulative(resultsJson, 'cumulative_emissions'),
          ...this.extractIncremental(resultsJson, 'incremental_emissions'),
        ]
      : [];
  }

  protected parseDetails(details: string): Partial<EmissionsData> {
    const detailList = details.match(YEARLY_DETAILS_REGEX_PATTERN);
    const cleanedUp = detailList.map((detail) =>
      detail.replace(/\'/, '').replace(/\'/, ''),
    );
    return {
      scope: this.getElement(0, cleanedUp),
      type: this.getElement(1, cleanedUp),
      name: this.getElement(2, cleanedUp),
      node: this.getElement(3, cleanedUp),
    };
  }
}
