<ng-container *ngrxLet="period$ as period">
  <div
    fxLayout="row"
    fxLayoutGap="10px"
    [formGroup]="limitsForm"
    *ngrxLet="loadingMap$ as loading"
  >
    <div fxFlex="10%" class="variation-name">
      <span title="{{ scenVarName$ | ngrxPush }}">{{
        scenVarName$ | ngrxPush
      }}</span>
    </div>
    <prosumer-yearly-chart-input
      fxFlex="25%"
      formControlName="yearlyMaximumEmissions"
      inputLabel="Scenario.labels.optimizationCockpit.yearlyMaximumEmissions"
      errorMessage="Scenario.messages.yearlyMaximumEmissions"
      prosumerVariationUpdater
      [nextValue]="yearlyMaxEmissions$ | ngrxPush"
      (statusChange)="onYearlyEmissionsChange($event)"
      [invalid]="yearlyMaximumEmissionsCtr.errors"
      [startYear]="period[0]"
      [endYear]="period[1]"
      [minValue]="minValue"
      [notRequired]="true"
      [isViewOnly]="isViewOnly"
      [processing]="loading.yearlyMaximumEmissions"
      [contextHelpMsg]="
        'wizard_optimization_cockpit.wizard_yearly_maximum_emissions'
      "
      data-testid="variation-yearlyMaximumEmissions"
    >
    </prosumer-yearly-chart-input>

    <prosumer-yearly-chart-input
      data-testid="optimization-cockpit-yearly-max-costs-input"
      fxFlex="25%"
      formControlName="yearlyMaximumCosts"
      inputLabel="Scenario.labels.optimizationCockpit.yearlyMaximumCosts"
      errorMessage="Scenario.messages.yearlyMaximumCosts"
      prosumerVariationUpdater
      [nextValue]="yearlyMaxCosts$ | ngrxPush"
      (statusChange)="onYearlyCostsChange($event)"
      [invalid]="yearlyMaximumCostsCtrl.errors"
      [startYear]="period[0]"
      [endYear]="period[1]"
      [minValue]="minValue"
      [notRequired]="true"
      [isViewOnly]="isViewOnly"
      [processing]="loading.yearlyMaximumCosts"
      [contextHelpMsg]="
        'wizard_optimization_cockpit.wizard_yearly_maximum_cost'
      "
      data-testid="variation-yearlyMaximumCosts"
    >
    </prosumer-yearly-chart-input>

    <div fxLayout="column" fxFlex="20%">
      <mat-form-field
        data-testid="optimization-cockpit-global-max-emissions-input"
        class="eyes-maximize-width"
        appearance="outline"
        [@fadeIn]
      >
        <mat-label>
          {{
            'Scenario.labels.optimizationCockpit.globalMaximumEmissions'
              | translate
          }}
        </mat-label>
        <input
          matInput
          formControlName="globalMaximumEmissions"
          type="number"
          [placeholder]="
            'Scenario.labels.optimizationCockpit.globalMaximumEmissions'
              | translate
          "
          [readonly]="isViewOnly"
          prosumerVariationUpdater
          [nextValue]="globalMaxEmissions$ | ngrxPush"
          [hasSkip]="false"
          (statusChange)="onGlobalEmissionsChange($event)"
          [invalid]="globalMaximumEmissionsCtrl.errors"
          data-testid="variation-globalMaximumEmissions"
        />
        <mat-icon
          class="eyes-input-tooltip-anchor"
          matSuffix
          [prosumerTooltip]="
            'wizard_optimization_cockpit.global_maximum_emissions'
          "
          tooltipPosition="left"
          data-testid="prosumer-limits-global-max-emissions-tooltip"
        >
          help
        </mat-icon>
        <mat-error
          *ngFor="
            let error of limitsForm.get('globalMaximumEmissions')?.errors
              | keyvalue
          "
          [ngSwitch]="error.key"
        >
          <div
            *ngSwitchCase="'required'"
            [innerHtml]="
              'Scenario.messages.globalMaximumEmissions.required' | translate
            "
          ></div>
          <div
            *ngSwitchCase="'negativeValue'"
            [innerHtml]="
              'Scenario.messages.globalMaximumEmissions.negativeValue'
                | translate
            "
          ></div>
        </mat-error>
      </mat-form-field>
      <mat-progress-bar
        color="accent"
        mode="indeterminate"
        *ngIf="loading.globalMaximumEmissions"
      >
      </mat-progress-bar>
    </div>

    <div fxLayout="column" fxFlex="20%">
      <mat-form-field
        data-testid="optimization-cockpit-global-max-costs-input"
        class="eyes-maximize-width"
        appearance="outline"
        [@fadeIn]
      >
        <mat-label>
          {{
            'Scenario.labels.optimizationCockpit.globalMaximumCosts' | translate
          }}
        </mat-label>
        <input
          matInput
          formControlName="globalMaximumCosts"
          type="number"
          [placeholder]="
            'Scenario.labels.optimizationCockpit.globalMaximumCosts' | translate
          "
          [readonly]="isViewOnly"
          prosumerVariationUpdater
          [nextValue]="globalMaxCosts$ | ngrxPush"
          [hasSkip]="false"
          (statusChange)="onGlobalCostsChange($event)"
          [invalid]="globalMaximumCostsCtrl.errors"
          data-testid="variation-globalMaximumCosts"
        />
        <mat-icon
          class="eyes-input-tooltip-anchor"
          matSuffix
          [prosumerTooltip]="'wizard_optimization_cockpit.global_maximum_cost'"
          tooltipPosition="left"
          data-testid="prosumer-limits-global-max-costs-tooltip"
        >
          help
        </mat-icon>
        <mat-error
          *ngFor="
            let error of limitsForm.get('globalMaximumCosts')?.errors | keyvalue
          "
          [ngSwitch]="error.key"
        >
          <div
            *ngSwitchCase="'required'"
            [innerHtml]="
              'Scenario.messages.globalMaximumCosts.required' | translate
            "
          ></div>
          <div
            *ngSwitchCase="'negativeValue'"
            [innerHtml]="
              'Scenario.messages.globalMaximumCosts.negativeValue' | translate
            "
          ></div>
        </mat-error>
      </mat-form-field>
      <mat-progress-bar
        color="accent"
        mode="indeterminate"
        *ngIf="loading.globalMaximumCosts"
      >
      </mat-progress-bar>
    </div>
  </div>
</ng-container>
