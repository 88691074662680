import { ConfigService, RouterStore } from 'prosumer-app/libs/eyes-core';
import { generateEndpoint } from 'prosumer-app/libs/eyes-shared';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { ScenarioStore } from './scenario-store.service';
import {
  GetLatestModifiedAction,
  GetLatestModifiedFailureAction,
  GetLatestModifiedSuccessAction,
  GetLatestSimulationAction,
  GetLatestSimulationFailureAction,
  GetLatestSimulationSuccessAction,
  ScenarioActionTypes,
  ScenarioActions,
  ScenarioDownloadFileAction,
  ScenarioDownloadFileFailureAction,
  ScenarioDownloadFileSuccessAction,
  ScenarioDownloadXlsxTemplateAction,
  ScenarioDownloadXlsxTemplateFailureAction,
  ScenarioDownloadXlsxTemplateSuccessAction,
  ScenarioGetDraftsFailure,
  ScenarioGetDraftsSuccess,
} from './scenario.actions';

@Injectable()
export class ScenarioEffects {
  @Effect()
  downloadXlsxTemplate$: Observable<ScenarioActions> = this._actions$.pipe(
    ofType(ScenarioActionTypes.DOWNLOAD_XLSX_TEMPLATE),
    mergeMap((action: ScenarioDownloadXlsxTemplateAction) => {
      const endpointName = action.payload.endpointName;
      return this._http
        .get(
          generateEndpoint(
            this._config.api.baseUrl,
            this._config.api.endpoints['scenario'][endpointName],
          ),
        )
        .pipe(
          mergeMap((downloadSignedUrl: any) =>
            this._http
              .get(downloadSignedUrl.url, { responseType: 'blob' })
              .pipe(
                map((response: any) => {
                  const regex = /([^/]+)\.xlsx/;
                  // finds name of file with .xlsx extension
                  const res = regex.exec(downloadSignedUrl.url);

                  const blob = new Blob([response], {
                    type: 'application/octet-stream',
                  });
                  const resUrl = window.URL.createObjectURL(blob);
                  const anchor = document.createElement('a');
                  anchor.download = res[0];
                  anchor.href = resUrl;
                  anchor.click();
                  return new ScenarioDownloadXlsxTemplateSuccessAction(
                    response,
                    true,
                  );
                }),
                catchError((errResponse: HttpErrorResponse) =>
                  of(
                    new ScenarioDownloadXlsxTemplateFailureAction(
                      errResponse.error.error ||
                        this._translate.instant(
                          'Scenario.messages.downloadXlsxTemplateFailure',
                        ),
                    ),
                  ),
                ),
              ),
          ),
          catchError((errResponse: HttpErrorResponse) =>
            of(
              new ScenarioDownloadXlsxTemplateFailureAction(
                !errResponse.error.error ||
                errResponse.error.error === 'Unexpected error'
                  ? this._translate.instant(
                      'Scenario.messages.downloadXlsxTemplateFailure',
                    )
                  : errResponse.error.error,
              ),
            ),
          ),
        );
    }),
  );

  @Effect({ dispatch: false })
  downloadXlsxTemplateSuccess$: Observable<ScenarioActions> =
    this._actions$.pipe(
      ofType(ScenarioActionTypes.DOWNLOAD_XLSX_TEMPLATE_SUCCESS),
      tap((action: ScenarioDownloadXlsxTemplateSuccessAction) => {
        if (action.payload.notify) {
          this._notification.showSuccess(
            this._translate.instant(
              'Scenario.messages.downloadXlsxTemplateSuccess',
            ),
          );
        }
      }),
    );

  @Effect({ dispatch: false })
  downloadXlsxTemplateFailure$: Observable<ScenarioActions> =
    this._actions$.pipe(
      ofType(ScenarioActionTypes.DOWNLOAD_XLSX_TEMPLATE_FAILURE),
      tap((action: ScenarioDownloadXlsxTemplateFailureAction) => {
        this._notification.showError(action.payload.error);
      }),
    );

  @Effect({ dispatch: false })
  downloadFileSuccess$: Observable<ScenarioActions> = this._actions$.pipe(
    ofType(ScenarioActionTypes.DOWNLOAD_FILE_SUCCESS),
    tap((action: ScenarioDownloadFileSuccessAction) => {
      if (action.payload.notify) {
        this._notification.showSuccess(
          this._translate.instant('Scenario.messages.downloadSuccess'),
        );
      }
    }),
  );

  @Effect({ dispatch: false })
  downloadFileFailure$: Observable<ScenarioActions> = this._actions$.pipe(
    ofType(ScenarioActionTypes.DOWNLOAD_FILE_FAILURE),
    tap((action: ScenarioDownloadFileFailureAction) => {
      this._notification.showError(action.payload.error);
    }),
  );

  /** @deprecated No longer used after scenario-api.service download is used */
  @Effect()
  downloadFile$: Observable<ScenarioActions> = this._actions$.pipe(
    ofType(ScenarioActionTypes.DOWNLOAD_FILE),
    mergeMap((action: ScenarioDownloadFileAction) => {
      const projId = action.payload.projectId;
      const caseId = action.payload.caseId;
      const scenarioId = action.payload.scenarioId;
      const endpointName = action.payload.endpointName;

      return this._http
        .get(
          generateEndpoint(
            this._config.api.baseUrl,
            this._config.api.endpoints['scenario'][endpointName],
            projId,
            caseId,
            scenarioId,
          ),
        )
        .pipe(
          mergeMap((downloadSignedUrl: any) =>
            this._http
              .get(downloadSignedUrl.url, { responseType: 'blob' })
              .pipe(
                map((response) => {
                  const buildName = (
                    endpoint: string,
                    getSignedRsp: any,
                    name: string,
                  ) => {
                    // Default the download file name to the scenario name
                    let correctName = getSignedRsp.name;
                    const splittedName: Array<string> = correctName.split('.');
                    const FILE_EXT = splittedName[splittedName.length - 1];
                    switch (endpoint) {
                      case 'getSigned': {
                        correctName = `${name}.${FILE_EXT}`;
                        break;
                      }
                      case 'getOutputSigned': {
                        correctName = `${name}_out.${FILE_EXT}`;
                        break;
                      }
                    }
                    return correctName;
                  };
                  const blob = new Blob([response], {
                    type: 'application/octet-stream',
                  });
                  const resUrl = window.URL.createObjectURL(blob);
                  const anchor = document.createElement('a');
                  anchor.download = buildName(
                    endpointName,
                    downloadSignedUrl,
                    action.payload.scenarioName,
                  );
                  anchor.href = resUrl;
                  anchor.click();
                  return new ScenarioDownloadFileSuccessAction(response, true);
                }),
                catchError((errResponse: HttpErrorResponse) =>
                  of(
                    new ScenarioDownloadFileFailureAction(
                      errResponse.error.error ||
                        this._translate.instant(
                          'Scenario.messages.logDownloadFailure',
                        ),
                      projId,
                      caseId,
                      scenarioId,
                    ),
                  ),
                ),
              ),
          ),
          catchError((errResponse: HttpErrorResponse) =>
            of(
              new ScenarioDownloadFileFailureAction(
                !errResponse.error.error ||
                errResponse.error.error === 'Unexpected error'
                  ? this._translate.instant(
                      'Scenario.messages.logDownloadFailure',
                    )
                  : errResponse.error.error,
                projId,
                caseId,
                scenarioId,
              ),
            ),
          ),
        );
    }),
  );

  @Effect()
  getLatestSimulation$: Observable<ScenarioActions> = this._actions$.pipe(
    ofType(ScenarioActionTypes.GET_LATEST),
    mergeMap((action: GetLatestSimulationAction) =>
      this._http
        .get(
          generateEndpoint(
            this._config.api.baseUrl,
            this._config.api.endpoints['scenario']['latestSimulations'],
          ),
        )
        .pipe(
          map(
            (response: any) =>
              new GetLatestSimulationSuccessAction(
                response,
                this._translate.instant(
                  'Scenario.messages.getLatestSimulationSuccess',
                ),
                false,
              ),
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              new GetLatestSimulationFailureAction(
                (response && response.error && response.error.error) ||
                  this._translate.instant(
                    'Scenario.messages.getLatestSimulationFailure',
                  ),
              ),
            ),
          ),
        ),
    ),
  );

  @Effect({ dispatch: false })
  getLatesSimulationFailure$: Observable<ScenarioActions> = this._actions$.pipe(
    ofType(ScenarioActionTypes.GET_LATEST_FAILURE),
    mergeMap((action: GetLatestSimulationFailureAction) =>
      this._notification
        .showError(action.payload.error, 'Retry')
        .onAction()
        .pipe(tap(() => this._scenarioStore.getLatestSimulation())),
    ),
  );

  @Effect()
  getLatestModified$: Observable<ScenarioActions> = this._actions$.pipe(
    ofType(ScenarioActionTypes.GET_LATEST_MODIFIED),
    mergeMap((action: GetLatestModifiedAction) =>
      this._http
        .get(
          generateEndpoint(
            this._config.api.baseUrl,
            this._config.api.endpoints['scenario']['latestModifiedScenarios'],
          ),
        )
        .pipe(
          map(
            (response: any) =>
              new GetLatestModifiedSuccessAction(
                response,
                this._translate.instant(
                  'Scenario.messages.getLatestModifiedSuccess',
                ),
                false,
              ),
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              new GetLatestModifiedFailureAction(
                (response && response.error && response.error.error) ||
                  this._translate.instant(
                    'Scenario.messages.getLatestModifiedFailure',
                  ),
              ),
            ),
          ),
        ),
    ),
  );

  @Effect({ dispatch: false })
  getLatestModifiedFailure$: Observable<ScenarioActions> = this._actions$.pipe(
    ofType(ScenarioActionTypes.GET_LATEST_MODIFIED_FAILURE),
    mergeMap((action: GetLatestModifiedFailureAction) =>
      this._notification
        .showError(action.payload.error, 'Retry')
        .onAction()
        .pipe(tap(() => this._scenarioStore.getLatestSimulation())),
    ),
  );

  @Effect()
  getDrafts$: Observable<ScenarioActions> = this._actions$.pipe(
    ofType(ScenarioActionTypes.GET_DRAFTS),
    mergeMap(() => {
      const MOCK_EM_JOHN = '/assets/mocks/ongoing-scenarios.mocka.json';
      // return this._http.get(
      //   MOCK_EM_JOHN
      // )
      return this._http
        .get(
          generateEndpoint(
            this._config.api.baseUrl,
            this._config.api.endpoints['scenario']['getDrafts'],
          ),
        )
        .pipe(
          map((response: any) => new ScenarioGetDraftsSuccess(response)),
          catchError((error: HttpErrorResponse) =>
            of(
              new ScenarioGetDraftsFailure(
                ((error || ({} as any)).error || {}).error ||
                  this._translate.instant('Dashboard.messages.draftsError'),
              ),
            ),
          ),
        );
    }),
  );

  @Effect({ dispatch: false })
  getDraftsSuccess$: Observable<ScenarioActions> = this._actions$.pipe(
    ofType(ScenarioActionTypes.GET_DRAFTS_SUCCESS),
    tap((action: ScenarioGetDraftsSuccess) => {
      // ¯\_(ツ)_/¯
    }),
  );

  @Effect({ dispatch: false })
  getDraftsFailure$: Observable<ScenarioActions> = this._actions$.pipe(
    ofType(ScenarioActionTypes.GET_DRAFTS_FAILURE),
    tap((action: ScenarioGetDraftsFailure) => {
      this._notification
        .showError(action.payload.error, 'Retry')
        .onAction()
        .subscribe(() => this._scenarioStore.getScenariosInDraft());
    }),
  );

  constructor(
    private _actions$: Actions,
    private _config: ConfigService,
    // private _http: HttpService,
    private _http: HttpClient,
    private _scenarioStore: ScenarioStore,
    private _routerStore: RouterStore,
    private _notification: NotificationsService,
    private _translate: TranslateService,
  ) {}
}
