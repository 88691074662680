import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PolicyDialogData } from './policy-dialog.model';

@Component({
  selector: 'prosumer-policy-dialog',
  templateUrl: './policy-dialog.component.html',
  styleUrls: ['./policy-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyDialogComponent {
  formData: FormGroup = this._formBuilder.group({});
  accepted = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PolicyDialogData,
    public dialogRef: MatDialogRef<PolicyDialogComponent>,
    private _formBuilder: FormBuilder,
  ) {}

  onConfirm(): void {
    this.dialogRef.close(this.formData.getRawValue());
  }

  checkValue(event): void {
    this.accepted = !this.accepted;
  }
}
