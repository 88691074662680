<mat-card class="message-card" data-testid="session-expires-message">
  <mat-card-title data-testid="title">
    {{ 'Dashboard.messages.sessionExpired' | translate }}</mat-card-title
  >
  <mat-card-content data-testid="message-a">
    {{ 'Dashboard.messages.expiredAccess' | translate }}
    {{ 'Dashboard.messages.expiredAccessParagraph' | translate }}
  </mat-card-content>
  <mat-card-content data-testid="message-b">
    {{ 'Dashboard.messages.clickOkToRedirectLoginScreen' | translate }}
  </mat-card-content>
  <mat-card-actions>
    <button
      data-testid="redirect"
      class="redirect"
      data-testid="redirect"
      mat-flat-button
      color="primary"
      (click)="redirectLogin()"
    >
      OK
    </button>
  </mat-card-actions>
</mat-card>
