import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { ComboLineChartComponent } from './combo-line-chart-component';
import { ComboSeriesVerticalComponent } from './combo-series-vertical.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
  imports: [CommonModule, OverlayModule, NgxChartsModule],
  declarations: [ComboLineChartComponent, ComboSeriesVerticalComponent],
  exports: [ComboLineChartComponent, ComboSeriesVerticalComponent],
  entryComponents: [ComboLineChartComponent, ComboSeriesVerticalComponent],
})
export class ComboLineChartModule {}
