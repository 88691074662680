import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { RouterStore } from './router-store.service';
import { routerFeatureName } from './router.actions';
import { RouterEffects } from './router.effects';
import { CustomSerializer, routerReducerExtended } from './router.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('router', routerReducerExtended),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
      stateKey: routerFeatureName,
    }),
    EffectsModule.forFeature([RouterEffects]),
  ],
})
export class RouterModule {
  static forRoot(): ModuleWithProviders<RouterModule> {
    return {
      ngModule: RouterModule,
      providers: [
        [{ provide: RouterStateSerializer, useClass: CustomSerializer }],
        RouterStore,
      ],
    };
  }
}
