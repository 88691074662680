import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { filterNilValue } from '@datorama/akita';
import { TranslateService } from '@ngx-translate/core';
import {
  ColumnDefinition,
  FormFieldErrorMessageMap,
  fadeInAnimation,
  getKeys,
} from 'prosumer-app/libs/eyes-shared';
import { FileUploadDragAndDropComponent } from 'prosumer-app/shared';
import { ManagedDataService } from 'prosumer-app/shared/services/managed-data';

const FILE_FORMATS = ['.gms'];

@Component({
  selector: 'prosumer-gams-upload-form',
  templateUrl: './gams-upload-form.component.html',
  styleUrls: ['./gams-upload-form.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GamsUploadFormComponent implements OnInit {
  @ViewChild(FileUploadDragAndDropComponent, { read: ElementRef })
  fileUploadDragAndDropComponent: ElementRef;

  fileFormats = FILE_FORMATS;
  gamsUploadForm: FormGroup = this._formBuilder.group({
    filesInput: [''],
  });

  get filesInputCtrl() {
    return this.gamsUploadForm.controls.filesInput;
  }

  columnDef: ColumnDefinition = {
    name: {
      name: 'File Name',
    },
    actions: {
      name: 'Actions',
      type: 'action',
      flex: '20%',
    },
  };

  gamsFiles$ = this.managedData.listData$;

  gamsUploadErrorMessages(): FormFieldErrorMessageMap {
    return {
      alreadyExist: this._translate.instant(
        'Scenario.messages.gamsUploadAttachment.alreadyExist',
      ),
    };
  }

  constructor(
    private readonly _translate: TranslateService,
    private readonly _formBuilder: FormBuilder,
    readonly managedData: ManagedDataService<any>,
  ) {
    this.clearGamsListData();
  }

  ngOnInit(): void {
    this.subscribeToInputFile();
  }

  private clearGamsListData() {
    this.managedData.setListData([]);
  }

  private subscribeToInputFile() {
    this.filesInputCtrl.valueChanges
      .pipe(filterNilValue())
      .subscribe((inputFile) => {
        let err = null;
        if (this.validateUniqueFileName(inputFile)) {
          this.managedData.add(inputFile);
        } else {
          err = { alreadyExist: true };
        }
        this.filesInputCtrl.patchValue(null, { emitEvent: false });
        this.filesInputCtrl.setErrors(err);

        this._clearInputElementRefValue();
      });
  }

  _clearInputElementRefValue() {
    const el = this.fileUploadDragAndDropComponent;
    el.nativeElement.querySelector('input').value = null;
  }

  private validateUniqueFileName(inputFile): boolean {
    const existing = this.managedData.getListData();
    const res = existing.find((file) => file.name === inputFile.name);
    return res ? false : true;
  }

  onDelete(data: any) {
    this.managedData.delete(data);
    this.filesInputCtrl.reset(null);
  }

  /**
   * Collects form error
   *
   * @param errorObj - form control error objects
   */
  getErrors(errorObj: any) {
    return errorObj ? getKeys(errorObj) : [];
  }
}
