import { Pipe, PipeTransform } from '@angular/core';
import { ColorHelper } from '@swimlane/ngx-charts';

/**
 * @param seriesDataList - the data array of the chart
 * @param currentSeriesData - the current data the mouse is currently hovered at in the chart
 * @param scheme - default color sets could be defined here,
 * refer to https://github.com/swimlane/ngx-charts/blob/master/src/utils/color-sets.ts
 * @param type - the chart type this pipe is used for
 * @param customColors - not mandatory, but overrides the chart scheme default domain color
 */
@Pipe({
  name: 'seriesDataList',
})
export class SeriesDataListPipe implements PipeTransform {
  transform(
    seriesDataList: Array<any>,
    currentSeriesData: any,
    scheme: any,
    type: 'bar' | 'default' = 'default',
    customColors?: any,
  ): any {
    switch (type) {
      case 'bar':
        return this.transformBarChartSeries(
          seriesDataList,
          currentSeriesData,
          scheme,
          customColors,
        );
      case 'default':
        const seriesList = [];
        if (!seriesDataList || !currentSeriesData) {
          return seriesList;
        }
        seriesDataList.forEach((data) => {
          const seriesLookup = data.series.find(
            (series) => series.name === currentSeriesData.name,
          );
          if (seriesLookup) {
            seriesList.push({
              series: data.name,
              value: seriesLookup.value,
              current: data.name === currentSeriesData.series,
              color: new ColorHelper(
                scheme,
                'ordinal',
                seriesDataList.map((d) => d.name),
              ).getColor(data.name),
            });
          }
        });
        return seriesList;
    }
  }

  transformBarChartSeries(
    seriesDataList: Array<any>,
    currentSeriesData: any,
    scheme: any,
    customColors?: any,
  ) {
    const foundData = seriesDataList.find(
      (data) => data.name === currentSeriesData.series,
    );
    return foundData.series.map((data) => ({
      series: data.name,
      value: data.value,
      current: data.name === currentSeriesData.name,
      color: new ColorHelper(
        scheme,
        'ordinal',
        foundData.series.map((d) => d.name),
        customColors,
      ).getColor(data.name),
    }));
  }
}
