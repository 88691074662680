<div
  class="energy-vectors-container"
  fxLayout="row wrap"
  fxLayoutGap="10px"
  fxLayoutAlign="stretch center"
>
  <prosumer-icon-button-toggle
    *ngFor="let energyVector of energyVectors; let i = index"
    [disabled]="disabled"
    [readonly]="readonly"
    [icon]="energyVector?.icon"
    [label]="energyVector?.label"
    [value]="energyVector?.value"
    [selected]="selection?.isSelected(energyVector?.value)"
    (select)="onSelect($event)"
    [attr.data-testid]="'energy-vector-select-' + energyVector?.label"
    [waitingResponse]="waitingResponse$ | ngrxPush"
  >
  </prosumer-icon-button-toggle>

  <button
    *ngIf="showAdd && !readonly"
    mat-icon-button
    class="add-custom-vector-button"
    type="button"
    color="primary"
    [disabled]="disabled"
    (click)="onClickAdd()"
    [attr.data-testid]="'energy-vector-add-custom'"
  >
    <mat-icon matPrefix class="add-custom-vector-icon">add_circle</mat-icon>
  </button>
</div>
