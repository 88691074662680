import { ConfigService } from 'prosumer-app/libs/eyes-core';
import { ApiService } from 'prosumer-app/libs/eyes-shared';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';

import { Case } from '../models';
import { UpdateRequestCode } from './case-api.tokens';

@Injectable()
export class CaseApiService extends ApiService<Case> {
  constructor(private _http: HttpClient, private _config: ConfigService) {
    super(_config.api.baseUrl);
  }

  create(data: Case): Observable<Case> {
    return this._http
      .post(
        this.endpoint(this._config.api.endpoints.case.create, data.projectId),
        this.mapDataToBackend(data),
      )
      .pipe(
        map((response) => this.mapDataToFrontend(response)),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  get(id: string, params?: Dictionary<string>): Observable<Case> {
    return this._http
      .get<any>(this.endpoint(this._config.api.endpoints.case.get, id, params))
      .pipe(
        map((response) => this.mapDataToFrontend(response)),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  getList(id: string): Observable<Array<string>> {
    return this._http
      .get<Array<string>>(
        this.endpoint(this._config.api.endpoints.case.list, id),
      )
      .pipe(catchError((error) => throwError(this.handleError(error))));
  }

  /* For getting list of cases: slim=2 */
  getListWithDetails(id: string): Observable<Array<Case>> {
    return this._http
      .get<Array<Case>>(
        this.endpoint(this._config.api.endpoints.case.listWithDetails, id),
      )
      .pipe(
        map((cases) =>
          cases.map((data) => this.mapDataToFrontend(data) as Case),
        ),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  updateChartColors(data: Case): Observable<Case> {
    return this.update(data, {
      requestCode: UpdateRequestCode.UPDATE_CHART_COLORS,
    });
  }

  update(data: Case, params?: Dictionary<string>): Observable<Case> {
    return this._http
      .post(
        this.endpoint(this._config.api.endpoints.case.update, data.id, {
          projectId: data.projectId,
        }),
        {
          ...this.mapDataToBackend(data),
          ...(params || {}),
        },
      )
      .pipe(
        map((response) => this.mapDataToFrontend(response)),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  delete(data: Case, params?: Dictionary<string>): Observable<any> {
    return this._http
      .delete<any>(
        this.endpoint(this._config.api.endpoints.case.delete, data.id, {
          projectId: data.projectId,
        }),
      )
      .pipe(catchError((error) => throwError(this.handleError(error))));
  }

  copy(data: Case, name: string): Observable<any> {
    return this._http
      .post<any>(
        this.endpoint(this._config.api.endpoints.case.copy, data.id, {
          projectId: data.projectId,
        }),
        { name },
      )
      .pipe(catchError((error) => throwError(this.handleError(error))));
  }

  getCaseListByNodeType(
    nodetype: 'single' | 'multinode',
  ): Observable<Array<Case>> {
    return this._http
      .get<Array<Case>>(
        this.endpoint(
          this._config.api.endpoints.case.getListByNodeType,
          nodetype,
        ),
      )
      .pipe(
        mergeMap((cases) =>
          cases.map((data) => this.mapDataToFrontend(data) as Array<Case>),
        ),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  mapDataToBackend(data: Case): any {
    const DEFAULT_VALUE = '0.0';
    return {
      projectUuid: data.projectId,
      caseUuid: data.id,
      owner: data.owner,
      name: data.name,
      description: data.description,
      createdAt: data.createdDate,
      updatedAt: data.updatedDate,
      economics: {
        wacc: data.wacc,
        inflationRate: DEFAULT_VALUE,
        taxDepreciation: DEFAULT_VALUE,
        taxRevenue: DEFAULT_VALUE,
      },
      nodeType: data.nodeType,
      colorScheme: { ...data.colorScheme },
    };
  }

  mapDataToFrontend(response: any): Case {
    return {
      id: response.caseUuid,
      owner: response.owner,
      projectId: response.projectUuid,
      name: response.name,
      description: response.description,
      createdFrom: response.createdFrom,
      nodeType: response.nodeType,
      // economics
      wacc: (response.economics || {}).wacc,
      createdDate: response.createdAt,
      updatedDate: response.updatedAt,
      copyStatus: response.duplicateStatus,
      colorScheme: response.colorScheme,
      createdBy: response.createdBy,
      ownerName: response.ownerName,
    };
  }
}
