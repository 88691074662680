import { EfficiencyPoint } from 'prosumer-app/+scenario';
import {
  BaseFormComponent,
  fadeInAnimation,
} from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  NgControl,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'prosumer-eff-form-array',
  templateUrl: './efficiency-form-array.component.html',
  styleUrls: ['./efficiency-form-array.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EfficiencyFormArrayComponent
  extends BaseFormComponent
  implements OnInit
{
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly MINIMUM_POINTS = 2;

  points$ = new BehaviorSubject<EfficiencyPoint[]>([]);
  efficiencyPointsLength$ = this.points$.pipe(
    map((points: EfficiencyPoint[]) => points.length),
  );
  havePoints$ = this.efficiencyPointsLength$.pipe(map((length) => length > 0));
  addOneVisibility$ = this.efficiencyPointsLength$.pipe(
    map((length) => length > 0 && length < 10),
  );

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: FormBuilder,
  ) {
    super(ngControl, changeDetector, formBuilder);
  }

  ngOnInit() {
    super.ngOnInit();
    // Subscribe to formArray value changes
    this.getFormArray()
      .valueChanges.pipe(
        filter((_) => !!_),
        this.takeUntilShare(),
      )
      .subscribe((points) => this.points$.next(points));
  }

  createItem(item?: EfficiencyPoint): FormGroup {
    const form = this.formBuilder.group({
      percentageOfCapacity: ['0', Validators.required],
      consumption: ['0', Validators.required],
    });
    if (item) {
      form.patchValue(item);
    }
    return form;
  }

  defineForm() {
    return {
      items: this.formBuilder.array([]),
    };
  }

  getFormArray(): FormArray {
    return this.form.get('items') as FormArray;
  }

  addItem(item?: EfficiencyPoint): void {
    this.getFormArray().push(this.createItem(item));
  }

  removeItem(index = 0): void {
    this.getFormArray().removeAt(index);
  }

  isHiddenBtn(index: number): boolean {
    return index > 1;
  }

  disableAdd(): boolean {
    if (this.getFormArray() && this.getFormArray().value.length >= 10) {
      return false;
    }
    return true;
  }

  writeValue(efficiencyCurve: EfficiencyPoint[]) {
    if (!!efficiencyCurve) {
      efficiencyCurve.forEach((point) => this.addItem(point));
      this.points$.next(efficiencyCurve);
    }
  }

  clearFormArray = () =>
    Array(this.getFormArray().length)
      .fill(0)
      .forEach((_) => this.removeItem());

  initializePoints = () =>
    Array(this.MINIMUM_POINTS)
      .fill(0)
      .forEach((_) => this.addItem());
}
