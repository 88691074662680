import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

import { getKeys, isKeyPressedNumber } from '../../../utilities/index';
import { FormFieldComponent } from '../form-field.component';
import { FormFieldErrorMessageMap } from '../form-field.model';
import { InputIcon } from './input.model';

@Component({
  selector: 'prosumer-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComponent extends FormFieldComponent implements OnInit {
  @Input() isTextArea: boolean;
  @Input() required: boolean;
  @Input() readonly: boolean;
  @Input() maxLength: number;
  @Input() hint: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() type: string;
  @Input() textAreaLines = 8;

  @Input() set disabled(disabled: boolean) {
    if (!this.control) {
      return;
    }
    if (disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  @Input() hideNumberSpinner = true;

  @Input() control: AbstractControl = new FormControl();

  @Input() prefixIcon: InputIcon;
  @Input() suffixIcon: InputIcon;
  @Input() inputTooltip: string;
  @Input() inputTooltipPosition = 'right';

  @Input() set errorMessageMap(errorMessageMap: FormFieldErrorMessageMap) {
    this._errorMessageMap = errorMessageMap;
    if (!this._errorMessageMap) {
      this._errorMessageMap = {};
    }
  }

  @Output() eyesKeyUp: EventEmitter<any> = new EventEmitter<any>();

  _errorMessageMap: FormFieldErrorMessageMap = {};

  constructor(@Optional() private _changeDetector?: ChangeDetectorRef) {
    super();
    this.floatLabel = 'auto';
    this.appearance = 'outline';
  }

  ngOnInit() {
    if (this.readonly) {
      this.class = { readonly: this.readonly };
    }
    if (this.control) {
      this.addSubscription(
        this.control.statusChanges.subscribe(() =>
          this._changeDetector ? this._changeDetector.markForCheck() : true,
        ),
      );
    }
  }

  getErrors(errorObj: any) {
    return errorObj ? getKeys(errorObj) : [];
  }

  onKeyUp(event: UIEvent) {
    this.eyesKeyUp.emit(event);
  }

  onKeyPress(event: KeyboardEvent) {
    if (this.type === 'number' && !isKeyPressedNumber(event)) {
      event.preventDefault();
    }
  }
}
