import { SelectNewModule } from 'prosumer-app/shared/components';
import { SharedModule } from 'prosumer-app/shared/shared.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveComponentModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';

import { CamelToTextPipe } from '../../pipes';
import { TdbTechnologySecondaryFiltersComponent } from '../tdb-technology-secondary-filters';
import { TdbTechnologyFiltersComponent } from './tdb-technology-filters.component';

@NgModule({
  declarations: [
    TdbTechnologyFiltersComponent,
    TdbTechnologySecondaryFiltersComponent,
    CamelToTextPipe,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    ReactiveComponentModule,
    SelectNewModule,
    MatButtonModule,
    SharedModule,
  ],
  exports: [TdbTechnologyFiltersComponent],
})
export class TdbTechnologyFiltersModule {}
