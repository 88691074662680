import { Coerce } from 'prosumer-app/core/utils';
import { filter } from 'rxjs/operators';

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import {
  FloatLabelType,
  MatFormFieldAppearance,
} from '@angular/material/form-field';
import { MatSelectChange } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export type Position = 'left' | 'center' | 'right';

export type FormFieldErrorMessageMap = Readonly<{
  [module: string]: { [errorKey: string]: string };
}>;

export type FormFieldOption<T> = Readonly<{
  name: string;
  value: T;
  tooltip?: string;
  tooltipPosition?: Position;
}>;

@UntilDestroy()
@Component({
  selector: 'prosumer-select-new',
  templateUrl: './select-new.component.html',
  styleUrls: ['./select-new.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectNewComponent implements OnInit {
  @Input() appearance: MatFormFieldAppearance;
  @Input() floatLabel: FloatLabelType;
  @Input() hintLabel: string;
  @Input() hint: string;
  @Input() label: string;
  @Input() tooltip: string;
  @Input() placeholder: string;
  @Input() class: string;

  @Input() readonly: boolean;
  @Input() required: boolean;
  @Input() hideRequired: boolean;
  @Input() emitPatchValue = false;
  @Input() validateInOptions = false;
  @Input() shouldDisplaySingle = true;

  @Input() set value(value: string) {
    const hadReset = this.resetIfInvalid(value);
    if (!hadReset) {
      this.patchControlValue(value);
    }
  }

  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.updateControlState();
  }

  @Input() set control(control: AbstractControl) {
    this._control = Coerce.toObject(control, new FormControl());
    this.updateControlState();
  }
  get control(): AbstractControl {
    return this._control;
  }

  @Input() set options(options: FormFieldOption<string>[]) {
    this._options = Coerce.toArray(options);
  }
  get options(): FormFieldOption<string>[] {
    return this._options;
  }

  @Input() set errorMessageMap(errorMessageMap: FormFieldErrorMessageMap) {
    this._errorMessageMap = Coerce.toObject(errorMessageMap);
  }
  get errorMessageMap(): FormFieldErrorMessageMap {
    return this._errorMessageMap;
  }

  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();

  shouldDisplay = true;
  private _disabled = false;
  private _options: FormFieldOption<string>[] = [];
  private _control: AbstractControl = new FormControl();
  private _errorMessageMap: FormFieldErrorMessageMap = {};

  constructor(@Optional() private _changeDetector?: ChangeDetectorRef) {
    this.appearance = 'outline';
    this.floatLabel = 'auto';
  }

  ngOnInit() {
    this.subscribeOnStatusAndCheck();
    this.initControlState();
  }

  getErrors(errorObj: any) {
    return Object.keys(Coerce.toObject(errorObj));
  }

  onSelectionChange(change: MatSelectChange) {
    this.selectionChange.emit(change.value);
  }

  private subscribeOnStatusAndCheck(): void {
    this.control.statusChanges
      .pipe(
        untilDestroyed(this),
        filter(() => !!this._changeDetector),
      )
      .subscribe(() => this._changeDetector.markForCheck());
  }

  private patchControlValue(value: string): void {
    let args: Object = { emitEvent: true, onlySelf: false };
    if (!this.emitPatchValue) {
      args = { emitEvent: false };
    }
    this.control.patchValue(value, args);
  }

  private resetIfInvalid(value: string): boolean {
    const isInvalid = this.checkValueValidity(value);
    if (isInvalid) {
      this.control.reset();
    }
    return isInvalid;
  }

  private checkValueValidity(value: string): boolean {
    const isIncluded = this.options.some((item) => item.value === value);
    return [!isIncluded, this.validateInOptions].every(Boolean);
  }

  private initControlState(): void {
    this.shouldDisplay = [
      !!this.shouldDisplaySingle,
      this.options.length !== 1,
    ].some(Boolean);
    if (!this.shouldDisplay) {
      this.control.patchValue(this.options[0].value, { emitEvent: false });
    }
  }

  private updateControlState(): void {
    if (!!this._disabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }
}
