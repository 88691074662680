import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog'; // Keep MatDialogRef to fix issue in build

import {
  BaseDialogComponent,
  DialogData,
  NUMBERS_REGEXP,
} from 'prosumer-app/libs/eyes-shared';
import { Observable } from 'rxjs';

export const DEFAULT_WIDTH = 300;

@Injectable()
export class DialogService {
  constructor(private _dialog: MatDialog) {}

  allDialogsClosed$ = this._dialog.afterAllClosed;
  dialogOpened$ = this._dialog.afterOpened.asObservable();

  openDialog(
    component: ComponentType<any> = BaseDialogComponent,
    data: DialogData | any = { width: DEFAULT_WIDTH },
  ): Observable<any> {
    const dialogRef: MatDialogRef<any, any> = this._dialog.open(component, {
      width: this.parseWidth(data.width),
      data: { ...data },
      disableClose: data.disableClose,
      panelClass: data.panelClass,
    });

    return dialogRef.afterClosed();
  }

  parseWidth(width: any) {
    if (!width) {
      return `${DEFAULT_WIDTH}px`;
    }
    return NUMBERS_REGEXP.test(String(width)) ? width : `${width}px`;
  }

  closeAllDialogs(): void {
    this._dialog.closeAll();
  }
}
