import { ScenarioResult } from 'prosumer-app/+scenario/models';
import { SCENARIO_RESULT_DISPATCH_OPTIONS } from 'prosumer-app/app.references';

import { ScenarioCompareState } from './scenario-compare-state.model';
import {
  AllCompareActions,
  CompareActionTypes,
} from './scenario-compare.actions';

const initialState: ScenarioCompareState = {
  resultList: undefined,
  referenceId: undefined,
  scenarioResultList: undefined,
  emissionsResult: undefined,
  dispatchTypes: SCENARIO_RESULT_DISPATCH_OPTIONS,
  loading: false,
  loaded: false,
  message: undefined,
  isOutputSplit: false,
};

// TODO: Update the expected return values based on the data needed

export function scenarioCompareReducer(
  state: ScenarioCompareState = initialState,
  action: AllCompareActions,
): ScenarioCompareState {
  switch (action.type) {
    case CompareActionTypes.COMPARE_SCENARIO:
      return {
        ...state,
        resultList: action.payload.resultList,
        referenceId: action.payload.referenceId,
        loading: true,
      };
    case CompareActionTypes.COMPARE_SCENARIO_SUCCESS:
      return {
        ...state,
        scenarioResultList: action.payload.data,
        loading: false,
        loaded: true,
        message: action.payload.message,
      };
    case CompareActionTypes.COMPARE_SCENARIO_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case CompareActionTypes.MIGRATE_SCENARIO:
      return {
        ...state,
        resultList: action.payload.resultList,
        referenceId: action.payload.referenceId,
        loading: true,
      };
    case CompareActionTypes.MIGRATE_SCENARIO_SUCCESS:
      return {
        ...state,
        scenarioResultList: action.payload.data,
        isOutputSplit: action.payload.isOutputSplit,
        message: action.payload.message,
      };
    case CompareActionTypes.MIGRATE_SCENARIO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case CompareActionTypes.COMPARE_SCENARIO_MAIN_TAB:
      return {
        ...state,
        resultList: action.payload.resultList,
        referenceId: action.payload.referenceId,
        loading: true,
      };

    case CompareActionTypes.COMPARE_SCENARIO_MAIN_TAB_SUCCESS:
      return {
        ...state,
        scenarioResultList: action.payload.data,
        loading: false,
        message: action.payload.message,
      };

    case CompareActionTypes.COMPARE_SCENARIO_MAIN_TAB_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };

    case CompareActionTypes.COMPARE_SCENARIO_EQUIPMENT_TAB:
      return {
        ...state,
        loading: true,
      };
    case CompareActionTypes.COMPARE_SCENARIO_EQUIPMENT_TAB_SUCCESS:
      return {
        ...state,
        equipmentsResult: action.payload.data,
        loading: false,
        message: action.payload.message,
      };
    case CompareActionTypes.COMPARE_SCENARIO_EQUIPMENT_TAB_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case CompareActionTypes.COMPARE_SCENARIO_DISPATCH_TAB:
      return {
        ...state,
        loading: true,
      };
    case CompareActionTypes.COMPARE_SCENARIO_DISPATCH_TAB_SUCCESS:
      const resultListDispatch = state.scenarioResultList.map(
        (scenarioResult) => ({
          ...scenarioResult,
          dispatchResult: filterResultData(action.payload.data, scenarioResult),
        }),
      );
      return {
        ...state,
        scenarioResultList: resultListDispatch,
        loading: false,
        message: action.payload.message,
      };
    case CompareActionTypes.COMPARE_SCENARIO_DISPATCH_TAB_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case CompareActionTypes.COMPARE_SCENARIO_ENERGY_BALANCE_TAB:
      return {
        ...state,
        loading: true,
      };
    case CompareActionTypes.COMPARE_SCENARIO_ENERGY_BALANCE_TAB_SUCCESS:
      const resultListEnergyBalance = state.scenarioResultList.map(
        (scenarioResult) => ({
          ...scenarioResult,
          energyBalanceResult: filterResultData(
            action.payload.data,
            scenarioResult,
          ),
        }),
      );
      return {
        ...state,
        scenarioResultList: resultListEnergyBalance,
        loading: false,
        message: action.payload.message,
      };
    case CompareActionTypes.COMPARE_SCENARIO_ENERGY_BALANCE_TAB_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case CompareActionTypes.COMPARE_SCENARIO_TOPOLOGY_TAB:
      return {
        ...state,
        loading: true,
      };
    case CompareActionTypes.COMPARE_SCENARIO_TOPOLOGY_TAB_SUCCESS:
      return {
        ...state,
        topologiesResult: action.payload.data,
        loading: false,
        message: action.payload.message,
      };
    case CompareActionTypes.COMPARE_SCENARIO_TOPOLOGY_TAB_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case CompareActionTypes.COMPARE_SCENARIO_CO2_EMISSION_TAB:
      return {
        ...state,
        loading: true,
      };

    case CompareActionTypes.COMPARE_SCENARIO_CO2_EMISSION_TAB_SUCCESS:
      return {
        ...state,
        emissionsResult: action.payload.data,
        loading: false,
      };
    case CompareActionTypes.COMPARE_SCENARIO_CO2_EMISSION_TAB_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };

    case CompareActionTypes.COMPARE_SCENARIO_CASHFLOW_TAB:
      return {
        ...state,
        loading: true,
      };
    case CompareActionTypes.COMPARE_SCENARIO_CASHFLOW_TAB_SUCCESS:
      const resultListCashFlow = state.scenarioResultList.map(
        (scenarioResult, index) => ({
          ...scenarioResult,
          cashFlowResult: action.payload.data[index][0],
          paybackResult: action.payload.data[index][1],
        }),
      );
      return {
        ...state,
        scenarioResultList: resultListCashFlow,
        loading: false,
        message: action.payload.message,
      };
    case CompareActionTypes.COMPARE_SCENARIO_CASHFLOW_TAB_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    default:
      return state;
  }
}

export const filterResultData = (
  data: any[],
  scenarioResult: ScenarioResult,
): any =>
  data.find(
    (datum) =>
      datum.scenarioId === scenarioResult.scenarioId &&
      (!!!scenarioResult.variationId ||
        datum.variationId === scenarioResult.variationId),
  );
