import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { filterNilValue } from '@datorama/akita';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Emissions, ProsumerView } from 'prosumer-app/+scenario/models';
import { PipeUtils, Utils } from 'prosumer-app/core/utils';
import { mapYearlyValuesToBackend } from 'prosumer-app/shared';
import { UpdateStatus } from 'prosumer-app/shared/directives/scenario-updater';
import {
  ScenarioGenericQuery,
  ScenarioUpdateKey,
} from 'prosumer-app/stores/scenario-generic';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  BEModel,
  FormModel,
  IncomingEmissions,
  OutgoingEmissions,
} from './commodities-emissions.models';
import { CommoditiesEmissionsFormMapper } from './commodities-emissions-form.mapper';

@UntilDestroy()
@Component({
  selector: 'prosumer-commodities-emissions',
  templateUrl: './commodities-emissions.component.html',
  styleUrls: ['./commodities-emissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommoditiesEmissionsComponent implements OnInit {
  private readonly scope1Status = new BehaviorSubject<UpdateStatus>(null);
  private readonly scope2Status = new BehaviorSubject<UpdateStatus>(null);
  private readonly scope3Status = new BehaviorSubject<UpdateStatus>(null);
  readonly emissionsGroup = new FormGroup({
    co2Price: new FormControl(),
    co2Scope2Price: new FormControl(),
    co2Scope3Price: new FormControl(),
  });
  readonly outgoingScope1$ = this.selectOutgoing1();
  readonly outgoingScope2$ = this.selectOutgoing2();
  readonly outgoingScope3$ = this.selectOutgoing3();
  readonly scope1Loading$ = this.selectScope1Loading();
  readonly scope2Loading$ = this.selectScope2Loading();
  readonly scope3Loading$ = this.selectScope3Loading();
  readonly updateKey: ScenarioUpdateKey = 'emissions';
  readonly updateView: ProsumerView = ProsumerView.commodities;

  startYear$: Observable<number>;
  endYear$: Observable<number>;
  startYearValue?: number;
  endYearValue?: number;

  get co2PriceCtrl() {
    return this.emissionsGroup.controls.co2Price;
  }
  get co2Scope2PriceCtrl() {
    return this.emissionsGroup.controls.co2Scope2Price;
  }
  get co2Scope3PriceCtrl() {
    return this.emissionsGroup.controls.co2Scope3Price;
  }

  constructor(private readonly scenarios: ScenarioGenericQuery) {}

  ngOnInit(): void {
    this.startYear$ = this.selectStartYear();
    this.endYear$ = this.selectEndYear();
    this.waitForYearValuesToInitialize();
  }

  onScope1Change(status: UpdateStatus): void {
    this.scope1Status.next(status);
  }

  onScope2Change(status: UpdateStatus): void {
    this.scope2Status.next(status);
  }

  onScope3Change(status: UpdateStatus): void {
    this.scope3Status.next(status);
  }

  private selectScope1Loading(): Observable<boolean> {
    return this.scope1Status.pipe(map((status) => this.isLoading(status)));
  }

  private selectScope2Loading(): Observable<boolean> {
    return this.scope2Status.pipe(map((status) => this.isLoading(status)));
  }

  private selectScope3Loading(): Observable<boolean> {
    return this.scope3Status.pipe(map((status) => this.isLoading(status)));
  }

  private isLoading(status: UpdateStatus): boolean {
    return Utils.resolveToEmptyObject(status).status === 'loading';
  }

  private waitForYearValuesToInitialize() {
    combineLatest([this.startYear$, this.endYear$])
      .pipe(
        filter(([startYear, endYear]) => !!startYear && !!endYear),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        tap(([startYear, endYear]) => {
          this.startYearValue = startYear;
          this.endYearValue = endYear;
        }),
        switchMap((_) => this.take1FromActiveEmissionsForFormPatching()),
      )
      .subscribe((emissions) =>
        this.emissionsGroup.patchValue(emissions, { emitEvent: false }),
      );
  }

  private take1FromActiveEmissionsForFormPatching(): Observable<Emissions> {
    return this.scenarios.selectActiveEmissions().pipe(
      filterNilValue(),
      map((emissions) => this.toIncoming(emissions)),
    );
  }

  private toIncoming(from: OutgoingEmissions): IncomingEmissions {
    return CommoditiesEmissionsFormMapper.toFE(from, [
      this.startYearValue,
      this.endYearValue,
    ]);
  }

  private selectOutgoing1(): Observable<OutgoingEmissions> {
    return this.selectScope1ValueChanges().pipe(
      PipeUtils.filterOutNullUndefined,
      filter(this.filterOutNegativeValues.bind(this)),
      map((value) => ({ co2Price: this.toOutgoingScope(value) })),
    );
  }

  private selectOutgoing2(): Observable<OutgoingEmissions> {
    return this.selectScope2ValueChanges().pipe(
      PipeUtils.filterOutNullUndefined,
      filter(this.filterOutNegativeValues.bind(this)),
      map((value) => ({ co2Scope2Price: this.toOutgoingScope(value) })),
    );
  }

  private selectOutgoing3(): Observable<OutgoingEmissions> {
    return this.selectScope3ValueChanges().pipe(
      PipeUtils.filterOutNullUndefined,
      filter(this.filterOutNegativeValues.bind(this)),
      map((value) => ({ co2Scope3Price: this.toOutgoingScope(value) })),
    );
  }

  private toOutgoingScope(from: FormModel): BEModel {
    return mapYearlyValuesToBackend(from) as BEModel;
  }

  private selectScope1ValueChanges(): Observable<FormModel> {
    const control = this.emissionsGroup.get('co2Price');
    return control.valueChanges.pipe(startWith(control.value));
  }

  private selectScope2ValueChanges(): Observable<FormModel> {
    const control = this.emissionsGroup.get('co2Scope2Price');
    return control.valueChanges.pipe(startWith(control.value));
  }

  private selectScope3ValueChanges(): Observable<FormModel> {
    const control = this.emissionsGroup.get('co2Scope3Price');
    return control.valueChanges.pipe(startWith(control.value));
  }

  private selectStartYear(): Observable<number> {
    return this.scenarios
      .selectActivePeriod()
      .pipe(map(([startYear]) => startYear));
  }

  private selectEndYear(): Observable<number> {
    return this.scenarios
      .selectActivePeriod()
      .pipe(map(([, endYear]) => endYear));
  }

  private filterOutNegativeValues(values: FormModel): boolean {
    return [Object.values(values).every((v) => Number(v) >= 0)].some(Boolean);
  }
}
