<div
  fxLayout="row"
  fxLayoutAlign="start center"
  *ngIf="scenario"
  [ngClass]="'scenario-name-div-' + infoStyle"
>
  <div
    fxLayout="row"
    fxLayoutAlign="center start"
    fxLayoutGap="4px"
    [class.unclickable]="!allowScenarioNameClick"
    [class.clickable]="allowScenarioNameClick"
    (mouseenter)="hovering = true"
    (mouseleave)="hovering = false"
    *ngIf="!clicked; else editName"
    data-testid="viewName"
    [ngClass]="'scenario-name-' + infoStyle"
  >
    <span
      data-testid="scenarioName"
      (click)="onClickScenarioName(); $event.stopPropagation()"
    >
      {{ scenario.name }}
    </span>
    <div
      *ngIf="!editable || !hovering; else editIconContainer"
      class="edit-icon"
    ></div>
    <ng-template #editIconContainer>
      <div *ngIf="hovering" class="edit-icon">
        <mat-icon
          *ngIf="!isEditable(); else editIcon"
          class="disabled-icon"
          [inline]="true"
          data-testid="editButtonDisabled"
          >edit_off</mat-icon
        >
        <ng-template #editIcon>
          <mat-icon
            data-testid="editButton"
            (click)="switchToEdit(); $event.stopPropagation()"
            [inline]="true"
            color="primary"
            >edit</mat-icon
          >
        </ng-template>
      </div>
    </ng-template>
  </div>

  <ng-template #editName>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Scenario.labels.general.name' | translate }}</mat-label>
        <input
          matInput
          type="text"
          data-testid="editName"
          [formControl]="nameControl"
          (click)="$event.stopPropagation()"
        />
      </mat-form-field>
      <button
        *ngrxLet="updating$ as updating"
        mat-icon-button
        color="primary"
        data-testid="confirmChange"
        [disabled]="updating || nameControl.invalid || nameControl.pristine"
        [class.spinner]="updating"
        (click)="confirmChanges(); $event.stopPropagation()"
      >
        <mat-icon>check_circle</mat-icon>
      </button>
    </div>
  </ng-template>
</div>
