import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { CustomLegend } from './custom-legend.model';
@Component({
  selector: 'prosumer-custom-legend',
  templateUrl: './custom-legend.component.html',
  styleUrls: ['./custom-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomLegendComponent {
  @Input() details: CustomLegend;

  @Output() mouseclickItem = new EventEmitter();
  @Output() mouseenterItem = new EventEmitter();
  @Output() mouseleaveItem = new EventEmitter();

  toggleCustomLegendItem = (event, index) => {
    const checkBox = event.currentTarget.querySelector('mat-checkbox');
    event.currentTarget.classList.toggle('hidden-chart-legend');
    checkBox.classList.toggle('mat-checkbox-checked');
    this.mouseclickItem.emit({ event, index });
  };

  onMouseEnter = (event, index) => this.mouseenterItem.emit({ event, index });

  onMouseLeave = () => this.mouseleaveItem.emit();
}
