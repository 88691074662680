import {
  BaseComponent,
  CustomValidators,
  FormService,
} from 'prosumer-app/libs/eyes-shared';

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'prosumer-duplicate-dialog',
  templateUrl: './duplicate-dialog.component.html',
  styleUrls: ['./duplicate-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DuplicateDialogComponent extends BaseComponent implements OnInit {
  duplicateEntityForm: FormGroup = this.formService.initForm({
    duplicateName: `${this.data.duplicateName}_copy`,
  });

  duplicateNameErrorMap = {
    invalid: `${this.getEntityType()} Name is invalid.`,
    required: `${this.getEntityType()} Name is required.`,
    dataExist: `A ${this.getEntityType()} with this name already exists.`,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DuplicateDialogComponent>,
    public formService: FormService,
  ) {
    super();
  }

  ngOnInit() {
    this.duplicateEntityForm.controls.duplicateName.setAsyncValidators(
      CustomValidators.dataExist(this.data.existingData$, 'name'),
    );
  }

  onFinish(isOkay: boolean): void {
    if (isOkay) {
      if (this.duplicateEntityForm.invalid) {
        return;
      }
      this.dialogRef.close({
        ...this.data,
        duplicateName: this.duplicateEntityForm.getRawValue()['duplicateName'],
      });
    } else {
      this.dialogRef.close();
    }
  }

  protected getEntityType(): string {
    const str = this.data.entityArchetype;
    if (!this.data.entityArchetype) {
      return undefined;
    }
    return str
      .split(' ')
      .map((word) => word.replace(word[0], word[0].toUpperCase()))
      .join(' ');
  }
}
