import { BINARY_LOCATIONS } from 'prosumer-app/app.references';
import {
  doNothing,
  getKeys,
  getValues,
  hasMultipleValues,
} from 'prosumer-app/libs/eyes-shared';

import { coerceNumberProperty } from '@angular/cdk/coercion';

import {
  Profile,
  ProfileBE,
  ProfileRoutes,
  RouteProfileBE,
} from 'prosumer-app/+scenario';
import { Coerce } from 'prosumer-app/core/utils';
import { YearlyValues } from '../models';

/**
 * Converts list of values to a dictionary of yearly values based on the start year. The length of the list determines the end year using
 * the start year as starting point. Returns null if values or start year parameters are not defined.
 *
 * @param values    - the list of values to be assigned each year
 * @param startYear - the start year
 */
export const convertListToYearlyValues = (
  values: Array<string | number | null | undefined>,
  startYear: number,
): YearlyValues => {
  if (!values || !startYear) {
    return null;
  }

  return values
    .map((value, index) => ({ value, year: startYear + index }))
    .reduce(
      (prev, next) => {
        prev[next.year] = next.value;
        return prev;
      },
      {}, // Generate an object of yearly values via reduce function
    );
};

const arrayFromRange = (start: number, end: number): number[] =>
  Array.from(Array(end - start + 1)).map((_, index) => start + index);

const extendSingleValue = (
  value: string,
  startYear: number,
  endYear: number,
): YearlyValues =>
  arrayFromRange(startYear, endYear).reduce((acc, year) => {
    acc[year] = value;
    return acc;
  }, {});

const expandSingleYearlyValues = (
  value: YearlyValues,
  period: [number, number],
): YearlyValues =>
  extendSingleValue(Object.values(value)[0], period[0], period[1]);

export const expandYearlyValuesIfApplicable = (
  value: YearlyValues,
  period: [number, number],
): YearlyValues =>
  Object.values(Coerce.toObject(value)).length > 1
    ? value
    : expandSingleYearlyValues(Coerce.toObject(value), period);

/**
 * Converts a single value parameter to a dictionary of yearly values based on the start and end year parameters. Returns null if start
 * or end year parameters are not defined; or start year is greater than end year.
 *
 * @param value     - the value to be assigned to each year
 * @param startYear - the start year
 * @param endYear   - the end year
 */
export const convertToYearlyValues = (
  value: string | number | null | undefined,
  startYear: number,
  endYear: number,
): YearlyValues => {
  if (!startYear || !endYear) {
    return null;
  }

  if (startYear > endYear) {
    return null;
  }

  if (_isYearlyValueModel(value, startYear)) {
    return value as unknown as YearlyValues;
  } else {
    return convertListToYearlyValues(
      Array.from(Array(endYear - startYear + 1)).map((_) => value), // Create an array from the start and end year parameters
      startYear,
    );
  }
};

function _isYearlyValueModel(value: any, startYear: number): boolean {
  if (
    !!value &&
    typeof value == 'object' &&
    1900 < Number(Object.keys(value)[0]) &&
    Number(Object.keys(value)[0]) < 3000 &&
    Number(Object.keys(value)[0]) == startYear
  )
    return true;
  return false;
}

/**
 * Maps the yearly value back-end model to the front-end model. The method expects an object with year and values properties; start year
 * and end year parameters are optional but should be provided in certain cases to generate the yearly values properly such as the
 * following:
 *
 * - if data provided is a string, start year and end year should be provided; else null will be returned
 * - if data provided has no year and values contain more than 1 elements, start year should be provided (end year will be ignored)
 * - if data provided has no year and values contain only 1 element, both start year and end year should be provided
 * - if data provided has no year and it does not satisfy the previous conditions, null will be returned
 * - if data provided has a year and values contain only 1 element, both start year an end year should be provided
 * - if data provided satisfies the model with year and values properties, start year and end year are not needed (start year and end year
 *  will be ignored)
 *
 * Returns null if data is not a string or an object with year and values properties.
 *
 * @param data      - the yearly value based on back-end model
 * @param startYear - the start year
 * @param endYear   - the end year
 */
export const mapYearlyValuesToFrontend = (
  data: { year?: number; values: Array<string> } | string,
  startYear?: number,
  endYear?: number,
): YearlyValues => {
  // Data is not migrated and its value is just a string
  if (typeof data === 'string') {
    if (isNaN(Number.parseFloat(data))) {
      return null;
    }

    if (startYear && endYear) {
      return convertToYearlyValues(String(data), startYear, endYear);
    }

    return null;
  } else if (typeof data === 'object') {
    if (!!!(data || ({} as any)).year) {
      // Data has no year; but the values contain more than 1 elements
      if (startYear && ((data || ({} as any)).values || []).length > 1) {
        return convertListToYearlyValues(
          (data || ({} as any)).values,
          startYear,
        );
      }

      // Data has no year; but the values contain only 1 element
      if (
        startYear &&
        endYear &&
        ((data || ({} as any)).values || []).length === 1
      ) {
        return convertToYearlyValues(
          (data as { year?: number; values: Array<string> }).values[0],
          startYear,
          endYear,
        );
      }

      return null;
    }

    // Data has a year; but the values contain only 1 element
    if (
      startYear &&
      endYear &&
      ((data || ({} as any)).values || []).length === 1
    ) {
      return convertToYearlyValues(data.values[0], startYear, endYear);
    }

    // Default
    return convertListToYearlyValues(
      (data || ({} as any)).values,
      (data || ({} as any)).year,
    );
  }

  return null;
};

/**
 * Maps the yearly value front-end model to the back-end model. The year keys of the yearly values data parameter will be ignored except for
 * the first one which is considered the start year. Returns null if data is not defined.
 *
 * @param data - the yearly value based on front-end model
 */
export const mapYearlyValuesToBackend = (
  data: YearlyValues,
): { year?: number; values: Array<string> } => {
  if (!!!data || getValues(data).length === 0) {
    return null;
  }

  let startYear;
  const values = getKeys(data)
    .sort()
    .map((year, index) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      index === 0 ? (startYear = coerceNumberProperty(year)) : doNothing();
      return data[year];
    });

  if (hasMultipleValues(data)) {
    return { year: startYear, values };
  }
  return { year: startYear, values: [values[0]] };
};

export const mapYearlyProfileToFrontend = (profile, location): Profile => ({
  localId: profile.localId,
  loadType: profile.isCustom ? 'custom' : 'library',
  location: BINARY_LOCATIONS[location],
  yearlyLoad: profile.yearlyLoad,
  startYear: profile.year,
  endYear: profile.year + profile.duration - 1,
  library: !!(profile || {}).libraryId ? profile.libraryId : undefined,
  loadError: false,
});

export const mapYearlyProfileRoutesToFrontend = (
  profile,
  location,
): ProfileRoutes => ({
  localId: profile.localId,
  loadType: profile.isCustom ? 'custom' : 'library',
  location: BINARY_LOCATIONS[location],
  yearlyLoad: Number(profile.yearlyLoad),
  startYear: profile.year,
  endYear: profile.year + profile.duration - 1,
  library: !!(profile || {}).libraryId ? profile.libraryId : undefined,
  loadError: false,
  nodes: profile.nodes,
});

export const mapYearlyProfileRouteToBackend = (
  profile: any,
): RouteProfileBE => ({
  isCustom: profile.loadType === 'custom' || profile.loadType === undefined,
  localId: profile.localId,
  libraryId: profile.library,
  yearlyLoad: !!profile.yearlyLoad ? String(profile.yearlyLoad) : undefined,
  year: profile.startYear,
  duration: profile.endYear - profile.startYear + 1,
  nodes: profile.nodes,
});

export const mapYearlyProfileToBackend = (profile: any): ProfileBE => ({
  isCustom: profile.loadType === 'custom' || profile.loadType === undefined,
  localId: profile.localId,
  libraryId: profile.library,
  yearlyLoad: !!profile.yearlyLoad ? String(profile.yearlyLoad) : undefined,
  year: profile.startYear,
  duration: profile.endYear - profile.startYear + 1,
});
