import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { BaseComponent, contains, toList } from 'prosumer-app/libs/eyes-shared';

import { Coerce } from 'prosumer-app/core/utils';
import { YearlyValues } from 'prosumer-app/shared/models';
import { expandYearlyValuesIfApplicable } from 'prosumer-app/shared/utils';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { YearlyChartDialogModel } from './yearly-chart-dialog.model';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const YearlyValuesUtilWrapper = {
  expandYearlyValuesIfApplicable,
};

@Component({
  selector: 'prosumer-yearly-chart-dialog',
  templateUrl: './yearly-chart-dialog.component.html',
  styleUrls: ['./yearly-chart-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearlyChartDialogComponent
  extends BaseComponent
  implements OnInit
{
  private readonly yearlyValuesCache = new BehaviorSubject<YearlyValues>({});
  readonly yearlyValues$ = this.yearlyValuesCache.asObservable();
  readonly sanitized$ = this.selectSanitizedYearlyValues();

  readonly disabled$ = this.selectDisabled();

  get startYear(): number {
    return Coerce.toNumber(this.dialogData.startYear);
  }

  get endYear(): number {
    return Coerce.toNumber(this.dialogData.endYear);
  }

  private get dialogData(): YearlyChartDialogModel {
    return Coerce.toObject(this.data);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: YearlyChartDialogModel,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<YearlyChartDialogModel>,
    public formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit() {
    this.updateYearlyValuesUsingDialogData();
  }

  onConfirm() {
    this.dialogRef.close(this.yearlyValuesCache.value);
  }

  rangeValueChange(range: any) {
    this.yearlyValuesCache.next(range);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private selectSanitizedYearlyValues(): Observable<YearlyValues> {
    return this.yearlyValuesCache.pipe(
      map((values) => this.sanitizeYearlyValues(values)),
    );
  }

  private sanitizeYearlyValues(values: YearlyValues): YearlyValues {
    return YearlyValuesUtilWrapper.expandYearlyValuesIfApplicable(values, [
      this.startYear,
      this.endYear,
    ]);
  }

  private selectDisabled(): Observable<boolean> {
    return combineLatest([
      this.yearlyValuesCache.pipe(map((values) => this.hasNull(values))),
      this.yearlyValuesCache.pipe(
        map((values) => this.doesNotMeetRangeConstraints(values)),
      ),
    ]).pipe(
      map(
        ([hasNull, doesNotMeetRangeConstraints]) =>
          hasNull || doesNotMeetRangeConstraints,
      ),
    );
  }

  private hasNull(values: YearlyValues): boolean {
    return contains(toList(values), null);
  }

  private doesNotMeetRangeConstraints(values: YearlyValues): boolean {
    return !toList(values).every((v) => Number(v) >= this.data?.minValue);
  }

  private updateYearlyValuesUsingDialogData(): void {
    this.yearlyValuesCache.next(this.resolveYearlyValuesFromDialogData());
  }

  private resolveYearlyValuesFromDialogData(): YearlyValues {
    if (!!!this.data.yearlyValues) {
      const startYearStr = this.data.startYear.toString();
      const defaultYearlyValues = { startYearStr: '0.0' } as YearlyValues;
      return defaultYearlyValues;
    }
    return this.data.yearlyValues;
  }
}
