<mat-card fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
    <div
      class="chips-filter-container"
      fxFlex="calc(50% - 20px)"
      fxLayout="row wrap"
      fxLayoutGap="5px"
      fxLayoutAlign="space-between"
    >
      <prosumer-filter-chips
        fxFlex
        filterTitle="Node"
        filterBy="value"
        class="eyes-spacer-half"
        [dataSource]="nodeOptions"
        (filters)="onFilterNode($event)"
        data-testid="compare-eb-filter-node"
      >
      </prosumer-filter-chips>

      <prosumer-filter-chips
        fxFlex
        filterTitle="Year"
        filterBy="value"
        class="eyes-spacer-half"
        [dataSource]="yearOptions"
        (filters)="onFilterYear($event)"
        data-testid="compare-eb-filter-year"
      >
      </prosumer-filter-chips>

      <prosumer-filter-chips
        fxFlex
        filterTitle="Energy Vector"
        filterBy="value"
        class="eyes-spacer-half"
        [dataSource]="energyVectorOptions"
        (filters)="onFilterEnergyVector($event)"
        data-testid="compare-eb-filter-energyvector"
      >
      </prosumer-filter-chips>
    </div>

    <div fxFlex></div>
  </div>

  <div
    class="eyes-spacer-top eyes-spacer charts-container"
    fxLayout="row"
    fxLayoutGap="15px"
  >
    <div
      class="chart-container"
      fxLayout="column"
      fxLayoutGap="15px"
      fxLayoutAlign="space-evenly"
      fxFlex="calc(50% - 15px)"
    >
      <prosumer-stacked-bar-chartjs
        [id]="'compare-energy-balance-production'"
        [data]="productionData$ | async"
        [colors]="colors$ | async"
        resultsName="compare_energy_balance_production"
      ></prosumer-stacked-bar-chartjs>
    </div>

    <div
      class="chart-container"
      fxLayout="column"
      fxLayoutGap="15px"
      fxLayoutAlign="space-evenly"
      fxFlex="calc(50% - 15px)"
    >
      <prosumer-stacked-bar-chartjs
        [id]="'compare-energy-balance-consumption'"
        [data]="consumptionData$ | async"
        [colors]="colors$ | async"
        resultsName="compare_energy_balance_consumption"
      ></prosumer-stacked-bar-chartjs>
    </div>
  </div>
</mat-card>
