<prosumer-page>
  <prosumer-progress-card [showProgress]="uploading$ | async">
    <div fxLayout="row wrap-reverse">
      <prosumer-upload-form
        [isCloneMode]="(routeMode$ | async) === 'clone'"
        (cancel)="onCancel()"
        (save)="onCreate($event)"
        (saveClone)="onClone($event)"
        [isLoading]="uploading$ | async"
      >
      </prosumer-upload-form>
      <prosumer-gams-upload-form fxFlex="450px"></prosumer-gams-upload-form>
    </div>
  </prosumer-progress-card>
</prosumer-page>
