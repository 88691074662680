import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveComponentModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared';
import { ScenarioVariationOptionComponent } from './scenario-variation-option.component';

@NgModule({
  declarations: [ScenarioVariationOptionComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    ReactiveComponentModule,
    EyesSharedModule,
  ],
  exports: [ScenarioVariationOptionComponent],
})
export class ScenarioVariationOptionModule {}
