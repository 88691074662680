<mat-progress-bar
  color="accent"
  mode="indeterminate"
  data-testid="'scenario-details-duplicate-dialog-form-progress'"
  *ngIf="(variationLoading$ | ngrxPush) || loading"
></mat-progress-bar>
<form (ngSubmit)="onSubmit()" [formGroup]="duplicateScenarioDetailForm">
  <div fxLayout="row" class="title-container">
    <h2 class="mat-title" mat-dialog-title>
      {{ 'Scenario.labels.general.duplicate' | translate | titlecase }}
      {{ titleType | titlecase }} Option
    </h2>
    <prosumer-tooltip-anchor
      [message]="'wizard_renewable.wizard_renewable_equipment_type'"
    >
    </prosumer-tooltip-anchor>
  </div>

  <div
    fxLayout="row"
    fxLayoutGap="16px"
    class="mat-body duplicate-inputs"
    mat-dialog-content
  >
    <!-- name -->
    <prosumer-input
      fxFlex="50%"
      [placeholder]="'Scenario.placeholders.equipments.name' | translate"
      [label]="'Scenario.labels.equipments.name' | translate"
      [control]="duplicateScenarioDetailForm?.controls?.name"
      [required]="true"
      [errorMessageMap]="errorMessages?.name"
      data-testid="'scenario-details-duplicate-dialog-form-name'"
    >
    </prosumer-input>

    <prosumer-select
      fxFlex="50%"
      *ngIf="(scenarioVariationOptions$ | ngrxPush).length > 1"
      [placeholder]="
        'Scenario.placeholders.equipments.scenarioVariation' | translate
      "
      [label]="'Scenario.labels.equipments.scenarioVariation' | translate"
      [options]="scenarioVariationOptions$ | ngrxPush"
      [control]="duplicateScenarioDetailForm?.controls?.scenarioVariation"
      data-testid="scenario-details-duplicate-dialog-form-variation"
    ></prosumer-select>
  </div>

  <div fxLayout="column">
    <span class="disclaimer">
      {{
        'Scenario.details.duplication.disclaimer.title' | translate | uppercase
      }}:
      {{ 'Scenario.details.duplication.disclaimer.message' | translate }}
    </span>

    <div mat-dialog-actions fxLayout="row">
      <button
        mat-flat-button
        type="submit"
        color="primary"
        [disabled]="(isInvalid$ | ngrxPush) || loading"
        data-testid="okBtn"
      >
        <span>{{ 'Generic.labels.ok' | translate }}</span>
      </button>

      <button mat-flat-button type="button" (click)="onCancel()">
        <span>{{ 'Generic.labels.cancel' | translate }}</span>
      </button>
    </div>
  </div>
</form>
