import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { ReactiveComponentModule } from '@ngrx/component';

import { ScenarioActionsModule } from '../scenario-actions';
import { ScenarioNavigationComponent } from './scenario-navigation.component';

@NgModule({
  declarations: [ScenarioNavigationComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveComponentModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    ScenarioActionsModule,
    MatMenuModule,
    MatDialogModule,
    MatTabsModule,
    RouterModule,
  ],
  exports: [ScenarioNavigationComponent],
})
export class ScenarioNavigationModule {}
