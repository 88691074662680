<div class="prosumer-tooltip" fxLayout="column" fxLayoutGap="5px">
  <div class="prosumer-tooltip-title">{{ titleFormat(currentSeriesData) }}</div>
  <div class="prosumer-tooltip-content" fxLayout="column">
    <div
      *ngFor="let seriesData of seriesDataList"
      class="prosumer-tooltip-item"
      fxLayout="row"
      fxLayoutAlign="start center"
      [ngClass]="{ current: seriesData?.current }"
    >
      <div
        class="prosumer-tooltip-item-color"
        [style.background-color]="seriesData?.color"
      ></div>
      <div class="prosumer-tooltip-item-series">{{ seriesData?.series }}:</div>
      <div fxFlex></div>
      <div class="prosumer-tooltip-item-value">
        {{ seriesData?.value | number : format }} {{ unit }}
      </div>
    </div>
  </div>
  <div
    class="prosumer-tooltip-item prosumer-tooltip-total"
    fxLayout="row"
    fxLayoutAlign="start center"
    *ngIf="!hideTotal"
  >
    <div class="prosumer-tooltip-item-series">
      {{ 'Result.labels.total' | translate | uppercase }}:
    </div>
    <div fxFlex></div>
    <div class="prosumer-tooltip-item-value">
      {{ seriesDataList | sum : 'value' | number : format }} {{ unit }}
    </div>
  </div>
</div>
