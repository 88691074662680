// TODO: Update the following model needed for Result
export interface Result {
  readonly id?: string;
  readonly name?: string;
  readonly referenceId?: string;
  readonly variationId?: string;
  readonly scenarioId?: string;
  readonly colorClass?: string;
  readonly isOutputSplit?: boolean;
}

export interface ScenarioResult extends Result {
  readonly mainResult?: MainResult;
  readonly equipmentResult?: EquipmentResult;
  readonly topologyResult?: TopologyResult;
  readonly cashFlowResult?: CashFlowResult;
  readonly energyBalanceResult?: any;
  readonly dispatchResult?: DispatchResult;
  readonly paybackResult?: PaybackResult;
}

/* ITEMS */

export interface EnergyBalanceResultItem {
  readonly equipmentName: string;
  readonly type: string;
  readonly node: string;
  readonly energyVector: string;
  readonly value: number;
  readonly year: string;
}

export interface CashFlowResultItem<T extends CashFlowResultItemValue> {
  readonly year: string;
  readonly data: Array<T>;
}

export interface CashFlowResultItemValue {
  readonly cashFlowName?: string;
  readonly value: number;
  readonly type: string;
}

export interface EquipmentResultItem {
  readonly equipmentName: string;
  readonly type: string;
  readonly node: string;
  readonly outputEnergyVector: string;
  readonly inputEnergyVector: string;
  // Values
  readonly sizekW?: number;
  readonly sizekWh?: number;
  readonly initialInvestmentCost?: number;
  readonly omCostFirstYear?: number;
  readonly totalDiscountedCost?: number;
  readonly annualEnergyConsumption?: number;
  readonly annualEnergyProduction?: number;
}

export interface TopologyResultItem {
  readonly lineName: string;
  readonly energyVector: string;
  readonly sizekW?: number;
  readonly sizekWh?: number;
  readonly sizekEUR: number;
  readonly originNode: string;
  readonly destinationNode: string;
  readonly sizekWhBa?: number;
}

export interface DispatchResultItem {
  readonly year: string;
  readonly node: string;
  readonly energyVector: string;
  readonly resultType: 'production' | 'consumption' | 'storage_mvts' | 'soc';
  readonly equipment: string;
  readonly values: Array<number>;
}

/* RESULTS */

export interface MainResult {
  readonly totalDistributedCost: number;
  readonly capexFirstYear: number;
  readonly opexFirstYear: number;
  readonly co2Emission: number;
}

export interface PaybackResultItem {
  readonly name?: string;
  readonly value?: number;
}

export interface EquipmentResult {
  readonly equipments: Array<EquipmentResultItem>;
}

export interface EnergyBalanceResult {
  readonly equipments: Array<EnergyBalanceResultItem>;
  readonly scenarioId?: string;
  readonly variationId?: string;
}

export interface CashFlowResult {
  readonly cashFlowList: Array<CashFlowResultItem<CashFlowResultItemValue>>;
}

export interface TopologyResult {
  readonly topologies: Array<TopologyResultItem>;
}

export interface DispatchResult {
  readonly dispatchList: Array<DispatchResultItem>;
  readonly scenarioId?: string;
  readonly variationId?: string;
}

export interface PaybackResult {
  readonly series?: Array<PaybackResultItem>;
}

export enum ResultsTabs {
  main = 0,
  systemVisualization,
  equipment,
  topology,
  energyBalance,
  cashflow,
  co2Emissions,
  dispatch,
  flows,
}
