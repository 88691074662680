<div class="legend-container" fxLayout="row" fxLayoutGap="8px">
  <button
    *ngFor="let fluid of activeFluids; let i = index"
    mat-fab
    (click)="toggleLegend(i)"
    [ngStyle]="{
      'background-color': selection?.isSelected(fluid.name)
        ? fluid?.color
        : 'gray'
    }"
    [matTooltip]="fluid?.icon || fluid?.name | uppercase"
  >
    <mat-icon [svgIcon]="fluid?.icon" *ngIf="fluid?.icon"></mat-icon>
    <mat-label *ngIf="!fluid?.icon && fluid?.name">
      {{ fluid.name | uppercase | slice : 0 : 3 }}
    </mat-label>
  </button>
</div>
