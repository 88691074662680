import {
  Directive,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import {
  ResultsVisualizationType,
  ResultsVisualizerTokens,
  ResultVisualizerSlice,
  VisualizerFilter,
} from './results-visualizer.model';
import { ResultsVisualizerService } from './results-visualizer.service';

@Directive()
export abstract class VisualizerComponent implements OnInit, OnDestroy {
  private localVizService: ResultsVisualizerService;
  private justDestroyedSubject = new Subject();
  @Output() sliceSelect = new EventEmitter<string>();
  @Output() typeChange = new EventEmitter<ResultsVisualizationType>();

  readonly resultTypes = Object.values(ResultsVisualizationType);
  readonly resultTypeIconMap = ResultsVisualizerTokens.typeIconMap;

  justDestroyed$ = this.justDestroyedSubject.asObservable();
  filterBies$: Observable<VisualizerFilter[]>;
  slices: ResultVisualizerSlice[];

  sliceControl: FormControl = new FormControl();
  resultTypeControl: FormControl = new FormControl(this.resultTypes[0]);

  constructor(service: ResultsVisualizerService) {
    this.localVizService = service;
  }
  ngOnInit(): void {
    this.slices = this.localVizService.getSlices();
    this.filterBies$ = this.localVizService.getAvailableFiltersStream();

    this.patchSliceControlWithFirstSlice();
    this.subscribeToSliceControlForServiceSyncAndEmission();
    this.subscribeToResultTypeControlForEmission();
    this.subscribeToResultTypeControlForSecondaryFiltersClearing();
  }
  ngOnDestroy(): void {
    this.localVizService.clearActiveFilters();
    this.localVizService.clearSecondaryFilters();

    this.justDestroyedSubject.next();
  }

  getResultTypeValueStream(): Observable<ResultsVisualizationType> {
    return this.resultTypeControl.valueChanges.pipe(
      startWith(this.resultTypeControl.value),
    );
  }

  onFilterChange(filterKey: string, selected: string[]): void {
    this.localVizService.setActiveFilter(filterKey, selected);
  }

  private subscribeToResultTypeControlForEmission(): void {
    this.getResultTypeValueStream().subscribe((type) =>
      this.typeChange.emit(type),
    );
  }

  private patchSliceControlWithFirstSlice(): void {
    this.sliceControl.patchValue(this.slices[0].name);
  }

  private subscribeToSliceControlForServiceSyncAndEmission(): void {
    this.sliceControl.valueChanges
      .pipe(startWith(this.sliceControl.value))
      .subscribe((slice) => {
        this.localVizService.setSelectedSlice(slice);
        this.sliceSelect.emit(slice);
      });
  }

  private subscribeToResultTypeControlForSecondaryFiltersClearing(): void {
    this.getResultTypeValueStream().subscribe(() =>
      this.localVizService.clearSecondaryFilters(),
    );
  }
}
