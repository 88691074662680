import {
  ConfigService,
  HttpService,
  LoggerService,
} from 'prosumer-app/libs/eyes-core';
import { generateEndpoint } from 'prosumer-app/libs/eyes-shared';
import { Observable, Observer, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

/* eslint-disable @typescript-eslint/naming-convention */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ResultsDownloadResponse } from '@prosumer/results/models';

import { NotificationsService } from 'prosumer-app/shared/services/notification';
import {
  ResultActionTypes,
  ResultActions,
  ResultGetAction,
  ResultGetFailureAction,
  ResultGetSuccessAction,
  ResultGetSystemVisualizationAction,
  ResultGetSystemVisualizationFailureAction,
  ResultGetSystemVisualizationSuccessAction,
  ResultMigrateAction,
  ResultMigrateFailureAction,
  ResultMigrateSuccessAction,
  ResultsGetCO2EmissionsAction,
  ResultsGetCO2EmissionsFailureAction,
  ResultsGetCO2EmissionsSuccessAction,
  ResultsGetCashFlowAction,
  ResultsGetCashFlowFailureAction,
  ResultsGetCashFlowSuccessAction,
  ResultsGetDispatchAction,
  ResultsGetDispatchFailureAction,
  ResultsGetDispatchSuccessAction,
  ResultsGetEnergyBalanceAction,
  ResultsGetEnergyBalanceFailureAction,
  ResultsGetEnergyBalanceSuccessAction,
  ResultsGetEquipmentAction,
  ResultsGetEquipmentFailureAction,
  ResultsGetEquipmentSuccessAction,
  ResultsGetFlowsAction,
  ResultsGetFlowsFailureAction,
  ResultsGetFlowsSuccessAction,
  ResultsGetLatestScenarioVersionFailureAction,
  ResultsGetLatestScenarioVersionSuccessAction,
  ResultsGetMainAction,
  ResultsGetMainFailureAction,
  ResultsGetMainSuccessAction,
  ResultsGetMainSuccessSplitAction,
  ResultsGetTopologyAction,
  ResultsGetTopologyFailureAction,
  ResultsGetTopologySuccessAction,
} from './result.actions';

@Injectable()
export class ResultEffects {
  getTabResults$({
    endPoint,
    payloads: { projectId, caseId, scenarioId },
    mapFn,
    catchErrorFn,
  }: {
    endPoint: string;
    payloads: {
      projectId;
      caseId;
      scenarioId;
      scenarioVariationId?;
    };
    mapFn: (response) => ResultActions;
    catchErrorFn: (error: HttpErrorResponse) => Observable<any>;
  }) {
    return this._http
      .get(
        generateEndpoint(
          this._config.api.baseUrl,
          endPoint,
          projectId,
          caseId,
          scenarioId,
        ),
      )
      .pipe(
        switchMap((resultsResponse: ResultsDownloadResponse) => {
          const { url, isOutputSplit } = resultsResponse;
          return this._http.get(url, { responseType: 'blob' }).pipe(
            map((response) => ({
              jsonResult: response,
              isOutputSplit,
            })),
            map(mapFn),
            catchError(catchErrorFn),
          );
        }),
        catchError(catchErrorFn),
      );
  }

  addVariationQueryParam = (variationId?: string) =>
    variationId ? `&variationUuid=${variationId}` : '';

  /** @deprecated No longer used after tab splits  */
  @Effect()
  get$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET),
    switchMap((action: ResultGetAction) => {
      const projId = action.payload.projectId;
      const caseId = action.payload.caseId;
      const scenarioId = action.payload.scenarioId;

      return this._http
        .get(
          generateEndpoint(
            this._config.api.baseUrl,
            this._config.api.endpoints.scenario.getResultSigned,
            projId,
            caseId,
            scenarioId,
          ),
        )
        .pipe(
          switchMap((downloadSignedUrl: any) =>
            this._http
              .get(downloadSignedUrl.url, { responseType: 'blob' })
              .pipe(
                map(
                  (response) =>
                    new ResultGetSuccessAction(
                      scenarioId,
                      response,
                      this._translate.instant(
                        'Result.messages.getResultsSuccess',
                      ),
                    ),
                ),
                catchError((errResponse: HttpErrorResponse) =>
                  of(
                    new ResultGetFailureAction(
                      (errResponse &&
                        errResponse.error &&
                        errResponse.error.error) ||
                        this._translate.instant(
                          'Result.messages.getResultsFailure',
                        ),
                    ),
                  ),
                ),
              ),
          ),
          catchError((errResponse: HttpErrorResponse) =>
            of(
              new ResultGetFailureAction(
                (errResponse && errResponse.error && errResponse.error.error) ||
                  this._translate.instant('Result.messages.getResultsFailure'),
              ),
            ),
          ),
        );
    }),
  );

  @Effect({ dispatch: false })
  getFailure$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_FAILURE),
    tap((action: ResultGetFailureAction) =>
      this._notification.showError(action.payload.error),
    ),
  );

  @Effect({ dispatch: false })
  getSuccess$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_SUCCESS),
  );

  @Effect()
  getSystemVisualization$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_SYSTEM_VISUALIZATION),
    switchMap((action: ResultGetSystemVisualizationAction) => {
      const projId = action.payload.projectId;
      const caseId = action.payload.caseId;
      const scenarioId = action.payload.scenarioId;

      return this._http
        .get(
          generateEndpoint(
            this._config.api.baseUrl,
            `${
              this._config.api.endpoints.scenario.getResultSigned
            }?tab=system${this.addVariationQueryParam(
              action.payload.scenarioVariationId,
            )}`,
            projId,
            caseId,
            scenarioId,
          ),
        )
        .pipe(
          switchMap((downloadSignedUrl: any) =>
            this._http
              .get(downloadSignedUrl.url, { responseType: 'blob' })
              .pipe(
                map(
                  (response) =>
                    new ResultGetSystemVisualizationSuccessAction(
                      scenarioId,
                      response,
                      this._translate.instant(
                        'Result.messages.getSystemVisualizationSuccess',
                      ),
                    ),
                ),
                catchError((errResponse: HttpErrorResponse) =>
                  of(
                    new ResultGetSystemVisualizationFailureAction(
                      (errResponse &&
                        errResponse.error &&
                        errResponse.error.error) ||
                        this._translate.instant(
                          'Result.messages.getSystemVisualizationFailure',
                        ),
                    ),
                  ),
                ),
              ),
          ),
          catchError((errResponse: HttpErrorResponse) =>
            of(
              new ResultGetSystemVisualizationFailureAction(
                (errResponse && errResponse.error && errResponse.error.error) ||
                  this._translate.instant(
                    'Result.messages.getSystemVisualizationFailure',
                  ),
              ),
            ),
          ),
        );
    }),
  );

  @Effect({ dispatch: false })
  getSystemVisualizationFailure$: Observable<ResultActions> =
    this._action$.pipe(
      ofType(ResultActionTypes.GET_SYSTEM_VISUALIZATION_FAILURE),
      tap((action: ResultGetSystemVisualizationFailureAction) =>
        this._logger.error(
          'System Visualization Error: ',
          action.payload.errorSystemVisualization,
        ),
      ),
    );

  @Effect({ dispatch: false })
  getSystemVisualizationSuccess$: Observable<ResultActions> =
    this._action$.pipe(
      ofType(ResultActionTypes.GET_SYSTEM_VISUALIZATION_SUCCESS),
    );

  @Effect()
  migrate$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.MIGRATE),
    switchMap((action: ResultMigrateAction) => {
      const projId = action.payload.projectId;
      const caseId = action.payload.caseId;
      const scenarioId = action.payload.scenarioId;

      return this._http
        .post(
          generateEndpoint(
            this._config.api.baseUrl,
            this._config.api.endpoints.scenario.migrate,
            projId,
            caseId,
            scenarioId,
          ),
        )
        .pipe(
          map(
            (response) =>
              new ResultMigrateSuccessAction(
                scenarioId,
                response,
                this._translate.instant('Result.messages.migrateSuccess'),
              ),
          ),
          catchError((errResponse: HttpErrorResponse) =>
            of(
              new ResultMigrateFailureAction(
                (errResponse && errResponse.error && errResponse.error.error) ||
                  this._translate.instant('Result.messages.migrateFailure'),
              ),
            ),
          ),
        );
    }),
  );

  @Effect({ dispatch: false })
  migrateSuccess$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.MIGRATE_SUCCESS),
  );

  @Effect({ dispatch: false })
  migrateFailure$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.MIGRATE_FAILURE),
  );

  @Effect()
  getMain$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_MAIN_DATA),
    switchMap((action: ResultsGetMainAction) =>
      this.getTabResults$({
        endPoint: `${
          this._config.api.endpoints.scenario.getResultSigned
        }?tab=main${this.addVariationQueryParam(
          action.payload.scenarioVariationId,
        )}`,
        payloads: {
          ...action.payload,
        },
        mapFn: ({ jsonResult, isOutputSplit }) => {
          if (isOutputSplit) {
            const { Main_outputs, all_years, optimized_years, is_multi_node } =
              jsonResult;
            return new ResultsGetMainSuccessSplitAction(
              action.payload.scenarioId,
              {
                mainData: {
                  Main_outputs,
                },
                allYears: all_years,
                optimizedYears: optimized_years,
                isMultiNode: is_multi_node,
              },
              isOutputSplit,
              this._translate.instant('Result.messages.getResultsMainSuccess'),
            );
          }
          return new ResultsGetMainSuccessAction(
            action.payload.scenarioId,
            jsonResult,
            this._translate.instant('Result.messages.getResultsMainSuccess'),
          );
        },
        catchErrorFn: (errResponse: HttpErrorResponse) =>
          of(
            new ResultsGetMainFailureAction(
              (errResponse && errResponse.error && errResponse.error.error) ||
                this._translate.instant(
                  'Result.messages.getResultsMainFailure',
                ),
            ),
          ),
      }),
    ),
  );

  @Effect({ dispatch: false })
  getMainFailure$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_MAIN_DATA_FAILURE),
    tap((action: ResultsGetMainFailureAction) =>
      this._notification.showError(action.payload.error),
    ),
  );

  @Effect({ dispatch: false })
  getMainSuccess$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_MAIN_DATA_SUCCESS),
  );

  @Effect()
  getEquipment$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_EQUIPMENT_DATA),
    switchMap((action: ResultsGetEquipmentAction) =>
      this.getTabResults$({
        endPoint: `${
          this._config.api.endpoints.scenario.getResultSigned
        }?tab=equipment${this.addVariationQueryParam(
          action.payload.scenarioVariationId,
        )}`,
        payloads: {
          ...action.payload,
        },
        mapFn: ({ jsonResult }) => {
          const { DER_investments } = jsonResult;
          return new ResultsGetEquipmentSuccessAction(
            {
              DER_investments,
            },
            this._translate.instant(
              'Result.messages.getResultsEquipmentSuccess',
            ),
          );
        },
        catchErrorFn: (errResponse: HttpErrorResponse) =>
          of(
            new ResultsGetEquipmentFailureAction(
              (errResponse && errResponse.error && errResponse.error.error) ||
                this._translate.instant(
                  'Result.messages.getResultsEquipmentFailure',
                ),
            ),
          ),
      }),
    ),
  );

  @Effect({ dispatch: false })
  getEquipmentFailure$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_EQUIPMENT_DATA_FAILURE),
    tap((action: ResultsGetEquipmentFailureAction) =>
      this._notification.showError(action.payload.error),
    ),
  );

  @Effect({ dispatch: false })
  getEquipmentSuccess$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_EQUIPMENT_DATA_SUCCESS),
  );

  @Effect()
  getTopology$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_TOPOLOGY_DATA),
    switchMap((action: ResultsGetTopologyAction) =>
      this.getTabResults$({
        endPoint: `${
          this._config.api.endpoints.scenario.getResultSigned
        }?tab=topology${this.addVariationQueryParam(
          action.payload.scenarioVariationId,
        )}`,
        payloads: {
          ...action.payload,
        },
        mapFn: ({ jsonResult }) => {
          const { line_investments } = jsonResult;
          return new ResultsGetTopologySuccessAction(
            {
              line_investments,
            },
            this._translate.instant(
              'Result.messages.getResultsTopologySuccess',
            ),
          );
        },
        catchErrorFn: (errResponse: HttpErrorResponse) =>
          of(
            new ResultsGetTopologyFailureAction(
              (errResponse && errResponse.error && errResponse.error.error) ||
                this._translate.instant(
                  'Result.messages.getResultsTopologyFailure',
                ),
            ),
          ),
      }),
    ),
  );

  @Effect({ dispatch: false })
  getTopologyFailure$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_TOPOLOGY_DATA_FAILURE),
    tap((action: ResultsGetTopologyFailureAction) =>
      this._notification.showError(action.payload.error),
    ),
  );

  @Effect({ dispatch: false })
  getTopologySuccess$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_TOPOLOGY_DATA_SUCCESS),
  );

  @Effect()
  getEnergyBalance$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_ENERGY_BALANCE_DATA),
    switchMap((action: ResultsGetEnergyBalanceAction) =>
      this.getTabResults$({
        endPoint: `${
          this._config.api.endpoints.scenario.getResultSigned
        }?tab=energy_balance${this.addVariationQueryParam(
          action.payload.scenarioVariationId,
        )}`,
        payloads: {
          ...action.payload,
        },
        mapFn: ({ jsonResult }) => {
          const { energy_balance } = jsonResult;
          return new ResultsGetEnergyBalanceSuccessAction(
            {
              energy_balance,
            },
            this._translate.instant(
              'Result.messages.getResultsEnergyBalanceSuccess',
            ),
          );
        },
        catchErrorFn: (errResponse: HttpErrorResponse) =>
          of(
            new ResultsGetEnergyBalanceFailureAction(
              (errResponse && errResponse.error && errResponse.error.error) ||
                this._translate.instant(
                  'Result.messages.getResultsEnergyBalanceFailure',
                ),
            ),
          ),
      }),
    ),
  );

  @Effect({ dispatch: false })
  getEnergyBalanceFailure$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_ENERGY_BALANCE_DATA_FAILURE),
    tap((action: ResultsGetEnergyBalanceFailureAction) =>
      this._notification.showError(action.payload.error),
    ),
  );

  @Effect({ dispatch: false })
  getEnergyBalanceSuccess$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_ENERGY_BALANCE_DATA_SUCCESS),
  );

  @Effect()
  getCashFlow$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_CASH_FLOW_DATA),
    switchMap((action: ResultsGetCashFlowAction) =>
      this.getTabResults$({
        endPoint: `${
          this._config.api.endpoints.scenario.getResultSigned
        }?tab=cash_flow${this.addVariationQueryParam(
          action.payload.scenarioVariationId,
        )}`,
        payloads: {
          ...action.payload,
        },
        mapFn: ({ jsonResult }) => {
          const { cumulative_cashflow, incremental_cashflow } = jsonResult;
          return new ResultsGetCashFlowSuccessAction(
            {
              cumulative_cashflow,
              incremental_cashflow,
            },
            this._translate.instant(
              'Result.messages.getResultsCashFlowSuccess',
            ),
          );
        },
        catchErrorFn: (errResponse: HttpErrorResponse) =>
          of(
            new ResultsGetCashFlowFailureAction(
              (errResponse && errResponse.error && errResponse.error.error) ||
                this._translate.instant(
                  'Result.messages.getResultsCashFlowFailure',
                ),
            ),
          ),
      }),
    ),
  );

  @Effect({ dispatch: false })
  getCashFlowFailure$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_CASH_FLOW_DATA_FAILURE),
    tap((action: ResultsGetCashFlowFailureAction) =>
      this._notification.showError(action.payload.error),
    ),
  );

  @Effect({ dispatch: false })
  getCashFlowSuccess$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_CASH_FLOW_DATA_SUCCESS),
  );

  @Effect()
  getCO2Emission$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_CO2_EMISSIONS_DATA),
    switchMap((action: ResultsGetCO2EmissionsAction) =>
      this.getTabResults$({
        endPoint: `${
          this._config.api.endpoints.scenario.getResultSigned
        }?tab=co2_emissions${this.addVariationQueryParam(
          action.payload.scenarioVariationId,
        )}`,
        payloads: {
          ...action.payload,
        },
        mapFn: ({ jsonResult }) => {
          const { cumulative_emissions, incremental_emissions } = jsonResult;
          return new ResultsGetCO2EmissionsSuccessAction(
            {
              cumulative_emissions,
              incremental_emissions,
            },
            this._translate.instant(
              'Result.messages.getResultsCO2EmissionSuccess',
            ),
          );
        },
        catchErrorFn: (errResponse: HttpErrorResponse) =>
          of(
            new ResultsGetCO2EmissionsFailureAction(
              (errResponse && errResponse.error && errResponse.error.error) ||
                this._translate.instant(
                  'Result.messages.getResultsCO2EmissionFailure',
                ),
            ),
          ),
      }),
    ),
  );

  @Effect({ dispatch: false })
  getCO2EmissionFailure$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_CO2_EMISSIONS_DATA_FAILURE),
    tap((action: ResultsGetCO2EmissionsFailureAction) =>
      this._notification.showError(action.payload.error),
    ),
  );

  @Effect({ dispatch: false })
  getCO2EmissionSuccess$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_CO2_EMISSIONS_DATA_SUCCESS),
  );

  @Effect()
  getDispatch$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_DISPATCH_DATA),
    switchMap((action: ResultsGetDispatchAction) =>
      this.getTabResults$({
        endPoint: `${
          this._config.api.endpoints.scenario.getResultSigned
        }?tab=dispatch${this.addVariationQueryParam(
          action.payload.scenarioVariationId,
        )}`,
        payloads: {
          ...action.payload,
        },
        mapFn: ({ jsonResult }) => {
          const { dispatch } = jsonResult;
          return new ResultsGetDispatchSuccessAction(
            {
              dispatch,
            },
            this._translate.instant(
              'Result.messages.getResultsDispatchSuccess',
            ),
          );
        },
        catchErrorFn: (errResponse: HttpErrorResponse) =>
          of(
            new ResultsGetDispatchFailureAction(
              (errResponse && errResponse.error && errResponse.error.error) ||
                this._translate.instant(
                  'Result.messages.getResultsDispatchFailure',
                ),
            ),
          ),
      }),
    ),
  );

  @Effect({ dispatch: false })
  getDispatchFailure$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_DISPATCH_DATA_FAILURE),
    tap((action: ResultsGetDispatchFailureAction) =>
      this._notification.showError(action.payload.error),
    ),
  );

  @Effect({ dispatch: false })
  getDispatchSuccess$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_DISPATCH_DATA_SUCCESS),
  );

  @Effect()
  getFlows$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_FLOWS_DATA),
    switchMap((action: ResultsGetFlowsAction) =>
      this.getTabResults$({
        endPoint: `${
          this._config.api.endpoints.scenario.getResultSigned
        }?tab=flows${this.addVariationQueryParam(
          action.payload.scenarioVariationId,
        )}`,
        payloads: {
          ...action.payload,
        },
        mapFn: ({ jsonResult }) => {
          const { sankey } = jsonResult;
          return new ResultsGetFlowsSuccessAction(
            {
              sankey,
            },
            this._translate.instant('Result.messages.getResultsFlowsSuccess'),
          );
        },
        catchErrorFn: (errResponse: HttpErrorResponse) =>
          of(
            new ResultsGetFlowsFailureAction(
              (errResponse && errResponse.error && errResponse.error.error) ||
                this._translate.instant(
                  'Result.messages.getResultsFlowsFailure',
                ),
            ),
          ),
      }),
    ),
  );

  @Effect({ dispatch: false })
  getFlowFailure$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_FLOWS_DATA_FAILURE),
    tap((action: ResultsGetFlowsFailureAction) =>
      this._notification.showError(action.payload.error),
    ),
  );

  @Effect({ dispatch: false })
  getFlowSuccess$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_FLOWS_DATA_SUCCESS),
  );

  @Effect()
  getLatestScenarioVersion$: Observable<ResultActions> = this._action$.pipe(
    ofType(ResultActionTypes.GET_LATEST_SCENARIO_VERSION),
    switchMap(() =>
      this._http
        .get(
          generateEndpoint(
            this._config.api.baseUrl,
            `${this._config.api.endpoints.scenario.latestScenarioVersion}`,
          ),
        )
        .pipe(
          map(
            ({ version }) =>
              new ResultsGetLatestScenarioVersionSuccessAction(version),
          ),
          catchError((errResponse: HttpErrorResponse) =>
            of(
              new ResultsGetLatestScenarioVersionFailureAction(
                (errResponse && errResponse.error && errResponse.error.error) ||
                  this._translate.instant(
                    'Result.messages.getLatestScenarioVersionFailure',
                  ),
              ),
            ),
          ),
        ),
    ),
  );

  @Effect({ dispatch: false })
  getLatestScenarioVersionFailure$: Observable<ResultActions> =
    this._action$.pipe(
      ofType(ResultActionTypes.GET_LATEST_SCENARIO_VERSION_FAILURE),
      tap((action: ResultsGetCO2EmissionsFailureAction) =>
        this._notification.showError(action.payload.error),
      ),
    );

  @Effect({ dispatch: false })
  getLatestScenarioVersionSuccess$: Observable<ResultActions> =
    this._action$.pipe(
      ofType(ResultActionTypes.GET_LATEST_SCENARIO_VERSION_SUCCESS),
    );

  constructor(
    /**
     * Inject them beaches!
     */
    private _action$: Actions,
    private _config: ConfigService,
    private _http: HttpService,
    private _notification: NotificationsService,
    private _translate: TranslateService,
    private _logger: LoggerService,
  ) {}
}
