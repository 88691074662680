import { coerceNumberProperty } from '@angular/cdk/coercion';
import { DecimalPipe } from '@angular/common';
import { getKeys } from 'prosumer-app/libs/eyes-shared';
import { Asset, AssetLink, NodeViewData } from '../models/nodes-view.model';

// Node View JSON Property Definition
export const NODE_VIEW_JSON_PROPS = {
  asset: {
    name: 'name',
    fluid: 'Fluid',
    mainInputFluid: 'Main input fluid',
    mainOutputFluid: 'Main output fluid',
    node: 'node',
    sizeKw: 'Size [kw]',
    sizeKwh: 'Size [kwh]',
    yearlyBuyKWh: 'Yearly Buy [kWh]',
    yearlySellKWh: 'Yearly Sell [kWh]',
    type: 'type',
  },
  assetLink: {
    name: 'name',
    destination: 'destination',
    origin: 'origin',
    fluid: 'fluid',
  },
};

/**
 * Checks if node view asset is hideable when showing only optimized assets
 *
 * @param asset - the asset data
 */
export const isNodeViewAssetHideable = (asset: any) =>
  (!!asset[NODE_VIEW_JSON_PROPS.asset.sizeKw] &&
    coerceNumberProperty(asset[NODE_VIEW_JSON_PROPS.asset.sizeKw]) === 0) ||
  (!!asset[NODE_VIEW_JSON_PROPS.asset.yearlyBuyKWh] &&
    coerceNumberProperty(asset[NODE_VIEW_JSON_PROPS.asset.yearlyBuyKWh]) ===
      0 &&
    !!asset[NODE_VIEW_JSON_PROPS.asset.yearlySellKWh] &&
    coerceNumberProperty(asset[NODE_VIEW_JSON_PROPS.asset.yearlySellKWh]) ===
      0);

export const determineHideableAssetsFromHideableLinks = (
  assets: Array<Asset>,
  assetLinks: Array<AssetLink>,
): Array<string> => {
  const notYetHideableAssets = assets.filter((_) => !!!_.hideable);
  return notYetHideableAssets
    .filter((candidate) => {
      const connectedLinks = assetLinks.filter((link) =>
        [link.sourceId, link.targetId].includes(candidate.id),
      );
      return (
        !!!candidate.hideable && connectedLinks.every((link) => link.hideable)
      );
    })
    .map((link) => link.id);
};

export const shouldShowInfo = (asset: any) => {
  const type = asset[NODE_VIEW_JSON_PROPS.asset.type];
  const equipments = [
    'converter',
    'storage',
    'renewable generator',
    'generator',
  ];
  return type ? equipments.includes(type.toLowerCase()) : false;
};

/**
 * Maps the JSON data parameter to the NodeViewData
 *
 * @param data - the JSON data
 */
export const mapToNodesViewModel = (data: any): NodeViewData => {
  const decimalPipe = new DecimalPipe('en_US');
  const assets = getKeys(data.assets).map(
    (assetId) =>
      ({
        id: assetId,
        name: data.assets[assetId][NODE_VIEW_JSON_PROPS.asset.name],
        fluid:
          data.assets[assetId][NODE_VIEW_JSON_PROPS.asset.fluid] ||
          data.assets[assetId][NODE_VIEW_JSON_PROPS.asset.mainInputFluid],
        outputFluid:
          data.assets[assetId][NODE_VIEW_JSON_PROPS.asset.fluid] ||
          data.assets[assetId][NODE_VIEW_JSON_PROPS.asset.mainOutputFluid],
        node: data.assets[assetId][NODE_VIEW_JSON_PROPS.asset.node],
        rawData: {
          ...data.assets[assetId],
          [NODE_VIEW_JSON_PROPS.asset.sizeKwh]: decimalPipe.transform(
            data.assets[assetId][NODE_VIEW_JSON_PROPS.asset.sizeKwh],
            '1.1-1',
          ),
          [NODE_VIEW_JSON_PROPS.asset.sizeKw]: decimalPipe.transform(
            data.assets[assetId][NODE_VIEW_JSON_PROPS.asset.sizeKw],
            '1.1-1',
          ),
        },
        hideable: isNodeViewAssetHideable(data.assets[assetId]),
        showInfo: shouldShowInfo(data.assets[assetId]),
      } as Asset),
  );

  const hideableAssets = assets.filter((_) => _.hideable).map((_) => _.id);
  const assetLinks = getKeys(data.asset_links).map((assetLinkId) => {
    const targetId =
      data.asset_links[assetLinkId][NODE_VIEW_JSON_PROPS.assetLink.destination];
    const sourceId =
      data.asset_links[assetLinkId][NODE_VIEW_JSON_PROPS.assetLink.origin];
    return {
      id: assetLinkId,
      name: data.asset_links[assetLinkId][NODE_VIEW_JSON_PROPS.assetLink.name],
      // bidirectional: data.asset_links[assetLinkId]['bidirectional'],
      targetId,
      sourceId,
      fluid:
        data.asset_links[assetLinkId][NODE_VIEW_JSON_PROPS.assetLink.fluid],
      rawData: data.asset_links[assetLinkId],
      hideable:
        hideableAssets.includes(targetId) || hideableAssets.includes(sourceId),
    } as AssetLink;
  });

  // re evaluate nodes if they should be hidden, only hide nodes when all links are hideable
  const trulyHideableAssets = determineHideableAssetsFromHideableLinks(
    assets,
    assetLinks,
  );

  return {
    assets: assets.map((asset) => ({
      ...asset,
      hideable: asset.hideable || trulyHideableAssets.includes(asset.id),
    })),
    assetLinks,
  };
};
