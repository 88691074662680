import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

/* Angular Specific Third-party Modules */
@NgModule({
  exports: [
    TranslateModule,
    NgxChartsModule,
    Ng5SliderModule,
    NgxMaskModule,
    NgxSkeletonLoaderModule,
  ],
})
export class NgxModuleModule {}
