import { coerceNumberProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { curveStep } from 'd3-shape';
import { BaseChartComponent, getKeys } from 'prosumer-app/libs/eyes-shared';
import { YearlyValues } from 'prosumer-shared/models';

import { YearlyLineChartData } from './yearly-line-chart.model';

const DEFAULT_CHART_WIDTH = 800;
const DEFAULT_CHART_HEIGHT = 400;

@Component({
  selector: 'prosumer-yearly-line-chart',
  templateUrl: './yearly-line-chart.component.html',
  styleUrls: ['./yearly-line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearlyLineChartComponent extends BaseChartComponent<
  Array<YearlyLineChartData>,
  YearlyValues
> {
  lineCurve = curveStep; // The line chart curve
  showLegend = false;

  /**
   * The dictionary of number values where the keys represent unique years
   */
  @Input() set yearlyValuesMap(value: YearlyValues) {
    this.chartData = this.mapToChartData(value);
  }

  /**
   * Maps the yearly values dictionary to a line-chart compatible data
   *
   * @param value - the yearly values
   */
  mapToChartData(value: YearlyValues): Array<YearlyLineChartData> {
    return [
      {
        name: this.yAxisLabel,
        series: getKeys(value).map((year) => ({
          name: year,
          value: coerceNumberProperty(value[year]),
        })),
      },
    ];
  }

  calculateWidth() {
    return DEFAULT_CHART_WIDTH;
  }

  calculateHeight() {
    return DEFAULT_CHART_HEIGHT;
  }
}
