import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '@oculus/auth';
import { User, UserFacadeService } from 'prosumer-app/libs/eyes-core';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class UserInfoResolver implements Resolve<User> {
  constructor(
    private authService: AuthService,
    private userFacade: UserFacadeService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<User> | Observable<never> {
    return this.authService.user$.pipe(
      filter((data) => !!data && !!data.attributes),
      map((data) => data.attributes.email),
      mergeMap((email) => this.userFacade.getByEmail(email)),
      mergeMap((_) => this.userFacade.clientUser$),
      take(1),
    );
  }
}
