<mat-progress-bar
  color="accent"
  mode="indeterminate"
  *ngIf="binaryLoading$ | async"
></mat-progress-bar>
<form [formGroup]="routesForm">
  <h2 class="mat-title" mat-dialog-title>
    {{ data?.mode | titlecase }}
    {{ 'Scenario.labels.wizard.mobility.headers.routes' | translate }}
  </h2>
  <div class="mat-body" mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutGap="10px">
        <prosumer-input
          type="string"
          fxFlex="60%"
          [inputTooltip]="'wizard_mobility.wizard_mobility_name'"
          [placeholder]="'Scenario.placeholders.mobility.name' | translate"
          [label]="'Scenario.labels.wizard.mobility.labels.name' | translate"
          [control]="routesForm?.controls?.name"
          [required]="true"
          [errorMessageMap]="errorMessages['name']"
          data-testid="mobility-routes-name"
        ></prosumer-input>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <prosumer-yearly-loads-interval
          formControlName="profileRoutes"
          [required]="true"
          [startYear]="data?.startYear"
          [endYear]="data?.endYear"
          [defaultLocation]="data?.profileRoutesLocation"
          [useLibraryPanel]="true"
          [mode]="data.mode"
          [loading]="binaryLoading$ | async"
          [alwaysCustom]="true"
          [yearlyLoadEnabled]="false"
          [yearlyLoadMessages]="yearlyLoadMessage"
          [nodeOptions]="data?.nodeOptions"
          [allowStringInput]="true"
          (selectedTabChange)="onSelectInterval($event)"
        >
        </prosumer-yearly-loads-interval>
      </div>
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutGap="8px">
    <prosumer-entity-upsert-button
      *ngIf="!isViewOnly"
      [id]="data?.id"
      [disabled]="routesForm?.pristine || (binaryLoading$ | async)"
      [data]="routesForm?.value"
      [mode]="data?.mode"
      [valid]="routesForm?.valid"
      (ok)="onClose()"
      (attempt)="onSaveAttempt()"
    ></prosumer-entity-upsert-button>
    <button
      mat-flat-button
      type="button"
      data-testid="mobility-routes-cancel-btn"
      color=""
      (click)="onClose()"
      [disabled]="binaryLoading$ | async"
    >
      <span *ngIf="!isViewOnly; else closeButton">{{
        data?.close || ('Generic.labels.cancel' | translate)
      }}</span>
      <ng-template #closeButton>
        <span>{{ 'Generic.labels.close' | translate }}</span>
      </ng-template>
    </button>
  </div>
</form>
