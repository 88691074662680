import {
  EnergyVector,
  Equipment,
  EquipmentType,
} from 'prosumer-app/+scenario/models';
import { DER_TYPES } from 'prosumer-app/app.references';
import {
  BaseComponent,
  FormFieldOption,
  FormService,
  fadeInAnimation,
} from 'prosumer-app/libs/eyes-shared';
import { ProfileFormHelperService } from 'prosumer-app/shared';
import {
  LibraryService,
  ScenarioBinStore,
  ScenarioDetailType,
  TDBDataQuery,
} from 'prosumer-app/stores';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  EnergyVectorQuery,
  EnergyVectorStore,
} from 'prosumer-app/stores/energy-vector';
import {
  EquipmentCreator,
  EquipmentCreatorData,
  EquipmentInfo,
  EquipmentParents,
  EquipmentQuery,
  EquipmentStore,
} from 'prosumer-app/stores/equipment';
import { NodeQuery, NodeStore } from 'prosumer-app/stores/node';
import { ConverterComponent } from './converter-form';
import { EquipmentFormDialogData } from './equipment-dialog.model';
import { GeneratorComponent } from './generator-form';
import { VREComponent } from './renewable-form';
import { StationComponent } from './station-form';
import { StorageComponent } from './storage-form';
import { VehicleComponent } from './vehicle-form';

@UntilDestroy()
@Component({
  selector: 'prosumer-equipment-dialog',
  templateUrl: './equipment-dialog.component.html',
  styleUrls: ['./equipment-dialog.component.scss'],
  providers: [ProfileFormHelperService],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentDialogComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild(VREComponent) vre: VREComponent;
  @ViewChild(ConverterComponent) converter: ConverterComponent;
  @ViewChild(StorageComponent) storage: StorageComponent;
  @ViewChild(GeneratorComponent) generator: GeneratorComponent;
  @ViewChild(VehicleComponent) vehicle: VehicleComponent;
  @ViewChild(StationComponent) station: StationComponent;

  binaryLoading$: Observable<boolean>;
  libraryLoading$: Observable<boolean>;
  readonly isMultiNode = false;
  disableTypeSelect = false;
  isViewOnly: boolean;
  hasMobilityFeature = false;
  equipmentTypeOptions: Array<FormFieldOption<any>> = [];
  equipmentForm: FormGroup = this.formBuilder.group({
    type: '',
    id: '',
  });

  readonly nodeOptions$ = this.nodeQuery.selectActiveNodes();
  energyVectorOptions$ = new BehaviorSubject<Array<EnergyVector>>([]);
  loading$ = this.equipmentQuery.selectLoading();
  creatorLoading$ = this.equipmentCreator.selectLoading();
  detailsLoading$ = this.equipmentQuery.selectLoadingOfEntity(
    this.data.equipment?.id,
  );
  errorMsg$ = new BehaviorSubject<string>('');
  readonly isTdbReqLoading$ = this._tdbDataQuery.selectLoading();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: EquipmentFormDialogData<EquipmentInfo>,
    private _formService: FormService,
    public dialogRef: MatDialogRef<EquipmentDialogComponent>,
    public profileFormHelperService: ProfileFormHelperService,
    private _libraryService: LibraryService,
    public _binaryStore: ScenarioBinStore,
    public formBuilder: FormBuilder,
    private evStore: EnergyVectorStore,
    private nodeStore: NodeStore,
    private evQuery: EnergyVectorQuery,
    private nodeQuery: NodeQuery,
    private readonly equipmentQuery: EquipmentQuery,
    private readonly equipmentCreator: EquipmentCreator,
    private readonly store: EquipmentStore,
    private readonly _tdbDataQuery: TDBDataQuery,
  ) {
    super();
  }

  get isOkDisabled() {
    switch (this.equipmentForm.controls.type.value) {
      case 'vre':
        return !!this.vre && this.vre.vreForm.pristine;
      case 'converter':
        return !!this.converter && this.converter.converterForm.pristine;
      case 'storage':
        return !!this.storage && this.storage.storageForm.pristine;
      case 'generator':
        return !!this.generator && this.generator.generatorForm.pristine;
      case 'vehicle':
        return !!this.vehicle && this.vehicle.vehicleForm.pristine;
      case 'station':
        return !!this.station && this.station.stationForm.pristine;
    }
    return true;
  }

  initObservables() {
    this.binaryLoading$ = this._binaryStore.loading$;
    this.libraryLoading$ = this._libraryService.loading$;
  }

  initOptions() {
    this.evStore
      .getEnergyVectors({ dataType: ScenarioDetailType.energyVector })
      .pipe(untilDestroyed(this))
      .subscribe();

    this.nodeStore.getNodes().pipe(untilDestroyed(this)).subscribe();

    this.evQuery
      .selectActiveVectors()
      .pipe(untilDestroyed(this))
      .subscribe((energyVectors: Array<EnergyVector>) => {
        this.energyVectorOptions$.next(energyVectors);
      });
  }

  ngOnInit() {
    this.initOptions();
    this.initObservables();
    this.disableTypeSelect = ['edit', 'duplicate'].includes(this.data.mode);
    this.isViewOnly = this.data.isViewOnly || false;

    this.hasMobilityFeature = this.data.hasMobilityFeature;
    this.equipmentTypeOptions.push(
      ...this._formService.convertToFormFieldOptions(DER_TYPES),
    );
    if (this.hasMobilityFeature) {
      this.equipmentTypeOptions.push({ name: 'Vehicle', value: 'vehicle' });
      this.equipmentTypeOptions.push({ name: 'Station', value: 'station' });
    }
    this.equipmentForm.controls.type.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((type) => {
        this._libraryService.get(type);
      });

    // istanbul ignore next
    this.equipmentForm.controls.type.patchValue(
      this.data.equipment.type || 'vre',
    );
    this.equipmentForm.controls.id.patchValue(this.data.equipment.id);
  }

  onConfirm(): void {
    this.errorMsg$.next('');
    if (this.hasValidFormChanges()) {
      const equipmentData = this.getCreatorData();
      this.createEditEquipment(equipmentData);
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  hasValidFormChanges(): boolean {
    const type = this.getSelectedType();
    return (
      this.equipmentFormInitialized[type]() && this.equipmentFormValid[type]()
    );
  }

  private getCreatorData(): EquipmentCreatorData {
    return this.buildCreatorData(this.getEquipmentdata(this.getSelectedType()));
  }

  private getSelectedType(): EquipmentType {
    return this.equipmentForm.controls.type.value;
  }

  private getEquipmentdata(type: EquipmentType): Equipment {
    return this.deduceOnConfirmFn(type)();
  }

  private deduceOnConfirmFn(type: EquipmentType): () => Equipment {
    return this.equipmentTypeOnConfirmFnMap[type] || (() => null);
  }

  private get equipmentTypeOnConfirmFnMap(): Record<
    EquipmentType,
    () => Equipment
  > {
    return {
      vre: () => this.vre.onConfirm(),
      converter: () => this.converter.onConfirm(),
      storage: () => this.storage.onConfirm(),
      generator: () => this.generator.onConfirm(),
      vehicle: () => this.vehicle.onConfirm(),
      station: () => this.station.onConfirm(),
    };
  }

  private get equipmentFormInitialized(): Record<EquipmentType, () => boolean> {
    return {
      vre: () => !!this.vre,
      converter: () => !!this.converter,
      storage: () => !!this.storage,
      generator: () => !!this.generator,
      vehicle: () => !!this.vehicle,
      station: () => !!this.station,
    };
  }

  private get equipmentFormValid(): Record<EquipmentType, () => boolean> {
    return {
      vre: () => this.vre.vreForm.valid,
      converter: () => this.converter.converterForm.valid,
      storage: () => this.storage.storageForm.valid,
      generator: () => this.generator.generatorForm.valid,
      vehicle: () => this.vehicle.vehicleForm.valid,
      station: () => this.station.stationForm.valid,
    };
  }

  private buildCreatorData(equipmentData: Equipment): EquipmentCreatorData {
    return { ...equipmentData, ...this.buildLoadParents() };
  }

  private buildLoadParents(): EquipmentParents {
    return { ...this.data.scenarioIdentity };
  }

  private isEditMode(): boolean {
    return this.data.mode === 'edit';
  }

  private createEditEquipment(equipmentData: EquipmentCreatorData): void {
    if (this.isEditMode()) {
      this.editEquipment(equipmentData);
    } else {
      this.createEquipment(equipmentData);
    }
  }

  private editEquipment(data: EquipmentCreatorData): void {
    this.equipmentCreator
      .update(data)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.dialogRef.close();
        },
        (error) => {
          this.errorMsg$.next(error.error?.error ?? error.message);
        },
      );
  }

  private createEquipment(data: EquipmentCreatorData): void {
    this.equipmentCreator
      .create(data)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.dialogRef.close();
        },
        (error) => {
          this.errorMsg$.next(error.error?.error ?? error.message);
        },
      );
  }

  ngOnDestroy(): void {
    this.store.setActive(null);
  }

  get childFormsLoading$(): Observable<boolean> {
    return combineLatest(
      this.deduceChildIsFormLoading(this.vre),
      this.deduceChildIsFormLoading(this.storage),
      this.deduceChildIsFormLoading(this.generator),
      this.deduceChildIsFormLoading(this.converter),
    ).pipe(map((booleanList) => booleanList.some((bool) => bool)));
  }

  private deduceChildIsFormLoading(childForm): Observable<boolean> {
    return childForm?.isFormLoading$.asObservable() || of(false);
  }
}
