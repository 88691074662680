<mat-button-toggle-group
  [fxLayout]="layout"
  [fxLayoutGap]="layout === 'row' ? '10px' : '5px'"
  fxLayoutAlign="start center"
  #group="matButtonToggleGroup"
  [value]="value"
  [disabled]="isViewOnly"
  (change)="onValChange(group.value)"
>
  <mat-button-toggle
    *ngFor="let energyVector of _energyVectors"
    class="button-toggle"
    [value]="energyVector?.value"
    [class.is-unsaved-ev]="energyVector.isUnsaved"
    [class.is-view-only]="isViewOnly"
    [style.min-width.px]="width - 4"
    [style.max-width.px]="width - 4"
    [tabindex]="readonly ? -1 : 0"
    [attr.data-testid]="'ev-toggle-group-btn-'+energyVector.name"
  >
    <div
      class="toggle-content-wrapper"
      fxLayoutAlign="start center"
      fxLayoutGap="5px"
    >
      <mat-icon
        [svgIcon]="energyVector | energyVectorIcon"
        class="energy-vector-icon"
      ></mat-icon>
      <h4 class="energy-vector-name">{{ energyVector?.name }}</h4>
    </div>
    <div *ngIf="!isViewOnly" class="action-buttons-container">
      <button
        mat-icon-button
        class="delete-button"
        type="button"
        color="warn"
        [disabled]="disabled"
        (click)="onDelete(energyVector)"
        data-testid="ev-toggle-group-btn-delete"
      >
        <mat-icon aria-label="delete">close</mat-icon>
      </button>
      <prosumer-menu-button
        class="edit-button"
        [hasCustomTemplate]="true"
        [options]="evOptions"
        [disabled]="!evOptions.length"
        (selectOption)="onReplace($event, energyVector)"
        data-testid="ev-toggle-group-btn-edit"
      >
        <button
          mat-icon-button
          type="button"
          color="primary"
          [disabled]="!evOptions.length"
        >
          <mat-icon aria-label="delete" class="custom-icon-edit">edit</mat-icon>
        </button>
      </prosumer-menu-button>
    </div>
  </mat-button-toggle>
</mat-button-toggle-group>
