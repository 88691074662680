import { Utils } from 'prosumer-app/core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { CaseFacadeService } from '../state';

@Injectable()
export class CaseResolverService implements Resolve<any> {
  constructor(
    private readonly caseFacade: CaseFacadeService,
    private readonly keeper: ActiveKeeperService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): void {
    const { caseId, projectId } = Utils.resolveToEmptyObject(route.params);
    this.keeper.setActive({ project: projectId, case: caseId });
    if ([caseId, projectId].every((value) => !!value)) {
      this.caseFacade.get(caseId, { projectId });
      this.caseFacade.selectId(caseId);
    }
  }
}
