import {
  DetailEntity,
  ScenarioDetailType,
} from 'prosumer-app/stores/scenario-detail/scenario-detail.state';

export type CascadableEntity = DetailEntity &
  Readonly<{ type: ScenarioDetailType; action: CascadeAction }>;

export enum CascadeAction {
  edit = 'edit',
  delete = 'delete',
}
