<mat-progress-bar color="accent" mode="indeterminate" *ngIf="loading$ | async">
</mat-progress-bar>
<form [formGroup]="sharingForm">
  <h2
    class="mat-title"
    mat-dialog-title
    data-testid="prosumer-share-dialog-title"
  >
    {{ 'Project.labels.sharing.dialogTitlePrefix' | translate }}
    <b>{{ data?.project?.name }}</b>
    {{ 'Project.labels.sharing.dialogTitleSuffix' | translate }}
  </h2>
  <div class="mat-body" mat-dialog-content fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="column" fxLayoutGap="8px">
      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" floatLabel="always" fxFlex>
          <mat-label>{{
            'Project.labels.sharing.emailAddresses' | translate
          }}</mat-label>
          <div fxLayout fxLayoutGap="10px">
            <mat-chip-list
              #emailChips
              aria-label="Email selection"
              formControlName="emailAddresses"
            >
              <mat-chip
                *ngFor="let email of shareTo$ | async"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="removeEmail(email)"
                [ngClass]="{ duplicate: email?.isDuplicate }"
              >
                {{ email.email }}
                <mat-icon
                  matChipRemove
                  *ngIf="removable"
                  data-testid="remove-email"
                  >cancel</mat-icon
                >
              </mat-chip>
              <input
                [placeholder]="
                  (shareTo$ | async)?.length === 0
                    ? ('Project.placeholders.sharing.emailAddressesEmpty'
                      | translate)
                    : ('Project.placeholders.sharing.emailAddresses'
                      | translate)
                "
                [matChipInputFor]="emailChips"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="addEmail($event)"
                data-testid="share-to-input"
              />
            </mat-chip-list>
            <prosumer-permission-picker
              fxFlexAlign="center"
              (changePermission)="shareToChangePermission($event)"
              class="share"
              [showRemove]="false"
              data-testid="share-to-permission-picker"
            ></prosumer-permission-picker>
          </div>
          <mat-error
            *ngIf="
              sharingForm?.controls?.emailAddresses?.errors?.invalidEmails;
              else maxCount
            "
            [innerHtml]="'Project.messages.sharing.invalidEmails' | translate"
          >
          </mat-error>
          <ng-template #maxCount>
            <mat-error
              *ngIf="
                sharingForm?.controls?.emailAddresses?.errors?.maxCount;
                else userDoesntExist
              "
              [innerHtml]="'Project.messages.sharing.maxCount' | translate"
            >
            </mat-error>
          </ng-template>
          <ng-template #userDoesntExist>
            <mat-error
              *ngIf="
                sharingForm?.controls?.emailAddresses?.errors?.userDoesntExist;
                else emailUsageError
              "
              [innerHtml]="
                'Project.messages.sharing.userDoesntExist' | translate
              "
            >
            </mat-error>
          </ng-template>
          <ng-template #emailUsageError>
            <mat-error
              *ngIf="
                sharingForm?.controls?.emailAddresses?.errors?.emailUsageError;
                else hasAlreadyShared
              "
              [innerHtml]="
                'Project.messages.sharing.emailUsageError' | translate
              "
            >
            </mat-error>
          </ng-template>
          <ng-template #hasAlreadyShared>
            <mat-error
              *ngIf="
                sharingForm?.controls?.emailAddresses?.errors?.hasAlreadyShared
              "
              [innerHtml]="
                'Project.messages.sharing.hasAlreadyShared' | translate
              "
            >
            </mat-error>
          </ng-template>
        </mat-form-field>

        <button
          mat-flat-button
          color="primary"
          data-testid="prosumer-share-dialog-share-button"
          fxFlexAlign="center"
          [disabled]="
            (loading$ | async) ||
            sharingForm.invalid ||
            !(emailAddresses$ | async)?.length
          "
          (click)="shareToUsers()"
        >
          Share
        </button>
      </div>

      <div
        *ngIf="(sharedWith$ | async).length > 0"
        class="prosumer-shared-with-list"
      >
        <p class="eyes-remove-margin">
          Currently {{ 'Project.labels.sharing.sharedWith' | translate }}:
        </p>
        <mat-list data-testid="share-to-user-list">
          <mat-list-item
            *ngFor="let detail of sharedWith$ | async"
            class="prosumer-shared-with-detail"
          >
            <img
              *ngIf="detail?.imageUrl; else placeholder"
              matListAvatar
              [src]="detail?.imageUrl"
              [alt]="'Photo of ' + detail?.fullName"
            />
            <ng-template #placeholder>
              <div
                class="prosumer-image-placeholder mat-list-avatar"
                fxLayoutAlign="center center"
              >
                <mat-icon *ngIf="!getNameInitials(detail)">person</mat-icon>
                {{ getNameInitials(detail) }}
              </div>
            </ng-template>
            <div matLine fxLayout="row" fxLayoutAlign="space-between center">
              <div class="prosumer-shared-to-detail-text" fxLayout="column">
                <p class="share-with-user-fullname eyes-remove-margin">
                  {{ detail?.fullName }}
                </p>
              </div>
              <prosumer-permission-picker
                [user]="detail"
                (unshare)="onUnshare($event)"
                (changePermission)="onChangePermission(detail?.id, $event)"
                [showRemove]="true"
                data-testid="shared-with-permission-picker"
              ></prosumer-permission-picker>
            </div>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
    <div fxLayoutGap="16px" fxLayoutAlign="start center" class="share-divider">
      <mat-divider fxFlex> </mat-divider>
      <span>OR</span>
      <mat-divider fxFlex> </mat-divider>
    </div>
    <prosumer-project-share
      [project]="this.data.project"
    ></prosumer-project-share>
  </div>
  <div mat-dialog-actions fxLayout="row">
    <button
      mat-flat-button
      type="button"
      [disabled]="loading$ | async"
      (click)="onCloseOrCancel()"
      data-testid="prosumer-share-dialog-close-button"
    >
      {{ 'Generic.labels.close' | translate }}
    </button>
  </div>
</form>
