import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import {
  ConfigService,
  RouterStore,
  UserFacadeService,
} from 'prosumer-app/libs/eyes-core';
import { Observable } from 'rxjs';

import { StateEffects } from 'prosumer-app/libs/eyes-shared';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { ProsumerRoutePathService } from 'prosumer-core';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { RenewableProfile } from '../models';
import { RenewableProfileApiService } from '../services';
import { RenewableProfileFacadeService } from './renewable-profile-facade.service';
import { RenewableProfileState } from './renewable-profile-state.model';
import * as RenewableProfileActions from './renewable-profile.actions';
import { renewableProfileStateFactory } from './renewable-profile.factory';

@Injectable()
export class RenewableProfileEffects extends StateEffects<
  RenewableProfileState,
  RenewableProfile
> {
  @Effect() createRenewableProfile$: Observable<RenewableProfileActions.All> =
    this._actions$.pipe(
      ofType<RenewableProfileActions.CreateRenewableProfile>(
        RenewableProfileActions.ActionTypes.CREATE_RENEWABLE_PROFILE,
      ),
      mergeMap((action) =>
        this._renewableProfileApi.create(action.payload.data).pipe(
          map(
            (response) =>
              new RenewableProfileActions.CreateRenewableProfileSuccess({
                data: response,
                message: this._translate.instant(
                  'RenewableProfile.messages.submitSuccess',
                ),
              }),
          ),
          catchError((message) =>
            of(
              new RenewableProfileActions.CreateRenewableProfileFailure({
                message:
                  message ||
                  this._translate.instant(
                    'RenewableProfile.messages.submitFailure',
                  ),
              }),
            ),
          ),
        ),
      ),
    );

  @Effect({ dispatch: false })
  createRenewableProfileFailure$: Observable<RenewableProfileActions.All> =
    this.actions$.pipe(
      ofType<RenewableProfileActions.GetUserRenewableProfileListFailure>(
        RenewableProfileActions.ActionTypes.CREATE_RENEWABLE_PROFILE_FAILURE,
      ),
      tap((action) => this._notification.showError(action.payload.message)),
    );

  @Effect({ dispatch: false })
  createRenewableProfileSuccess$: Observable<RenewableProfileActions.All> =
    this.actions$.pipe(
      ofType<RenewableProfileActions.GetUserRenewableProfileListFailure>(
        RenewableProfileActions.ActionTypes.CREATE_RENEWABLE_PROFILE_SUCCESS,
      ),
      tap((action) => this._notification.showSuccess(action.payload.message)),
    );

  @Effect()
  getUserRenewableProfileList$: Observable<RenewableProfileActions.All> =
    this._actions$.pipe(
      ofType<RenewableProfileActions.GetUserRenewableProfileList>(
        RenewableProfileActions.ActionTypes.GET_USER_RENEWABLE_PROFILE_LIST,
      ),
      mergeMap((action) =>
        this._renewableProfileApi.getUserRenewableProfileList().pipe(
          map(
            (data) =>
              new RenewableProfileActions.GetUserRenewableProfileListSuccess({
                data,
                message: this._translate.instant(
                  'RenewableProfile.messages.getUserRenewableProfileListSuccess',
                ),
              }),
          ),
          catchError((message) =>
            of(
              new RenewableProfileActions.GetUserRenewableProfileListFailure({
                message:
                  message ||
                  this._translate.instant(
                    'RenewableProfile.messages.getUserRenewableProfileListFailure',
                  ),
              }),
            ),
          ),
        ),
      ),
    );

  @Effect({ dispatch: false })
  getUserRenewableProfileListFailure$: Observable<RenewableProfileActions.All> =
    this.actions$.pipe(
      ofType<RenewableProfileActions.GetUserRenewableProfileListFailure>(
        RenewableProfileActions.ActionTypes
          .GET_USER_RENEWABLE_PROFILE_LIST_FAILURE,
      ),
      tap((action) => this._notification.showError(action.payload.message)),
    );

  @Effect({ dispatch: false })
  getUserRenewableProfileListSuccess$: Observable<RenewableProfileActions.All> =
    this.actions$.pipe(
      ofType<RenewableProfileActions.GetUserRenewableProfileListSuccess>(
        RenewableProfileActions.ActionTypes
          .GET_USER_RENEWABLE_PROFILE_LIST_SUCCESS,
      ),
    );

  @Effect()
  getRenewableProfile$: Observable<RenewableProfileActions.All> =
    this._actions$.pipe(
      ofType<RenewableProfileActions.GetRenewableProfile>(
        RenewableProfileActions.ActionTypes.GET_RENEWABLE_PROFILE,
      ),
      mergeMap((action) =>
        this._renewableProfileApi.getRenewableProfile(action.payload.data).pipe(
          map(
            (data) =>
              new RenewableProfileActions.GetRenewableProfileSuccess({
                data,
                message: this._translate.instant(
                  'RenewableProfile.messages.getRenewableProfileSuccess',
                ),
              }),
          ),
          catchError((message) =>
            of(
              new RenewableProfileActions.GetRenewableProfileFailure({
                message:
                  message ||
                  this._translate.instant(
                    'RenewableProfile.messages.getRenewableProfileFailure',
                  ),
              }),
            ),
          ),
        ),
      ),
    );

  @Effect({ dispatch: false })
  getRenewableProfileFailure$: Observable<RenewableProfileActions.All> =
    this.actions$.pipe(
      ofType<RenewableProfileActions.GetRenewableProfileFailure>(
        RenewableProfileActions.ActionTypes.GET_RENEWABLE_PROFILE_FAILURE,
      ),
      tap((action) => this._notification.showError(action.payload.message)),
    );

  @Effect({ dispatch: false })
  getRenewableProfileSuccess$: Observable<RenewableProfileActions.All> =
    this.actions$.pipe(
      ofType<RenewableProfileActions.GetRenewableProfileSuccess>(
        RenewableProfileActions.ActionTypes.GET_RENEWABLE_PROFILE_SUCCESS,
      ),
    );

  @Effect() downloadRenewableProfile$: Observable<RenewableProfileActions.All> =
    this._actions$.pipe(
      ofType<RenewableProfileActions.DownloadRenewableProfile>(
        RenewableProfileActions.ActionTypes.DOWNLOAD_RENEWABLE_PROFILE,
      ),
      mergeMap((action) =>
        this._renewableProfileApi
          .downloadRenewableProfile(action.payload.data)
          .pipe(
            map(
              (data) =>
                new RenewableProfileActions.DownloadProfileSuccess({
                  message: this._translate.instant(
                    'RenewableProfile.messages.downloadProfileSuccess',
                  ),
                }),
            ),
            catchError((message) =>
              of(
                new RenewableProfileActions.DownloadProfileFailure({
                  message:
                    message ||
                    this._translate.instant(
                      'RenewableProfile.messages.downloadProfileFailure',
                    ),
                }),
              ),
            ),
          ),
      ),
    );

  @Effect({ dispatch: false })
  downloadRenewableProfileFailure$: Observable<RenewableProfileActions.All> =
    this._actions$.pipe(
      ofType<RenewableProfileActions.DownloadProfileFailure>(
        RenewableProfileActions.ActionTypes.DOWNLOAD_RENEWABLE_PROFILE_FAILURE,
      ),
      tap((action) => this._notification.showError(action.payload.message)),
    );

  @Effect()
  updateRenewableProfile$: Observable<RenewableProfileActions.All> =
    this._actions$.pipe(
      ofType<RenewableProfileActions.UpdateRenewableProfile>(
        RenewableProfileActions.ActionTypes.UPDATE_RENEWABLE_PROFILE,
      ),
      mergeMap((action) =>
        this._renewableProfileApi.getRenewableProfile(action.payload.data).pipe(
          map(
            (data) =>
              new RenewableProfileActions.UpdateRenewableProfileSuccess({
                data,
                message: this._translate.instant(
                  'RenewableProfile.messages.getRenewableProfileSuccess',
                ),
              }),
          ),
          catchError((message) =>
            of(
              new RenewableProfileActions.UpdateRenewableProfileFailure({
                message:
                  message ||
                  this._translate.instant(
                    'RenewableProfile.messages.getRenewableProfileFailure',
                  ),
              }),
            ),
          ),
        ),
      ),
    );

  @Effect({ dispatch: false })
  updateRenewableProfileFailure$: Observable<RenewableProfileActions.All> =
    this.actions$.pipe(
      ofType<RenewableProfileActions.UpdateRenewableProfileFailure>(
        RenewableProfileActions.ActionTypes.UPDATE_RENEWABLE_PROFILE_FAILURE,
      ),
      tap((action) => this._notification.showError(action.payload.message)),
    );

  @Effect({ dispatch: false })
  updateRenewableProfileSuccess$: Observable<RenewableProfileActions.All> =
    this.actions$.pipe(
      ofType<RenewableProfileActions.UpdateRenewableProfileSuccess>(
        RenewableProfileActions.ActionTypes.UPDATE_RENEWABLE_PROFILE_SUCCESS,
      ),
    );

  constructor(
    private _actions$: Actions,
    private _config: ConfigService,
    private _notification: NotificationsService,
    private _renewableProfileApi: RenewableProfileApiService,
    private _renewableProfileFacade: RenewableProfileFacadeService,
    private _routePath: ProsumerRoutePathService,
    private _routerFacade: RouterStore,
    private _translate: TranslateService,
    private _userFacade: UserFacadeService,
  ) {
    super(
      _actions$,
      _renewableProfileApi,
      _notification,
      _renewableProfileFacade,
      renewableProfileStateFactory,
      _translate,
    );
  }

  getMessageMap() {
    return {
      createSuccess: 'RenewableProfile.messages.submitSuccess',
      createFailure: 'RenewableProfile.messages.submitFailure',
      getSuccess: 'RenewableProfile.messages.getSuccess',
      getFailure: 'RenewableProfile.messages.getFailure',
      getListSuccess: 'RenewableProfile.messages.getAllSuccess',
      getListFailure: 'RenewableProfile.messages.getAllFailure',
      updateSuccess: '',
      updateFailure: '',
      deleteSuccess: '',
      deleteFailure: '',
      retry: 'Generic.messages.retry',
    };
  }
}
