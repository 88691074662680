import { Injectable } from '@angular/core';
import {
  CascadableEntity,
  CascadeAction,
  CascadeController,
} from 'prosumer-app/services/cascade-controller';
import { StoreApiService } from 'prosumer-app/services/store-api';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import {
  DetailEntity,
  ScenarioDetailType,
} from '../scenario-detail/scenario-detail.state';
import { ScenarioDetailStore } from '../scenario-detail/scenario-detail.store';

@Injectable({ providedIn: 'root' })
export abstract class CascadableDetailStore<
  T extends DetailEntity,
> extends ScenarioDetailStore<T> {
  constructor(
    readonly storeApi: StoreApiService,
    readonly cascader: CascadeController,
  ) {
    super(storeApi);
  }

  deleteOne(id: string): Observable<unknown> {
    return this.confirmCascade(id, CascadeAction.delete).pipe(
      switchMap(() => super.deleteOne(id)),
      tap(() => this.postCascadeDeletion(id)),
    );
  }

  cascadeEdit(id: string, data: T): Observable<unknown> {
    return this.confirmCascade(id, CascadeAction.edit).pipe(
      switchMap(() => super.edit(id, data)),
    );
  }

  confirmCascade(id: string, action: CascadeAction): Observable<unknown> {
    return this.cascader
      .confirmIfApplicable(this.buildCascadableEntity(id, action))
      .pipe(filter((confirmed) => !!confirmed));
  }

  postCascadeDeletion(id: string): void {
    // do something
  }

  private buildCascadableEntity(
    id: string,
    action: CascadeAction,
  ): CascadableEntity {
    return {
      ...this.getEntity(id),
      type: this.storeName as ScenarioDetailType,
      action,
    };
  }
}
