import { ProjectFacadeService } from 'prosumer-app/+project/state';
import { Utils } from 'prosumer-app/core';
import { take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { ScenarioFacadeService } from '../state';

@Injectable()
export class ScenarioResolverService implements Resolve<any> {
  constructor(
    private projectFacade: ProjectFacadeService,
    private scenarioFacade: ScenarioFacadeService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): void {
    const { scenarioId, caseId, projectId } = Utils.resolveToEmptyObject(
      route.params,
    );

    if ([scenarioId, caseId, projectId].every((value) => !!value)) {
      this.scenarioFacade
        .dataById$(scenarioId)
        .pipe(take(1))
        .subscribe((scenario) => {
          this.scenarioFacade.get(
            scenarioId,
            {
              projectId,
              caseId,
            },
            !scenario?.general,
          );
          this.scenarioFacade.resetCoherence(scenarioId);
          this.scenarioFacade.selectId(scenarioId);
          this.projectFacade.selectId(projectId);
        });
    }
  }
}
