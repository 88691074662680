<mat-progress-bar
  color="accent"
  mode="indeterminate"
  *ngIf="binaryLoading$ | async"
>
</mat-progress-bar>
<form [formGroup]="form">
  <h2 class="mat-title" mat-dialog-title>
    {{ data?.mode | titlecase }}
    {{
      'Scenario.dialog.frequencyControl.spinningReserves.margin.title'
        | translate
    }}
  </h2>

  <div class="mat-body" mat-dialog-content fxLayout="column" fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutGap="10px">
      <!-- Energy Vector -->
      <mat-form-field
        [@fadeIn]
        appearance="outline"
        class="eyes-maximize-width"
        [attr.data-testid]="'prosumer-margin-form-energy-vector'"
      >
        <mat-label>{{
          'Scenario.dialog.frequencyControl.spinningReserves.margin.labels.energyVector'
            | translate
        }}</mat-label>

        <mat-select
          required
          formControlName="energyVectorId"
          [placeholder]="
            'Scenario.dialog.frequencyControl.spinningReserves.margin.placeholders.energyVector'
              | translate
          "
        >
          <mat-option
            *ngFor="let energyVector of energyVectorOptions"
            [value]="energyVector.value"
          >
            {{ energyVector.name }}
          </mat-option>
        </mat-select>
        <mat-icon
          matSuffix
          class="eyes-input-tooltip-anchor"
          [prosumerTooltip]="'wizard_frequency_control.reserve_energy_vector'"
        >
          help
        </mat-icon>
        <mat-error
          *ngFor="let error of energyVectorIdControl?.errors | keyvalue"
          [ngSwitch]="error.key"
        >
          <div
            *ngSwitchCase="'required'"
            [innerHtml]="
              'Scenario.dialog.frequencyControl.spinningReserves.margin.errors.energyVector.required'
                | translate
            "
          ></div>
          <div
            *ngSwitchCase="'dataExist'"
            [style.line-height.px]="12"
            [innerHtml]="
              'Scenario.dialog.frequencyControl.spinningReserves.margin.errors.energyVector.dataExist'
                | translate
            "
          ></div>
        </mat-error>
      </mat-form-field>

      <!-- Direction -->
      <mat-form-field
        [@fadeIn]
        appearance="outline"
        class="eyes-maximize-width"
        [attr.data-testid]="'prosumer-margin-form-direction'"
      >
        <mat-label>{{
          'Scenario.dialog.frequencyControl.spinningReserves.margin.labels.direction'
            | translate
        }}</mat-label>

        <mat-select
          required
          formControlName="direction"
          [placeholder]="
            'Scenario.dialog.frequencyControl.spinningReserves.margin.placeholders.direction'
              | translate
          "
        >
          <mat-option
            *ngFor="let direction of sortedDirections"
            [value]="direction.key"
          >
            {{ direction.value }}
          </mat-option>
        </mat-select>

        <mat-icon
          class="eyes-input-tooltip-anchor"
          matSuffix
          [prosumerTooltip]="'wizard_frequency_control.direction'"
        >
          help
        </mat-icon>

        <mat-error
          *ngFor="let error of directionControl?.errors | keyvalue"
          [ngSwitch]="error.key"
        >
          <div
            *ngSwitchCase="'required'"
            [innerHtml]="
              'Scenario.dialog.frequencyControl.spinningReserves.margin.errors.direction.required'
                | translate
            "
          ></div>
        </mat-error>
      </mat-form-field>
      <div fxFlex="25%"></div>
      <div fxFlex="25%"></div>
    </div>
    <prosumer-yearly-loads-interval
      formControlName="profiles"
      [required]="true"
      [defaultLocation]="'reserve.margin'"
      [startYear]="startYear"
      [endYear]="endYear"
      [useLibraryPanel]="false"
      [yearlyLoadMessages]="yearlyLoadMessage"
      [alwaysCustom]="true"
      [mode]="data.mode"
      [yearlyLoadEnabled]="false"
      [loading]="binaryLoading$ | async"
      (selectedTabChange)="onSelectInterval($event)"
    >
    </prosumer-yearly-loads-interval>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutGap="8px">
    <prosumer-entity-upsert-button
      *ngIf="data.mode !== 'view'"
      [id]="data.currentReserveMargin?.id"
      [disabled]="form.pristine"
      [data]="form.value"
      [mode]="data.mode"
      [valid]="form.valid"
      (ok)="onSaveSuccess()"
      (attempt)="onSaveAttempt()"
    ></prosumer-entity-upsert-button>

    <button
      mat-flat-button
      type="button"
      (click)="onClose()"
      [attr.data-testid]="'prosumer-margin-form-cancel-button'"
    >
      <span
        [attr.data-testid]="'prosumer-margin-form-cancel-label'"
        *ngIf="data?.mode !== 'view'; else closeButton"
      >
        {{ 'Generic.labels.cancel' | translate }}
      </span>
      <ng-template #closeButton>
        <span [attr.data-testid]="'prosumer-margin-form-close-label'">{{
          'Generic.labels.close' | translate
        }}</span>
      </ng-template>
    </button>
  </div>
</form>
