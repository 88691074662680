import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveComponentModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { ChartsModule } from 'prosumer-shared';
import { SimpleChipsModule } from 'prosumer-shared/components/simple-chips';
import { PieChartjsModule } from 'prosumer-shared/modules/chartjs/pie-chartjs';
import { StackedBarChartjsModule } from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';
import { EmissionsDirectionModule } from '../emissions-direction';
import { ResultsTabulatorModule } from '../results-tabulator';
import { EnergyBalanceResultsComponent } from './energy-balance-results.component';

@NgModule({
  declarations: [EnergyBalanceResultsComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ChartsModule,
    SimpleChipsModule,
    TranslateModule,
    ResultsTabulatorModule,
    PieChartjsModule,
    StackedBarChartjsModule,
    EmissionsDirectionModule,
    ReactiveComponentModule,
  ],
  exports: [EnergyBalanceResultsComponent],
})
export class EnergyBalanceResultsModule {}
