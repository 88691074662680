import { Result } from 'prosumer-app/+scenario/models';
import { Utils } from 'prosumer-app/core';
import { buildEntityId } from 'prosumer-app/shared';
import { ScenarioCompareApiService } from 'prosumer-scenario/services';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  tap,
  toArray,
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ComparisonTray } from '@prosumer/comparison/base';
import {
  EmissionsData,
  EquipmentResult,
  TopologyResult,
} from '@prosumer/results/models';

import { NotificationsService } from 'prosumer-app/shared/services/notification';
import * as CompareStateActions from './scenario-compare.actions';

@Injectable()
export class ScenarioCompareEffects {
  constructor(
    public actions$: Actions,
    private _compareApi: ScenarioCompareApiService,
    private _notification: NotificationsService,
  ) {}

  private mapResult(
    request: Array<Result>,
    response: Array<Result>,
  ): Array<Result> {
    return request.map((itm) => ({
      ...itm,
      ...response.find(
        ({ id, variationId, name }) =>
          buildEntityId(id, variationId) === itm.id && name === itm.name,
      ),
      // retain concatenated id
      id: itm.id,
    }));
  }

  private isAllOutputSplitted(data: Array<Result>): boolean {
    return data.every((result) => result?.isOutputSplit);
  }

  @Effect()
  compareScenario$: Observable<CompareStateActions.AllCompareActions> =
    this.actions$.pipe(
      ofType<CompareStateActions.CompareScenario>(
        CompareStateActions.CompareActionTypes.COMPARE_SCENARIO,
      ),
      mergeMap((action) =>
        from(action.payload.resultList).pipe(
          mergeMap((data) =>
            this._compareApi.compare(
              action.payload.referenceId,
              action.payload.projectId,
              action.payload.caseId,
              data.scenarioId,
              data.name,
              data.variationId,
            ),
          ),
          toArray(),
          map(
            (resultList) =>
              new CompareStateActions.CompareScenarioSuccess({
                data: this.mapResult(action.payload.resultList, resultList),
              }),
          ),
          catchError((error) =>
            of(
              new CompareStateActions.CompareScenarioFailure({
                referenceId: action.payload.referenceId,
                resultList: action.payload.resultList,
                message: error,
              }),
            ),
          ),
        ),
      ),
    );
  @Effect({ dispatch: false }) compareScenarioFailure$ = this.actions$.pipe(
    ofType<CompareStateActions.CompareScenarioFailure>(
      CompareStateActions.CompareActionTypes.COMPARE_SCENARIO_FAILURE,
    ),
    tap((action) => this._notification.showError(action.payload.message || '')),
  );

  @Effect()
  migrateScenario$: Observable<CompareStateActions.AllCompareActions> =
    this.actions$.pipe(
      ofType<CompareStateActions.MigrateScenario>(
        CompareStateActions.CompareActionTypes.MIGRATE_SCENARIO,
      ),
      mergeMap((action) =>
        from(action.payload.resultList).pipe(
          mergeMap((data) =>
            this._compareApi.migrate(
              action.payload.projectId,
              action.payload.caseId,
              data.scenarioId,
              data.variationId,
            ),
          ),
          toArray(),
          map(
            (resultList) =>
              new CompareStateActions.MigrateScenarioSuccess({
                data: this.mapResult(action.payload.resultList, resultList),
                isOutputSplit: this.isAllOutputSplitted(resultList),
              }),
          ),
          catchError((error) =>
            of(new CompareStateActions.MigrateScenarioFailure({ error })),
          ),
        ),
      ),
    );
  @Effect({ dispatch: false })
  migrateScenarioSuccess$: Observable<CompareStateActions.AllCompareActions> =
    this.actions$.pipe(
      ofType(CompareStateActions.CompareActionTypes.MIGRATE_SCENARIO_SUCCESS),
    );
  @Effect({ dispatch: false })
  migrateScenarioFailure$: Observable<CompareStateActions.AllCompareActions> =
    this.actions$.pipe(
      ofType(CompareStateActions.CompareActionTypes.MIGRATE_SCENARIO_FAILURE),
    );

  @Effect()
  compareScenarioMainTab$: Observable<CompareStateActions.AllCompareActions> =
    this.actions$.pipe(
      ofType<CompareStateActions.CompareScenarioMainTab>(
        CompareStateActions.CompareActionTypes.COMPARE_SCENARIO_MAIN_TAB,
      ),
      mergeMap((action) =>
        from(action.payload.resultList).pipe(
          mergeMap((data) =>
            this._compareApi.compareMain(
              action.payload.referenceId,
              action.payload.projectId,
              action.payload.caseId,
              data.scenarioId,
              data.name,
              data.variationId,
            ),
          ),
          toArray(),
          map(
            (resultList) =>
              new CompareStateActions.CompareScenarioMainTabSuccess({
                data: this.mapResult(action.payload.resultList, resultList),
              }),
          ),
          catchError((error) =>
            of(
              new CompareStateActions.CompareScenarioMainTabFailure({
                message: error,
              }),
            ),
          ),
        ),
      ),
    );
  @Effect({ dispatch: false })
  compareScenarioMainTabFailure$: Observable<CompareStateActions.AllCompareActions> =
    this.actions$.pipe(
      ofType<CompareStateActions.CompareScenarioMainTabFailure>(
        CompareStateActions.CompareActionTypes
          .COMPARE_SCENARIO_MAIN_TAB_FAILURE,
      ),
      tap((action) =>
        this._notification.showError(action.payload.message || ''),
      ),
    );

  @Effect()
  compareScenarioEquipmentTab$: Observable<CompareStateActions.AllCompareActions> =
    this.actions$.pipe(
      ofType<CompareStateActions.CompareScenarioEquipmentTab>(
        CompareStateActions.CompareActionTypes.COMPARE_SCENARIO_EQUIPMENT_TAB,
      ),
      mergeMap((action) =>
        from(action.payload.resultList).pipe(
          mergeMap((data) =>
            this._compareApi.compareEquipment(
              action.payload.projectId,
              action.payload.caseId,
              data.scenarioId,
              data.name,
              data.variationId,
            ),
          ),
          toArray(),
          map(
            (resultList) =>
              new CompareStateActions.CompareScenarioEquipmentTabSuccess({
                data: Utils.flattenArrayOfObject<
                  ComparisonTray<EquipmentResult>,
                  EquipmentResult[]
                >(resultList),
              }),
          ),
          catchError((error) =>
            of(
              new CompareStateActions.CompareScenarioEquipmentTabFailure({
                message: error,
              }),
            ),
          ),
        ),
      ),
    );
  @Effect({ dispatch: false })
  compareScenarioEquipmentTabFailure$: Observable<CompareStateActions.AllCompareActions> =
    this.actions$.pipe(
      ofType<CompareStateActions.CompareScenarioEquipmentTabFailure>(
        CompareStateActions.CompareActionTypes
          .COMPARE_SCENARIO_EQUIPMENT_TAB_FAILURE,
      ),
      tap((action) =>
        this._notification.showError(action.payload.message || ''),
      ),
    );

  @Effect()
  compareScenarioDispatchTab$: Observable<CompareStateActions.AllCompareActions> =
    this.actions$.pipe(
      ofType<CompareStateActions.CompareScenarioDispatchTab>(
        CompareStateActions.CompareActionTypes.COMPARE_SCENARIO_DISPATCH_TAB,
      ),
      mergeMap((action) =>
        from(action.payload.resultList).pipe(
          mergeMap((data) =>
            this._compareApi.compareDispatch(
              action.payload.projectId,
              action.payload.caseId,
              data.scenarioId,
              data.name,
              data.variationId,
            ),
          ),
          toArray(),
          map(
            (resultList) =>
              new CompareStateActions.CompareScenarioDispatchTabSuccess({
                data: resultList,
              }),
          ),
          catchError((error) =>
            of(
              new CompareStateActions.CompareScenarioDispatchTabFailure({
                message: error,
              }),
            ),
          ),
        ),
      ),
    );
  @Effect({ dispatch: false })
  compareScenarioDispatchTabFailure$: Observable<CompareStateActions.AllCompareActions> =
    this.actions$.pipe(
      ofType<CompareStateActions.CompareScenarioDispatchTabFailure>(
        CompareStateActions.CompareActionTypes
          .COMPARE_SCENARIO_DISPATCH_TAB_FAILURE,
      ),
      tap((action) =>
        this._notification.showError(action.payload.message || ''),
      ),
    );

  @Effect()
  compareScenarioEnergyBalanceTab$: Observable<CompareStateActions.AllCompareActions> =
    this.actions$.pipe(
      ofType<CompareStateActions.CompareScenarioEnergyBalanceTab>(
        CompareStateActions.CompareActionTypes
          .COMPARE_SCENARIO_ENERGY_BALANCE_TAB,
      ),
      mergeMap((action) =>
        from(action.payload.resultList).pipe(
          mergeMap((data) =>
            this._compareApi.compareEnergyBalance(
              action.payload.projectId,
              action.payload.caseId,
              data.scenarioId,
              data.name,
              data.variationId,
            ),
          ),
          toArray(),
          map(
            (resultList) =>
              new CompareStateActions.CompareScenarioEnergyBalanceTabSuccess({
                data: resultList,
              }),
          ),
          catchError((error) =>
            of(
              new CompareStateActions.CompareScenarioEnergyBalanceTabFailure({
                message: error,
              }),
            ),
          ),
        ),
      ),
    );
  @Effect({ dispatch: false })
  compareScenarioEnergyBalanceTabFailure$: Observable<CompareStateActions.AllCompareActions> =
    this.actions$.pipe(
      ofType<CompareStateActions.CompareScenarioEnergyBalanceTabFailure>(
        CompareStateActions.CompareActionTypes
          .COMPARE_SCENARIO_ENERGY_BALANCE_TAB_FAILURE,
      ),
      tap((action) =>
        this._notification.showError(action.payload.message || ''),
      ),
    );

  @Effect()
  compareScenarioTopologyTab$: Observable<CompareStateActions.AllCompareActions> =
    this.actions$.pipe(
      ofType<CompareStateActions.CompareScenarioTopologyTab>(
        CompareStateActions.CompareActionTypes.COMPARE_SCENARIO_TOPOLOGY_TAB,
      ),
      mergeMap((action) =>
        from(action.payload.resultList).pipe(
          mergeMap((data) =>
            this._compareApi.compareTopology(
              action.payload.projectId,
              action.payload.caseId,
              data.scenarioId,
              data.name,
              data.variationId,
            ),
          ),
          toArray(),
          map(
            (resultList) =>
              new CompareStateActions.CompareScenarioTopologyTabSuccess({
                data: Utils.flattenArrayOfObject<
                  ComparisonTray<TopologyResult>,
                  TopologyResult[]
                >(resultList),
              }),
          ),
          catchError((error) =>
            of(
              new CompareStateActions.CompareScenarioTopologyTabFailure({
                message: error,
              }),
            ),
          ),
        ),
      ),
    );
  @Effect({ dispatch: false })
  compareScenarioTopologyTabFailure$: Observable<CompareStateActions.AllCompareActions> =
    this.actions$.pipe(
      ofType<CompareStateActions.CompareScenarioTopologyTabFailure>(
        CompareStateActions.CompareActionTypes
          .COMPARE_SCENARIO_TOPOLOGY_TAB_FAILURE,
      ),
      tap((action) =>
        this._notification.showError(action.payload.message || ''),
      ),
    );

  @Effect()
  compareScenarioCO2EmissionTab$: Observable<CompareStateActions.AllCompareActions> =
    this.actions$.pipe(
      ofType<CompareStateActions.CompareScenarioCO2EmissionTab>(
        CompareStateActions.CompareActionTypes
          .COMPARE_SCENARIO_CO2_EMISSION_TAB,
      ),
      mergeMap((action) =>
        from(action.payload.resultList).pipe(
          mergeMap((data) =>
            this._compareApi.compareCO2Emission(
              action.payload.projectId,
              action.payload.caseId,
              data.scenarioId,
              data.name,
              data.variationId,
            ),
          ),
          toArray(),
          map(
            (resultList) =>
              new CompareStateActions.CompareScenarioCO2EmissionTabSuccess({
                data: Utils.flattenArrayOfObject<
                  ComparisonTray<EmissionsData>,
                  EmissionsData[]
                >(resultList),
              }),
          ),
          catchError((error) =>
            of(
              new CompareStateActions.CompareScenarioCO2EmissionTabFailure({
                message: error,
              }),
            ),
          ),
        ),
      ),
    );
  @Effect({ dispatch: false })
  compareScenarioCO2EmissionTabFailure$: Observable<CompareStateActions.AllCompareActions> =
    this.actions$.pipe(
      ofType<CompareStateActions.CompareScenarioCO2EmissionTabFailure>(
        CompareStateActions.CompareActionTypes
          .COMPARE_SCENARIO_CO2_EMISSION_TAB_FAILURE,
      ),
      tap((action) =>
        this._notification.showError(action.payload.message || ''),
      ),
    );

  @Effect()
  compareScenarioCashFlowTab$: Observable<CompareStateActions.AllCompareActions> =
    this.actions$.pipe(
      ofType<CompareStateActions.CompareScenarioCashFlowTab>(
        CompareStateActions.CompareActionTypes.COMPARE_SCENARIO_CASHFLOW_TAB,
      ),
      mergeMap((action) =>
        from(action.payload.resultList).pipe(
          concatMap((data) =>
            this._compareApi.compareCashFlow(
              action.payload.projectId,
              action.payload.caseId,
              data.scenarioId,
              data.name,
              data.variationId,
            ),
          ),
          toArray(),
          map(
            (resultList) =>
              new CompareStateActions.CompareScenarioCashFlowTabSuccess({
                data: resultList,
              }),
          ),
          catchError((error) =>
            of(
              new CompareStateActions.CompareScenarioCashFlowTabFailure({
                message: error,
              }),
            ),
          ),
        ),
      ),
    );
  @Effect({ dispatch: false })
  compareScenarioCashFlowTabFailure$: Observable<CompareStateActions.AllCompareActions> =
    this.actions$.pipe(
      ofType<CompareStateActions.CompareScenarioCashFlowTabFailure>(
        CompareStateActions.CompareActionTypes
          .COMPARE_SCENARIO_CASHFLOW_TAB_FAILURE,
      ),
      tap((action) =>
        this._notification.showError(action.payload.message || ''),
      ),
    );
}
