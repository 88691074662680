import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';

import { Scenario } from 'prosumer-app/+scenario/models';
import {
  BaseComponent,
  CustomValidators,
  contains,
  fadeInAnimation,
} from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { mapTo, startWith, take, tap } from 'rxjs/operators';

import { CompareDialogModel } from './compare-dialog.model';

@Component({
  selector: 'prosumer-scenario-compare-dialog',
  templateUrl: './compare-dialog.component.html',
  styleUrls: ['./compare-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
})
export class ScenarioCompareDialogComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild(FormGroupDirective) compareFormGroupDirective: FormGroupDirective;

  scenarioOptions$ = new BehaviorSubject([]);
  submitted$: Observable<any> = of(false);

  compareForm = this.formBuilder.group({
    selectedScenarios: [
      null,
      [Validators.required, CustomValidators.minArrayValueLength(2)],
    ],
    referenceId: [null, Validators.required],
  });

  get selectedScenariosControl() {
    return this.compareForm.controls.selectedScenarios;
  }

  get referenceIdControl() {
    return this.compareForm.controls.referenceId;
  }

  selectedScenariosValue$: Observable<Array<Scenario>> =
    this.selectedScenariosControl.valueChanges.pipe(
      startWith(this.selectedScenariosControl.value),
      this.takeUntilShare(),
    );

  referenceIdValue$: Observable<string> =
    this.referenceIdControl.valueChanges.pipe(
      startWith(this.referenceIdControl.value),
      this.takeUntilShare(),
    );

  selectedScenarios: Array<Scenario> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CompareDialogModel,
    public dialogRef: MatDialogRef<CompareDialogModel>,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit() {
    this.initScenarioOptions();
    this.initAutoSelectRefenceId();
  }

  ngAfterViewInit() {
    this.initSubmittedObservable();
  }

  initScenarioOptions() {
    if (
      this.data &&
      this.data.scenarioOptions &&
      this.data.scenarioOptions.length > 0
    ) {
      this.scenarioOptions$.next(this.data.scenarioOptions);
    }
  }

  initAutoSelectRefenceId() {
    this.selectedScenariosValue$
      .pipe(
        tap((selectedScenarios) => {
          if (!!selectedScenarios && selectedScenarios.length > 0) {
            const isReferenceIdUnselected =
              !!this.referenceIdControl.value &&
              !contains(
                selectedScenarios.map((scenario) => scenario.id),
                this.referenceIdControl.value,
              );
            // istanbul ignore else
            if (!!!this.referenceIdControl.value || isReferenceIdUnselected) {
              this.referenceIdControl.patchValue(
                selectedScenarios[selectedScenarios.length - 1].id,
              );
            }
          } else {
            this.referenceIdControl.reset();
          }
        }),
      )
      .subscribe();
  }

  initSubmittedObservable() {
    // istanbul ignore else
    if (this.compareFormGroupDirective) {
      this.submitted$ = this.compareFormGroupDirective.ngSubmit
        .asObservable()
        .pipe(take(1), mapTo(true), this.takeUntil());
    }
  }

  onSelectedChange(selectedScenario: MatSelectionListChange) {
    const { option } = selectedScenario;

    if (option.selected) {
      this.selectedScenarios.push(option.value);
    } else {
      this.selectedScenarios = this.selectedScenarios.filter(
        (scenario) => scenario.id !== option.value.id,
      );
    }
  }

  onConfirm() {
    // istanbul ignore else
    if (this.compareForm.valid) {
      const formValues = {
        ...this.compareForm.value,
        selectedScenarios: this.selectedScenarios,
      };

      this.dialogRef.close(formValues);
    }
  }

  onClose() {
    this.dialogRef.close();
  }
}
