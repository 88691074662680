import { HasDataType } from 'prosumer-app/stores/scenario-detail/scenario-detail.state';

export enum ApiRequestType {
  get = 'httpGet',
  post = 'httpPost',
  patch = 'httpPatch',
  delete = 'httpDelete',
}

export interface ApiRequest<T> extends EndpointParam {
  body: T & Partial<HasDataType>;
}

export interface EndpointParam {
  data: Record<string, unknown>;
  key: string;
}

export interface GetAllResponse {
  readonly details: unknown[];
}
