<form [formGroup]="form">
  <div
    fxLayout="column"
    fxLayoutGap="10px"
    formArrayName="intervalList"
    *ngFor="let interval of intervalList.controls; let i = index"
  >
    <div [formGroupName]="i" fxLayout="row" fxLayoutGap="10px">
      <prosumer-select
        label="Start Year"
        [options]="getStartYearOptions(interval)"
        [value]="getStartYearControl(interval)"
        [disabled]="!!!i"
        (eyesSelectionChange)="updateRange(i, $event)"
        fxFlex="calc(30%-10px)"
      >
      </prosumer-select>
      <prosumer-select
        label="End Year"
        [options]="getEndYearOptions(interval)"
        [value]="getEndYearControl(interval)"
        [disabled]="true"
        fxFlex="calc(30%-10px)"
      >
      </prosumer-select>

      <mat-form-field
        class="eyes-maximize-width"
        appearance="outline"
        [@fadeIn]
        fxFlex="calc(30%-10px)"
      >
        <mat-label>Value</mat-label>
        <input
          matInput
          class="eyes-input"
          formControlName="value"
          placeholder="0.0"
          type="number"
          required
          [readonly]="isViewOnly"
        />
        <mat-error
          *ngIf="
            interval?.controls?.value?.errors?.required ||
            interval?.controls?.value === null
          "
          [innerHtml]="'Project.messages.requiredField' | translate"
        ></mat-error>
        <mat-error
          *ngIf="interval?.controls?.value?.errors?.min"
          [innerHtml]="'Project.messages.positiveOnly' | translate"
        >
        </mat-error>
      </mat-form-field>

      <button
        data-testid="remove-interval"
        type="button"
        mat-icon-button
        color="primary"
        (click)="deleteRange(i)"
        *ngIf="i && !isViewOnly"
      >
        <mat-icon color="warn">cancel</mat-icon>
      </button>
      <div *ngIf="!i" fxFlex="40px"></div>
    </div>
  </div>
  <button
    type="button"
    data-testid="add-interval"
    mat-button
    color="primary"
    (click)="addRange()"
    [disabled]="isDisabled()"
    *ngIf="!isViewOnly && !isEnd()"
  >
    <mat-icon>add</mat-icon>
    <span>Interval</span>
  </button>
</form>
