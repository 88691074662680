<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="16px">
  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <span data-testid="perception-title" class="perception-title"
        >Results Legend Colors</span
      >
      <span>-</span>
      <span data-testid="perception-case-name" class="perception-case-name">{{
        caseObject?.name || 'some random case'
      }}</span>
    </div>
    <span data-testid="perception-subtitle" class="perception-subtitle"
      >The colors used to show each result legend in all the graphs. Click a
      color to change.</span
    >
  </div>
  <mat-form-field appearance="outline">
    <input
      data-testid="perception-search-control"
      matInput
      [formControl]="searchControl"
      placeholder="Search by name..."
    />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
  <div
    data-testid="perception-legend-list"
    class="perception-legend-list"
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="8px"
  >
    <div
      *ngFor="let perception of resultNames$ | async"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="32px"
      data-testid="perception-legend-select"
    >
      <span data-testid="perception-legend-name">{{ perception }}</span>
      <div
        fxLayout="row"
        fxLayoutAlign="end center"
        data-testid="perception-legend-color-picker"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          class="perception-picker"
          [colorPicker]="(perceptionMap$ | async)[perception]"
          [cpPresetColors]="presetColors"
          [cpPresetLabel]="presetLabel"
          [cpAlphaChannel]="'disabled'"
          (colorPickerChange)="onColorChange(perception, $event)"
          (cpInputChange)="onColorChange(perception, $event.color)"
          [style.background]="(perceptionMap$ | async)[perception]"
        >
          <span [style.color]="(contrastMap$ | async)[perception]">{{
            (perceptionMap$ | async)[perception]
          }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
