<ng-container *ngrxLet="startYear$ as startYear">
  <ng-container *ngrxLet="endYear$ as endYear">
    <div
      fxLayout="row"
      fxLayoutGap="10px"
      [formGroup]="form"
      *ngrxLet="loadingMap$ as loading"
    >
      <div fxFlex="10%" class="variation-name">
        <span title="{{ scenarioName$ | ngrxPush }}">{{
          scenarioName$ | ngrxPush
        }}</span>
      </div>
      <prosumer-yearly-chart-input
        fxFlex="25%"
        formControlName="yearlyMaximumEmissions"
        inputLabel="Scenario.labels.optimizationCockpit.yearlyMaximumEmissions"
        errorMessage="Scenario.messages.yearlyMaximumEmissions"
        [contextHelpMsg]="
          'wizard_optimization_cockpit.wizard_yearly_maximum_emissions'
        "
        [startYear]="startYear"
        [endYear]="endYear"
        [notRequired]="true"
        [isViewOnly]="isViewOnly"
        [processing]="loading.yearlyMaximumEmissions"
        scenarioUpdater
        [key]="updateKey"
        [view]="updateView"
        [nextValue]="yearlyMaxEmissions$ | ngrxPush"
        [hasSkip]="true"
        [spin]="false"
        [invalid]="yearlyMaximumEmissionsCtr.errors"
        [minValue]="0"
        (statusChange)="onYearlyEmissionsChange($event)"
        data-testid="yearlyMaximumEmissions"
      >
      </prosumer-yearly-chart-input>

      <prosumer-yearly-chart-input
        data-testid="optimization-cockpit-yearly-max-costs-input"
        fxFlex="25%"
        formControlName="yearlyMaximumCosts"
        inputLabel="Scenario.labels.optimizationCockpit.yearlyMaximumCosts"
        errorMessage="Scenario.messages.yearlyMaximumCosts"
        [contextHelpMsg]="
          'wizard_optimization_cockpit.wizard_yearly_maximum_cost'
        "
        [startYear]="startYear"
        [endYear]="endYear"
        [notRequired]="true"
        [isViewOnly]="isViewOnly"
        [processing]="loading.yearlyMaximumCosts"
        scenarioUpdater
        [key]="updateKey"
        [view]="updateView"
        [nextValue]="yearlyMaxCosts$ | ngrxPush"
        [hasSkip]="true"
        [spin]="false"
        [invalid]="yearlyMaximumCostsCtrl.errors"
        [minValue]="0"
        (statusChange)="onYearlyCostsChange($event)"
        data-testid="yearlyMaximumCosts"
      >
      </prosumer-yearly-chart-input>

      <div fxLayout="column" fxFlex="20%">
        <mat-form-field
          data-testid="optimization-cockpit-global-max-emissions-input"
          class="eyes-maximize-width"
          appearance="outline"
          [@fadeIn]
        >
          <mat-label>
            {{
              'Scenario.labels.optimizationCockpit.globalMaximumEmissions'
                | translate
            }}
          </mat-label>
          <input
            matInput
            formControlName="globalMaximumEmissions"
            type="number"
            [placeholder]="
              'Scenario.labels.optimizationCockpit.globalMaximumEmissions'
                | translate
            "
            [readonly]="isViewOnly"
            scenarioUpdater
            [hasSkip]="false"
            [key]="updateKey"
            [view]="updateView"
            [nextValue]="globalMaxEmissions$ | ngrxPush"
            [spin]="false"
            [invalid]="globalMaximumEmissionsCtrl.errors"
            (statusChange)="onGlobalEmissionsChange($event)"
            data-testid="globalMaximumEmissions"
          />
          <mat-icon
            class="eyes-input-tooltip-anchor"
            matSuffix
            [prosumerTooltip]="
              'wizard_optimization_cockpit.global_maximum_emissions'
            "
            tooltipPosition="left"
            data-testid="prosumer-limits-global-max-emissions-tooltip"
          >
            help
          </mat-icon>
          <mat-error
            *ngFor="
              let error of form.get('globalMaximumEmissions')?.errors | keyvalue
            "
            [ngSwitch]="error.key"
          >
            <div
              *ngSwitchCase="'required'"
              [innerHtml]="
                'Scenario.messages.globalMaximumEmissions.required' | translate
              "
            ></div>
            <div
              *ngSwitchCase="'min'"
              [innerHtml]="
                'Scenario.messages.globalMaximumEmissions.min' | translate
              "
            ></div>
          </mat-error>
        </mat-form-field>
        <mat-progress-bar
          color="accent"
          mode="indeterminate"
          *ngIf="loading.globalMaximumEmissions"
        >
        </mat-progress-bar>
      </div>

      <div fxLayout="column" fxFlex="20%">
        <mat-form-field
          data-testid="optimization-cockpit-global-max-costs-input"
          class="eyes-maximize-width"
          appearance="outline"
          [@fadeIn]
        >
          <mat-label>
            {{
              'Scenario.labels.optimizationCockpit.globalMaximumCosts'
                | translate
            }}
          </mat-label>

          <input
            matInput
            formControlName="globalMaximumCosts"
            type="number"
            [placeholder]="
              'Scenario.labels.optimizationCockpit.globalMaximumCosts'
                | translate
            "
            [readonly]="isViewOnly"
            scenarioUpdater
            [hasSkip]="false"
            [key]="updateKey"
            [view]="updateView"
            [nextValue]="globalMaxCosts$ | ngrxPush"
            [spin]="false"
            [invalid]="globalMaximumCostsCtrl.errors"
            (statusChange)="onGlobalCostsChange($event)"
            data-testid="globalMaximumCosts"
          />
          <mat-icon
            class="eyes-input-tooltip-anchor"
            matSuffix
            [prosumerTooltip]="
              'wizard_optimization_cockpit.global_maximum_cost'
            "
            tooltipPosition="left"
            data-testid="prosumer-limits-global-max-costs-tooltip"
          >
            help
          </mat-icon>
          <mat-error
            *ngFor="
              let error of form.get('globalMaximumCosts')?.errors | keyvalue
            "
            [ngSwitch]="error.key"
          >
            <div
              *ngSwitchCase="'required'"
              [innerHtml]="
                'Scenario.messages.globalMaximumCosts.required' | translate
              "
            ></div>
            <div
              *ngSwitchCase="'min'"
              [innerHtml]="
                'Scenario.messages.globalMaximumCosts.min' | translate
              "
            ></div>
          </mat-error>
        </mat-form-field>
        <mat-progress-bar
          color="accent"
          mode="indeterminate"
          *ngIf="loading.globalMaximumCosts"
        >
        </mat-progress-bar>
      </div>
    </div>
  </ng-container>
</ng-container>
