import { Injectable } from '@angular/core';

@Injectable()
export class ProsumerRoutePathService {
  dashboard = () => ['/dashboard'];

  projects = () => ['/projects'];

  createProject = () => ['/projects', 'create'];
  createProjectRelative = () => ['create'];

  projectDetail = (projectId: string) => ['/projects', projectId];
  projectDetailRelative = (projectId: string) => [projectId];

  createCase = (projectId: string) => [
    '/projects',
    projectId,
    'cases',
    'create',
  ];
  createCaseRelative = () => ['cases', 'create'];

  caseDetail = (projectId: string, caseId: string) => [
    '/projects',
    projectId,
    'cases',
    caseId,
  ];
  caseDetailRelative = (caseId: string) => ['cases', caseId];

  createScenario = (projectId: string, caseId: string) => [
    '/projects',
    projectId,
    'cases',
    caseId,
    'scenarios',
    'create',
  ];
  createScenarioRelative = () => ['scenarios', 'create'];

  uploadScenario = (projectId: string, caseId: string) => [
    '/projects',
    projectId,
    'cases',
    caseId,
    'scenarios',
    'upload',
  ];
  uploadScenarioRelative = () => ['scenarios', 'upload'];
  cloneScenarioRelative = () => ['scenarios', 'clone'];

  viewScenario = (projectId: string, caseId: string, scenarioId: string) => [
    '/projects',
    projectId,
    'cases',
    caseId,
    'scenarios',
    scenarioId,
    'view',
  ];
  viewScenarioRelative = (scenarioId: string) => [
    'scenarios',
    scenarioId,
    'view',
  ];

  updateScenario = (projectId: string, caseId: string, scenarioId: string) => [
    '/projects',
    projectId,
    'cases',
    caseId,
    'scenarios',
    scenarioId,
    'update',
  ];
  updateScenarioRelative = (scenarioId: string) => [
    'scenarios',
    scenarioId,
    'update',
  ];
  updateScenarioRelative2 = () => ['update'];

  scenarioRunning = (projectId: string, caseId: string, scenarioId: string) => [
    '/projects',
    projectId,
    'cases',
    caseId,
    'scenarios',
    scenarioId,
    'running',
  ];
  scenarioResults = (projectId: string, caseId: string, scenarioId: string) => [
    '/projects',
    projectId,
    'cases',
    caseId,
    'scenarios',
    scenarioId,
    'results',
  ];
  scenarioCompare = (projectId: string, caseId: string) => [
    '/projects',
    projectId,
    'cases',
    caseId,
    'scenarios',
    'compare',
  ];
  compare(projectId: string, caseId: string, scenarioId: string): string[] {
    return [
      '/projects',
      projectId,
      'cases',
      caseId,
      'scenarios',
      scenarioId,
      'compare',
    ];
  }
  about = () => ['/about'];
  userPreferences = () => ['/user-preferences'];
}
