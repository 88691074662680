import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Scenario } from 'prosumer-app/+scenario/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum ScenarioBatchAction {
  delete = 'delete',
}

@Component({
  selector: 'prosumer-scenario-batch',
  templateUrl: './scenario-batch.component.html',
  styleUrls: ['./scenario-batch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioBatchComponent {
  private readonly scenariosSubject = new BehaviorSubject<Scenario[]>([]);
  @Input() set scenarios(scenarios: Scenario[]) {
    this.scenariosSubject.next(scenarios);
  }
  @Output() actionClick = new EventEmitter<ScenarioBatchAction>();

  readonly count$ = this.selectCount();

  onActionClick(action: string): void {
    this.actionClick.emit(action as ScenarioBatchAction);
  }

  private selectCount(): Observable<number> {
    return this.scenariosSubject.pipe(map((scenarios) => scenarios.length));
  }
}
