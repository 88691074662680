import cytoscape from 'cytoscape';
import { DetailsOverlayService } from 'prosumer-shared/modules/details-overlay';
import { Observable, Subject } from 'rxjs';
import { pluck, switchMap } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { FluidConfig, LineData, NodeData, OverviewData } from '../../models';
import { DiagramPositioner, SystemVisualizationService } from '../../services';
import { VizDiagramUtils } from '../../utils/viz-diagram.utils';
import { BaseDiagramComponent } from '../base-diagram.component';

type DataDefinition =
  | cytoscape.NodeDataDefinition
  | cytoscape.EdgeDataDefinition;
@UntilDestroy()
@Component({
  selector: 'prosumer-overview-diagram',
  templateUrl: './overview-diagram.component.html',
  styleUrls: ['./overview-diagram.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewDiagramComponent extends BaseDiagramComponent<OverviewData> {
  readonly dragFreeOn = new Subject();

  @Input() fluidConfig: FluidConfig = {}; // The fluid configuration input

  // Emit the respective data definition when an element is clicked
  @Output() elementTapped = new EventEmitter<
    cytoscape.EdgeDataDefinition | cytoscape.NodeDataDefinition
  >();

  constructor(
    public elementRef: ElementRef,
    public overlayService: DetailsOverlayService,
    public systemVisualizationService: SystemVisualizationService,
    public positioner: DiagramPositioner,
  ) {
    super(elementRef, systemVisualizationService);
    this.subscribeToDragFreeOnForPositionRemembering();
  }

  /**
   * Override - Maps the data to diagram options
   *
   * @param data - the overview model
   */
  mapDataToDiagramOptions(data: OverviewData): cytoscape.CytoscapeOptions {
    if (!data || !data.nodes || !data.lines) {
      return this.getDiagramOptions();
    }
    const savedPositions = this.getPositionsConfig();
    return {
      elements: {
        nodes: this.nodesToDiagramNodes(data.nodes),
        edges: this.linesToDiagramEdges(data.lines),
      },
      style: [
        this.getNodeStyles(),
        this.getEdgeStyles(),
        this.getSelectedEdgeStyle(),
        VizDiagramUtils.getFadedNodeStylesheet(),
        VizDiagramUtils.getFadedEdgeStylesheet(),
        VizDiagramUtils.getHiddenNodeStyle(),
        VizDiagramUtils.getHiddenEdgeStyle(),
      ],
      // add layout property if savedPositions is not empty
      ...(!!Object.keys(savedPositions).length && {
        layout: { name: 'preset', positions: savedPositions },
      }),
    };
  }

  private nodesToDiagramNodes(nodes: NodeData[]): cytoscape.NodeDefinition[] {
    return nodes.map((node) => this.toDiagramNodeWithType(node));
  }

  private linesToDiagramEdges(lines: LineData[]): cytoscape.EdgeDefinition[] {
    return lines.map((line) => this.toDiagramEdgeWithType(line));
  }

  private toDiagramEdgeWithType(line: LineData): cytoscape.EdgeDefinition {
    const mapped = VizDiagramUtils.connectorToDiagramEdge(
      line,
      this.determineEdgeColor(line),
    );
    return {
      ...mapped,
      data: this.insertTypeToData(
        mapped,
        'edge',
      ) as cytoscape.EdgeDataDefinition,
    };
  }

  private toDiagramNodeWithType(node: NodeData): cytoscape.NodeDefinition {
    const mapped = VizDiagramUtils.nodeToDiagramNode(node);
    return { ...mapped, data: this.insertTypeToData(mapped, 'node') };
  }

  private insertTypeToData(
    data: cytoscape.ElementDefinition,
    type: string,
  ): cytoscape.NodeDataDefinition | cytoscape.EdgeDataDefinition {
    return { ...data.data, type };
  }

  private determineEdgeColor(line: LineData): string {
    return this.fluidConfig[line.energyVector].color;
  }

  private getNodeStyles(): cytoscape.Stylesheet {
    return {
      selector: 'node',
      css: {
        label: 'data(name)',
        color: '#43425D',
        height: 100,
        width: 100,
        'background-color': '#eee',
        'text-halign': 'center',
        'text-valign': 'center',
        'text-wrap': 'wrap',
        'font-weight': 'normal',
        'border-style': 'solid',
        'border-width': 10,
        'border-color': '#43425D',
        'active-bg-opacity': 0.4,
        'z-index': 10,
      },
    };
  }

  private getEdgeStyles(): cytoscape.Stylesheet {
    return {
      selector: 'edge',
      css: {
        width: 3,
        'overlay-opacity': 0.2,
        'overlay-padding': 5,
        'line-cap': 'round',
        'line-style': 'dashed',
        'line-fill': 'solid',
        'curve-style': 'bezier',
        'line-dash-pattern': [10, 8],
        'line-dash-offset': 24,
        'target-arrow-shape': 'triangle-backcurve',
        'arrow-scale': 2,
        'line-color': 'data(color)',
        'overlay-color': 'data(color)',
        'target-arrow-color': 'data(color)',
        'z-index': 10,
      },
    };
  }

  private getSelectedEdgeStyle(): cytoscape.Stylesheet {
    return {
      selector: 'edge:selected',
      css: {
        label: 'data(name)',
        color: 'data(color)',
        'overlay-opacity': 0.4,
        'z-index': 20,
        'text-rotation': 'autorotate',
        'text-margin-x': 0,
        'text-margin-y': -20,
        'text-outline-color': '#fff',
        'text-outline-width': 1,
        'font-size': 12,
      },
    };
  }

  /**
   * Override - Add events
   */
  addEventListeners() {
    this.getDiagram().on('tap', 'edge', this.openDetailsPanel);
    this.getDiagram().on('tap', this.focusToEdgeElement);
    this.addPointerHandlers();
    this.addOnDragFreeOnHandler();
  }

  /**
   * Opens the panel details of the tapped edge (line)
   *
   * @param event - the tap event for an edge element
   */
  openDetailsPanel = (event) => {
    if (!event) {
      return;
    }

    const line: cytoscape.CollectionReturnValue = event.target;
    if (!line) {
      return;
    }

    if (!!line.data() && !line.data().hidden) {
      this.detailsPanel = this.overlayService.open({
        data: {
          name: line.data('name'),
          rawData: line.data('rawData'),
          metaData: [
            { label: 'Fluid', key: 'fluid' },
            { label: 'Origin node', key: 'origin_node' },
            { label: 'Destination node', key: 'destination_node' },
            { label: 'Size [kW]', key: 'size [kw]', format: '1.1-1' },
            {
              label: 'Initial Capex [kEUR]',
              key: 'initial capex [keur]',
              format: '1.1-1',
            },
            {
              label: 'Initial Opex [kEUR]',
              key: 'initial opex [keur]',
              format: '1.1-1',
            },
            {
              label: 'Total Cost [kEUR]',
              key: 'total cost [keur]',
              format: '1.1-1',
            },
            {
              label: 'Yearly Flow Orig to Dest [kWh]',
              key: 'yearly flow orig to dest [kwh]',
              format: '1.0-0',
            },
            {
              label: 'Yearly Flow Dest to Orig [kWh]',
              key: 'yearly flow dest to orig [kwh]',
              format: '1.0-0',
            },
          ],
        },
      });
      this.detailsPanel.beforeClose().subscribe(() => {
        this.getDiagram().elements().removeClass('faded');
      });
    }
  };

  /**
   * Focuses on the tapped edge element that applies faded style to other elements
   *
   * @param event - the tap event for all elements
   */
  focusToEdgeElement = (event) => {
    if (!event) {
      return;
    }

    const element: cytoscape.CollectionReturnValue = event.target;
    if (!element) {
      return;
    }

    this.elementTapped.emit(element.data()); // Emit the element data definition
    if (
      !this.isBackground(element) &&
      element.isEdge() &&
      !element.data().hidden
    ) {
      const edgeId = element.id();
      const sourceId = element.data('source');
      const targetId = element.data('target');
      this.getDiagram().elements().addClass('faded');
      this.getDiagram()
        .elements(
          `node[id = '${sourceId}'], [id = '${targetId}'], [id = '${edgeId}']`,
        )
        .removeClass('faded');
    } else {
      this.getDiagram().elements().removeClass('faded');
      this.closeDetailsPanel();
    }
  };

  private selectScenarioIDFromSysVizData(): Observable<string> {
    return this.systemVisualizationService.vizData$.pipe(pluck('scenarioId'));
  }

  private addOnDragFreeOnHandler(): void {
    this.getDiagram().on('dragfreeon', () => this.dragFreeOn.next());
  }

  private subscribeToDragFreeOnForPositionRemembering(): void {
    this.dragFreeOn
      .pipe(
        switchMap(() => this.selectScenarioIDFromSysVizData()),
        untilDestroyed(this),
      )
      .subscribe((scenario) => {
        this.positioner.storeOverview(
          scenario,
          this.systemVisualizationService.getVariationID(),
          this.getDiagramJSON(),
        );
      });
  }

  getSavedViewConfig(): cytoscape.CytoscapeOptions {
    return (
      this.positioner.getOverview(
        this.systemVisualizationService.getScenarioID(),
        this.systemVisualizationService.getVariationID(),
      ) || {}
    );
  }
}
