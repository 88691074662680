<div class="mat-elevation-z10 overlay">
  <div class="title">
    <h2 class="marginless-header">{{ title | titlecase }}</h2>
    <button mat-flat-button color="primary" (click)="close()">Close</button>
  </div>
  <div *ngFor="let property of data.metaData">
    <div
      *ngIf="
        data.rawData.hasOwnProperty(property.key) &&
        !!data.rawData[property.key]
      "
      class="overlay-body"
    >
      <div class="key">
        <h3>{{ property.label }} :</h3>
      </div>
      <div class="value" *ngIf="property?.format; else default">
        <h3>
          <b>{{ data.rawData[property.key] | number : property.format }}</b>
        </h3>
      </div>
      <ng-template #default>
        <div class="value">
          <h3>
            <b>{{ data.rawData[property.key] }}</b>
          </h3>
        </div>
      </ng-template>
    </div>
  </div>
</div>
