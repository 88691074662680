<div
  fxLayout="row wrap"
  fxLayoutGap="10px"
  fxAlign="start"
  class="container-select-inputs"
>
  <prosumer-select-new
    fxFlex="24%"
    class="select-input"
    *ngFor="let filter of filterDataOptionsMapped$ | ngrxPush"
    [options]="filter.values"
    [control]="secondaryFiltersForm.controls[filter.key]"
    [disabled]="isLoading"
    [label]="filter.key | camelToText | titlecase"
    [tooltip]="'tdb_filters.' + filter.key"
    [attr.data-testid]="'tdb-secondary-filters-select-' + filter.key"
  >
  </prosumer-select-new>
</div>
<button
  mat-flat-button
  color="primary"
  [disabled]="isLoading"
  (click)="handleSubmitBtnClick()"
  data-testid="tdb-secondary-filters-submit-btn"
>
  {{ 'Scenario.labels.tdb.submitBtn' | translate }}
</button>
<!-- 
  +++[options]="subsubcategoryOptions$ | ngrxPush"
(selectionChange)="handleControlChange([])"
+++++[control]="primaryFiltersForm.controls.subsubcategory"
-----[placeholder]="'Scenario.placeholders.tdb.subsubcategory' | translate"
+++++[label]="'Scenario.labels.tdb.subsubcategory' | translate"
+++++[disabled]="loading || checkForUnselectedControls(['subcategory'])" -->
