<form
  [formGroup]="uploadForm"
  class="eyes-width-limiter"
  fxLayout="column"
  fxLayoutGap="5px"
  #ngForm
  (ngSubmit)="onSubmit()"
>
  <mat-form-field class="eyes-maximize-width" appearance="outline" [@fadeIn]>
    <mat-label>{{ 'Scenario.upload.inputName' | translate }}</mat-label>
    <input
      data-testid="scenario-upload-name-input"
      matInput
      [placeholder]="'Scenario.upload.inputName' | translate"
      formControlName="name"
      required
    />
    <mat-error
      *ngFor="let error of getErrors(uploadForm?.controls?.name?.errors)"
      [innerHtml]="inputNameErrorMessages()[error]"
    >
    </mat-error>
  </mat-form-field>
  <mat-form-field class="eyes-maximize-width" appearance="outline" [@fadeIn]>
    <mat-label>{{ 'Scenario.upload.inputDescription' | translate }}</mat-label>
    <input
      data-testid="scenario-upload-description-input"
      matInput
      [placeholder]="'Scenario.upload.inputDescription' | translate"
      formControlName="description"
    />
  </mat-form-field>
  <prosumer-file-upload-drag-drop
    required
    ngDefaultControl
    [formControl]="uploadForm?.controls?.fileInput"
  >
  </prosumer-file-upload-drag-drop>
  <p class="mat-caption">
    {{ 'Scenario.information.missingTemplatePart1' | translate }}
    <a
      data-testid="scenario-download-link"
      class="eyes-href-bold custom-download-template-link"
      (click)="getXlsxlTemplate()"
    >
      {{ 'Scenario.information.missingTemplateLink' | translate }}
      <mat-icon
        class="material-icons-outlined"
        tooltipPosition="center"
        aria-label="Download Excel Template"
        title="Download Excel Template"
      >
        save_alt
      </mat-icon>
    </a>
    {{ 'Scenario.information.missingTemplatePart2' | translate }}
  </p>
  <p
    *ngIf="displayWarning"
    class="mat-caption prosumer-warning"
    data-testid="scenario-upload-warning-message"
  >
    <mat-icon ngClass="warning" matListIcon>warning</mat-icon>
    <b>{{ 'Scenario.information.warningCloneFrom' | translate }}</b>
    {{ 'Scenario.information.warningCloneFromMessage' | translate }}
  </p>
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start left">
    <button
      data-testid="scenario-upload-simulate-button"
      class="eyes-button"
      color="primary"
      mat-flat-button
      [disabled]="uploadForm?.pristine || isLoading"
    >
      {{ submitBtnName }}
    </button>
    <button
      data-testid="scenario-upload-cancel-button"
      class="eyes-button"
      color=""
      mat-flat-button
      type="button"
      [disabled]="isLoading"
      (click)="onCancel()"
    >
      Cancel
    </button>
  </div>
</form>
