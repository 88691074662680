import { RenewableProfile } from '../models';
import { RenewableProfileState } from './renewable-profile-state.model';
import * as RenewableProfileActions from './renewable-profile.actions';
import { renewableProfileStateFactory } from './renewable-profile.factory';

function removeDuplicateUserEntry(renewableProfiles: RenewableProfile[]) {
  return renewableProfiles
    .slice()
    .reverse()
    .filter((v, i, a) => a.findIndex((t) => t.uniqueId === v.uniqueId) === i)
    .reverse();
}

export function renewableProfileReducer(
  state: RenewableProfileState = renewableProfileStateFactory.initialState,
  action: RenewableProfileActions.All,
): RenewableProfileState {
  switch (action.type) {
    case RenewableProfileActions.ActionTypes
      .GET_USER_RENEWABLE_PROFILE_LIST_FAILURE:
      return {
        ...state,
        userProfiles: [],
        error: action.payload.message,
      };

    case RenewableProfileActions.ActionTypes
      .GET_USER_RENEWABLE_PROFILE_LIST_SUCCESS:
      return {
        ...state,
        renewableProfiles: removeDuplicateUserEntry(action.payload.data),
      };

    case RenewableProfileActions.ActionTypes.GET_RENEWABLE_PROFILE_FAILURE:
      return {
        ...state,
        error: action.payload.message,
      };

    case RenewableProfileActions.ActionTypes.GET_RENEWABLE_PROFILE_SUCCESS:
      return {
        ...state,
        renewableProfiles: [...state.renewableProfiles, action.payload.data],
      };

    // Submit
    case RenewableProfileActions.ActionTypes.CREATE_RENEWABLE_PROFILE:
      return {
        ...state,
        submitting: true,
      };

    case RenewableProfileActions.ActionTypes.CREATE_RENEWABLE_PROFILE_SUCCESS:
      return {
        ...state,
        renewableProfiles: [action.payload.data, ...state.renewableProfiles],
        submitting: false,
      };

    case RenewableProfileActions.ActionTypes.CREATE_RENEWABLE_PROFILE_FAILURE:
      return {
        ...state,
        submitting: false,
      };

    // Download
    case RenewableProfileActions.ActionTypes.DOWNLOAD_RENEWABLE_PROFILE:
      return {
        ...state,
        downloading: true,
      };

    case RenewableProfileActions.ActionTypes.DOWNLOAD_RENEWABLE_PROFILE_SUCCESS:
      return {
        ...state,
        downloading: false,
      };

    case RenewableProfileActions.ActionTypes.DOWNLOAD_RENEWABLE_PROFILE_FAILURE:
      return {
        ...state,
        downloading: false,
      };

    case RenewableProfileActions.ActionTypes.UPDATE_RENEWABLE_PROFILE:
      return {
        ...state,
        submitting: true,
      };

    case RenewableProfileActions.ActionTypes.UPDATE_RENEWABLE_PROFILE_FAILURE:
      return {
        ...state,
        submitting: false,
      };

    case RenewableProfileActions.ActionTypes.UPDATE_RENEWABLE_PROFILE_SUCCESS:
      const filteredProfiles = state.renewableProfiles.filter(
        (profile) => profile.uniqueId !== action.payload.data.uniqueId,
      ) as RenewableProfile[];
      filteredProfiles.push(action.payload.data);
      return {
        ...state,
        renewableProfiles: filteredProfiles,
        submitting: false,
      };
    /* Default */
    default:
      return state;
  }
}
