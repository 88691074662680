import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ReactiveComponentModule } from '@ngrx/component';

import { ChartExporterModule } from 'prosumer-shared/components';
import { ExportChartDirectiveModule } from 'prosumer-shared/directives/export-chart';
import { CustomLegendModule } from '../custom-legend/custom-legend.module';

import { LineChartjsComponent } from './line-chartjs.component';

@NgModule({
  declarations: [LineChartjsComponent],
  imports: [
    CommonModule,
    ExportChartDirectiveModule,
    MatMenuModule,
    MatIconModule,
    FlexLayoutModule,
    ChartExporterModule,
    CustomLegendModule,
    ReactiveComponentModule,
  ],
  exports: [LineChartjsComponent],
})
export class LineChartjsModule {}
