import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XlsxDataframeExtractorComponent } from './xlsx-dataframe-extractor.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TooltipModule } from 'prosumer-app/libs/eyes-shared';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [XlsxDataframeExtractorComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    TooltipModule,
    MatProgressSpinnerModule,
  ],
  exports: [XlsxDataframeExtractorComponent],
})
export class XlsxDataframeExtractorModule {}
