import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveComponentModule } from '@ngrx/component';

import { ScenarioDetailsDuplicateDialogModule } from '../../scenario-details-duplicate-dialog';
import { LinesDuplicateDialogComponent } from './lines-duplicate-dialog.component';

@NgModule({
  declarations: [LinesDuplicateDialogComponent],
  imports: [
    CommonModule,
    ScenarioDetailsDuplicateDialogModule,
    ReactiveComponentModule,
  ],
  exports: [LinesDuplicateDialogComponent],
})
export class LinesDuplicateDialogModule {}
