<mat-card fxLayout="column" fxLayoutGap="10px">
  <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center">
    <div
      class="chips-filter-container"
      fxFlex="calc(50% - 20px)"
      fxLayout="row wrap"
      fxLayoutGap="5px"
      fxLayoutAlign="space-between"
    >
      <prosumer-filter-chips
        fxFlex
        filterTitle="Origin Node"
        filterBy="value"
        class="eyes-spacer-half"
        [dataSource]="topologyOriginNodeOptions"
        (filters)="onFilterOriginNode($event)"
      >
      </prosumer-filter-chips>

      <prosumer-filter-chips
        fxFlex
        filterTitle="Destination Node"
        filterBy="value"
        class="eyes-spacer-half"
        [dataSource]="topologyDestinationNodeOptions"
        (filters)="onFilterDestinationNode($event)"
      >
      </prosumer-filter-chips>

      <prosumer-filter-chips
        fxFlex
        filterTitle="Energy Vector"
        filterBy="value"
        class="eyes-spacer-half"
        [dataSource]="energyVectorOptions"
        (filters)="onFilterEnergyVector($event)"
      >
      </prosumer-filter-chips>
    </div>

    <div fxFlex></div>

    <div
      class="value-toggle-container"
      fxFlex="calc(50% - 20px)"
      fxLayoutAlign="end"
    >
      <mat-button-toggle-group
        name="valueToggle"
        aria-label="Font Style"
        fxLayout="row wrap"
        fxLayoutAlign="end center"
        [formControl]="valueToggleControl"
      >
        <mat-button-toggle
          *ngFor="let option of valueOptions"
          [value]="option?.key"
        >
          {{ option?.value }}
          <prosumer-tooltip-anchor
            tooltipPosition="left"
            [message]="option?.context"
          ></prosumer-tooltip-anchor>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div class="chart-container" *ngIf="showChart$ | async" fxFlex [@fadeIn]>
    <ngx-charts-bar-horizontal-2d
      legendPosition="below"
      yAxisLabel="Line"
      [scheme]="chartScheme"
      [view]="[chartWidth$ | async, chartHeight$ | async]"
      [results]="(filteredData$ | async) || []"
      [showXAxisLabel]="true"
      [xAxis]="true"
      [xAxisLabel]="equipmentValueOptions[valueToggleControl?.value]?.label"
      [showYAxisLabel]="true"
      [yAxis]="true"
      [legend]="true"
      [tooltipDisabled]="false"
      [customColors]="customColors"
    >
    </ngx-charts-bar-horizontal-2d>
  </div>
</mat-card>
