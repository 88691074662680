<div class="checkbox-container" fxLayout="row" fxLayoutAlign="start center">
  <mat-checkbox [formControl]="control"></mat-checkbox>
  <div class="label">
    <mat-label>{{ label | translate }}</mat-label>
  </div>
  <mat-icon
    class="eyes-input-tooltip-anchor"
    matSuffix
    [prosumerTooltip]="tooltip | translate"
    data-testid="prosumer-slide-toggle-tooltip"
  >
    help
  </mat-icon>
</div>
<label
  class="mat-error"
  *ngFor="let error of getErrors(control?.errors)"
  [innerHtml]="_errorMessageMap[error]"
>
</label>
