import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveComponentModule } from '@ngrx/component';
import { EquipmentDuplicateDialogComponent } from './equipment-duplicate-dialog.component';
import { ScenarioDetailsDuplicateDialogModule } from '../../scenario-details-duplicate-dialog';

@NgModule({
  declarations: [EquipmentDuplicateDialogComponent],
  imports: [
    CommonModule,
    ScenarioDetailsDuplicateDialogModule,
    ReactiveComponentModule,
  ],
  exports: [EquipmentDuplicateDialogComponent],
})
export class EquipmentDuplicateDialogModule {}
