import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Action,
  BatchScenariosResponse,
  BatchService,
} from 'prosumer-app/stores/batch';
import { Scenario } from 'prosumer-scenario/models';
import { BehaviorSubject } from 'rxjs';

export interface DeleteScenariosData {
  readonly scenarios: Scenario[];
}

@Component({
  selector: 'prosumer-delete-scenarios',
  templateUrl: './delete-scenarios.component.html',
  styleUrls: ['./delete-scenarios.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteScenariosComponent {
  private readonly deleting = new BehaviorSubject<boolean>(false);
  readonly scenarios = this.data.scenarios;
  readonly deleting$ = this.deleting.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: DeleteScenariosData,
    private readonly dialogRef: MatDialogRef<unknown>,
    private readonly batch: BatchService,
  ) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onDelete(): void {
    this.deleting.next(true);
    this.batch.batchScenarios(Action.delete, this.data.scenarios).subscribe(
      (response) => this.onDeleteComplete(response),
      () => this.onDeleteFailed(),
    );
  }

  private onDeleteComplete(response: BatchScenariosResponse): void {
    this.deleting.next(false);
    this.dialogRef.close(response);
  }

  private onDeleteFailed(): void {
    this.deleting.next(false);
    this.dialogRef.close();
  }
}
