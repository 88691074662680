import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NodesFormDialogData } from 'prosumer-app/+scenario/models';
import {
  BaseComponent,
  CustomValidators,
  FormFieldErrorMessageMap,
} from 'prosumer-app/libs/eyes-shared';
import { GeneralValidators, NameValidator } from 'prosumer-app/shared';
import { provideUpserter } from 'prosumer-app/stores';
import { NodeInfo, NodeStore } from 'prosumer-app/stores/node';
import { take } from 'rxjs/operators';

@Component({
  selector: 'prosumer-topology-nodes-dialog',
  templateUrl: './topology-nodes-dialog.component.html',
  styleUrls: ['./topology-nodes-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TitleCasePipe, provideUpserter(NodeStore)],
})
export class TopologyNodesDialogComponent
  extends BaseComponent
  implements OnInit
{
  nodeForm: FormGroup;

  get nameCtrl() {
    return this.nodeForm.controls.name;
  }
  get surfaceCtrl() {
    return this.nodeForm.controls.surface;
  }
  get isFormValid() {
    return this.nodeForm.valid;
  }
  get isFormPristine() {
    return this.nodeForm.pristine;
  }

  errormessages: FormFieldErrorMessageMap = {
    name: {
      required: this._translate.instant('Scenario.messages.node.required'),
      invalidString: this._translate.instant('Scenario.messages.node.invalid'),
      invalidCharacter: this._translate.instant(
        'Scenario.messages.node.invalidCharacter',
      ),
      [GeneralValidators.uniqueValueKey]: this._translate.instant(
        'Scenario.messages.node.alreadyExist',
      ),
    },
    surface: {
      [GeneralValidators.nonNegativeKey]: this._translate.instant(
        'Scenario.messages.surface.negativeValue',
      ),
    },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: NodesFormDialogData,
    private readonly _dialogRef: MatDialogRef<TopologyNodesDialogComponent>,
    private readonly _formBuilder: FormBuilder,
    private readonly _translate: TranslateService,
  ) {
    super();
    this.nodeForm = this.initForm();
  }

  ngOnInit(): void {
    this.initData();
    this.initValidators();
    this.subscribeToFormFirstChangeToShowErrorFirstTime();
  }

  private subscribeToFormFirstChangeToShowErrorFirstTime() {
    this.nodeForm.valueChanges.pipe(take(1)).subscribe((_) => {
      this.nameCtrl.markAsTouched();
      this.surfaceCtrl.markAsTouched();
    });
  }

  private initForm() {
    return this._formBuilder.group({
      name: [''],
      surface: [''],
    });
  }

  private initValidators() {
    this.nameCtrl.setValidators([
      Validators.required,
      NameValidator.validWithCore(),
      CustomValidators.invalidStringValidator(['all', 'all nodes', 'n:']),
      GeneralValidators.uniqueValue(this.getExistingNodes(), 'name'),
    ]);
    this.surfaceCtrl.setValidators([GeneralValidators.nonNegativeValue()]);
  }

  getExistingNodes(): NodeInfo[] {
    return this.excludeNodeBeingEditted(this.data.existingNodes);
  }

  private excludeNodeBeingEditted(existingNodes: NodeInfo[]) {
    if (this.data.mode === 'edit' && existingNodes.length) {
      return existingNodes.filter(
        (node) => node.nodeId !== this.data.nodeData?.nodeId,
      );
    }
    return existingNodes;
  }

  onClose(): void {
    this._dialogRef.close();
  }

  initData() {
    this.nameCtrl.patchValue(this.data.nodeData?.name || '', {
      emitEvent: false,
    });
    this.surfaceCtrl.patchValue(this.data.nodeData?.surface || '', {
      emitEvent: false,
    });
  }
}
