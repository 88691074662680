import {
  PREDEFINED_ENERGY_VECTOR_MAP_LABEL,
  PREDEFINED_ENERGY_VECTORS,
} from 'prosumer-app/app.references';
import { mapCaseDetailsScenarioListToFrontEnd } from 'prosumer-app/stores';

import { ScenarioState } from './scenario-state.model';
import * as ScenarioActions from './scenario.actions';
import { scenarioStateFactory } from './scenario.factory';

const initialState: ScenarioState = {
  ...scenarioStateFactory.initialState,
  predefinedEnergyVectors: PREDEFINED_ENERGY_VECTORS.filter(
    (filterItem) => filterItem.value !== 'others',
  ).map((energyVector) => ({
    icon: energyVector.value,
    label: PREDEFINED_ENERGY_VECTOR_MAP_LABEL[energyVector.value],
    value: energyVector.id,
  })),
};

export function scenarioReducer(
  state: ScenarioState = initialState,
  action: ScenarioActions.All,
): ScenarioState {
  switch (action.type) {
    // Copy
    case ScenarioActions.ActionTypes.COPY_SCENARIO:
      return scenarioStateFactory.adapter.upsertOne(
        { ...action.payload.data, copying: true, error: undefined },
        state,
      );
    case ScenarioActions.ActionTypes.COPY_SCENARIO_SUCCESS:
      return scenarioStateFactory.adapter.upsertOne(
        { ...action.payload.data, copying: false },
        state,
      );
    case ScenarioActions.ActionTypes.COPY_SCENARIO_FAILURE:
      return scenarioStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          copying: false,
          error: action.payload.message,
        },
        state,
      );
    // Remove
    case ScenarioActions.ActionTypes.REMOVE_SCENARIO:
      return scenarioStateFactory.adapter.upsertOne(action.payload.id, state);
    // Download
    case ScenarioActions.ActionTypes.DOWNLOAD:
      return scenarioStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          downloading: action.payload.downloadEntity,
          error: undefined,
        },
        state,
      );
    case ScenarioActions.ActionTypes.DOWNLOAD_SUCCESS:
      return scenarioStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          downloading: undefined,
        },
        state,
      );
    case ScenarioActions.ActionTypes.DOWNLOAD_FAILURE:
      return scenarioStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          downloading: undefined,
          error: action.payload.message,
        },
        state,
      );
    // Download Draft Input
    case ScenarioActions.ActionTypes.DOWNLOAD_DRAFT_INPUT:
      return scenarioStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          downloading: action.payload.downloadEntity,
        },
        state,
      );
    case ScenarioActions.ActionTypes.DOWNLOAD_DRAFT_INPUT_SUCCESS:
      return scenarioStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          downloading: undefined,
        },
        state,
      );
    case ScenarioActions.ActionTypes.DOWNLOAD_DRAFT_INPUT_FAILURE:
      return scenarioStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          error: action.payload.message,
          downloading: undefined,
        },
        state,
      );
    // Get Draft Input File
    case ScenarioActions.ActionTypes.GET_DRAFT_INPUT:
      return scenarioStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          downloading: action.payload.downloadEntity,
        },
        state,
      );
    case ScenarioActions.ActionTypes.GET_DRAFT_INPUT_SUCCESS:
      return scenarioStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          downloading: undefined,
        },
        state,
      );
    case ScenarioActions.ActionTypes.GET_DRAFT_INPUT_FAILURE:
      return scenarioStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          error: action.payload.message,
          downloading: undefined,
        },
        state,
      );
    // Upload
    case ScenarioActions.ActionTypes.UPLOAD:
      return { ...state, uploading: true, error: undefined };
    case ScenarioActions.ActionTypes.UPLOAD_SUCCESS:
      return scenarioStateFactory.adapter.upsertOne(
        { ...action.payload.data },
        { ...state, uploading: false },
      );
    case ScenarioActions.ActionTypes.UPLOAD_FAILURE:
      return { ...state, uploading: false, error: action.payload.message };
    // Upload Clone
    case ScenarioActions.ActionTypes.UPLOAD_CLONE:
      return { ...state, uploading: true, error: undefined };
    case ScenarioActions.ActionTypes.UPLOAD_CLONE_SUCCESS:
      return scenarioStateFactory.adapter.upsertOne(
        { ...action.payload.data },
        { ...state, uploading: false },
      );
    case ScenarioActions.ActionTypes.UPLOAD_CLONE_FAILURE:
      return { ...state, uploading: false, error: action.payload.message };
    // Launch
    case ScenarioActions.ActionTypes.LAUNCH:
      return { ...state, simulating: true, launched: true, error: undefined };
    case ScenarioActions.ActionTypes.LAUNCH_SUCCESS:
      return scenarioStateFactory.adapter.upsertOne(
        { ...action.payload.data },
        { ...state, simulating: false },
      );
    case ScenarioActions.ActionTypes.LAUNCH_FAILURE:
      return { ...state, simulating: false, error: action.payload.message };
    // Validate Coherence
    case ScenarioActions.ActionTypes.VALIDATE_RESET:
    case ScenarioActions.ActionTypes.VALIDATE:
      // return scenarioStateFactory.adapter.upsertOne({ ...action.payload.data },
      //   { ...state, validating: true, coherenceResults: undefined });
      return { ...state, validating: true, coherenceResults: undefined };
    case ScenarioActions.ActionTypes.VALIDATE_SUCCESS:
      // return scenarioStateFactory.adapter.upsertOne({ ...action.payload.data },
      return {
        ...state,
        validating: false,
        coherenceResults: action.payload.data,
      };
    case ScenarioActions.ActionTypes.VALIDATE_FAILURE:
      // return scenarioStateFactory.adapter.upsertOne({ ...action.payload.data },
      return { ...state, validating: false, error: action.payload.error };
    // Get scenario list
    case ScenarioActions.ActionTypes.GET_CASE_DETAIL_SCENARIO_LIST:
      return scenarioStateFactory.adapter.upsertOne(
        {
          ...action.payload,
          loading: state.ids.length === 0,
          launched: true,
          error: undefined,
        },
        { ...state, loading: true, entities: {}, ids: [] },
      );
    case ScenarioActions.ActionTypes.GET_CASE_DETAIL_SCENARIO_LIST_SUCCESS:
      const stateIds: any[] = state.ids;
      return scenarioStateFactory.adapter.upsertMany(
        action.payload.results
          .filter((item) => !stateIds.includes(item.scenarioUuid))
          .map((item) => ({
            ...mapCaseDetailsScenarioListToFrontEnd(item),
            loading: false,
            loaded: true,
            error: undefined,
          })),
        Object.assign(
          {},
          scenarioStateFactory.adapter.removeOne(action.payload.id, state),
          {
            loading: false,
            loaded: true,
          },
        ),
      );
    case ScenarioActions.ActionTypes.GET_CASE_DETAIL_SCENARIO_LIST_FAILURE:
      return scenarioStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          loading: false,
          error: action.payload.message,
        },
        state,
      );
    // Get latest simulation
    case ScenarioActions.ActionTypes.GET_LATEST_SIMULATIONS:
      return scenarioStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          loading: true,
          launched: true,
          error: undefined,
        },
        state,
      );
    case ScenarioActions.ActionTypes.GET_LATEST_SIMULATIONS_SUCCESS:
      return scenarioStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          loading: false,
        },
        state,
      );
    case ScenarioActions.ActionTypes.GET_LATEST_SIMULATIONS_FAILURE:
      return scenarioStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          loading: false,
          error: action.payload.message,
        },
        state,
      );
    // Update with Binary Data handling
    case ScenarioActions.ActionTypes.UPDATE_WITH_BINARY_HANDLING:
      return scenarioStateFactory.adapter.updateOne(
        {
          id: action.payload.data.id,
          changes: {
            updating: true,
            error: undefined,
          },
        },
        state,
      );
    case ScenarioActions.ActionTypes.UPDATE_WITH_BINARY_HANDLING_FAILURE:
      return scenarioStateFactory.adapter.updateOne(
        {
          id: action.payload.data.id,
          changes: {
            updating: false,
            error: action.payload.message,
          },
        },
        state,
      );
    case ScenarioActions.ActionTypes.UPDATE_WITH_BINARY_HANDLING_SUCCESS:
      return scenarioStateFactory.adapter.updateOne(
        {
          id: action.payload.data.id,
          changes: {
            ...action.payload.data,
            updating: false,
          },
        },
        state,
      );
    // Get Scenario Variations
    /*     // fetch single variation - for future implementation
    case ScenarioActions.ActionTypes.GET_SCENARIO_VARIATION:
      return scenarioStateFactory.adapter.upsertOne(
        {
          ...action.payload,
          loading: true,
          error: undefined,
        },
        state,
      ); */
    case ScenarioActions.ActionTypes.GET_SCENARIO_VARIATIONS:
      return scenarioStateFactory.adapter.upsertOne(
        {
          ...action.payload,
          loading: true,
          error: undefined,
        },
        state,
      );
    case ScenarioActions.ActionTypes.GET_SCENARIO_VARIATIONS_SUCCESS:
      return scenarioStateFactory.adapter.upsertOne(
        {
          loading: false,
          id: action.payload.id,
          scenarioVariations: action.payload.datalist,
          error: undefined,
        },
        state,
      );
    case ScenarioActions.ActionTypes.GET_SCENARIO_VARIATIONS_FAILURE:
      return scenarioStateFactory.adapter.upsertOne(
        {
          loading: false,
          id: action.payload.id,
          scenarioVariations: undefined,
          //error: action.payload.message,
        },
        state,
      );
    case ScenarioActions.ActionTypes.SELECT_SCENARIO_VARIATION_ID:
      return Object.assign({}, state, {
        selectedScenarioVariationId: action.payload.id,
      });
    case ScenarioActions.ActionTypes.UPDATE_SCENARIO_FROM_CASE_VIEW:
      return scenarioStateFactory.adapter.updateOne(
        {
          id: action.payload.data.id,
          changes: {
            updating: true,
            error: undefined,
          },
        },
        state,
      );
    case ScenarioActions.ActionTypes.UPDATE_SCENARIO_FROM_CASE_VIEW_SUCCESS:
      return scenarioStateFactory.adapter.updateOne(
        {
          id: action.payload.data.id,
          changes: {
            ...action.payload.data,
            updating: false,
          },
        },
        state,
      );
    case ScenarioActions.ActionTypes.UPDATE_SCENARIO_FROM_CASE_VIEW_FAILURE:
      return scenarioStateFactory.adapter.updateOne(
        {
          id: action.payload.data.id,
          changes: {
            updating: false,
            error: action.payload.message,
          },
        },
        state,
      );
    // Default
    default:
      return scenarioStateFactory.createReducer(state, action);
  }
}
