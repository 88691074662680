<div *ngrxLet="count$ as count" class="batch-frame">
  <div
    *ngIf="count > 0"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
    class="batch-div"
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <span data-testid="count" class="batch-count">
        {{ count }}
      </span>
      <span class="batch-selected">scenario(s) selected</span>
    </div>
    <button
      data-testid="delete"
      mat-button
      color="warn"
      (click)="onActionClick('delete')"
    >
      <mat-icon color="warn">delete_sweep</mat-icon>
      DELETE SELECTED
    </button>
  </div>
</div>
