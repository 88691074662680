import {
  AuthActionTypes,
  ConfigService,
  DialogService,
  HttpService,
  LoggerService,
  NotificationStoreService,
  PageStore,
  RouterStore,
  UpdateNotificationService,
  UserFacade,
  UserFacadeService,
  WebSocketService,
} from 'prosumer-app/libs/eyes-core';
import {
  BaseComponent,
  BaseDialogComponent,
  Breadcrumb,
  Dimensions,
  Feature,
  I18NService,
  TooltipContextHolderService,
  generateEndpoint,
  generateUuid,
  getValues,
  routerAnimation,
} from 'prosumer-app/libs/eyes-shared';
import { ProsumerRoutePathService } from 'prosumer-core';
import { environment } from 'prosumer-env';
import { PolicyDialogComponent, PolicyDialogData } from 'prosumer-shared';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  of,
  throwError,
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  switchMapTo,
  take,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@oculus/auth';
import { DrawerNavLink } from '@oculus/components/drawer';

import { CookieService } from 'ngx-cookie-service';
import { CaseFacadeService } from './+case';
import { ProjectFacadeService } from './+project';
import { TurbineListHolderService } from './+renewableprofile/services/turbine-list-holder.service';
import { RenewableProfileFacadeService } from './+renewableprofile/state';
import {
  ScenarioCompareFacadeService,
  ScenarioFacadeService,
} from './+scenario';
import { DetailGettingDelegator } from './+scenario/directives/detail-entities-getter/detail-entities-getter.service';
import { FEATURES } from './app-routing.module';
import { AppComponentExts } from './app.component.ext';
import {
  DASHBOARD_ICONS,
  PREDEFINED_ENERGY_VECTORS,
  REDIRECT_URL_KEY,
  SIMULATION_ICONS,
} from './app.references';
import { PipeUtils } from './core/utils';
import { UserSurveyFormComponent } from './shared/modules/user-survey-form';
import {
  CoreVersionService,
  LicensePlanService,
  SupportLinkService,
} from './shared/services';
import { AnalyticsService } from './shared/services/amplitude-analytics/analytics.model';
import { ANALYTICS_SERVICE } from './shared/services/amplitude-analytics/analytics.tokens';
import { ResultStore, ScenarioDetailType } from './stores';

@Component({
  selector: 'prosumer-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent
  extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChildren('oculus-drawer') oculusDrawer: QueryList<HTMLElement>;
  isHandset$: Observable<boolean> = this._breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(1),
    );

  toolbarHeight: number;
  version: string;
  currentAuthJwtToken: string;
  crumbs: Breadcrumb[];
  currentUrl$: Observable<string>;
  isSideNavOpened = true;
  coreVersion = new BehaviorSubject<string>('');

  // eslint-disable-next-line @typescript-eslint/naming-convention
  CORE_LINK = environment.updatesUrl;

  crumbsReferences$ = combineLatest([
    this._projectFacade.dataList$.pipe(
      // map(dataList => new Map(dataList.map(data => [data.id, data.name])))
      map((dataList) =>
        Object.assign(
          {},
          ...dataList.map((data) => ({ [data.id]: data.name })),
        ),
      ),
    ),
    this._caseFacade.dataList$.pipe(
      // map(dataList => new Map(dataList.map(data => [data.id, data.name])))
      map((dataList) =>
        Object.assign(
          {},
          ...dataList.map((data) => ({ [data.id]: data.name })),
        ),
      ),
    ),
    this._scenarioFacade.dataList$.pipe(
      // map(dataList => new Map(dataList.map(data => [data.id, data.name])))
      map((dataList) =>
        Object.assign(
          {},
          ...dataList.map((data) => ({ [data.id]: data.name })),
        ),
      ),
    ),
  ]).pipe(
    map(([projectId, caseId, scenarioId]) => ({
      projectId,
      caseId,
      scenarioId,
    })),
  );

  drawerLinks$ = new BehaviorSubject<DrawerNavLink[]>([]);
  isLinkShowing$: Observable<boolean>;

  constructor(
    private readonly exts: AppComponentExts,
    public notificationStore: NotificationStoreService,
    public pageStore: PageStore,
    public routerStore: RouterStore,
    public routePath: ProsumerRoutePathService,
    private _actions$: Actions,
    private _breakpointObserver: BreakpointObserver,
    private _config: ConfigService,
    private _contextHolderService: TooltipContextHolderService,
    private _dialogService: DialogService,
    private _domSanitizer: DomSanitizer,
    private _http: HttpService,
    private _i18n: I18NService,
    private _logger: LoggerService,
    private _matIconRegistry: MatIconRegistry,
    private _titleService: Title,
    private _translate: TranslateService,
    private _updateNotification: UpdateNotificationService,
    private _userFacade: UserFacade,
    private _websocketService: WebSocketService,
    private _turbineListHolderService: TurbineListHolderService,
    private readonly _detailGetDelegator: DetailGettingDelegator,
    /* Entities */
    private _userFacadeService: UserFacadeService,
    private _projectFacade: ProjectFacadeService,
    private _caseFacade: CaseFacadeService,
    private _scenarioFacade: ScenarioFacadeService,
    private _renewableProfileFacade: RenewableProfileFacadeService,
    private _scenarioCompareFacade: ScenarioCompareFacadeService,
    private _resultStore: ResultStore,
    private dialogService: MatDialog,
    private _coreVersionService: CoreVersionService,
    public authService: AuthService,
    public supportLinkService: SupportLinkService,
    public router: Router,
    public licensePlanService: LicensePlanService,
    @Inject(ANALYTICS_SERVICE) private analytics: AnalyticsService,
    private _cookieService: CookieService,
  ) {
    super();

    this.initSideNavLinks();
    this.pageStore.setToolbarLinks(
      getValues(FEATURES).filter(
        (feature: Feature) => feature.toolbarLink === true,
      ),
    );
    PREDEFINED_ENERGY_VECTORS.forEach((ev) =>
      this._matIconRegistry.addSvgIcon(
        ev.value,
        this._domSanitizer.bypassSecurityTrustResourceUrl(`../${ev.image}`),
      ),
    );

    DASHBOARD_ICONS.forEach(({ name, image }) => {
      this._matIconRegistry.addSvgIcon(
        name,
        this._domSanitizer.bypassSecurityTrustResourceUrl(`../${image}`),
      );
    });

    SIMULATION_ICONS.forEach(({ name, image }) => {
      this._matIconRegistry.addSvgIcon(
        name,
        this._domSanitizer.bypassSecurityTrustResourceUrl(`../${image}`),
      );
    });
  }

  ngAfterViewInit(): void {
    this.isLinkShowing$ = this.supportLinkService.initScrollDetection();
  }

  ngOnInit() {
    this.initTranslation();
    this.initRedirection();
    this.initHttp();
    this.initUpdateNotification();
    this.initRoutePage();
    this.initPageTitle();
    this.initVersion();
    this.initAuthDataDependents();
    this.initRouterUrl();
    this.initContextualHelp();
    this.initTurbineListlHelp();
    this.initGDPR();
    this.initLatestCoreVersion();
    this.initLatestScenarioVersion();
  }

  ngOnDestroy() {
    this._websocketService.disconnect();
  }

  initRouterUrl(): void {
    this.currentUrl$ = this.routerStore.url$.pipe(
      takeUntil(this.componentDestroyed$),
      shareReplay(1),
    );
  }

  initTranslation(): void {
    this._i18n.initTranslation();
  }

  initContextualHelp(): void {
    this._http.get('assets/help/contextHelp.json').subscribe(
      (data) => this._contextHolderService.setContextualData(data),
      (error) => this._contextHolderService.setContextualData(undefined),
    );
  }

  initTurbineListlHelp(): void {
    this._http.get('assets/lists/wind-turbines.json').subscribe(
      (data) => this._turbineListHolderService.setTurbineListData(data),
      (error) => this._turbineListHolderService.setTurbineListData(undefined),
    );
  }

  initRedirection(): void {
    const redirectUrl = localStorage.getItem(REDIRECT_URL_KEY);
    if (redirectUrl) {
      this.checkAuthHelper()
        .pipe(
          filter((authenticated) => authenticated),
          take(1),
        )
        .subscribe((_) => {
          localStorage.removeItem(REDIRECT_URL_KEY);
          this.router.navigateByUrl(redirectUrl);
        });
    }
  }

  checkAuthHelper(): Observable<boolean> {
    return this.authService.checkAuth$;
  }

  initHttp(): void {
    this._http.setInterruptObservable(
      this._actions$.pipe(ofType(AuthActionTypes.LOGOUT)),
    );
    this._http.setErrorCallback((error: HttpErrorResponse) => {
      this._logger.debug(error);
      return throwError(error);
    });
  }

  initUpdateNotification(): void {
    this._updateNotification.availableUpdate$
      .pipe(
        switchMap((event) => {
          this._logger.info(`Current App Hash Version: ${event.current}`);
          this._logger.info(`Available App Hash Version: ${event.available}`);
          if (event.current !== event.available) {
            return this._updateNotification.forceUpdate().pipe(map(() => true));
          }
          return of(false);
        }),
        mergeMap((isNewVersionAvailable) => {
          if (isNewVersionAvailable) {
            return this._dialogService.openDialog(BaseDialogComponent, {
              title: this._translate.instant('Generic.labels.newVersion'),
              message: this._translate.instant('Generic.messages.newVersion'),
              confirm: this._translate.instant('Generic.labels.ok'),
              width: 460,
              disableClose: true,
            });
          }
          return of(undefined);
        }),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe((response) => {
        if (response) {
          this.clearSiteData();
          this.routerStore.reload();
        }
      });
  }

  private clearSiteData() {
    this._cookieService.deleteAll();
    localStorage.clear();
    sessionStorage.clear();
  }

  initRoutePage(): void {
    this.routerStore.activeRoute$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (activeRoute) => {
          if (activeRoute && activeRoute.data) {
            this.pageStore.setBackground(activeRoute.data.background);
            this.pageStore.setName(activeRoute.data.name);
            this.pageStore.setTitle(activeRoute.data.title);
            this.crumbs = activeRoute.breadcrumbs;
          }
        },
        (error) => {},
      );
  }

  initPageTitle(): void {
    this.pageStore.title$.pipe(takeUntil(this.componentDestroyed$)).subscribe(
      (title) => this._titleService.setTitle(title),
      (error) => {},
    );
  }

  initVersion(): void {
    this.version = (this._config.version || {}).version
      ? `v${(this._config.version || {}).version}`
      : '';
  }

  initGDPR(): void {
    this.authService.user$
      .pipe(
        filter((authData) => authData !== undefined),
        take(1),
        tap(() => this._userFacade.getGDPRPolicy()),
        switchMapTo(this._userFacade.gdpr$),
        filter((gdpr) => gdpr !== undefined && !gdpr.loading && !gdpr.error),
        take(1),
        takeUntil(this.componentDestroyed$),
        mergeMap((gdpr) => {
          this._logger.debug(gdpr);
          if (
            !gdpr ||
            !gdpr.acceptedVersion ||
            gdpr.latestVersion !== gdpr.acceptedVersion
          ) {
            this._logger.debug('Showing GDPR dialog...');
            return this._dialogService
              .openDialog(PolicyDialogComponent, {
                message: gdpr.text,
                width: 800,
                disableClose: true,
                confirm: this._translate.instant(
                  'User.labels.gdpr.acceptButton',
                ),
                panelClass: 'eyes-gdpr-dialog',
              } as PolicyDialogData)
              .pipe(map(() => gdpr.latestVersion));
          } else {
            // user GDPR is NOT empty; considered as old user
            this.initSurveyForm();
          }
          return of(undefined);
        }),
      )
      .subscribe((latestVersion) => {
        if (latestVersion) {
          this._userFacade.acceptGDPRPolicy(latestVersion);
          // update prosumerFeedback so the survey will be called after 30 days
          this.updateFeedbackDate(-60);
        }
      });
  }

  initAuthDataDependents(): void {
    this.authService.user$
      .pipe(
        filter((authData) => authData !== undefined),
        take(1),
        switchMap((authData) => {
          if (
            !authData ||
            !this._config.websocket ||
            !this._config.websocket.url
          ) {
            return of(undefined);
          }
          const {
            accessToken: { jwtToken: accessJwtToken },
            idToken: { jwtToken: idJwtToken },
          } = authData.signInUserSession;
          this.initNotification();
          this._websocketService.disconnect();
          this._websocketService.connect(this._config.websocket.url, {
            params: {
              idToken: idJwtToken,
              accessToken: accessJwtToken,
              'x-correlation-id': generateUuid(),
            },
            keepAlive: this._config.websocket.keepAlive,
            keepAliveIntervalMs: this._config.websocket.keepAliveIntervalMs,
            retryOnClose: this._config.websocket.retryOnClose,
            retryOnError: this._config.websocket.retryOnError,
            maxRetryAttempts: this._config.websocket.maxRetryAttempts,
            retryScalingDuration: this._config.websocket.retryScalingDuration,
            errorHandler: (error: any) => {
              this._websocketService.logError(error);
              if (
                !this._websocketService.refreshSubscription ||
                this._websocketService.refreshSubscription.closed
              ) {
                this._websocketService.refreshSubscription =
                  this.authService.user$
                    .pipe(
                      switchMap((authData) => {
                        const {
                          accessToken: { jwtToken: accessJwtToken },
                        } = authData.signInUserSession;
                        if (this.currentAuthJwtToken !== accessJwtToken) {
                          this.currentAuthJwtToken = accessJwtToken;
                          return this.authService.refreshToken();
                        }
                        this._websocketService.retryConnectOnError();
                        return of(authData);
                      }),
                      take(1),
                      catchError((errorResponse) => {
                        this._websocketService.retryConnectOnError();
                        return throwError(errorResponse);
                      }),
                    )
                    .subscribe();
              }
            },
          });
          this._websocketService.wsObservable$
            .pipe(
              takeWhile(() => this._websocketService.isConnected()),
              takeUntil(this.componentDestroyed$),
              PipeUtils.filterOutUndefined,
              filter(({ subject, data }) => [!!subject, !!data].every(Boolean)),
            )
            .subscribe((wsData: any) => {
              if (
                !wsData.data.generated_by ||
                wsData.data.generated_by.indexOf('migration_hook') === -1
              ) {
                this.notificationStore.addNotification(wsData);
              }
              this.refreshTableInfo(wsData);
            });
          return of(true);
        }),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe();
  }

  initNotification(): void {
    this.notificationStore.fetchNotifications();
  }

  initSurveyForm(): void {
    this._userFacadeService.clientUser$
      .pipe(this.takeUntil(), take(1))
      .subscribe(({ preferences = null }) => {
        const { prosumerFeedback = null } = preferences
          ? JSON.parse(JSON.stringify(preferences))
          : {};
        const shouldDisplayFeeback = this.daysPassed(prosumerFeedback) >= 0;
        if (!prosumerFeedback || shouldDisplayFeeback) {
          this.displayFeedbackForm();
        }
      });
  }

  getState(outlet: any) {
    return outlet && outlet.isActivated ? outlet.activatedRoute : '';
  }

  generateProfileName(data: any): string {
    if (data && (data.given_name || data.family_name)) {
      return `${data.given_name || ''} ${data.family_name || ''}`;
    }
    return 'Someone';
  }

  setToolbarHeight(height: number): void {
    this.toolbarHeight = height;
  }

  onClickLogout(): void {
    this.clearSiteData();
    this.authService.logout();
    this._websocketService.disconnect();
  }

  markAsReadNotifications(notificationIds: Array<string>) {
    if (notificationIds && notificationIds.length > 0) {
      this.notificationStore.readNotifications(notificationIds);
    }
  }

  onDeleteNotif(notification: Notification) {
    this.notificationStore.removeNotification(notification);
  }

  goToNotifList() {
    this.routerStore.go(this.routePath.dashboard());
  }

  routeToNotification(notifInfo: Array<any>) {
    const notification = notifInfo[0];
    const notificationIdentity = notifInfo[1];
    let path = this.routePath.dashboard();
    let query: Record<string, string>;

    if (notificationIdentity === notification.projectId) {
      path = this.routePath.projectDetail(notification.projectId);
    } else if (notificationIdentity === notification.caseId) {
      path = this.routePath.caseDetail(
        notification.projectId,
        notification.caseId,
      );
    } else if (notificationIdentity === notification.scenarioId) {
      path = this.routePath.caseDetail(
        notification.projectId,
        notification.caseId,
      );
      if (notification.code === 200) {
        path = this.routePath.scenarioResults(
          notification.projectId,
          notification.caseId,
          notification.scenarioId,
        );
        query = notification.data?.variationUuid
          ? { variationId: notification.data?.variationUuid }
          : undefined;
        this._resultStore.clearResults();
      }
    }

    this.routerStore.go(path, query);
  }

  refreshTableInfo(wsData: any) {
    this.duplicationHookHandler(wsData);
    this.sharingHookHandler(wsData);
    this.simulationHookHandler(wsData);
    this.renewableProfileHookHandler(wsData);
    this.migrationHookHandler(wsData);
    this.getDraftInputHookHandler(wsData);
    this.importScenarioHookHandler(wsData);
    this.duplicationScenarioDetailHookHandler(wsData);
  }

  private duplicationScenarioDetailHookHandler(wsData: any) {
    if (wsData.data.generated_by === 'duplicate_scenario_details_hook') {
      const dataType = ScenarioDetailType[wsData.data.dataType];
      this._detailGetDelegator.delegateFor([dataType]);
    }
  }

  duplicationHookHandler(wsData: any) {
    if (wsData.data.generated_by === 'duplication_hook') {
      const duplicateType = wsData.data.type.split('.')[0];
      this.getData(duplicateType, wsData);
    }
  }

  sharingHookHandler(wsData: any) {
    if (wsData.data.generated_by === 'sharing_hook') {
      if (wsData.data.type.split('.')[1] === 'share') {
        const shareType = wsData.data.type.split('.')[0];
        this.getData(shareType, wsData);
      }
      if (wsData.data.type.split('.')[1] === 'unshare') {
        const shareType = wsData.data.type.split('.')[0];
        this.removeData(shareType, wsData);
      }
    }
  }

  simulationHookHandler(wsData: any) {
    if (wsData.data.generated_by === 'simulation_hook') {
      setTimeout(() => {
        if (!!wsData.data.variationUuid) {
          this._scenarioFacade.getScenarioVariations(wsData.data.scenarioUuid, {
            projectId: wsData.data.projectUuid,
            caseId: wsData.data.caseUuid,
          });
        } else {
          this._scenarioFacade.get(
            wsData.data.scenarioUuid,
            {
              projectId: wsData.data.projectUuid,
              caseId: wsData.data.caseUuid,
            },
            true,
          );
        }
      }, 2000);
    }
  }

  getDraftInputHookHandler(wsData: any) {
    if (
      wsData.data.generated_by === 'generate_input_excel' &&
      ((wsData.body || ':').split(':')[1] || '').toLowerCase().trim() ===
        'succeeded'
    ) {
      setTimeout(() => {
        const { projectUuid, caseUuid, scenarioUuid, scenarioName } =
          wsData.data;
        this._scenarioFacade.downloadDraftInput(
          {
            projectId: projectUuid,
            caseId: caseUuid,
            id: scenarioUuid,
            name: scenarioName,
            general: {
              name: scenarioName,
            },
          },
          'input',
        );
      }, 2000);
    }
  }

  renewableProfileHookHandler(wsData: any) {
    // TODO: generatedBy is not he same with other hook's generated_by
    if (wsData.data.generatedBy === 'WSSTrigger') {
      this._renewableProfileFacade.getUserRenewableProfileList();
    }
  }

  migrationHookHandler(wsData: any) {
    const activeUrl = this.routerStore.getActiveUrl();
    if (
      activeUrl.indexOf(wsData.data.projectUuid) !== -1 &&
      activeUrl.indexOf(wsData.data.caseUuid) !== -1 &&
      activeUrl.indexOf(wsData.data.scenarioUuid) !== -1
    ) {
      if (wsData.data.generated_by === 'single_migration_hook') {
        if (wsData.data.code === 200) {
          this._resultStore.getMainResults(
            wsData.data.projectUuid,
            wsData.data.caseUuid,
            wsData.data.scenarioUuid,
          );
        } else {
          this._resultStore.setFailedMigration(wsData.subject);
        }
      } else if (wsData.data.generated_by === 'multiple_migration_hook') {
        if (wsData.data.code === 200) {
          this._scenarioCompareFacade.setMigratedScenario(
            wsData.data.scenarioUuid,
            wsData.data.variationUuid,
          );
        } else {
          this._scenarioCompareFacade.setFailedMigration(wsData.subject);
        }
      }
    }
  }

  importScenarioHookHandler(wsData: any) {
    if (wsData.data.generated_by === 'scenario_import_input') {
      setTimeout(() => this.getData('scenario', wsData), 4000);
    }
  }

  getData(switchType: string, wsData: any) {
    switch (switchType.toLowerCase()) {
      case 'project':
        this._projectFacade.get(wsData.data.projectUuid);
        break;
      case 'case':
        this._caseFacade.get(wsData.data.caseUuid, {
          projectId: wsData.data.projectUuid,
        });
        break;
      case 'scenario':
        this._scenarioFacade.get(wsData.data.scenarioUuid, {
          projectId: wsData.data.projectUuid,
          caseId: wsData.data.caseUuid,
        });
        break;
      default:
        break;
    }
  }

  removeData(switchType: string, wsData: any) {
    switch (switchType.toLowerCase()) {
      case 'project':
        this._projectFacade.remove(wsData.data.projectUuid);
        break;
      case 'case':
        this._caseFacade.remove(wsData.data.caseUuid);
        break;
      case 'scenario':
        this._scenarioFacade.remove(wsData.data.scenarioUuid);
        break;
      default:
        break;
    }
  }

  onDimensionsChange(dimensions: Dimensions) {
    this.pageStore.setDimensions(dimensions);
  }

  initSideNavLinks() {
    this.exts
      .selectSideNavLinks()
      .pipe(take(1))
      .subscribe((links) => this.onNavLinksInit(links));
  }

  private onNavLinksInit(links: Feature[]): void {
    this.pageStore.setSidenavLinks(links);
    this.drawerLinks$.next(
      links.map(
        ({ icon, name, path }) =>
          ({ icon, label: name, path } as DrawerNavLink),
      ),
    );
  }

  private displayFeedbackForm() {
    this.dialogService.open(UserSurveyFormComponent, {
      width: '500px',
      disableClose: true,
      data: {
        endPoint: generateEndpoint(
          this._config.api.baseUrl,
          this._config.api.endpoints.feedback.feedback,
        ),
        userPrefEndpoint: generateEndpoint(
          this._config.api.baseUrl,
          this._config.api.endpoints.user.userPreferences,
        ),
      },
    });
  }

  /**
   *updates user prosumerFeedback user preferences
   *
   * @param dayModifier - number to adjust generated date with different day
   */
  private updateFeedbackDate(dayModifier: number = 0) {
    const endpoint = generateEndpoint(
      this._config.api.baseUrl,
      this._config.api.endpoints.user.userPreferences,
    );
    this._http
      .patch(endpoint, { requestCode: 'updateFeedbackDate', dayModifier })
      .subscribe((response) => {
        this._logger.debug('preferences updated successfully');
      });
  }

  daysPassed(
    userSetDate: string | Date,
    currentDate: string | Date = undefined,
  ) {
    const setDate = userSetDate ? new Date(userSetDate) : new Date();
    const currDate = currentDate ? new Date(currentDate) : new Date();
    const differenceInTime = currDate.getTime() - setDate.getTime();
    const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  }

  public initLatestCoreVersion() {
    this.authService.user$
      .pipe(
        filter((authData) => authData !== undefined),
        take(1),
        tap(() => {
          this._coreVersionService.getLatestVersion().subscribe((version) => {
            this.coreVersion.next(version);
          });
        }),
      )
      .subscribe();
  }

  initLatestScenarioVersion() {
    this.authService.user$
      .pipe(
        filter((authData) => authData !== undefined),
        take(1),
        tap(() => {
          this._resultStore.getLatestScenarioVersion();
        }),
      )
      .subscribe();
  }

  onClickSidebar(link) {
    const { label } = link;
    switch (label) {
      case 'Projects': {
        this.analytics.trackSidebarProjects();
        break;
      }
      default:
    }
  }

  navigateToSupportPage() {
    this.router.navigate(['/support']);
  }
}
