import {
  contains,
  FormFieldOption,
  toList,
} from 'prosumer-app/libs/eyes-shared';

import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  LibraryActions,
  LibraryActionTypes,
  libraryFeatureName,
} from './library-store.actions';
import { LibraryState } from './library-store.model';

export const libraryInitialState: LibraryState = {
  loading: false,
  error: undefined,
  library: {},
};

export const filterLibraryListForConvertersFn = (
  state: LibraryState,
  parameters: {
    type: string;
    energyVectors?: Array<any>;
  },
) => {
  if (state.library) {
    const libraryList = toList(state.library);
    const { energyVectors } = parameters;
    return !!!libraryList
      ? []
      : libraryList.filter(
          (library) =>
            (parameters.type ? library.type === parameters.type : true) &&
            (!!energyVectors
              ? library.energyVectorsIn.every((ev) =>
                  energyVectors.includes(ev),
                )
              : true) &&
            (!!energyVectors
              ? library.energyVectorsOut.every((ev) =>
                  energyVectors.includes(ev),
                )
              : true),
        );
  }
  return [];
};

/**
 * The reducer for library store
 *
 * @param state   the current state of the store
 * @param action  the action being dispatched with the type and payload
 */

export function libraryReducer(
  state: LibraryState = libraryInitialState,
  action: LibraryActions,
): LibraryState {
  switch (action.type) {
    case LibraryActionTypes.CLEAR_DATA:
      return {
        ...state,
        library: {},
        loads: {},
      };
    case LibraryActionTypes.GET:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case LibraryActionTypes.GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case LibraryActionTypes.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        library: { ...state.library, ...action.payload.library },
      };
    case LibraryActionTypes.GET_LOADS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case LibraryActionTypes.GET_LOADS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case LibraryActionTypes.GET_LOADS_SUCCESS:
      return {
        ...state,
        loading: false,
        loads: { ...state.loads, ...action.payload.loads },
      };
    default:
      return state;
  }
}

export const libraryFeature =
  createFeatureSelector<LibraryState>(libraryFeatureName);
export const librarySelectors = {
  state: libraryFeature,
  loading: createSelector(
    libraryFeature,
    (state: LibraryState) => state.loading,
  ),
  library: createSelector(
    libraryFeature,
    (state: LibraryState) => state.library,
  ),
  loads: createSelector(libraryFeature, (state: LibraryState) => state.loads),
  error: createSelector(libraryFeature, (state: LibraryState) => state.error),
  filterLibraryAsList: createSelector(
    libraryFeature,
    (
      state: LibraryState,
      parameters: {
        type: string;
        energyVector?: string;
        outputEnergyVector?: string;
        secondOutputEnergyVector?: string;
      },
    ) => {
      if (state.library) {
        const libraryList = toList(state.library);
        return !libraryList
          ? []
          : libraryList.filter(
              (library) =>
                (parameters.type ? library.type === parameters.type : true) &&
                (parameters.energyVector
                  ? library.energyVector === parameters.energyVector
                  : true) &&
                (parameters.outputEnergyVector
                  ? library.outputEnergyVector === parameters.outputEnergyVector
                  : true) &&
                (parameters.secondOutputEnergyVector
                  ? library.secondOutputEnergyVector ===
                    parameters.secondOutputEnergyVector
                  : true),
            );
      }
      return [];
    },
  ),
  filterLibraryListForConverters: createSelector(
    libraryFeature,
    filterLibraryListForConvertersFn,
  ),
  findLibraryById: createSelector(
    libraryFeature,
    (state: LibraryState, parameters: { id: string }) =>
      state.library ? state.library[parameters.id] : undefined,
  ),
  findLoadsById: createSelector(
    libraryFeature,
    (state: LibraryState, parameters: { id: string }) =>
      state.loads ? state.loads[parameters.id] : undefined,
  ),
  filterLibraryByParams: createSelector(
    libraryFeature,
    (
      state: LibraryState,
      parameters: {
        library?: Array<any>;
        vectorType?: Array<string>;
        businessType?: Array<string>;
        buildingType?: Array<string>;
        buildingCategory?: Array<string>;
        location?: Array<string>;
        energyVector?: string;
      },
    ) => {
      if (state.library) {
        const listData = toList(state.library);
        const filteredData = !listData
          ? []
          : listData.filter(
              (data) =>
                (parameters.vectorType && parameters.vectorType.length > 0
                  ? contains(parameters.vectorType, data.vectorType)
                  : true) &&
                (parameters.businessType && parameters.businessType.length > 0
                  ? contains(parameters.businessType, data.businessType)
                  : true) &&
                (parameters.buildingType && parameters.buildingType.length > 0
                  ? contains(parameters.buildingType, data.buildingType)
                  : true) &&
                (parameters.buildingCategory &&
                parameters.buildingCategory.length > 0
                  ? contains(parameters.buildingCategory, data.buildingCategory)
                  : true) &&
                (parameters.location && parameters.location.length > 0
                  ? contains(parameters.location, data.location)
                  : true) &&
                (parameters.energyVector
                  ? data.energyVector === parameters.energyVector
                  : true),
            );
        return filteredData;
      }
    },
  ),
  getLibraryFilterOptions: createSelector(
    libraryFeature,
    (
      state: LibraryState,
      parameters: {
        library?: Array<any>;
        field?:
          | 'vectorType'
          | 'businessType'
          | 'buildingType'
          | 'buildingCategory'
          | 'location';
      },
    ) => {
      if (parameters.library) {
        const filterOptions =
          !parameters.library || !parameters.field
            ? []
            : parameters.library.map((data) => data[parameters.field]);
        return [...new Set(filterOptions)].map(
          (option) =>
            ({
              name: option,
              value: option,
            } as FormFieldOption<string>),
        );
      }
    },
  ),
};
