import { type } from 'prosumer-app/libs/eyes-shared';

/* eslint-disable @typescript-eslint/naming-convention */
import { Action } from '@ngrx/store';

export const resultFeatureName = 'result';

export const ResultActionTypes = {
  GET: type(`[${resultFeatureName}] Get Results`),
  GET_FAILURE: type(`[${resultFeatureName}] Get Results Failure`),
  GET_SUCCESS: type(`[${resultFeatureName}] Get Results Success`),
  GET_SYSTEM_VISUALIZATION: type(
    `[${resultFeatureName}] Get System Visualization`,
  ),
  GET_SYSTEM_VISUALIZATION_FAILURE: type(
    `[${resultFeatureName}] Get System Visualization Failure`,
  ),
  GET_SYSTEM_VISUALIZATION_SUCCESS: type(
    `[${resultFeatureName}] Get System Visualization Success`,
  ),
  MIGRATE: type(`[${resultFeatureName}] Migrate`),
  MIGRATE_SUCCESS: type(`[${resultFeatureName}] Migrate Success`),
  MIGRATE_FAILURE: type(`[${resultFeatureName}] Migrate Failure`),
  CLEAR: type(`[${resultFeatureName}] Clear Simulation Results`),
  UPDATE_CHART_DATA: type(`[${resultFeatureName}] Update Chart Data Mapping`),
  GET_MAIN_DATA: type(`[${resultFeatureName}] Get Main`),
  GET_MAIN_DATA_SUCCESS: type(`[${resultFeatureName}] Get Main Success`),
  GET_MAIN_DATA_SUCCESS_SPLIT: type(
    `[${resultFeatureName}] Get Main Success Split`,
  ),
  GET_MAIN_DATA_FAILURE: type(`[${resultFeatureName}] Get Main Failure`),
  GET_EQUIPMENT_DATA: type(`[${resultFeatureName}] Get Equipment`),
  GET_EQUIPMENT_DATA_SUCCESS: type(
    `[${resultFeatureName}] Get Equipment Success`,
  ),
  GET_EQUIPMENT_DATA_FAILURE: type(
    `[${resultFeatureName}] Get Equipment Failure`,
  ),
  GET_TOPOLOGY_DATA: type(`[${resultFeatureName}] Get Topology`),
  GET_TOPOLOGY_DATA_SUCCESS: type(
    `[${resultFeatureName}] Get Topology Success`,
  ),
  GET_TOPOLOGY_DATA_FAILURE: type(
    `[${resultFeatureName}] Get Topology Failure`,
  ),
  GET_ENERGY_BALANCE_DATA: type(`[${resultFeatureName}] Get Energy Balance`),
  GET_ENERGY_BALANCE_DATA_SUCCESS: type(
    `[${resultFeatureName}] Get Energy Balance Success`,
  ),
  GET_ENERGY_BALANCE_DATA_FAILURE: type(
    `[${resultFeatureName}] Get Energy Balance Failure`,
  ),
  GET_CASH_FLOW_DATA: type(`[${resultFeatureName}] Get Cash Flow`),
  GET_CASH_FLOW_DATA_SUCCESS: type(
    `[${resultFeatureName}] Get Cash Flow Success`,
  ),
  GET_CASH_FLOW_DATA_FAILURE: type(
    `[${resultFeatureName}] Get Cash Flow Failure`,
  ),
  GET_CO2_EMISSIONS_DATA: type(`[${resultFeatureName}] Get CO2 Emissions`),
  GET_CO2_EMISSIONS_DATA_SUCCESS: type(
    `[${resultFeatureName}] Get CO2 Emissions Success`,
  ),
  GET_CO2_EMISSIONS_DATA_FAILURE: type(
    `[${resultFeatureName}] Get CO2 Emissions Failure`,
  ),
  GET_DISPATCH_DATA: type(`[${resultFeatureName}] Get Dispatch`),
  GET_DISPATCH_DATA_SUCCESS: type(
    `[${resultFeatureName}] Get Dispatch Success`,
  ),
  GET_DISPATCH_DATA_FAILURE: type(
    `[${resultFeatureName}] Get Dispatch Failure`,
  ),
  GET_FLOWS_DATA: type(`[${resultFeatureName}] Get Flows`),
  GET_FLOWS_DATA_SUCCESS: type(`[${resultFeatureName}] Get Flows Success`),
  GET_FLOWS_DATA_FAILURE: type(`[${resultFeatureName}] Get Flows Failure`),
  GET_LATEST_SCENARIO_VERSION: type(
    `[${resultFeatureName}] Get Latest Scenario JSON Version`,
  ),
  GET_LATEST_SCENARIO_VERSION_SUCCESS: type(
    `[${resultFeatureName}] Get Latest Scenario JSON Version Success`,
  ),
  GET_LATEST_SCENARIO_VERSION_FAILURE: type(
    `[${resultFeatureName}] Get Latest Scenario JSON Version Failure`,
  ),
};

/**
 * Dispatched action when get result has been requested
 */
export class ResultGetAction implements Action {
  readonly type = ResultActionTypes.GET;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
  };
  constructor(projectId: string, caseId: string, scenarioId: string) {
    this.payload = { projectId, caseId, scenarioId };
  }
}

/**
 * Dispatched action when get result was a success. Maps the retrieved domain model to view.
 */
export class ResultGetSuccessAction implements Action {
  readonly type = ResultActionTypes.GET_SUCCESS;
  readonly payload: { currentScenarioID: string; data: any; message: string };
  constructor(currentID: string, data: any, message: string) {
    this.payload = { currentScenarioID: currentID, data, message };
  }
}

/**
 * Dispatched action when get result was a failure. REturns the error msg retrieved from server.
 */
export class ResultGetFailureAction implements Action {
  readonly type = ResultActionTypes.GET_FAILURE;
  readonly payload: { error: string };
  constructor(error: string) {
    this.payload = { error };
  }
}

/**
 * Dispatched action when get result has been requested
 */
export class ResultGetSystemVisualizationAction implements Action {
  readonly type = ResultActionTypes.GET_SYSTEM_VISUALIZATION;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    scenarioVariationId?: string;
  };
  constructor(
    projectId: string,
    caseId: string,
    scenarioId: string,
    scenarioVariationId?: string,
  ) {
    this.payload = { projectId, caseId, scenarioId, scenarioVariationId };
  }
}

/**
 * Dispatched action when get result was a success. Maps the retrieved domain model to view.
 */
export class ResultGetSystemVisualizationSuccessAction implements Action {
  readonly type = ResultActionTypes.GET_SYSTEM_VISUALIZATION_SUCCESS;
  readonly payload: {
    currentScenarioID: string;
    systemVisualization: any;
    message: string;
  };
  constructor(currentID: string, systemVisualization: any, message: string) {
    this.payload = {
      currentScenarioID: currentID,
      systemVisualization,
      message,
    };
  }
}

/**
 * Dispatched action when get result was a failure. REturns the error msg retrieved from server.
 */
export class ResultGetSystemVisualizationFailureAction implements Action {
  readonly type = ResultActionTypes.GET_SYSTEM_VISUALIZATION_FAILURE;
  readonly payload: { errorSystemVisualization: boolean };
  constructor(errorSystemVisualization: boolean) {
    this.payload = { errorSystemVisualization };
  }
}

/**
 * Dispatched action when migrate result has been requested
 */
export class ResultMigrateAction implements Action {
  readonly type = ResultActionTypes.MIGRATE;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
  };
  constructor(projectId: string, caseId: string, scenarioId: string) {
    this.payload = { projectId, caseId, scenarioId };
  }
}

/**
 * Dispatched action when migrate result was a failure. Returns the error msg retrieved from server.
 */
export class ResultMigrateFailureAction implements Action {
  readonly type = ResultActionTypes.MIGRATE_FAILURE;
  readonly payload: { error: string };
  constructor(error: string) {
    this.payload = { error };
  }
}

/**
 * Dispatched action when migrate result was a success. Maps the retrieved domain model to view.
 */
export class ResultMigrateSuccessAction implements Action {
  readonly type = ResultActionTypes.MIGRATE_SUCCESS;
  readonly payload: { currentScenarioID: string; data: any; message: string };
  constructor(currentID: string, data: any, message: string) {
    this.payload = { currentScenarioID: currentID, data, message };
  }
}

/**
 * Dispatched action when a clear data has been requested.
 */
export class ResultClearAction implements Action {
  readonly type = ResultActionTypes.CLEAR;
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly payload: {};
  constructor() {}
}

export class UpdateChartDataAction implements Action {
  readonly type = ResultActionTypes.UPDATE_CHART_DATA;
  readonly payload: { chartId: string; chartData: any };
  constructor(chartId: string, chartData: any) {
    this.payload = { chartId, chartData };
  }
}

export class ResultsGetMainAction implements Action {
  readonly type = ResultActionTypes.GET_MAIN_DATA;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    scenarioVariationId?: string;
  };
  constructor(
    projectId: string,
    caseId: string,
    scenarioId: string,
    scenarioVariationId?: string,
  ) {
    this.payload = { projectId, caseId, scenarioId, scenarioVariationId };
  }
}

export class ResultsGetMainSuccessAction implements Action {
  readonly type = ResultActionTypes.GET_MAIN_DATA_SUCCESS;
  readonly payload: unknown;
  constructor(currentID: string, data: any, message: string) {
    this.payload = { currentScenarioID: currentID, data, message };
  }
}

export class ResultsGetMainSuccessSplitAction implements Action {
  readonly type = ResultActionTypes.GET_MAIN_DATA_SUCCESS_SPLIT;
  readonly payload: unknown;

  constructor(
    currentID: string,
    data: any,
    isOutputSplit: boolean,
    message: string,
  ) {
    this.payload = {
      currentScenarioID: currentID,
      data,
      isOutputSplit,
      message,
    };
  }
}

export class ResultsGetMainFailureAction implements Action {
  readonly type = ResultActionTypes.GET_MAIN_DATA_FAILURE;
  readonly payload: { error: string };
  constructor(error: string) {
    this.payload = { error };
  }
}

export class ResultsGetEquipmentAction implements Action {
  readonly type = ResultActionTypes.GET_EQUIPMENT_DATA;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    scenarioVariationId?: string;
  };
  constructor(
    projectId: string,
    caseId: string,
    scenarioId: string,
    scenarioVariationId?: string,
  ) {
    this.payload = { projectId, caseId, scenarioId, scenarioVariationId };
  }
}

export class ResultsGetEquipmentSuccessAction implements Action {
  readonly type = ResultActionTypes.GET_EQUIPMENT_DATA_SUCCESS;
  readonly payload: unknown;
  constructor(data: any, message: string) {
    this.payload = { data, message };
  }
}

export class ResultsGetEquipmentFailureAction implements Action {
  readonly type = ResultActionTypes.GET_EQUIPMENT_DATA_FAILURE;
  readonly payload: { error: string };
  constructor(error: string) {
    this.payload = { error };
  }
}

export class ResultsGetTopologyAction implements Action {
  readonly type = ResultActionTypes.GET_TOPOLOGY_DATA;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    scenarioVariationId?: string;
  };
  constructor(
    projectId: string,
    caseId: string,
    scenarioId: string,
    scenarioVariationId?: string,
  ) {
    this.payload = { projectId, caseId, scenarioId, scenarioVariationId };
  }
}

export class ResultsGetTopologySuccessAction implements Action {
  readonly type = ResultActionTypes.GET_TOPOLOGY_DATA_SUCCESS;
  readonly payload: unknown;
  constructor(data: any, message: string) {
    this.payload = { data, message };
  }
}

export class ResultsGetTopologyFailureAction implements Action {
  readonly type = ResultActionTypes.GET_TOPOLOGY_DATA_FAILURE;
  readonly payload: { error: string };
  constructor(error: string) {
    this.payload = { error };
  }
}

export class ResultsGetEnergyBalanceAction implements Action {
  readonly type = ResultActionTypes.GET_ENERGY_BALANCE_DATA;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    scenarioVariationId?: string;
  };
  constructor(
    projectId: string,
    caseId: string,
    scenarioId: string,
    scenarioVariationId?: string,
  ) {
    this.payload = { projectId, caseId, scenarioId, scenarioVariationId };
  }
}

export class ResultsGetEnergyBalanceSuccessAction implements Action {
  readonly type = ResultActionTypes.GET_ENERGY_BALANCE_DATA_SUCCESS;
  readonly payload: unknown;
  constructor(data: any, message: string) {
    this.payload = { data, message };
  }
}

export class ResultsGetEnergyBalanceFailureAction implements Action {
  readonly type = ResultActionTypes.GET_ENERGY_BALANCE_DATA_FAILURE;
  readonly payload: { error: string };
  constructor(error: string) {
    this.payload = { error };
  }
}

export class ResultsGetCashFlowAction implements Action {
  readonly type = ResultActionTypes.GET_CASH_FLOW_DATA;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    scenarioVariationId?: string;
  };
  constructor(
    projectId: string,
    caseId: string,
    scenarioId: string,
    scenarioVariationId?: string,
  ) {
    this.payload = { projectId, caseId, scenarioId, scenarioVariationId };
  }
}

export class ResultsGetCashFlowSuccessAction implements Action {
  readonly type = ResultActionTypes.GET_CASH_FLOW_DATA_SUCCESS;
  readonly payload: unknown;
  constructor(data: any, message: string) {
    this.payload = { data, message };
  }
}

export class ResultsGetCashFlowFailureAction implements Action {
  readonly type = ResultActionTypes.GET_CASH_FLOW_DATA_FAILURE;
  readonly payload: { error: string };
  constructor(error: string) {
    this.payload = { error };
  }
}

export class ResultsGetCO2EmissionsAction implements Action {
  readonly type = ResultActionTypes.GET_CO2_EMISSIONS_DATA;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    scenarioVariationId?: string;
  };
  constructor(
    projectId: string,
    caseId: string,
    scenarioId: string,
    scenarioVariationId?: string,
  ) {
    this.payload = { projectId, caseId, scenarioId, scenarioVariationId };
  }
}

export class ResultsGetCO2EmissionsSuccessAction implements Action {
  readonly type = ResultActionTypes.GET_CO2_EMISSIONS_DATA_SUCCESS;
  readonly payload: unknown;
  constructor(data: any, message: string) {
    this.payload = { data, message };
  }
}

export class ResultsGetCO2EmissionsFailureAction implements Action {
  readonly type = ResultActionTypes.GET_CO2_EMISSIONS_DATA_FAILURE;
  readonly payload: { error: string };
  constructor(error: string) {
    this.payload = { error };
  }
}

export class ResultsGetDispatchAction implements Action {
  readonly type = ResultActionTypes.GET_DISPATCH_DATA;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    scenarioVariationId?: string;
  };
  constructor(
    projectId: string,
    caseId: string,
    scenarioId: string,
    scenarioVariationId?: string,
  ) {
    this.payload = { projectId, caseId, scenarioId, scenarioVariationId };
  }
}

export class ResultsGetDispatchSuccessAction implements Action {
  readonly type = ResultActionTypes.GET_DISPATCH_DATA_SUCCESS;
  readonly payload: unknown;
  constructor(data: any, message: string) {
    this.payload = { data, message };
  }
}

export class ResultsGetDispatchFailureAction implements Action {
  readonly type = ResultActionTypes.GET_DISPATCH_DATA_FAILURE;
  readonly payload: { error: string };
  constructor(error: string) {
    this.payload = { error };
  }
}

export class ResultsGetFlowsAction implements Action {
  readonly type = ResultActionTypes.GET_FLOWS_DATA;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    scenarioVariationId?: string;
  };
  constructor(
    projectId: string,
    caseId: string,
    scenarioId: string,
    scenarioVariationId?: string,
  ) {
    this.payload = { projectId, caseId, scenarioId, scenarioVariationId };
  }
}

export class ResultsGetFlowsSuccessAction implements Action {
  readonly type = ResultActionTypes.GET_FLOWS_DATA_SUCCESS;
  readonly payload: unknown;
  constructor(data: any, message: string) {
    this.payload = { data, message };
  }
}

export class ResultsGetFlowsFailureAction implements Action {
  readonly type = ResultActionTypes.GET_FLOWS_DATA_FAILURE;
  readonly payload: { error: string };
  constructor(error: string) {
    this.payload = { error };
  }
}

export class ResultsGetLatestScenarioVersion implements Action {
  readonly type = ResultActionTypes.GET_LATEST_SCENARIO_VERSION;
  constructor() {}
}

export class ResultsGetLatestScenarioVersionSuccessAction implements Action {
  readonly type = ResultActionTypes.GET_LATEST_SCENARIO_VERSION_SUCCESS;
  readonly payload: { version: string };
  constructor(version: string) {
    this.payload = { version };
  }
}

export class ResultsGetLatestScenarioVersionFailureAction implements Action {
  readonly type = ResultActionTypes.GET_LATEST_SCENARIO_VERSION_FAILURE;
  readonly payload: { error: string };
  constructor(error: string) {
    this.payload = { error };
  }
}

export type ResultActions =
  | any
  | ResultGetAction
  | ResultGetSuccessAction
  | ResultGetFailureAction
  | ResultGetSystemVisualizationAction
  | ResultGetSystemVisualizationFailureAction
  | ResultGetSystemVisualizationSuccessAction
  | UpdateChartDataAction
  | ResultsGetMainAction
  | ResultsGetMainSuccessAction
  | ResultsGetMainSuccessSplitAction
  | ResultsGetMainFailureAction
  | ResultsGetEquipmentAction
  | ResultsGetEquipmentSuccessAction
  | ResultsGetEquipmentFailureAction
  | ResultsGetTopologyAction
  | ResultsGetTopologySuccessAction
  | ResultsGetTopologyFailureAction
  | ResultsGetEnergyBalanceAction
  | ResultsGetEnergyBalanceSuccessAction
  | ResultsGetEnergyBalanceFailureAction
  | ResultsGetCashFlowAction
  | ResultsGetCashFlowSuccessAction
  | ResultsGetCashFlowFailureAction
  | ResultsGetCO2EmissionsAction
  | ResultsGetCO2EmissionsSuccessAction
  | ResultsGetCO2EmissionsFailureAction
  | ResultsGetDispatchAction
  | ResultsGetDispatchSuccessAction
  | ResultsGetDispatchFailureAction
  | ResultsGetFlowsAction
  | ResultsGetFlowsSuccessAction
  | ResultsGetFlowsFailureAction
  | ResultsGetLatestScenarioVersion
  | ResultsGetLatestScenarioVersionSuccessAction
  | ResultsGetLatestScenarioVersionFailureAction;
